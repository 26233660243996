import React from 'react';
import { SendButton, SendButtonName} from './styles';

export default function() {
    
    return (

      <SendButton type='submit'>
        <SendButtonName>
            ENVIAR
        </SendButtonName>
    </SendButton>
     
    )
  }