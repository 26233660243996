export const genderChoices = [
    { value: 'Mulher Cis', label: 'Mulher Cis' },
    { value: 'Homem Cis', label: 'Homem Cis' },
    { value: 'Mulher Trans', label: 'Mulher trans' },
    { value: 'Homem Trans', label: 'Homem Trans' },
    { value: 'Não-binárie', label: 'Não-binárie' },
];

export const raceChoices = [
    { value: 'Branco', label: 'Branco' },
    { value: 'Indígena', label: 'Indígena' },
    { value: 'Pardo', label: 'Pardo' },
    { value: 'Preto', label: 'Preto' },
    { value: 'Amarelo', label: 'Amarelo' },
];

export const countryChoices = [
    { value: 'África do Sul', label: 'África do Sul' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Antígua e Barbuda', label: 'Antígua e Barbuda' },
    { value: 'Argélia', label: 'Argélia' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Aruba', label: 'Aruba' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bolívia', label: 'Bolívia' },
    { value: 'Brazil', label: 'Brasil' },
    { value: 'Cabo Verde', label: 'Cabo Verde' },
    { value: 'Camarões', label: 'Camarões' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Colômbia', label: 'Colômbia' },
    { value: 'Congo', label: 'Congo' },
    { value: 'Costa do Marfim', label: 'Costa do Marfim' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Domínica', label: 'Domínica' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equador', label: 'Equador' },
    { value: 'Gabão', label: 'Gabão' },
    { value: 'Gana', label: 'Gana' },
    { value: 'Granada', label: 'Granada' },
    { value: 'Guadalupe', label: 'Guadalupe' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guiana', label: 'Guiana' },
    { value: 'Guiana Francesa', label: 'Guiana Francesa' },
    { value: 'Guiné-Bissau', label: 'Guiné-Bissau' },
    { value: 'Guiné-Equatorial', label: 'Guiné-Equatorial' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Ilhas Caimão', label: 'Ilhas Caimão' },
    { value: 'Ilhas Turcas e Caicos', label: 'Ilhas Turcas e Caicos' },
    { value: 'Ilhas Virgens', label: 'Ilhas Virgens' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Máli', label: 'Máli' },
    { value: 'Martinica', label: 'Martinica' },
    { value: 'México', label: 'México' },
    { value: 'Moçambique', label: 'Moçambique' },
    { value: 'Namíbia', label: 'Namíbia' },
    { value: 'Nicarágua', label: 'Nicarágua' },
    { value: 'Nigéria', label: 'Nigéria' },
    { value: 'Panamá', label: 'Panamá' },
    { value: 'Paquistão', label: 'Paquistão' },
    { value: 'Paraguai', label: 'Paraguai' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Porto Rico', label: 'Porto Rico' },
    { value: 'Quênia', label: 'Quênia' },
    {
      value: 'República Democrática do Congo',
      label: 'República Democrática do Congo',
    },
    { value: 'República do Congo', label: 'República do Congo' },
    { value: 'República Dominicana', label: 'República Dominicana' },
    { value: 'Santa Lúcia', label: 'Santa Lúcia' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'São Cristóvão e Neves', label: 'São Cristóvão e Neves' },
    { value: 'São Tomé e Príncipe', label: 'São Tomé e Príncipe' },
    { value: 'São Vicente e Granadinas', label: 'São Vicente e Granadinas' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Tailândia', label: 'Tailândia' },
    { value: 'Timor Leste', label: 'Timor Leste' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Trindad e Tobago', label: 'Trindad e Tobago' },
    { value: 'Uruguai', label: 'Uruguai' },
    { value: 'Venezuela', label: 'Venezuela' },
];
