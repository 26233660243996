export const modelsCheckboxes = [
  {
    label: "Gerir Usuários",
    value: "user"
  },
  {

    label: "Gerir Grupos",
    value: "group"
  },
  {
    label: "Gerir Dashboards",
    value: "dashboard"
  },
  {
    label: "Gerir Casos da Vigilância Ativa",
    value: "vigilance"
  },
];
