export const modelsCheckboxes = [
    {
        label: "Gerir Usuários",
        value: "user"
    },
    {

        label: "Gerir Municípios",
        value: "citymanager"
    },
    {
        label: "Gerir Dicas",
        value: "content"
    },
    {
        label: "Gerir Sintomas",
        value: "symptom"
    },
    {
        label: "Gerir Síndromes",
        value: "syndrome"
    }
];