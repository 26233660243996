// Informação de Estados
export const stateOptions = [
     { value: 'ACRE', label: 'ACRE' },
     { value: 'ALAGOAS', label: 'ALAGOAS' },
     { value: 'AMAPÁ', label: 'AMAPÁ' },
     { value: 'AMAZONAS', label: 'AMAZONAS' },
     { value: 'BAHIA', label: 'BAHIA' },
     { value: 'CEARÁ', label: 'CEARÁ' },
     { value: 'DISTRITO FEDERAL', label: "DISTRITO FEDERAL" },
     { value: 'ESPÍRITO SANTO', label: 'ESPÍRITO SANTO' },
     { value: 'GOIÁS', label: 'GOIÁS' },
     { value: 'MARANHÃO', label: 'MARANHÃO' },
     { value: 'MATO GROSSO', label: 'MATO GROSSO' },
     { value: 'MATO GROSSO DO SUL', label: 'MATO GROSSO DO SUL' },
     { value: 'MINAS GERAIS', label: 'MINAS GERAIS' },
     { value: 'PARÁ', label: 'PARÁ' },
     { value: 'PARAÍBA', label: 'PARAÍBA' },
     { value: 'PARANÁ', label: 'PARANÁ' },
     { value: 'PERNAMBUCO', label: 'PERNAMBUCO' },
     { value: 'PIAUÍ', label: 'PIAUÍ' },
     { value: 'RIO DE JANEIRO', label: 'RIO DE JANEIRO' },
     { value: 'RIO GRANDE DO NORTE', label: 'RIO GRANDE DO NORTE' },
     { value: 'RIO GRANDE DO SUL', label: 'RIO GRANDE DO SUL' },
     { value: 'RONDÔNIA', label: 'RONDÔNIA' },
     { value: 'RORAIMA', label: 'RORAIMA' },
     { value: 'SANTA CATARINA', label: 'SANTA CATARINA' },
     { value: 'SÃO PAULO', label: 'SÃO PAULO' },
     { value: 'SERGIPE', label: 'SERGIPE' },
     { value: 'TOCANTINS', label: 'TOCANTINS' },
]

// Informação de Municipios
export function getCity(city) {
     let CITIES = []
     switch (city) {
          case "ACRE":
               CITIES = [
                    { value: 'ACRELÂNDIA', label: 'ACRELÂNDIA' },
                    { value: 'ASSIS BRASIL', label: 'ASSIS BRASIL' },
                    { value: 'BRASILÉIA', label: 'BRASILÉIA' },
                    { value: 'BUJARI', label: 'BUJARI' },
                    { value: 'CAPIXABA', label: 'CAPIXABA' },
                    { value: 'CRUZEIRO DO SUL', label: 'CRUZEIRO DO SUL' },
                    { value: 'EPITACIOLÂNDIA', label: 'EPITACIOLÂNDIA' },
                    { value: 'FEIJÓ', label: 'FEIJÓ' },
                    { value: 'JORDÃO', label: 'JORDÃO' },
                    { value: 'MÂNCIO LIMA', label: 'MÂNCIO LIMA' },
                    { value: 'MANOEL URBANO', label: 'MANOEL URBANO' },
                    { value: 'MARECHAL THAUMATURGO', label: 'MARECHAL THAUMATURGO' },
                    { value: 'PLÁCIDO DE CASTRO', label: 'PLÁCIDO DE CASTRO' },
                    { value: 'PORTO ACRE', label: 'PORTO ACRE' },
                    { value: 'PORTO WALTER', label: 'PORTO WALTER' },
                    { value: 'RIO BRANCO', label: 'RIO BRANCO' },
                    { value: 'RODRIGUES ALVES', label: 'RODRIGUES ALVES' },
                    { value: 'SANTA ROSA DO PURUS', label: 'SANTA ROSA DO PURUS' },
                    { value: 'SENA MADUREIRA', label: 'SENA MADUREIRA' },
                    { value: 'SENADOR GUIOMARD', label: 'SENADOR GUIOMARD' },
                    { value: 'TARAUACÁ', label: 'TARAUACÁ' },
                    { value: 'XAPURI', label: 'XAPURI' },
               ]
               break;

          case "ALAGOAS":
               CITIES = [
                    { value: 'ÁGUA BRANCA', label: 'ÁGUA BRANCA' },
                    { value: 'ANADIA', label: 'ANADIA' },
                    { value: 'ARAPIRACA', label: 'ARAPIRACA' },
                    { value: 'ATALAIA', label: 'ATALAIA' },
                    { value: 'BARRA DE SANTO ANTÔNIO', label: 'BARRA DE SANTO ANTÔNIO' },
                    { value: 'BARRA DE SÃO MIGUEL', label: 'BARRA DE SÃO MIGUEL' },
                    { value: 'BATALHA', label: 'BATALHA' },
                    { value: 'BELÉM', label: 'BELÉM' },
                    { value: 'BELO MONTE', label: 'BELO MONTE' },
                    { value: 'BOCA DA MATA', label: 'BOCA DA MATA' },
                    { value: 'BRANQUINHA', label: 'BRANQUINHA' },
                    { value: 'CACIMBINHAS', label: 'CACIMBINHAS' },
                    { value: 'CAJUEIRO', label: 'CAJUEIRO' },
                    { value: 'CAMPESTRE', label: 'CAMPESTRE' },
                    { value: 'CAMPO ALEGRE', label: 'CAMPO ALEGRE' },
                    { value: 'CAMPO GRANDE', label: 'CAMPO GRANDE' },
                    { value: 'CANAPI', label: 'CANAPI' },
                    { value: 'CAPELA', label: 'CAPELA' },
                    { value: 'CARNEIROS', label: 'CARNEIROS' },
                    { value: 'CHÃ PRETA', label: 'CHÃ PRETA' },
                    { value: 'COITÉ DO NÓIA', label: 'COITÉ DO NÓIA' },
                    { value: 'COLÔNIA LEOPOLDINA', label: 'COLÔNIA LEOPOLDINA' },
                    { value: 'COQUEIRO SECO', label: 'COQUEIRO SECO' },
                    { value: 'CORURIPE', label: 'CORURIPE' },
                    { value: 'CRAÍBAS', label: 'CRAÍBAS' },
                    { value: 'DELMIRO GOUVEIA', label: 'DELMIRO GOUVEIA' },
                    { value: 'DOIS RIACHOS', label: 'DOIS RIACHOS' },
                    { value: 'ESTRELA DE ALAGOAS', label: 'ESTRELA DE ALAGOAS' },
                    { value: 'FEIRA GRANDE', label: 'FEIRA GRANDE' },
                    { value: 'FELIZ DESERTO', label: 'FELIZ DESERTO' },
                    { value: 'FLEXEIRAS', label: 'FLEXEIRAS' },
                    { value: 'GIRAU DO PONCIANO', label: 'GIRAU DO PONCIANO' },
                    { value: 'IBATEGUARA', label: 'IBATEGUARA' },
                    { value: 'IGACI', label: 'IGACI' },
                    { value: 'IGREJA NOVA', label: 'IGREJA NOVA' },
                    { value: 'INHAPI', label: 'INHAPI' },
                    { value: 'JACARÉ DOS HOMENS', label: 'JACARÉ DOS HOMENS' },
                    { value: 'JACUÍPE', label: 'JACUÍPE' },
                    { value: 'JAPARATINGA', label: 'JAPARATINGA' },
                    { value: 'JARAMATAIA', label: 'JARAMATAIA' },
                    { value: 'JEQUIÁ DA PRAIA', label: 'JEQUIÁ DA PRAIA' },
                    { value: 'JOAQUIM GOMES', label: 'JOAQUIM GOMES' },
                    { value: 'JUNDIÁ', label: 'JUNDIÁ' },
                    { value: 'JUNQUEIRO', label: 'JUNQUEIRO' },
                    { value: 'LAGOA DA CANOA', label: 'LAGOA DA CANOA' },
                    { value: 'LIMOEIRO DE ANADIA', label: 'LIMOEIRO DE ANADIA' },
                    { value: 'MACEIÓ', label: 'MACEIÓ' },
                    { value: 'MAJOR ISIDORO', label: 'MAJOR ISIDORO' },
                    { value: 'MAR VERMELHO', label: 'MAR VERMELHO' },
                    { value: 'MARAGOGI', label: 'MARAGOGI' },
                    { value: 'MARAVILHA', label: 'MARAVILHA' },
                    { value: 'MARECHAL DEODORO', label: 'MARECHAL DEODORO' },
                    { value: 'MARIBONDO', label: 'MARIBONDO' },
                    { value: 'MATA GRANDE', label: 'MATA GRANDE' },
                    { value: 'MATRIZ DE CAMARAGIBE', label: 'MATRIZ DE CAMARAGIBE' },
                    { value: 'MESSIAS', label: 'MESSIAS' },
                    { value: 'MINADOR DO NEGRÃO', label: 'MINADOR DO NEGRÃO' },
                    { value: 'MONTEIRÓPOLIS', label: 'MONTEIRÓPOLIS' },
                    { value: 'MURICI', label: 'MURICI' },
                    { value: 'NOVO LINO', label: 'NOVO LINO' },
                    { value: "OLHO D'ÁGUA DAS FLORES", label: "OLHO D'ÁGUA DAS FLORES" },
                    { value: "OLHO D'ÁGUA DO CASADO", label: "OLHO D'ÁGUA DO CASADO" },
                    { value: "OLHO D'ÁGUA GRANDE", label: "OLHO D'ÁGUA GRANDE" },
                    { value: 'OLIVENÇA', label: 'OLIVENÇA' },
                    { value: 'OURO BRANCO', label: 'OURO BRANCO' },
                    { value: 'PALESTINA', label: 'PALESTINA' },
                    { value: 'PALMEIRA DOS ÍNDIOS', label: 'PALMEIRA DOS ÍNDIOS' },
                    { value: 'PÃO DE AÇÚCAR', label: 'PÃO DE AÇÚCAR' },
                    { value: 'PARICONHA', label: 'PARICONHA' },
                    { value: 'PARIPUEIRA', label: 'PARIPUEIRA' },
                    { value: 'PASSO DE CAMARAGIBE', label: 'PASSO DE CAMARAGIBE' },
                    { value: 'PAULO JACINTO', label: 'PAULO JACINTO' },
                    { value: 'PENEDO', label: 'PENEDO' },
                    { value: 'PIAÇABUÇU', label: 'PIAÇABUÇU' },
                    { value: 'PILAR', label: 'PILAR' },
                    { value: 'PINDOBA', label: 'PINDOBA' },
                    { value: 'PIRANHAS', label: 'PIRANHAS' },
                    { value: 'POÇO DAS TRINCHEIRAS', label: 'POÇO DAS TRINCHEIRAS' },
                    { value: 'PORTO CALVO', label: 'PORTO CALVO' },
                    { value: 'PORTO DE PEDRAS', label: 'PORTO DE PEDRAS' },
                    { value: 'PORTO REAL DO COLÉGIO', label: 'PORTO REAL DO COLÉGIO' },
                    { value: 'QUEBRANGULO', label: 'QUEBRANGULO' },
                    { value: 'RIO LARGO', label: 'RIO LARGO' },
                    { value: 'ROTEIRO', label: 'ROTEIRO' },
                    { value: 'SANTA LUZIA DO NORTE', label: 'SANTA LUZIA DO NORTE' },
                    { value: 'SANTANA DO IPANEMA', label: 'SANTANA DO IPANEMA' },
                    { value: 'SANTANA DO MUNDAÚ', label: 'SANTANA DO MUNDAÚ' },
                    { value: 'SÃO BRÁS', label: 'SÃO BRÁS' },
                    { value: 'SÃO JOSÉ DA LAJE', label: 'SÃO JOSÉ DA LAJE' },
                    { value: 'SÃO JOSÉ DA TAPERA', label: 'SÃO JOSÉ DA TAPERA' },
                    { value: 'SÃO LUÍS DO QUITUNDE', label: 'SÃO LUÍS DO QUITUNDE' },
                    { value: 'SÃO MIGUEL DOS CAMPOS', label: 'SÃO MIGUEL DOS CAMPOS' },
                    { value: 'SÃO MIGUEL DOS MILAGRES', label: 'SÃO MIGUEL DOS MILAGRES' },
                    { value: 'SÃO SEBASTIÃO', label: 'SÃO SEBASTIÃO' },
                    { value: 'SATUBA', label: 'SATUBA' },
                    { value: 'SENADOR RUI PALMEIRA', label: 'SENADOR RUI PALMEIRA' },
                    { value: "TANQUE D'ARCA", label: "TANQUE D'ARCA" },
                    { value: 'TAQUARANA', label: 'TAQUARANA' },
                    { value: 'TEOTÔNIO VILELA', label: 'TEOTÔNIO VILELA' },
                    { value: 'TRAIPU', label: 'TRAIPU' },
                    { value: 'UNIÃO DOS PALMARES', label: 'UNIÃO DOS PALMARES' },
                    { value: 'VIÇOSA', label: 'VIÇOSA' },
               ]
               break;

          case "AMAPÁ":
               CITIES = [
                    { value: "AMAPÁ", label: "AMAPÁ" },
                    { value: "CALÇOENE", label: "CALÇOENE" },
                    { value: "CUTIAS", label: "CUTIAS" },
                    { value: "FERREIRA GOMES", label: "FERREIRA GOMES" },
                    { value: "ITAUBAL", label: "ITAUBAL" },
                    { value: "LARANJAL DO JARI", label: "LARANJAL DO JARI" },
                    { value: "MACAPÁ", label: "MACAPÁ" },
                    { value: "MAZAGÃO", label: "MAZAGÃO" },
                    { value: "OIAPOQUE", label: "OIAPOQUE" },
                    { value: "PEDRA BRANCA DO AMAPARI", label: "PEDRA BRANCA DO AMAPARI" },
                    { value: "PORTO GRANDE", label: "PORTO GRANDE" },
                    { value: "PRACUÚBA", label: "PRACUÚBA" },
                    { value: "SANTANA", label: "SANTANA" },
                    { value: "SERRA DO NAVIO", label: "SERRA DO NAVIO" },
                    { value: "TARTARUGALZINHO", label: "TARTARUGALZINHO" },
                    { value: "VITÓRIA DO JARI", label: "VITÓRIA DO JARI" },
               ]
               break;

          case "AMAZONAS":
               CITIES = [
                    { value: "ALVARÃES", label: "ALVARÃES" },
                    { value: "AMATURÁ", label: "AMATURÁ" },
                    { value: "ANAMÃ", label: "ANAMÃ" },
                    { value: "ANORI", label: "ANORI" },
                    { value: "APUÍ", label: "APUÍ" },
                    { value: "ATALAIA DO NORTE", label: "ATALAIA DO NORTE" },
                    { value: "AUTAZES", label: "AUTAZES" },
                    { value: "BARCELOS", label: "BARCELOS" },
                    { value: "BARREIRINHA", label: "BARREIRINHA" },
                    { value: "BENJAMIN CONSTANT", label: "BENJAMIN CONSTANT" },
                    { value: "BERURI", label: "BERURI" },
                    { value: "BOA VISTA DO RAMOS", label: "BOA VISTA DO RAMOS" },
                    { value: "BOCA DO ACRE", label: "BOCA DO ACRE" },
                    { value: "BORBA", label: "BORBA" },
                    { value: "CAAPIRANGA", label: "CAAPIRANGA" },
                    { value: "CANUTAMA", label: "CANUTAMA" },
                    { value: "CARAUARI", label: "CARAUARI" },
                    { value: "CAREIRO", label: "CAREIRO" },
                    { value: "CAREIRO DA VÁRZEA", label: "CAREIRO DA VÁRZEA" },
                    { value: "COARI", label: "COARI" },
                    { value: "CODAJÁS", label: "CODAJÁS" },
                    { value: "EIRUNEPÉ", label: "EIRUNEPÉ" },
                    { value: "ENVIRA", label: "ENVIRA" },
                    { value: "FONTE BOA", label: "FONTE BOA" },
                    { value: "GUAJARÁ", label: "GUAJARÁ" },
                    { value: "HUMAITÁ", label: "HUMAITÁ" },
                    { value: "IPIXUNA", label: "IPIXUNA" },
                    { value: "IRANDUBA", label: "IRANDUBA" },
                    { value: "ITACOATIARA", label: "ITACOATIARA" },
                    { value: "ITAMARATI", label: "ITAMARATI" },
                    { value: "ITAPIRANGA", label: "ITAPIRANGA" },
                    { value: "JAPURÁ", label: "JAPURÁ" },
                    { value: "JURUÁ", label: "JURUÁ" },
                    { value: "JUTAÍ", label: "JUTAÍ" },
                    { value: "LÁBREA", label: "LÁBREA" },
                    { value: "MANACAPURU", label: "MANACAPURU" },
                    { value: "MANAQUIRI", label: "MANAQUIRI" },
                    { value: "MANAUS", label: "MANAUS" },
                    { value: "MANICORÉ", label: "MANICORÉ" },
                    { value: "MARAÃ", label: "MARAÃ" },
                    { value: "MAUÉS", label: "MAUÉS" },
                    { value: "NHAMUNDÁ", label: "NHAMUNDÁ" },
                    { value: "NOVA OLINDA DO NORTE", label: "NOVA OLINDA DO NORTE" },
                    { value: "NOVO AIRÃO", label: "NOVO AIRÃO" },
                    { value: "NOVO ARIPUANÃ", label: "NOVO ARIPUANÃ" },
                    { value: "PARINTINS", label: "PARINTINS" },
                    { value: "PAUINI", label: "PAUINI" },
                    { value: "PRESIDENTE FIGUEIREDO", label: "PRESIDENTE FIGUEIREDO" },
                    { value: "RIO PRETO DA EVA", label: "RIO PRETO DA EVA" },
                    { value: "SANTA ISABEL DO RIO NEGRO", label: "SANTA ISABEL DO RIO NEGRO" },
                    { value: "SANTO ANTÔNIO DO IÇÁ", label: "SANTO ANTÔNIO DO IÇÁ" },
                    { value: "SÃO GABRIEL DA CACHOEIRA", label: "SÃO GABRIEL DA CACHOEIRA" },
                    { value: "SÃO PAULO DE OLIVENÇA", label: "SÃO PAULO DE OLIVENÇA" },
                    { value: "SÃO SEBASTIÃO DO UATUMÃ", label: "SÃO SEBASTIÃO DO UATUMÃ" },
                    { value: "SILVES", label: "SILVES" },
                    { value: "TABATINGA", label: "TABATINGA" },
                    { value: "TAPAUÁ", label: "TAPAUÁ" },
                    { value: "TEFÉ", label: "TEFÉ" },
                    { value: "TONANTINS", label: "TONANTINS" },
                    { value: "UARINI", label: "UARINI" },
                    { value: "URUCARÁ", label: "URUCARÁ" },
                    { value: "URUCURITUBA", label: "URUCURITUBA" },
               ]
               break;

          case "BAHIA":
               CITIES = [
                    { value: "ABAÍRA", label: "ABAÍRA" },
                    { value: "ABARÉ", label: "ABARÉ" },
                    { value: "ACAJUTIBA", label: "ACAJUTIBA" },
                    { value: "ADUSTINA", label: "ADUSTINA" },
                    { value: "ÁGUA FRIA", label: "ÁGUA FRIA" },
                    { value: "AIQUARA", label: "AIQUARA" },
                    { value: "ALAGOINHAS", label: "ALAGOINHAS" },
                    { value: "ALCOBAÇA", label: "ALCOBAÇA" },
                    { value: "ALMADINA", label: "ALMADINA" },
                    { value: "AMARGOSA", label: "AMARGOSA" },
                    { value: "AMÉLIA RODRIGUES", label: "AMÉLIA RODRIGUES" },
                    { value: "AMÉRICA DOURADA", label: "AMÉRICA DOURADA" },
                    { value: "ANAGÉ", label: "ANAGÉ" },
                    { value: "ANDARAÍ", label: "ANDARAÍ" },
                    { value: "ANDORINHA", label: "ANDORINHA" },
                    { value: "ANGICAL", label: "ANGICAL" },
                    { value: "ANGUERA", label: "ANGUERA" },
                    { value: "ANTAS", label: "ANTAS" },
                    { value: "ANTÔNIO CARDOSO", label: "ANTÔNIO CARDOSO" },
                    { value: "ANTÔNIO GONÇALVES", label: "ANTÔNIO GONÇALVES" },
                    { value: "APORÁ", label: "APORÁ" },
                    { value: "APUAREMA", label: "APUAREMA" },
                    { value: "ARAÇAS", label: "ARAÇAS" },
                    { value: "ARACATU", label: "ARACATU" },
                    { value: "ARACI", label: "ARACI" },
                    { value: "ARAMARI", label: "ARAMARI" },
                    { value: "ARATACA", label: "ARATACA" },
                    { value: "ARATUÍPE", label: "ARATUÍPE" },
                    { value: "AURELINO LEAL", label: "AURELINO LEAL" },
                    { value: "BAIANÓPOLIS", label: "BAIANÓPOLIS" },
                    { value: "BAIXA GRANDE", label: "BAIXA GRANDE" },
                    { value: "BANZAÊ", label: "BANZAÊ" },
                    { value: "BARRA", label: "BARRA" },
                    { value: "BARRA DA ESTIVA", label: "BARRA DA ESTIVA" },
                    { value: "BARRA DO CHOÇA", label: "BARRA DO CHOÇA" },
                    { value: "BARRA DO MENDES", label: "BARRA DO MENDES" },
                    { value: "BARRA DO ROCHA", label: "BARRA DO ROCHA" },
                    { value: "BARREIRAS", label: "BARREIRAS" },
                    { value: "BARRO ALTO", label: "BARRO ALTO" },
                    { value: "BARRO PRETO", label: "BARRO PRETO" },
                    { value: "BARROCAS", label: "BARROCAS" },
                    { value: "BELMONTE", label: "BELMONTE" },
                    { value: "BELO CAMPO", label: "BELO CAMPO" },
                    { value: "BIRITINGA", label: "BIRITINGA" },
                    { value: "BOA NOVA", label: "BOA NOVA" },
                    { value: "BOA VISTA DO TUPIM", label: "BOA VISTA DO TUPIM" },
                    { value: "BOM JESUS DA LAPA", label: "BOM JESUS DA LAPA" },
                    { value: "BOM JESUS DA SERRA", label: "BOM JESUS DA SERRA" },
                    { value: "BONINAL", label: "BONINAL" },
                    { value: "BONITO", label: "BONITO" },
                    { value: "BOQUIRA", label: "BOQUIRA" },
                    { value: "BOTUPORÃ", label: "BOTUPORÃ" },
                    { value: "BREJÕES", label: "BREJÕES" },
                    { value: "BREJOLÂNDIA", label: "BREJOLÂNDIA" },
                    { value: "BROTAS DE MACAÚBAS", label: "BROTAS DE MACAÚBAS" },
                    { value: "BRUMADO", label: "BRUMADO" },
                    { value: "BUERAREMA", label: "BUERAREMA" },
                    { value: "BURITIRAMA", label: "BURITIRAMA" },
                    { value: "CAATIBA", label: "CAATIBA" },
                    { value: "CABACEIRAS DO PARAGUAÇU", label: "CABACEIRAS DO PARAGUAÇU" },
                    { value: "CACHOEIRA", label: "CACHOEIRA" },
                    { value: "CACULÉ", label: "CACULÉ" },
                    { value: "CAÉM", label: "CAÉM" },
                    { value: "CAETANOS", label: "CAETANOS" },
                    { value: "CAETITÉ", label: "CAETITÉ" },
                    { value: "CAFARNAUM", label: "CAFARNAUM" },
                    { value: "CAIRU", label: "CAIRU" },
                    { value: "CALDEIRÃO GRANDE", label: "CALDEIRÃO GRANDE" },
                    { value: "CAMACAN", label: "CAMACAN" },
                    { value: "CAMAÇARI", label: "CAMAÇARI" },
                    { value: "CAMAMU", label: "CAMAMU" },
                    { value: "CAMPO ALEGRE DE LOURDES", label: "CAMPO ALEGRE DE LOURDES" },
                    { value: "CAMPO FORMOSO", label: "CAMPO FORMOSO" },
                    { value: "CANÁPOLIS", label: "CANÁPOLIS" },
                    { value: "CANARANA", label: "CANARANA" },
                    { value: "CANAVIEIRAS", label: "CANAVIEIRAS" },
                    { value: "CANDEAL", label: "CANDEAL" },
                    { value: "CANDEIAS", label: "CANDEIAS" },
                    { value: "CANDIBA", label: "CANDIBA" },
                    { value: "CÂNDIDO SALES", label: "CÂNDIDO SALES" },
                    { value: "CANSANÇÃO", label: "CANSANÇÃO" },
                    { value: "CANUDOS", label: "CANUDOS" },
                    { value: "CAPELA DO ALTO ALEGRE", label: "CAPELA DO ALTO ALEGRE" },
                    { value: "CAPIM GROSSO", label: "CAPIM GROSSO" },
                    { value: "CARAÍBAS", label: "CARAÍBAS" },
                    { value: "CARAVELAS", label: "CARAVELAS" },
                    { value: "CARDEAL DA SILVA", label: "CARDEAL DA SILVA" },
                    { value: "CARINHANHA", label: "CARINHANHA" },
                    { value: "CASA NOVA", label: "CASA NOVA" },
                    { value: "CASTRO ALVES", label: "CASTRO ALVES" },
                    { value: "CATOLÂNDIA", label: "CATOLÂNDIA" },
                    { value: "CATU", label: "CATU" },
                    { value: "CATURAMA", label: "CATURAMA" },
                    { value: "CENTRAL", label: "CENTRAL" },
                    { value: "CHORROCHÓ", label: "CHORROCHÓ" },
                    { value: "CÍCERO DANTAS", label: "CÍCERO DANTAS" },
                    { value: "CIPÓ", label: "CIPÓ" },
                    { value: "COARACI", label: "COARACI" },
                    { value: "COCOS", label: "COCOS" },
                    { value: "CONCEIÇÃO DA FEIRA", label: "CONCEIÇÃO DA FEIRA" },
                    { value: "CONCEIÇÃO DO ALMEIDA", label: "CONCEIÇÃO DO ALMEIDA" },
                    { value: "CONCEIÇÃO DO COITÉ", label: "CONCEIÇÃO DO COITÉ" },
                    { value: "CONCEIÇÃO DO JACUÍPE", label: "CONCEIÇÃO DO JACUÍPE" },
                    { value: "CONDE", label: "CONDE" },
                    { value: "CONDEÚBA", label: "CONDEÚBA" },
                    { value: "CONTENDAS DO SINCORÁ", label: "CONTENDAS DO SINCORÁ" },
                    { value: "CORAÇÃO DE MARIA", label: "CORAÇÃO DE MARIA" },
                    { value: "CORDEIROS", label: "CORDEIROS" },
                    { value: "CORIBE", label: "CORIBE" },
                    { value: "CORONEL JOÃO SÁ", label: "CORONEL JOÃO SÁ" },
                    { value: "CORRENTINA", label: "CORRENTINA" },
                    { value: "COTEGIPE", label: "COTEGIPE" },
                    { value: "CRAVOLÂNDIA", label: "CRAVOLÂNDIA" },
                    { value: "CRISÓPOLIS", label: "CRISÓPOLIS" },
                    { value: "CRISTÓPOLIS", label: "CRISTÓPOLIS" },
                    { value: "CRUZ DAS ALMAS", label: "CRUZ DAS ALMAS" },
                    { value: "CURAÇÁ", label: "CURAÇÁ" },
                    { value: "DÁRIO MEIRA", label: "DÁRIO MEIRA" },
                    { value: "DIAS D'ÁVILA", label: "DIAS D'ÁVILA" },
                    { value: "DOM BASÍLIO", label: "DOM BASÍLIO" },
                    { value: "DOM MACEDO COSTA", label: "DOM MACEDO COSTA" },
                    { value: "ELÍSIO MEDRADO", label: "ELÍSIO MEDRADO" },
                    { value: "ENCRUZILHADA", label: "ENCRUZILHADA" },
                    { value: "ENTRE RIOS", label: "ENTRE RIOS" },
                    { value: "ÉRICO CARDOSO", label: "ÉRICO CARDOSO" },
                    { value: "ESPLANADA", label: "ESPLANADA" },
                    { value: "EUCLIDES DA CUNHA", label: "EUCLIDES DA CUNHA" },
                    { value: "EUNÁPOLIS", label: "EUNÁPOLIS" },
                    { value: "FÁTIMA", label: "FÁTIMA" },
                    { value: "FEIRA DA MATA", label: "FEIRA DA MATA" },
                    { value: "FEIRA DE SANTANA", label: "FEIRA DE SANTANA" },
                    { value: "FILADÉLFIA", label: "FILADÉLFIA" },
                    { value: "FIRMINO ALVES", label: "FIRMINO ALVES" },
                    { value: "FLORESTA AZUL", label: "FLORESTA AZUL" },
                    { value: "FORMOSA DO RIO PRETO", label: "FORMOSA DO RIO PRETO" },
                    { value: "GANDU", label: "GANDU" },
                    { value: "GAVIÃO", label: "GAVIÃO" },
                    { value: "GENTIO DO OURO", label: "GENTIO DO OURO" },
                    { value: "GLÓRIA", label: "GLÓRIA" },
                    { value: "GONGOGI", label: "GONGOGI" },
                    { value: "GOVERNADOR MANGABEIRA", label: "GOVERNADOR MANGABEIRA" },
                    { value: "GUAJERU", label: "GUAJERU" },
                    { value: "GUANAMBI", label: "GUANAMBI" },
                    { value: "GUARATINGA", label: "GUARATINGA" },
                    { value: "HELIÓPOLIS", label: "HELIÓPOLIS" },
                    { value: "IAÇU", label: "IAÇU" },
                    { value: "IBIASSUCÊ", label: "IBIASSUCÊ" },
                    { value: "IBICARAÍ", label: "IBICARAÍ" },
                    { value: "IBICOARA", label: "IBICOARA" },
                    { value: "IBICUÍ", label: "IBICUÍ" },
                    { value: "IBIPEBA", label: "IBIPEBA" },
                    { value: "IBIPITANGA", label: "IBIPITANGA" },
                    { value: "IBIQUERA", label: "IBIQUERA" },
                    { value: "IBIRAPITANGA", label: "IBIRAPITANGA" },
                    { value: "IBIRAPUÃ", label: "IBIRAPUÃ" },
                    { value: "IBIRATAIA", label: "IBIRATAIA" },
                    { value: "IBITIARA", label: "IBITIARA" },
                    { value: "IBITITÁ", label: "IBITITÁ" },
                    { value: "IBOTIRAMA", label: "IBOTIRAMA" },
                    { value: "ICHU", label: "ICHU" },
                    { value: "IGAPORÃ", label: "IGAPORÃ" },
                    { value: "IGRAPIÚNA", label: "IGRAPIÚNA" },
                    { value: "IGUAÍ", label: "IGUAÍ" },
                    { value: "ILHÉUS", label: "ILHÉUS" },
                    { value: "INHAMBUPE", label: "INHAMBUPE" },
                    { value: "IPECAETÁ", label: "IPECAETÁ" },
                    { value: "IPIAÚ", label: "IPIAÚ" },
                    { value: "IPIRÁ", label: "IPIRÁ" },
                    { value: "IPUPIARA", label: "IPUPIARA" },
                    { value: "IRAJUBA", label: "IRAJUBA" },
                    { value: "IRAMAIA", label: "IRAMAIA" },
                    { value: "IRAQUARA", label: "IRAQUARA" },
                    { value: "IRARÁ", label: "IRARÁ" },
                    { value: "IRECÊ", label: "IRECÊ" },
                    { value: "ITABELA", label: "ITABELA" },
                    { value: "ITABERABA", label: "ITABERABA" },
                    { value: "ITABUNA", label: "ITABUNA" },
                    { value: "ITACARÉ", label: "ITACARÉ" },
                    { value: "ITAETÉ", label: "ITAETÉ" },
                    { value: "ITAGI", label: "ITAGI" },
                    { value: "ITAGIBÁ", label: "ITAGIBÁ" },
                    { value: "ITAGIMIRIM", label: "ITAGIMIRIM" },
                    { value: "ITAGUAÇU DA BAHIA", label: "ITAGUAÇU DA BAHIA" },
                    { value: "ITAJU DO COLÔNIA", label: "ITAJU DO COLÔNIA" },
                    { value: "ITAJUÍPE", label: "ITAJUÍPE" },
                    { value: "ITAMARAJU", label: "ITAMARAJU" },
                    { value: "ITAMARI", label: "ITAMARI" },
                    { value: "ITAMBÉ", label: "ITAMBÉ" },
                    { value: "ITANAGRA", label: "ITANAGRA" },
                    { value: "ITANHÉM", label: "ITANHÉM" },
                    { value: "ITAPARICA", label: "ITAPARICA" },
                    { value: "ITAPÉ", label: "ITAPÉ" },
                    { value: "ITAPEBI", label: "ITAPEBI" },
                    { value: "ITAPETINGA", label: "ITAPETINGA" },
                    { value: "ITAPICURU", label: "ITAPICURU" },
                    { value: "ITAPITANGA", label: "ITAPITANGA" },
                    { value: "ITAQUARA", label: "ITAQUARA" },
                    { value: "ITARANTIM", label: "ITARANTIM" },
                    { value: "ITATIM", label: "ITATIM" },
                    { value: "ITIRUÇU", label: "ITIRUÇU" },
                    { value: "ITIÚBA", label: "ITIÚBA" },
                    { value: "ITORORÓ", label: "ITORORÓ" },
                    { value: "ITUAÇU", label: "ITUAÇU" },
                    { value: "ITUBERÁ", label: "ITUBERÁ" },
                    { value: "IUIÚ", label: "IUIÚ" },
                    { value: "JABORANDI", label: "JABORANDI" },
                    { value: "JACARACI", label: "JACARACI" },
                    { value: "JACOBINA", label: "JACOBINA" },
                    { value: "JAGUAQUARA", label: "JAGUAQUARA" },
                    { value: "JAGUARARI", label: "JAGUARARI" },
                    { value: "JAGUARIPE", label: "JAGUARIPE" },
                    { value: "JANDAÍRA", label: "JANDAÍRA" },
                    { value: "JEQUIÉ", label: "JEQUIÉ" },
                    { value: "JEREMOABO", label: "JEREMOABO" },
                    { value: "JIQUIRIÇÁ", label: "JIQUIRIÇÁ" },
                    { value: "JITAÚNA", label: "JITAÚNA" },
                    { value: "JOÃO DOURADO", label: "JOÃO DOURADO" },
                    { value: "JUAZEIRO", label: "JUAZEIRO" },
                    { value: "JUCURUÇU", label: "JUCURUÇU" },
                    { value: "JUSSARA", label: "JUSSARA" },
                    { value: "JUSSARI", label: "JUSSARI" },
                    { value: "JUSSIAPE", label: "JUSSIAPE" },
                    { value: "LAFAIETE COUTINHO", label: "LAFAIETE COUTINHO" },
                    { value: "LAGOA REAL", label: "LAGOA REAL" },
                    { value: "LAJE", label: "LAJE" },
                    { value: "LAJEDÃO", label: "LAJEDÃO" },
                    { value: "LAJEDINHO", label: "LAJEDINHO" },
                    { value: "LAJEDO DO TABOCAL", label: "LAJEDO DO TABOCAL" },
                    { value: "LAMARÃO", label: "LAMARÃO" },
                    { value: "LAPÃO", label: "LAPÃO" },
                    { value: "LAURO DE FREITAS", label: "LAURO DE FREITAS" },
                    { value: "LENÇÓIS", label: "LENÇÓIS" },
                    { value: "LICÍNIO DE ALMEIDA", label: "LICÍNIO DE ALMEIDA" },
                    { value: "LIVRAMENTO DE NOSSA SENHORA", label: "LIVRAMENTO DE NOSSA SENHORA" },
                    { value: "LUÍS EDUARDO MAGALHÃES", label: "LUÍS EDUARDO MAGALHÃES" },
                    { value: "MACAJUBA", label: "MACAJUBA" },
                    { value: "MACARANI", label: "MACARANI" },
                    { value: "MACAÚBAS", label: "MACAÚBAS" },
                    { value: "MACURURÉ", label: "MACURURÉ" },
                    { value: "MADRE DE DEUS", label: "MADRE DE DEUS" },
                    { value: "MAETINGA", label: "MAETINGA" },
                    { value: "MAIQUINIQUE", label: "MAIQUINIQUE" },
                    { value: "MAIRI", label: "MAIRI" },
                    { value: "MALHADA", label: "MALHADA" },
                    { value: "MALHADA DE PEDRAS", label: "MALHADA DE PEDRAS" },
                    { value: "MANOEL VITORINO", label: "MANOEL VITORINO" },
                    { value: "MANSIDÃO", label: "MANSIDÃO" },
                    { value: "MARACÁS", label: "MARACÁS" },
                    { value: "MARAGOGIPE", label: "MARAGOGIPE" },
                    { value: "MARAÚ", label: "MARAÚ" },
                    { value: "MARCIONÍLIO SOUZA", label: "MARCIONÍLIO SOUZA" },
                    { value: "MASCOTE", label: "MASCOTE" },
                    { value: "MATA DE SÃO JOÃO", label: "MATA DE SÃO JOÃO" },
                    { value: "MATINA", label: "MATINA" },
                    { value: "MEDEIROS NETO", label: "MEDEIROS NETO" },
                    { value: "MIGUEL CALMON", label: "MIGUEL CALMON" },
                    { value: "MILAGRES", label: "MILAGRES" },
                    { value: "MIRANGABA", label: "MIRANGABA" },
                    { value: "MIRANTE", label: "MIRANTE" },
                    { value: "MONTE SANTO", label: "MONTE SANTO" },
                    { value: "MORPARÁ", label: "MORPARÁ" },
                    { value: "MORRO DO CHAPÉU", label: "MORRO DO CHAPÉU" },
                    { value: "MORTUGABA", label: "MORTUGABA" },
                    { value: "MUCUGÊ", label: "MUCUGÊ" },
                    { value: "MUCURI", label: "MUCURI" },
                    { value: "MULUNGU DO MORRO", label: "MULUNGU DO MORRO" },
                    { value: "MUNDO NOVO", label: "MUNDO NOVO" },
                    { value: "MUNIZ FERREIRA", label: "MUNIZ FERREIRA" },
                    { value: "MUQUÉM DE SÃO FRANCISCO", label: "MUQUÉM DE SÃO FRANCISCO" },
                    { value: "MURITIBA", label: "MURITIBA" },
                    { value: "MUTUÍPE", label: "MUTUÍPE" },
                    { value: "NAZARÉ", label: "NAZARÉ" },
                    { value: "NILO PEÇANHA", label: "NILO PEÇANHA" },
                    { value: "NORDESTINA", label: "NORDESTINA" },
                    { value: "NOVA CANAÃ", label: "NOVA CANAÃ" },
                    { value: "NOVA FÁTIMA", label: "NOVA FÁTIMA" },
                    { value: "NOVA IBIÁ", label: "NOVA IBIÁ" },
                    { value: "NOVA ITARANA", label: "NOVA ITARANA" },
                    { value: "NOVA REDENÇÃO", label: "NOVA REDENÇÃO" },
                    { value: "NOVA SOURE", label: "NOVA SOURE" },
                    { value: "NOVA VIÇOSA", label: "NOVA VIÇOSA" },
                    { value: "NOVO HORIZONTE", label: "NOVO HORIZONTE" },
                    { value: "NOVO TRIUNFO", label: "NOVO TRIUNFO" },
                    { value: "OLINDINA", label: "OLINDINA" },
                    { value: "OLIVEIRA DOS BREJINHOS", label: "OLIVEIRA DOS BREJINHOS" },
                    { value: "OURIÇANGAS", label: "OURIÇANGAS" },
                    { value: "OUROLÂNDIA", label: "OUROLÂNDIA" },
                    { value: "PALMAS DE MONTE ALTO", label: "PALMAS DE MONTE ALTO" },
                    { value: "PALMEIRAS", label: "PALMEIRAS" },
                    { value: "PARAMIRIM", label: "PARAMIRIM" },
                    { value: "PARATINGA", label: "PARATINGA" },
                    { value: "PARIPIRANGA", label: "PARIPIRANGA" },
                    { value: "PAU BRASIL", label: "PAU BRASIL" },
                    { value: "PAULO AFONSO", label: "PAULO AFONSO" },
                    { value: "PÉ DE SERRA", label: "PÉ DE SERRA" },
                    { value: "PEDRÃO", label: "PEDRÃO" },
                    { value: "PEDRO ALEXANDRE", label: "PEDRO ALEXANDRE" },
                    { value: "PIATÃ", label: "PIATÃ" },
                    { value: "PILÃO ARCADO", label: "PILÃO ARCADO" },
                    { value: "PINDAÍ", label: "PINDAÍ" },
                    { value: "PINDOBAÇU", label: "PINDOBAÇU" },
                    { value: "PINTADAS", label: "PINTADAS" },
                    { value: "PIRAÍ DO NORTE", label: "PIRAÍ DO NORTE" },
                    { value: "PIRIPÁ", label: "PIRIPÁ" },
                    { value: "PIRITIBA", label: "PIRITIBA" },
                    { value: "PLANALTINO", label: "PLANALTINO" },
                    { value: "PLANALTO", label: "PLANALTO" },
                    { value: "POÇÕES", label: "POÇÕES" },
                    { value: "POJUCA", label: "POJUCA" },
                    { value: "PONTO NOVO", label: "PONTO NOVO" },
                    { value: "PORTO SEGURO", label: "PORTO SEGURO" },
                    { value: "POTIRAGUÁ", label: "POTIRAGUÁ" },
                    { value: "PRADO", label: "PRADO" },
                    { value: "PRESIDENTE DUTRA", label: "PRESIDENTE DUTRA" },
                    { value: "PRESIDENTE JÂNIO QUADROS", label: "PRESIDENTE JÂNIO QUADROS" },
                    { value: "PRESIDENTE TANCREDO NEVES", label: "PRESIDENTE TANCREDO NEVES" },
                    { value: "QUEIMADAS", label: "QUEIMADAS" },
                    { value: "QUIJINGUE", label: "QUIJINGUE" },
                    { value: "QUIXABEIRA", label: "QUIXABEIRA" },
                    { value: "RAFAEL JAMBEIRO", label: "RAFAEL JAMBEIRO" },
                    { value: "REMANSO", label: "REMANSO" },
                    { value: "RETIROLÂNDIA", label: "RETIROLÂNDIA" },
                    { value: "RIACHÃO DAS NEVES", label: "RIACHÃO DAS NEVES" },
                    { value: "RIACHÃO DO JACUÍPE", label: "RIACHÃO DO JACUÍPE" },
                    { value: "RIACHO DE SANTANA", label: "RIACHO DE SANTANA" },
                    { value: "RIBEIRA DO AMPARO", label: "RIBEIRA DO AMPARO" },
                    { value: "RIBEIRA DO POMBAL", label: "RIBEIRA DO POMBAL" },
                    { value: "RIBEIRÃO DO LARGO", label: "RIBEIRÃO DO LARGO" },
                    { value: "RIO DE CONTAS", label: "RIO DE CONTAS" },
                    { value: "RIO DO ANTÔNIO", label: "RIO DO ANTÔNIO" },
                    { value: "RIO DO PIRES", label: "RIO DO PIRES" },
                    { value: "RIO REAL", label: "RIO REAL" },
                    { value: "RODELAS", label: "RODELAS" },
                    { value: "RUY BARBOSA", label: "RUY BARBOSA" },
                    { value: "SALINAS DA MARGARIDA", label: "SALINAS DA MARGARIDA" },
                    { value: "SALVADOR", label: "SALVADOR" },
                    { value: "SANTA BÁRBARA", label: "SANTA BÁRBARA" },
                    { value: "SANTA BRÍGIDA", label: "SANTA BRÍGIDA" },
                    { value: "SANTA CRUZ CABRÁLIA", label: "SANTA CRUZ CABRÁLIA" },
                    { value: "SANTA CRUZ DA VITÓRIA", label: "SANTA CRUZ DA VITÓRIA" },
                    { value: "SANTA INÊS", label: "SANTA INÊS" },
                    { value: "SANTA LUZIA", label: "SANTA LUZIA" },
                    { value: "SANTA MARIA DA VITÓRIA", label: "SANTA MARIA DA VITÓRIA" },
                    { value: "SANTA RITA DE CÁSSIA", label: "SANTA RITA DE CÁSSIA" },
                    { value: "SANTA TERESINHA", label: "SANTA TERESINHA" },
                    { value: "SANTALUZ", label: "SANTALUZ" },
                    { value: "SANTANA", label: "SANTANA" },
                    { value: "SANTANÓPOLIS", label: "SANTANÓPOLIS" },
                    { value: "SANTO AMARO", label: "SANTO AMARO" },
                    { value: "SANTO ANTÔNIO DE JESUS", label: "SANTO ANTÔNIO DE JESUS" },
                    { value: "SANTO ESTÊVÃO", label: "SANTO ESTÊVÃO" },
                    { value: "SÃO DESIDÉRIO", label: "SÃO DESIDÉRIO" },
                    { value: "SÃO DOMINGOS", label: "SÃO DOMINGOS" },
                    { value: "SÃO FELIPE", label: "SÃO FELIPE" },
                    { value: "SÃO FÉLIX", label: "SÃO FÉLIX" },
                    { value: "SÃO FÉLIX DO CORIBE", label: "SÃO FÉLIX DO CORIBE" },
                    { value: "SÃO FRANCISCO DO CONDE", label: "SÃO FRANCISCO DO CONDE" },
                    { value: "SÃO GABRIEL", label: "SÃO GABRIEL" },
                    { value: "SÃO GONÇALO DOS CAMPOS", label: "SÃO GONÇALO DOS CAMPOS" },
                    { value: "SÃO JOSÉ DA VITÓRIA", label: "SÃO JOSÉ DA VITÓRIA" },
                    { value: "SÃO JOSÉ DO JACUÍPE", label: "SÃO JOSÉ DO JACUÍPE" },
                    { value: "SÃO MIGUEL DAS MATAS", label: "SÃO MIGUEL DAS MATAS" },
                    { value: "SÃO SEBASTIÃO DO PASSÉ", label: "SÃO SEBASTIÃO DO PASSÉ" },
                    { value: "SAPEAÇU", label: "SAPEAÇU" },
                    { value: "SÁTIRO DIAS", label: "SÁTIRO DIAS" },
                    { value: "SAUBARA", label: "SAUBARA" },
                    { value: "SAÚDE", label: "SAÚDE" },
                    { value: "SEABRA", label: "SEABRA" },
                    { value: "SEBASTIÃO LARANJEIRAS", label: "SEBASTIÃO LARANJEIRAS" },
                    { value: "SENHOR DO BONFIM", label: "SENHOR DO BONFIM" },
                    { value: "SENTO SÉ", label: "SENTO SÉ" },
                    { value: "SERRA DO RAMALHO", label: "SERRA DO RAMALHO" },
                    { value: "SERRA DOURADA", label: "SERRA DOURADA" },
                    { value: "SERRA PRETA", label: "SERRA PRETA" },
                    { value: "SERRINHA", label: "SERRINHA" },
                    { value: "SERROLÂNDIA", label: "SERROLÂNDIA" },
                    { value: "SIMÕES FILHO", label: "SIMÕES FILHO" },
                    { value: "SÍTIO DO MATO", label: "SÍTIO DO MATO" },
                    { value: "SÍTIO DO QUINTO", label: "SÍTIO DO QUINTO" },
                    { value: "SOBRADINHO", label: "SOBRADINHO" },
                    { value: "SOUTO SOARES", label: "SOUTO SOARES" },
                    { value: "TABOCAS DO BREJO VELHO", label: "TABOCAS DO BREJO VELHO" },
                    { value: "TANHAÇU", label: "TANHAÇU" },
                    { value: "TANQUE NOVO", label: "TANQUE NOVO" },
                    { value: "TANQUINHO", label: "TANQUINHO" },
                    { value: "TAPEROÁ", label: "TAPEROÁ" },
                    { value: "TAPIRAMUTÁ", label: "TAPIRAMUTÁ" },
                    { value: "TEIXEIRA DE FREITAS", label: "TEIXEIRA DE FREITAS" },
                    { value: "TEODORO SAMPAIO", label: "TEODORO SAMPAIO" },
                    { value: "TEOFILÂNDIA", label: "TEOFILÂNDIA" },
                    { value: "TEOLÂNDIA", label: "TEOLÂNDIA" },
                    { value: "TERRA NOVA", label: "TERRA NOVA" },
                    { value: "TREMEDAL", label: "TREMEDAL" },
                    { value: "TUCANO", label: "TUCANO" },
                    { value: "UAUÁ", label: "UAUÁ" },
                    { value: "UBAÍRA", label: "UBAÍRA" },
                    { value: "UBAITABA", label: "UBAITABA" },
                    { value: "UBATÃ", label: "UBATÃ" },
                    { value: "UIBAÍ", label: "UIBAÍ" },
                    { value: "UMBURANAS", label: "UMBURANAS" },
                    { value: "UNA", label: "UNA" },
                    { value: "URANDI", label: "URANDI" },
                    { value: "URUÇUCA", label: "URUÇUCA" },
                    { value: "UTINGA", label: "UTINGA" },
                    { value: "VALENÇA", label: "VALENÇA" },
                    { value: "VALENTE", label: "VALENTE" },
                    { value: "VÁRZEA DA ROÇA", label: "VÁRZEA DA ROÇA" },
                    { value: "VÁRZEA DO POÇO", label: "VÁRZEA DO POÇO" },
                    { value: "VÁRZEA NOVA", label: "VÁRZEA NOVA" },
                    { value: "VARZEDO", label: "VARZEDO" },
                    { value: "VERA CRUZ", label: "VERA CRUZ" },
                    { value: "VEREDA", label: "VEREDA" },
                    { value: "VITÓRIA DA CONQUISTA", label: "VITÓRIA DA CONQUISTA" },
                    { value: "WAGNER", label: "WAGNER" },
                    { value: "WANDERLEY", label: "WANDERLEY" },
                    { value: "WENCESLAU GUIMARÃES", label: "WENCESLAU GUIMARÃES" },
                    { value: "XIQUE-XIQUE", label: "XIQUE-XIQUE" },
               ]
               break;

          case "CEARÁ":
               CITIES = [
                    { value: "ABAIARA", label: "ABAIARA" },
                    { value: "ACARAPE", label: "ACARAPE" },
                    { value: "ACARAÚ", label: "ACARAÚ" },
                    { value: "ACOPIARA", label: "ACOPIARA" },
                    { value: "AIUABA", label: "AIUABA" },
                    { value: "ALCÂNTARAS", label: "ALCÂNTARAS" },
                    { value: "ALTANEIRA", label: "ALTANEIRA" },
                    { value: "ALTO SANTO", label: "ALTO SANTO" },
                    { value: "AMONTADA", label: "AMONTADA" },
                    { value: "ANTONINA DO NORTE", label: "ANTONINA DO NORTE" },
                    { value: "APUIARÉS", label: "APUIARÉS" },
                    { value: "AQUIRAZ", label: "AQUIRAZ" },
                    { value: "ARACATI", label: "ARACATI" },
                    { value: "ARACOIABA", label: "ARACOIABA" },
                    { value: "ARARENDÁ", label: "ARARENDÁ" },
                    { value: "ARARIPE", label: "ARARIPE" },
                    { value: "ARATUBA", label: "ARATUBA" },
                    { value: "ARNEIROZ", label: "ARNEIROZ" },
                    { value: "ASSARÉ", label: "ASSARÉ" },
                    { value: "AURORA", label: "AURORA" },
                    { value: "BAIXIO", label: "BAIXIO" },
                    { value: "BANABUIÚ", label: "BANABUIÚ" },
                    { value: "BARBALHA", label: "BARBALHA" },
                    { value: "BARREIRA", label: "BARREIRA" },
                    { value: "BARRO", label: "BARRO" },
                    { value: "BARROQUINHA", label: "BARROQUINHA" },
                    { value: "BATURITÉ", label: "BATURITÉ" },
                    { value: "BEBERIBE", label: "BEBERIBE" },
                    { value: "BELA CRUZ", label: "BELA CRUZ" },
                    { value: "BOA VIAGEM", label: "BOA VIAGEM" },
                    { value: "BREJO SANTO", label: "BREJO SANTO" },
                    { value: "CAMOCIM", label: "CAMOCIM" },
                    { value: "CAMPOS SALES", label: "CAMPOS SALES" },
                    { value: "CANINDÉ", label: "CANINDÉ" },
                    { value: "CAPISTRANO", label: "CAPISTRANO" },
                    { value: "CARIDADE", label: "CARIDADE" },
                    { value: "CARIRÉ", label: "CARIRÉ" },
                    { value: "CARIRIAÇU", label: "CARIRIAÇU" },
                    { value: "CARIÚS", label: "CARIÚS" },
                    { value: "CARNAUBAL", label: "CARNAUBAL" },
                    { value: "CASCAVEL", label: "CASCAVEL" },
                    { value: "CATARINA", label: "CATARINA" },
                    { value: "CATUNDA", label: "CATUNDA" },
                    { value: "CAUCAIA", label: "CAUCAIA" },
                    { value: "CEDRO", label: "CEDRO" },
                    { value: "CHAVAL", label: "CHAVAL" },
                    { value: "CHORÓ", label: "CHORÓ" },
                    { value: "CHOROZINHO", label: "CHOROZINHO" },
                    { value: "COREAÚ", label: "COREAÚ" },
                    { value: "CRATEÚS", label: "CRATEÚS" },
                    { value: "CRATO", label: "CRATO" },
                    { value: "CROATÁ", label: "CROATÁ" },
                    { value: "CRUZ", label: "CRUZ" },
                    { value: "DEPUTADO IRAPUAN PINHEIRO", label: "DEPUTADO IRAPUAN PINHEIRO" },
                    { value: "ERERÊ", label: "ERERÊ" },
                    { value: "EUSÉBIO", label: "EUSÉBIO" },
                    { value: "FARIAS BRITO", label: "FARIAS BRITO" },
                    { value: "FORQUILHA", label: "FORQUILHA" },
                    { value: "FORTALEZA", label: "FORTALEZA" },
                    { value: "FORTIM", label: "FORTIM" },
                    { value: "FRECHEIRINHA", label: "FRECHEIRINHA" },
                    { value: "GENERAL SAMPAIO", label: "GENERAL SAMPAIO" },
                    { value: "GRAÇA", label: "GRAÇA" },
                    { value: "GRANJA", label: "GRANJA" },
                    { value: "GRANJEIRO", label: "GRANJEIRO" },
                    { value: "GROAÍRAS", label: "GROAÍRAS" },
                    { value: "GUAIÚBA", label: "GUAIÚBA" },
                    { value: "GUARACIABA DO NORTE", label: "GUARACIABA DO NORTE" },
                    { value: "GUARAMIRANGA", label: "GUARAMIRANGA" },
                    { value: "HIDROLÂNDIA", label: "HIDROLÂNDIA" },
                    { value: "HORIZONTE", label: "HORIZONTE" },
                    { value: "IBARETAMA", label: "IBARETAMA" },
                    { value: "IBIAPINA", label: "IBIAPINA" },
                    { value: "IBICUITINGA", label: "IBICUITINGA" },
                    { value: "ICAPUÍ", label: "ICAPUÍ" },
                    { value: "ICÓ", label: "ICÓ" },
                    { value: "IGUATU", label: "IGUATU" },
                    { value: "INDEPENDÊNCIA", label: "INDEPENDÊNCIA" },
                    { value: "IPAPORANGA", label: "IPAPORANGA" },
                    { value: "IPAUMIRIM", label: "IPAUMIRIM" },
                    { value: "IPU", label: "IPU" },
                    { value: "IPUEIRAS", label: "IPUEIRAS" },
                    { value: "IRACEMA", label: "IRACEMA" },
                    { value: "IRAUÇUBA", label: "IRAUÇUBA" },
                    { value: "ITAIÇABA", label: "ITAIÇABA" },
                    { value: "ITAITINGA", label: "ITAITINGA" },
                    { value: "ITAPAGÉ", label: "ITAPAGÉ" },
                    { value: "ITAPIPOCA", label: "ITAPIPOCA" },
                    { value: "ITAPIÚNA", label: "ITAPIÚNA" },
                    { value: "ITAREMA", label: "ITAREMA" },
                    { value: "ITATIRA", label: "ITATIRA" },
                    { value: "JAGUARETAMA", label: "JAGUARETAMA" },
                    { value: "JAGUARIBARA", label: "JAGUARIBARA" },
                    { value: "JAGUARIBE", label: "JAGUARIBE" },
                    { value: "JAGUARUANA", label: "JAGUARUANA" },
                    { value: "JARDIM", label: "JARDIM" },
                    { value: "JATI", label: "JATI" },
                    { value: "JIJOCA DE JERICOACOARA", label: "JIJOCA DE JERICOACOARA" },
                    { value: "JUAZEIRO DO NORTE", label: "JUAZEIRO DO NORTE" },
                    { value: "JUCÁS", label: "JUCÁS" },
                    { value: "LAVRAS DA MANGABEIRA", label: "LAVRAS DA MANGABEIRA" },
                    { value: "LIMOEIRO DO NORTE", label: "LIMOEIRO DO NORTE" },
                    { value: "MADALENA", label: "MADALENA" },
                    { value: "MARACANAÚ", label: "MARACANAÚ" },
                    { value: "MARANGUAPE", label: "MARANGUAPE" },
                    { value: "MARCO", label: "MARCO" },
                    { value: "MARTINÓPOLE", label: "MARTINÓPOLE" },
                    { value: "MASSAPÊ", label: "MASSAPÊ" },
                    { value: "MAURITI", label: "MAURITI" },
                    { value: "MERUOCA", label: "MERUOCA" },
                    { value: "MILAGRES", label: "MILAGRES" },
                    { value: "MILHÃ", label: "MILHÃ" },
                    { value: "MIRAÍMA", label: "MIRAÍMA" },
                    { value: "MISSÃO VELHA", label: "MISSÃO VELHA" },
                    { value: "MOMBAÇA", label: "MOMBAÇA" },
                    { value: "MONSENHOR TABOSA", label: "MONSENHOR TABOSA" },
                    { value: "MORADA NOVA", label: "MORADA NOVA" },
                    { value: "MORAÚJO", label: "MORAÚJO" },
                    { value: "MORRINHOS", label: "MORRINHOS" },
                    { value: "MUCAMBO", label: "MUCAMBO" },
                    { value: "MULUNGU", label: "MULUNGU" },
                    { value: "NOVA OLINDA", label: "NOVA OLINDA" },
                    { value: "NOVA RUSSAS", label: "NOVA RUSSAS" },
                    { value: "NOVO ORIENTE", label: "NOVO ORIENTE" },
                    { value: "OCARA", label: "OCARA" },
                    { value: "ORÓS", label: "ORÓS" },
                    { value: "PACAJUS", label: "PACAJUS" },
                    { value: "PACATUBA", label: "PACATUBA" },
                    { value: "PACOTI", label: "PACOTI" },
                    { value: "PACUJÁ", label: "PACUJÁ" },
                    { value: "PALHANO", label: "PALHANO" },
                    { value: "PALMÁCIA", label: "PALMÁCIA" },
                    { value: "PARACURU", label: "PARACURU" },
                    { value: "PARAIPABA", label: "PARAIPABA" },
                    { value: "PARAMBU", label: "PARAMBU" },
                    { value: "PARAMOTI", label: "PARAMOTI" },
                    { value: "PEDRA BRANCA", label: "PEDRA BRANCA" },
                    { value: "PENAFORTE", label: "PENAFORTE" },
                    { value: "PENTECOSTE", label: "PENTECOSTE" },
                    { value: "PEREIRO", label: "PEREIRO" },
                    { value: "PINDORETAMA", label: "PINDORETAMA" },
                    { value: "PIQUET CARNEIRO", label: "PIQUET CARNEIRO" },
                    { value: "PIRES FERREIRA", label: "PIRES FERREIRA" },
                    { value: "PORANGA", label: "PORANGA" },
                    { value: "PORTEIRAS", label: "PORTEIRAS" },
                    { value: "POTENGI", label: "POTENGI" },
                    { value: "POTIRETAMA", label: "POTIRETAMA" },
                    { value: "QUITERIANÓPOLIS", label: "QUITERIANÓPOLIS" },
                    { value: "QUIXADÁ", label: "QUIXADÁ" },
                    { value: "QUIXELÔ", label: "QUIXELÔ" },
                    { value: "QUIXERAMOBIM", label: "QUIXERAMOBIM" },
                    { value: "QUIXERÉ", label: "QUIXERÉ" },
                    { value: "REDENÇÃO", label: "REDENÇÃO" },
                    { value: "RERIUTABA", label: "RERIUTABA" },
                    { value: "RUSSAS", label: "RUSSAS" },
                    { value: "SABOEIRO", label: "SABOEIRO" },
                    { value: "SALITRE", label: "SALITRE" },
                    { value: "SANTA QUITÉRIA", label: "SANTA QUITÉRIA" },
                    { value: "SANTANA DO ACARAÚ", label: "SANTANA DO ACARAÚ" },
                    { value: "SANTANA DO CARIRI", label: "SANTANA DO CARIRI" },
                    { value: "SÃO BENEDITO", label: "SÃO BENEDITO" },
                    { value: "SÃO GONÇALO DO AMARANTE", label: "SÃO GONÇALO DO AMARANTE" },
                    { value: "SÃO JOÃO DO JAGUARIBE", label: "SÃO JOÃO DO JAGUARIBE" },
                    { value: "SÃO LUÍS DO CURU", label: "SÃO LUÍS DO CURU" },
                    { value: "SENADOR POMPEU", label: "SENADOR POMPEU" },
                    { value: "SENADOR SÁ", label: "SENADOR SÁ" },
                    { value: "SOBRAL", label: "SOBRAL" },
                    { value: "SOLONÓPOLE", label: "SOLONÓPOLE" },
                    { value: "TABULEIRO DO NORTE", label: "TABULEIRO DO NORTE" },
                    { value: "TAMBORIL", label: "TAMBORIL" },
                    { value: "TARRAFAS", label: "TARRAFAS" },
                    { value: "TAUÁ", label: "TAUÁ" },
                    { value: "TEJUÇUOCA", label: "TEJUÇUOCA" },
                    { value: "TIANGUÁ", label: "TIANGUÁ" },
                    { value: "TRAIRI", label: "TRAIRI" },
                    { value: "TURURU", label: "TURURU" },
                    { value: "UBAJARA", label: "UBAJARA" },
                    { value: "UMARI", label: "UMARI" },
                    { value: "UMIRIM", label: "UMIRIM" },
                    { value: "URUBURETAMA", label: "URUBURETAMA" },
                    { value: "URUOCA", label: "URUOCA" },
                    { value: "VARJOTA", label: "VARJOTA" },
                    { value: "VÁRZEA ALEGRE", label: "VÁRZEA ALEGRE" },
                    { value: "VIÇOSA DO CEARÁ", label: "VIÇOSA DO CEARÁ" },
               ]
               break;

          case "DISTRITO FEDERAL":
               CITIES = [
                    { value: "ÁGUAS CLARAS", label: "ÁGUAS CLARAS" },
                    { value: "ARNIQUEIRA", label: "ARNIQUEIRA" },
                    { value: "BRAZLÂNDIA", label: "BRAZLÂNDIA" },
                    { value: "CANDANGOLÂNDIA", label: "CANDANGOLÂNDIA" },
                    { value: "CEILÂNDIA", label: "CEILÂNDIA" },
                    { value: "CRUZEIRO", label: "CRUZEIRO" },
                    { value: "FERCAL", label: "FERCAL" },
                    { value: "GAMA", label: "GAMA" },
                    { value: "GUARÁ", label: "GUARÁ" },
                    { value: "ITAPOÃ", label: "ITAPOÃ" },
                    { value: "JARDIM BOTÂNICO", label: "JARDIM BOTÂNICO" },
                    { value: "LAGO NORTE", label: "LAGO NORTE" },
                    { value: "LAGO SUL", label: "LAGO SUL" },
                    { value: "NÚCLEO BANDEIRANTE", label: "NÚCLEO BANDEIRANTE" },
                    { value: "PARANOÁ", label: "PARANOÁ" },
                    { value: "PARK WAY", label: "PARK WAY" },
                    { value: "PLANALTINA", label: "PLANALTINA" },
                    { value: "PLANO PILOTO", label: "PLANO PILOTO" },
                    { value: "RECANTO DAS EMAS", label: "RECANTO DAS EMAS" },
                    { value: "RIACHO FUNDO", label: "RIACHO FUNDO" },
                    { value: "RIACHO FUNDO II", label: "RIACHO FUNDO II" },
                    { value: "SAMAMBAIA", label: "SAMAMBAIA" },
                    { value: "SANTA MARIA", label: "SANTA MARIA" },
                    { value: "SÃO SEBASTIÃO", label: "SÃO SEBASTIÃO" },
                    { value: "SCIA/ESTRUTURAL", label: "SCIA/ESTRUTURAL" },
                    { value: "SIA", label: "SIA" },
                    { value: "SOBRADINHO", label: "SOBRADINHO" },
                    { value: "SOBRADINHO II", label: "SOBRADINHO II" },
                    { value: "SOL NASCENTE E POR DO SOL", label: "SOL NASCENTE E POR DO SOL" },
                    { value: "SUDOESTE/OCTOGONAL", label: "SUDOESTE/OCTOGONAL" },
                    { value: "TAGUATINGA", label: "TAGUATINGA" },
                    { value: "VARJÃO", label: "VARJÃO" },
                    { value: "VICENTE PIRES", label: "VICENTE PIRES" },
               ]
               break;

          case "ESPÍRITO SANTO":
               CITIES = [
                    { value: "AFONSO CLÁUDIO", label: "AFONSO CLÁUDIO" },
                    { value: "ÁGUA DOCE DO NORTE", label: "ÁGUA DOCE DO NORTE" },
                    { value: "ÁGUIA BRANCA", label: "ÁGUIA BRANCA" },
                    { value: "ALEGRE", label: "ALEGRE" },
                    { value: "ALFREDO CHAVES", label: "ALFREDO CHAVES" },
                    { value: "ALTO RIO NOVO", label: "ALTO RIO NOVO" },
                    { value: "ANCHIETA", label: "ANCHIETA" },
                    { value: "APIACÁ", label: "APIACÁ" },
                    { value: "ARACRUZ", label: "ARACRUZ" },
                    { value: "ATILIO VIVACQUA", label: "ATILIO VIVACQUA" },
                    { value: "BAIXO GUANDU", label: "BAIXO GUANDU" },
                    { value: "BARRA DE SÃO FRANCISCO", label: "BARRA DE SÃO FRANCISCO" },
                    { value: "BOA ESPERANÇA", label: "BOA ESPERANÇA" },
                    { value: "BOM JESUS DO NORTE", label: "BOM JESUS DO NORTE" },
                    { value: "BREJETUBA", label: "BREJETUBA" },
                    { value: "CACHOEIRO DE ITAPEMIRIM", label: "CACHOEIRO DE ITAPEMIRIM" },
                    { value: "CARIACICA", label: "CARIACICA" },
                    { value: "CASTELO", label: "CASTELO" },
                    { value: "COLATINA", label: "COLATINA" },
                    { value: "CONCEIÇÃO DA BARRA", label: "CONCEIÇÃO DA BARRA" },
                    { value: "CONCEIÇÃO DO CASTELO", label: "CONCEIÇÃO DO CASTELO" },
                    { value: "DIVINO DE SÃO LOURENÇO", label: "DIVINO DE SÃO LOURENÇO" },
                    { value: "DOMINGOS MARTINS", label: "DOMINGOS MARTINS" },
                    { value: "DORES DO RIO PRETO", label: "DORES DO RIO PRETO" },
                    { value: "ECOPORANGA", label: "ECOPORANGA" },
                    { value: "FUNDÃO", label: "FUNDÃO" },
                    { value: "GOVERNADOR LINDENBERG", label: "GOVERNADOR LINDENBERG" },
                    { value: "GUAÇUÍ", label: "GUAÇUÍ" },
                    { value: "GUARAPARI", label: "GUARAPARI" },
                    { value: "IBATIBA", label: "IBATIBA" },
                    { value: "IBIRAÇU", label: "IBIRAÇU" },
                    { value: "IBITIRAMA", label: "IBITIRAMA" },
                    { value: "ICONHA", label: "ICONHA" },
                    { value: "IRUPI", label: "IRUPI" },
                    { value: "ITAGUAÇU", label: "ITAGUAÇU" },
                    { value: "ITAPEMIRIM", label: "ITAPEMIRIM" },
                    { value: "ITARANA", label: "ITARANA" },
                    { value: "IÚNA", label: "IÚNA" },
                    { value: "JAGUARÉ", label: "JAGUARÉ" },
                    { value: "JERÔNIMO MONTEIRO", label: "JERÔNIMO MONTEIRO" },
                    { value: "JOÃO NEIVA", label: "JOÃO NEIVA" },
                    { value: "LARANJA DA TERRA", label: "LARANJA DA TERRA" },
                    { value: "LINHARES", label: "LINHARES" },
                    { value: "MANTENÓPOLIS", label: "MANTENÓPOLIS" },
                    { value: "MARATAÍZES", label: "MARATAÍZES" },
                    { value: "MARECHAL FLORIANO", label: "MARECHAL FLORIANO" },
                    { value: "MARILÂNDIA", label: "MARILÂNDIA" },
                    { value: "MIMOSO DO SUL", label: "MIMOSO DO SUL" },
                    { value: "MONTANHA", label: "MONTANHA" },
                    { value: "MUCURICI", label: "MUCURICI" },
                    { value: "MUNIZ FREIRE", label: "MUNIZ FREIRE" },
                    { value: "MUQUI", label: "MUQUI" },
                    { value: "NOVA VENÉCIA", label: "NOVA VENÉCIA" },
                    { value: "PANCAS", label: "PANCAS" },
                    { value: "PEDRO CANÁRIO", label: "PEDRO CANÁRIO" },
                    { value: "PINHEIROS", label: "PINHEIROS" },
                    { value: "PIÚMA", label: "PIÚMA" },
                    { value: "PONTO BELO", label: "PONTO BELO" },
                    { value: "PRESIDENTE KENNEDY", label: "PRESIDENTE KENNEDY" },
                    { value: "RIO BANANAL", label: "RIO BANANAL" },
                    { value: "RIO NOVO DO SUL", label: "RIO NOVO DO SUL" },
                    { value: "SANTA LEOPOLDINA", label: "SANTA LEOPOLDINA" },
                    { value: "SANTA MARIA DE JETIBÁ", label: "SANTA MARIA DE JETIBÁ" },
                    { value: "SANTA TERESA", label: "SANTA TERESA" },
                    { value: "SÃO DOMINGOS DO NORTE", label: "SÃO DOMINGOS DO NORTE" },
                    { value: "SÃO GABRIEL DA PALHA", label: "SÃO GABRIEL DA PALHA" },
                    { value: "SÃO JOSÉ DO CALÇADO", label: "SÃO JOSÉ DO CALÇADO" },
                    { value: "SÃO MATEUS", label: "SÃO MATEUS" },
                    { value: "SÃO ROQUE DO CANAÃ", label: "SÃO ROQUE DO CANAÃ" },
                    { value: "SERRA", label: "SERRA" },
                    { value: "SOORETAMA", label: "SOORETAMA" },
                    { value: "VARGEM ALTA", label: "VARGEM ALTA" },
                    { value: "VENDA NOVA DO IMIGRANTE", label: "VENDA NOVA DO IMIGRANTE" },
                    { value: "VIANA", label: "VIANA" },
                    { value: "VILA PAVÃO", label: "VILA PAVÃO" },
                    { value: "VILA VALÉRIO", label: "VILA VALÉRIO" },
                    { value: "VILA VELHA", label: "VILA VELHA" },
                    { value: "VITÓRIA", label: "VITÓRIA" },
               ]
               break;

          case "GOIÁS":
               CITIES = [
                    { value: "ABADIA DE GOIÁS", label: "ABADIA DE GOIÁS" },
                    { value: "ABADIÂNIA", label: "ABADIÂNIA" },
                    { value: "ACREÚNA", label: "ACREÚNA" },
                    { value: "ADELÂNDIA", label: "ADELÂNDIA" },
                    { value: "ÁGUA FRIA DE GOIÁS", label: "ÁGUA FRIA DE GOIÁS" },
                    { value: "ÁGUA LIMPA", label: "ÁGUA LIMPA" },
                    { value: "ÁGUAS LINDAS DE GOIÁS", label: "ÁGUAS LINDAS DE GOIÁS" },
                    { value: "ALEXÂNIA", label: "ALEXÂNIA" },
                    { value: "ALOÂNDIA", label: "ALOÂNDIA" },
                    { value: "ALTO HORIZONTE", label: "ALTO HORIZONTE" },
                    { value: "ALTO PARAÍSO DE GOIÁS", label: "ALTO PARAÍSO DE GOIÁS" },
                    { value: "ALVORADA DO NORTE", label: "ALVORADA DO NORTE" },
                    { value: "AMARALINA", label: "AMARALINA" },
                    { value: "AMERICANO DO BRASIL", label: "AMERICANO DO BRASIL" },
                    { value: "AMORINÓPOLIS", label: "AMORINÓPOLIS" },
                    { value: "ANÁPOLIS", label: "ANÁPOLIS" },
                    { value: "ANHANGUERA", label: "ANHANGUERA" },
                    { value: "ANICUNS", label: "ANICUNS" },
                    { value: "APARECIDA DE GOIÂNIA", label: "APARECIDA DE GOIÂNIA" },
                    { value: "APARECIDA DO RIO DOCE", label: "APARECIDA DO RIO DOCE" },
                    { value: "APORÉ", label: "APORÉ" },
                    { value: "ARAÇU", label: "ARAÇU" },
                    { value: "ARAGARÇAS", label: "ARAGARÇAS" },
                    { value: "ARAGOIÂNIA", label: "ARAGOIÂNIA" },
                    { value: "ARAGUAPAZ", label: "ARAGUAPAZ" },
                    { value: "ARENÓPOLIS", label: "ARENÓPOLIS" },
                    { value: "ARUANÃ", label: "ARUANÃ" },
                    { value: "AURILÂNDIA", label: "AURILÂNDIA" },
                    { value: "AVELINÓPOLIS", label: "AVELINÓPOLIS" },
                    { value: "BALIZA", label: "BALIZA" },
                    { value: "BARRO ALTO", label: "BARRO ALTO" },
                    { value: "BELA VISTA DE GOIÁS", label: "BELA VISTA DE GOIÁS" },
                    { value: "BOM JARDIM DE GOIÁS", label: "BOM JARDIM DE GOIÁS" },
                    { value: "BOM JESUS DE GOIÁS", label: "BOM JESUS DE GOIÁS" },
                    { value: "BONFINÓPOLIS", label: "BONFINÓPOLIS" },
                    { value: "BONÓPOLIS", label: "BONÓPOLIS" },
                    { value: "BRAZABRANTES", label: "BRAZABRANTES" },
                    { value: "BRITÂNIA", label: "BRITÂNIA" },
                    { value: "BURITI ALEGRE", label: "BURITI ALEGRE" },
                    { value: "BURITI DE GOIÁS", label: "BURITI DE GOIÁS" },
                    { value: "BURITINÓPOLIS", label: "BURITINÓPOLIS" },
                    { value: "CABECEIRAS", label: "CABECEIRAS" },
                    { value: "CACHOEIRA ALTA", label: "CACHOEIRA ALTA" },
                    { value: "CACHOEIRA DE GOIÁS", label: "CACHOEIRA DE GOIÁS" },
                    { value: "CACHOEIRA DOURADA", label: "CACHOEIRA DOURADA" },
                    { value: "CAÇU", label: "CAÇU" },
                    { value: "CAIAPÔNIA", label: "CAIAPÔNIA" },
                    { value: "CALDAS NOVAS", label: "CALDAS NOVAS" },
                    { value: "CALDAZINHA", label: "CALDAZINHA" },
                    { value: "CAMPESTRE DE GOIÁS", label: "CAMPESTRE DE GOIÁS" },
                    { value: "CAMPINAÇU", label: "CAMPINAÇU" },
                    { value: "CAMPINORTE", label: "CAMPINORTE" },
                    { value: "CAMPO ALEGRE DE GOIÁS", label: "CAMPO ALEGRE DE GOIÁS" },
                    { value: "CAMPO LIMPO DE GOIÁS", label: "CAMPO LIMPO DE GOIÁS" },
                    { value: "CAMPOS BELOS", label: "CAMPOS BELOS" },
                    { value: "CAMPOS VERDES", label: "CAMPOS VERDES" },
                    { value: "CARMO DO RIO VERDE", label: "CARMO DO RIO VERDE" },
                    { value: "CASTELÂNDIA", label: "CASTELÂNDIA" },
                    { value: "CATALÃO", label: "CATALÃO" },
                    { value: "CATURAÍ", label: "CATURAÍ" },
                    { value: "CAVALCANTE", label: "CAVALCANTE" },
                    { value: "CERES", label: "CERES" },
                    { value: "CEZARINA", label: "CEZARINA" },
                    { value: "CHAPADÃO DO CÉU", label: "CHAPADÃO DO CÉU" },
                    { value: "CIDADE OCIDENTAL", label: "CIDADE OCIDENTAL" },
                    { value: "COCALZINHO DE GOIÁS", label: "COCALZINHO DE GOIÁS" },
                    { value: "COLINAS DO SUL", label: "COLINAS DO SUL" },
                    { value: "CÓRREGO DO OURO", label: "CÓRREGO DO OURO" },
                    { value: "CORUMBÁ DE GOIÁS", label: "CORUMBÁ DE GOIÁS" },
                    { value: "CORUMBAÍBA", label: "CORUMBAÍBA" },
                    { value: "CRISTALINA", label: "CRISTALINA" },
                    { value: "CRISTIANÓPOLIS", label: "CRISTIANÓPOLIS" },
                    { value: "CRIXÁS", label: "CRIXÁS" },
                    { value: "CROMÍNIA", label: "CROMÍNIA" },
                    { value: "CUMARI", label: "CUMARI" },
                    { value: "DAMIANÓPOLIS", label: "DAMIANÓPOLIS" },
                    { value: "DAMOLÂNDIA", label: "DAMOLÂNDIA" },
                    { value: "DAVINÓPOLIS", label: "DAVINÓPOLIS" },
                    { value: "DIORAMA", label: "DIORAMA" },
                    { value: "DIVINÓPOLIS DE GOIÁS", label: "DIVINÓPOLIS DE GOIÁS" },
                    { value: "DOVERLÂNDIA", label: "DOVERLÂNDIA" },
                    { value: "EDEALINA", label: "EDEALINA" },
                    { value: "EDÉIA", label: "EDÉIA" },
                    { value: "ESTRELA DO NORTE", label: "ESTRELA DO NORTE" },
                    { value: "FAINA", label: "FAINA" },
                    { value: "FAZENDA NOVA", label: "FAZENDA NOVA" },
                    { value: "FIRMINÓPOLIS", label: "FIRMINÓPOLIS" },
                    { value: "FLORES DE GOIÁS", label: "FLORES DE GOIÁS" },
                    { value: "FORMOSA", label: "FORMOSA" },
                    { value: "FORMOSO", label: "FORMOSO" },
                    { value: "GAMELEIRA DE GOIÁS", label: "GAMELEIRA DE GOIÁS" },
                    { value: "GOIANÁPOLIS", label: "GOIANÁPOLIS" },
                    { value: "GOIANDIRA", label: "GOIANDIRA" },
                    { value: "GOIANÉSIA", label: "GOIANÉSIA" },
                    { value: "GOIÂNIA", label: "GOIÂNIA" },
                    { value: "GOIANIRA", label: "GOIANIRA" },
                    { value: "GOIÁS", label: "GOIÁS" },
                    { value: "GOIATUBA", label: "GOIATUBA" },
                    { value: "GOUVELÂNDIA", label: "GOUVELÂNDIA" },
                    { value: "GUAPÓ", label: "GUAPÓ" },
                    { value: "GUARAÍTA", label: "GUARAÍTA" },
                    { value: "GUARANI DE GOIÁS", label: "GUARANI DE GOIÁS" },
                    { value: "GUARINOS", label: "GUARINOS" },
                    { value: "HEITORAÍ", label: "HEITORAÍ" },
                    { value: "HIDROLÂNDIA", label: "HIDROLÂNDIA" },
                    { value: "HIDROLINA", label: "HIDROLINA" },
                    { value: "IACIARA", label: "IACIARA" },
                    { value: "INACIOLÂNDIA", label: "INACIOLÂNDIA" },
                    { value: "INDIARA", label: "INDIARA" },
                    { value: "INHUMAS", label: "INHUMAS" },
                    { value: "IPAMERI", label: "IPAMERI" },
                    { value: "IPIRANGA DE GOIÁS", label: "IPIRANGA DE GOIÁS" },
                    { value: "IPORÁ", label: "IPORÁ" },
                    { value: "ISRAELÂNDIA", label: "ISRAELÂNDIA" },
                    { value: "ITABERAÍ", label: "ITABERAÍ" },
                    { value: "ITAGUARI", label: "ITAGUARI" },
                    { value: "ITAGUARU", label: "ITAGUARU" },
                    { value: "ITAJÁ", label: "ITAJÁ" },
                    { value: "ITAPACI", label: "ITAPACI" },
                    { value: "ITAPIRAPUÃ", label: "ITAPIRAPUÃ" },
                    { value: "ITAPURANGA", label: "ITAPURANGA" },
                    { value: "ITARUMÃ", label: "ITARUMÃ" },
                    { value: "ITAUÇU", label: "ITAUÇU" },
                    { value: "ITUMBIARA", label: "ITUMBIARA" },
                    { value: "IVOLÂNDIA", label: "IVOLÂNDIA" },
                    { value: "JANDAIA", label: "JANDAIA" },
                    { value: "JARAGUÁ", label: "JARAGUÁ" },
                    { value: "JATAÍ", label: "JATAÍ" },
                    { value: "JAUPACI", label: "JAUPACI" },
                    { value: "JESÚPOLIS", label: "JESÚPOLIS" },
                    { value: "JOVIÂNIA", label: "JOVIÂNIA" },
                    { value: "JUSSARA", label: "JUSSARA" },
                    { value: "LAGOA SANTA", label: "LAGOA SANTA" },
                    { value: "LEOPOLDO DE BULHÕES", label: "LEOPOLDO DE BULHÕES" },
                    { value: "LUZIÂNIA", label: "LUZIÂNIA" },
                    { value: "MAIRIPOTABA", label: "MAIRIPOTABA" },
                    { value: "MAMBAÍ", label: "MAMBAÍ" },
                    { value: "MARA ROSA", label: "MARA ROSA" },
                    { value: "MARZAGÃO", label: "MARZAGÃO" },
                    { value: "MATRINCHÃ", label: "MATRINCHÃ" },
                    { value: "MAURILÂNDIA", label: "MAURILÂNDIA" },
                    { value: "MIMOSO DE GOIÁS", label: "MIMOSO DE GOIÁS" },
                    { value: "MINAÇU", label: "MINAÇU" },
                    { value: "MINEIROS", label: "MINEIROS" },
                    { value: "MOIPORÁ", label: "MOIPORÁ" },
                    { value: "MONTE ALEGRE DE GOIÁS", label: "MONTE ALEGRE DE GOIÁS" },
                    { value: "MONTES CLAROS DE GOIÁS", label: "MONTES CLAROS DE GOIÁS" },
                    { value: "MONTIVIDIU", label: "MONTIVIDIU" },
                    { value: "MONTIVIDIU DO NORTE", label: "MONTIVIDIU DO NORTE" },
                    { value: "MORRINHOS", label: "MORRINHOS" },
                    { value: "MORRO AGUDO DE GOIÁS", label: "MORRO AGUDO DE GOIÁS" },
                    { value: "MOSSÂMEDES", label: "MOSSÂMEDES" },
                    { value: "MOZARLÂNDIA", label: "MOZARLÂNDIA" },
                    { value: "MUNDO NOVO", label: "MUNDO NOVO" },
                    { value: "MUTUNÓPOLIS", label: "MUTUNÓPOLIS" },
                    { value: "NAZÁRIO", label: "NAZÁRIO" },
                    { value: "NERÓPOLIS", label: "NERÓPOLIS" },
                    { value: "NIQUELÂNDIA", label: "NIQUELÂNDIA" },
                    { value: "NOVA AMÉRICA", label: "NOVA AMÉRICA" },
                    { value: "NOVA AURORA", label: "NOVA AURORA" },
                    { value: "NOVA CRIXÁS", label: "NOVA CRIXÁS" },
                    { value: "NOVA GLÓRIA", label: "NOVA GLÓRIA" },
                    { value: "NOVA IGUAÇU DE GOIÁS", label: "NOVA IGUAÇU DE GOIÁS" },
                    { value: "NOVA ROMA", label: "NOVA ROMA" },
                    { value: "NOVA VENEZA", label: "NOVA VENEZA" },
                    { value: "NOVO BRASIL", label: "NOVO BRASIL" },
                    { value: "NOVO GAMA", label: "NOVO GAMA" },
                    { value: "NOVO PLANALTO", label: "NOVO PLANALTO" },
                    { value: "ORIZONA", label: "ORIZONA" },
                    { value: "OURO VERDE DE GOIÁS", label: "OURO VERDE DE GOIÁS" },
                    { value: "OUVIDOR", label: "OUVIDOR" },
                    { value: "PADRE BERNARDO", label: "PADRE BERNARDO" },
                    { value: "PALESTINA DE GOIÁS", label: "PALESTINA DE GOIÁS" },
                    { value: "PALMEIRAS DE GOIÁS", label: "PALMEIRAS DE GOIÁS" },
                    { value: "PALMELO", label: "PALMELO" },
                    { value: "PALMINÓPOLIS", label: "PALMINÓPOLIS" },
                    { value: "PANAMÁ", label: "PANAMÁ" },
                    { value: "PARANAIGUARA", label: "PARANAIGUARA" },
                    { value: "PARAÚNA", label: "PARAÚNA" },
                    { value: "PEROLÂNDIA", label: "PEROLÂNDIA" },
                    { value: "PETROLINA DE GOIÁS", label: "PETROLINA DE GOIÁS" },
                    { value: "PILAR DE GOIÁS", label: "PILAR DE GOIÁS" },
                    { value: "PIRACANJUBA", label: "PIRACANJUBA" },
                    { value: "PIRANHAS", label: "PIRANHAS" },
                    { value: "PIRENÓPOLIS", label: "PIRENÓPOLIS" },
                    { value: "PIRES DO RIO", label: "PIRES DO RIO" },
                    { value: "PLANALTINA", label: "PLANALTINA" },
                    { value: "PONTALINA", label: "PONTALINA" },
                    { value: "PORANGATU", label: "PORANGATU" },
                    { value: "PORTEIRÃO", label: "PORTEIRÃO" },
                    { value: "PORTELÂNDIA", label: "PORTELÂNDIA" },
                    { value: "POSSE", label: "POSSE" },
                    { value: "PROFESSOR JAMIL", label: "PROFESSOR JAMIL" },
                    { value: "QUIRINÓPOLIS", label: "QUIRINÓPOLIS" },
                    { value: "RIALMA", label: "RIALMA" },
                    { value: "RIANÁPOLIS", label: "RIANÁPOLIS" },
                    { value: "RIO QUENTE", label: "RIO QUENTE" },
                    { value: "RIO VERDE", label: "RIO VERDE" },
                    { value: "RUBIATABA", label: "RUBIATABA" },
                    { value: "SANCLERLÂNDIA", label: "SANCLERLÂNDIA" },
                    { value: "SANTA BÁRBARA DE GOIÁS", label: "SANTA BÁRBARA DE GOIÁS" },
                    { value: "SANTA CRUZ DE GOIÁS", label: "SANTA CRUZ DE GOIÁS" },
                    { value: "SANTA FÉ DE GOIÁS", label: "SANTA FÉ DE GOIÁS" },
                    { value: "SANTA HELENA DE GOIÁS", label: "SANTA HELENA DE GOIÁS" },
                    { value: "SANTA ISABEL", label: "SANTA ISABEL" },
                    { value: "SANTA RITA DO ARAGUAIA", label: "SANTA RITA DO ARAGUAIA" },
                    { value: "SANTA RITA DO NOVO DESTINO", label: "SANTA RITA DO NOVO DESTINO" },
                    { value: "SANTA ROSA DE GOIÁS", label: "SANTA ROSA DE GOIÁS" },
                    { value: "SANTA TEREZA DE GOIÁS", label: "SANTA TEREZA DE GOIÁS" },
                    { value: "SANTA TEREZINHA DE GOIÁS", label: "SANTA TEREZINHA DE GOIÁS" },
                    { value: "SANTO ANTÔNIO DA BARRA", label: "SANTO ANTÔNIO DA BARRA" },
                    { value: "SANTO ANTÔNIO DE GOIÁS", label: "SANTO ANTÔNIO DE GOIÁS" },
                    { value: "SANTO ANTÔNIO DO DESCOBERTO", label: "SANTO ANTÔNIO DO DESCOBERTO" },
                    { value: "SÃO DOMINGOS", label: "SÃO DOMINGOS" },
                    { value: "SÃO FRANCISCO DE GOIÁS", label: "SÃO FRANCISCO DE GOIÁS" },
                    { value: "SÃO JOÃO D'ALIANÇA", label: "SÃO JOÃO D'ALIANÇA" },
                    { value: "SÃO JOÃO DA PARAÚNA", label: "SÃO JOÃO DA PARAÚNA" },
                    { value: "SÃO LUÍS DE MONTES BELOS", label: "SÃO LUÍS DE MONTES BELOS" },
                    { value: "SÃO LUÍZ DO NORTE", label: "SÃO LUÍZ DO NORTE" },
                    { value: "SÃO MIGUEL DO ARAGUAIA", label: "SÃO MIGUEL DO ARAGUAIA" },
                    { value: "SÃO MIGUEL DO PASSA QUATRO", label: "SÃO MIGUEL DO PASSA QUATRO" },
                    { value: "SÃO PATRÍCIO", label: "SÃO PATRÍCIO" },
                    { value: "SÃO SIMÃO", label: "SÃO SIMÃO" },
                    { value: "SENADOR CANEDO", label: "SENADOR CANEDO" },
                    { value: "SERRANÓPOLIS", label: "SERRANÓPOLIS" },
                    { value: "SILVÂNIA", label: "SILVÂNIA" },
                    { value: "SIMOLÂNDIA", label: "SIMOLÂNDIA" },
                    { value: "SÍTIO D'ABADIA", label: "SÍTIO D'ABADIA" },
                    { value: "TAQUARAL DE GOIÁS", label: "TAQUARAL DE GOIÁS" },
                    { value: "TERESINA DE GOIÁS", label: "TERESINA DE GOIÁS" },
                    { value: "TEREZÓPOLIS DE GOIÁS", label: "TEREZÓPOLIS DE GOIÁS" },
                    { value: "TRÊS RANCHOS", label: "TRÊS RANCHOS" },
                    { value: "TRINDADE", label: "TRINDADE" },
                    { value: "TROMBAS", label: "TROMBAS" },
                    { value: "TURVÂNIA", label: "TURVÂNIA" },
                    { value: "TURVELÂNDIA", label: "TURVELÂNDIA" },
                    { value: "UIRAPURU", label: "UIRAPURU" },
                    { value: "URUAÇU", label: "URUAÇU" },
                    { value: "URUANA", label: "URUANA" },
                    { value: "URUTAÍ", label: "URUTAÍ" },
                    { value: "VALPARAÍSO DE GOIÁS", label: "VALPARAÍSO DE GOIÁS" },
                    { value: "VARJÃO", label: "VARJÃO" },
                    { value: "VIANÓPOLIS", label: "VIANÓPOLIS" },
                    { value: "VICENTINÓPOLIS", label: "VICENTINÓPOLIS" },
                    { value: "VILA BOA", label: "VILA BOA" },
                    { value: "VILA PROPÍCIO", label: "VILA PROPÍCIO" },
               ]
               break;

          case "MARANHÃO":
               CITIES = [
                    { value: "AÇAILÂNDIA", label: "AÇAILÂNDIA" },
                    { value: "AFONSO CUNHA", label: "AFONSO CUNHA" },
                    { value: "ÁGUA DOCE DO MARANHÃO", label: "ÁGUA DOCE DO MARANHÃO" },
                    { value: "ALCÂNTARA", label: "ALCÂNTARA" },
                    { value: "ALDEIAS ALTAS", label: "ALDEIAS ALTAS" },
                    { value: "ALTAMIRA DO MARANHÃO", label: "ALTAMIRA DO MARANHÃO" },
                    { value: "ALTO ALEGRE DO MARANHÃO", label: "ALTO ALEGRE DO MARANHÃO" },
                    { value: "ALTO ALEGRE DO PINDARÉ", label: "ALTO ALEGRE DO PINDARÉ" },
                    { value: "ALTO PARNAÍBA", label: "ALTO PARNAÍBA" },
                    { value: "AMAPÁ DO MARANHÃO", label: "AMAPÁ DO MARANHÃO" },
                    { value: "AMARANTE DO MARANHÃO", label: "AMARANTE DO MARANHÃO" },
                    { value: "ANAJATUBA", label: "ANAJATUBA" },
                    { value: "ANAPURUS", label: "ANAPURUS" },
                    { value: "APICUM-AÇU", label: "APICUM-AÇU" },
                    { value: "ARAGUANÃ", label: "ARAGUANÃ" },
                    { value: "ARAIOSES", label: "ARAIOSES" },
                    { value: "ARAME", label: "ARAME" },
                    { value: "ARARI", label: "ARARI" },
                    { value: "AXIXÁ", label: "AXIXÁ" },
                    { value: "BACABAL", label: "BACABAL" },
                    { value: "BACABEIRA", label: "BACABEIRA" },
                    { value: "BACURI", label: "BACURI" },
                    { value: "BACURITUBA", label: "BACURITUBA" },
                    { value: "BALSAS", label: "BALSAS" },
                    { value: "BARÃO DE GRAJAÚ", label: "BARÃO DE GRAJAÚ" },
                    { value: "BARRA DO CORDA", label: "BARRA DO CORDA" },
                    { value: "BARREIRINHAS", label: "BARREIRINHAS" },
                    { value: "BELA VISTA DO MARANHÃO", label: "BELA VISTA DO MARANHÃO" },
                    { value: "BELÁGUA", label: "BELÁGUA" },
                    { value: "BENEDITO LEITE", label: "BENEDITO LEITE" },
                    { value: "BEQUIMÃO", label: "BEQUIMÃO" },
                    { value: "BERNARDO DO MEARIM", label: "BERNARDO DO MEARIM" },
                    { value: "BOA VISTA DO GURUPI", label: "BOA VISTA DO GURUPI" },
                    { value: "BOM JARDIM", label: "BOM JARDIM" },
                    { value: "BOM JESUS DAS SELVAS", label: "BOM JESUS DAS SELVAS" },
                    { value: "BOM LUGAR", label: "BOM LUGAR" },
                    { value: "BREJO", label: "BREJO" },
                    { value: "BREJO DE AREIA", label: "BREJO DE AREIA" },
                    { value: "BURITI", label: "BURITI" },
                    { value: "BURITI BRAVO", label: "BURITI BRAVO" },
                    { value: "BURITICUPU", label: "BURITICUPU" },
                    { value: "BURITIRANA", label: "BURITIRANA" },
                    { value: "CACHOEIRA GRANDE", label: "CACHOEIRA GRANDE" },
                    { value: "CAJAPIÓ", label: "CAJAPIÓ" },
                    { value: "CAJARI", label: "CAJARI" },
                    { value: "CAMPESTRE DO MARANHÃO", label: "CAMPESTRE DO MARANHÃO" },
                    { value: "CÂNDIDO MENDES", label: "CÂNDIDO MENDES" },
                    { value: "CANTANHEDE", label: "CANTANHEDE" },
                    { value: "CAPINZAL DO NORTE", label: "CAPINZAL DO NORTE" },
                    { value: "CAROLINA", label: "CAROLINA" },
                    { value: "CARUTAPERA", label: "CARUTAPERA" },
                    { value: "CAXIAS", label: "CAXIAS" },
                    { value: "CEDRAL", label: "CEDRAL" },
                    { value: "CENTRAL DO MARANHÃO", label: "CENTRAL DO MARANHÃO" },
                    { value: "CENTRO DO GUILHERME", label: "CENTRO DO GUILHERME" },
                    { value: "CENTRO NOVO DO MARANHÃO", label: "CENTRO NOVO DO MARANHÃO" },
                    { value: "CHAPADINHA", label: "CHAPADINHA" },
                    { value: "CIDELÂNDIA", label: "CIDELÂNDIA" },
                    { value: "CODÓ", label: "CODÓ" },
                    { value: "COELHO NETO", label: "COELHO NETO" },
                    { value: "COLINAS", label: "COLINAS" },
                    { value: "CONCEIÇÃO DO LAGO-AÇU", label: "CONCEIÇÃO DO LAGO-AÇU" },
                    { value: "COROATÁ", label: "COROATÁ" },
                    { value: "CURURUPU", label: "CURURUPU" },
                    { value: "DAVINÓPOLIS", label: "DAVINÓPOLIS" },
                    { value: "DOM PEDRO", label: "DOM PEDRO" },
                    { value: "DUQUE BACELAR", label: "DUQUE BACELAR" },
                    { value: "ESPERANTINÓPOLIS", label: "ESPERANTINÓPOLIS" },
                    { value: "ESTREITO", label: "ESTREITO" },
                    { value: "FEIRA NOVA DO MARANHÃO", label: "FEIRA NOVA DO MARANHÃO" },
                    { value: "FERNANDO FALCÃO", label: "FERNANDO FALCÃO" },
                    { value: "FORMOSA DA SERRA NEGRA", label: "FORMOSA DA SERRA NEGRA" },
                    { value: "FORTALEZA DOS NOGUEIRAS", label: "FORTALEZA DOS NOGUEIRAS" },
                    { value: "FORTUNA", label: "FORTUNA" },
                    { value: "GODOFREDO VIANA", label: "GODOFREDO VIANA" },
                    { value: "GONÇALVES DIAS", label: "GONÇALVES DIAS" },
                    { value: "GOVERNADOR ARCHER", label: "GOVERNADOR ARCHER" },
                    { value: "GOVERNADOR EDISON LOBÃO", label: "GOVERNADOR EDISON LOBÃO" },
                    { value: "GOVERNADOR EUGÊNIO BARROS", label: "GOVERNADOR EUGÊNIO BARROS" },
                    { value: "GOVERNADOR LUIZ ROCHA", label: "GOVERNADOR LUIZ ROCHA" },
                    { value: "GOVERNADOR NEWTON BELLO", label: "GOVERNADOR NEWTON BELLO" },
                    { value: "GOVERNADOR NUNES FREIRE", label: "GOVERNADOR NUNES FREIRE" },
                    { value: "GRAÇA ARANHA", label: "GRAÇA ARANHA" },
                    { value: "GRAJAÚ", label: "GRAJAÚ" },
                    { value: "GUIMARÃES", label: "GUIMARÃES" },
                    { value: "HUMBERTO DE CAMPOS", label: "HUMBERTO DE CAMPOS" },
                    { value: "ICATU", label: "ICATU" },
                    { value: "IGARAPÉ DO MEIO", label: "IGARAPÉ DO MEIO" },
                    { value: "IGARAPÉ GRANDE", label: "IGARAPÉ GRANDE" },
                    { value: "IMPERATRIZ", label: "IMPERATRIZ" },
                    { value: "ITAIPAVA DO GRAJAÚ", label: "ITAIPAVA DO GRAJAÚ" },
                    { value: "ITAPECURU MIRIM", label: "ITAPECURU MIRIM" },
                    { value: "ITINGA DO MARANHÃO", label: "ITINGA DO MARANHÃO" },
                    { value: "JATOBÁ", label: "JATOBÁ" },
                    { value: "JENIPAPO DOS VIEIRAS", label: "JENIPAPO DOS VIEIRAS" },
                    { value: "JOÃO LISBOA", label: "JOÃO LISBOA" },
                    { value: "JOSELÂNDIA", label: "JOSELÂNDIA" },
                    { value: "JUNCO DO MARANHÃO", label: "JUNCO DO MARANHÃO" },
                    { value: "LAGO DA PEDRA", label: "LAGO DA PEDRA" },
                    { value: "LAGO DO JUNCO", label: "LAGO DO JUNCO" },
                    { value: "LAGO DOS RODRIGUES", label: "LAGO DOS RODRIGUES" },
                    { value: "LAGO VERDE", label: "LAGO VERDE" },
                    { value: "LAGOA DO MATO", label: "LAGOA DO MATO" },
                    { value: "LAGOA GRANDE DO MARANHÃO", label: "LAGOA GRANDE DO MARANHÃO" },
                    { value: "LAJEADO NOVO", label: "LAJEADO NOVO" },
                    { value: "LIMA CAMPOS", label: "LIMA CAMPOS" },
                    { value: "LORETO", label: "LORETO" },
                    { value: "LUÍS DOMINGUES", label: "LUÍS DOMINGUES" },
                    { value: "MAGALHÃES DE ALMEIDA", label: "MAGALHÃES DE ALMEIDA" },
                    { value: "MARACAÇUMÉ", label: "MARACAÇUMÉ" },
                    { value: "MARAJÁ DO SENA", label: "MARAJÁ DO SENA" },
                    { value: "MARANHÃOZINHO", label: "MARANHÃOZINHO" },
                    { value: "MATA ROMA", label: "MATA ROMA" },
                    { value: "MATINHA", label: "MATINHA" },
                    { value: "MATÕES", label: "MATÕES" },
                    { value: "MATÕES DO NORTE", label: "MATÕES DO NORTE" },
                    { value: "MILAGRES DO MARANHÃO", label: "MILAGRES DO MARANHÃO" },
                    { value: "MIRADOR", label: "MIRADOR" },
                    { value: "MIRANDA DO NORTE", label: "MIRANDA DO NORTE" },
                    { value: "MIRINZAL", label: "MIRINZAL" },
                    { value: "MONÇÃO", label: "MONÇÃO" },
                    { value: "MONTES ALTOS", label: "MONTES ALTOS" },
                    { value: "MORROS", label: "MORROS" },
                    { value: "NINA RODRIGUES", label: "NINA RODRIGUES" },
                    { value: "NOVA COLINAS", label: "NOVA COLINAS" },
                    { value: "NOVA IORQUE", label: "NOVA IORQUE" },
                    { value: "NOVA OLINDA DO MARANHÃO", label: "NOVA OLINDA DO MARANHÃO" },
                    { value: "OLHO D'ÁGUA DAS CUNHÃS", label: "OLHO D'ÁGUA DAS CUNHÃS" },
                    { value: "OLINDA NOVA DO MARANHÃO", label: "OLINDA NOVA DO MARANHÃO" },
                    { value: "PAÇO DO LUMIAR", label: "PAÇO DO LUMIAR" },
                    { value: "PALMEIRÂNDIA", label: "PALMEIRÂNDIA" },
                    { value: "PARAIBANO", label: "PARAIBANO" },
                    { value: "PARNARAMA", label: "PARNARAMA" },
                    { value: "PASSAGEM FRANCA", label: "PASSAGEM FRANCA" },
                    { value: "PASTOS BONS", label: "PASTOS BONS" },
                    { value: "PAULINO NEVES", label: "PAULINO NEVES" },
                    { value: "PAULO RAMOS", label: "PAULO RAMOS" },
                    { value: "PEDREIRAS", label: "PEDREIRAS" },
                    { value: "PEDRO DO ROSÁRIO", label: "PEDRO DO ROSÁRIO" },
                    { value: "PENALVA", label: "PENALVA" },
                    { value: "PERI MIRIM", label: "PERI MIRIM" },
                    { value: "PERITORÓ", label: "PERITORÓ" },
                    { value: "PINDARÉ-MIRIM", label: "PINDARÉ-MIRIM" },
                    { value: "PINHEIRO", label: "PINHEIRO" },
                    { value: "PIO XII", label: "PIO XII" },
                    { value: "PIRAPEMAS", label: "PIRAPEMAS" },
                    { value: "POÇÃO DE PEDRAS", label: "POÇÃO DE PEDRAS" },
                    { value: "PORTO FRANCO", label: "PORTO FRANCO" },
                    { value: "PORTO RICO DO MARANHÃO", label: "PORTO RICO DO MARANHÃO" },
                    { value: "PRESIDENTE DUTRA", label: "PRESIDENTE DUTRA" },
                    { value: "PRESIDENTE JUSCELINO", label: "PRESIDENTE JUSCELINO" },
                    { value: "PRESIDENTE MÉDICI", label: "PRESIDENTE MÉDICI" },
                    { value: "PRESIDENTE SARNEY", label: "PRESIDENTE SARNEY" },
                    { value: "PRESIDENTE VARGAS", label: "PRESIDENTE VARGAS" },
                    { value: "PRIMEIRA CRUZ", label: "PRIMEIRA CRUZ" },
                    { value: "RAPOSA", label: "RAPOSA" },
                    { value: "RIACHÃO", label: "RIACHÃO" },
                    { value: "RIBAMAR FIQUENE", label: "RIBAMAR FIQUENE" },
                    { value: "ROSÁRIO", label: "ROSÁRIO" },
                    { value: "SAMBAÍBA", label: "SAMBAÍBA" },
                    { value: "SANTA FILOMENA DO MARANHÃO", label: "SANTA FILOMENA DO MARANHÃO" },
                    { value: "SANTA HELENA", label: "SANTA HELENA" },
                    { value: "SANTA INÊS", label: "SANTA INÊS" },
                    { value: "SANTA LUZIA", label: "SANTA LUZIA" },
                    { value: "SANTA LUZIA DO PARUÁ", label: "SANTA LUZIA DO PARUÁ" },
                    { value: "SANTA QUITÉRIA DO MARANHÃO", label: "SANTA QUITÉRIA DO MARANHÃO" },
                    { value: "SANTA RITA", label: "SANTA RITA" },
                    { value: "SANTANA DO MARANHÃO", label: "SANTANA DO MARANHÃO" },
                    { value: "SANTO AMARO DO MARANHÃO", label: "SANTO AMARO DO MARANHÃO" },
                    { value: "SANTO ANTÔNIO DOS LOPES", label: "SANTO ANTÔNIO DOS LOPES" },
                    { value: "SÃO BENEDITO DO RIO PRETO", label: "SÃO BENEDITO DO RIO PRETO" },
                    { value: "SÃO BENTO", label: "SÃO BENTO" },
                    { value: "SÃO BERNARDO", label: "SÃO BERNARDO" },
                    { value: "SÃO DOMINGOS DO AZEITÃO", label: "SÃO DOMINGOS DO AZEITÃO" },
                    { value: "SÃO DOMINGOS DO MARANHÃO", label: "SÃO DOMINGOS DO MARANHÃO" },
                    { value: "SÃO FÉLIX DE BALSAS", label: "SÃO FÉLIX DE BALSAS" },
                    { value: "SÃO FRANCISCO DO BREJÃO", label: "SÃO FRANCISCO DO BREJÃO" },
                    { value: "SÃO FRANCISCO DO MARANHÃO", label: "SÃO FRANCISCO DO MARANHÃO" },
                    { value: "SÃO JOÃO BATISTA", label: "SÃO JOÃO BATISTA" },
                    { value: "SÃO JOÃO DO CARÚ", label: "SÃO JOÃO DO CARÚ" },
                    { value: "SÃO JOÃO DO PARAÍSO", label: "SÃO JOÃO DO PARAÍSO" },
                    { value: "SÃO JOÃO DO SOTER", label: "SÃO JOÃO DO SOTER" },
                    { value: "SÃO JOÃO DOS PATOS", label: "SÃO JOÃO DOS PATOS" },
                    { value: "SÃO JOSÉ DE RIBAMAR", label: "SÃO JOSÉ DE RIBAMAR" },
                    { value: "SÃO JOSÉ DOS BASÍLIOS", label: "SÃO JOSÉ DOS BASÍLIOS" },
                    { value: "SÃO LUÍS", label: "SÃO LUÍS" },
                    { value: "SÃO LUÍS GONZAGA DO MARANHÃO", label: "SÃO LUÍS GONZAGA DO MARANHÃO" },
                    { value: "SÃO MATEUS DO MARANHÃO", label: "SÃO MATEUS DO MARANHÃO" },
                    { value: "SÃO PEDRO DA ÁGUA BRANCA", label: "SÃO PEDRO DA ÁGUA BRANCA" },
                    { value: "SÃO PEDRO DOS CRENTES", label: "SÃO PEDRO DOS CRENTES" },
                    { value: "SÃO RAIMUNDO DAS MANGABEIRAS", label: "SÃO RAIMUNDO DAS MANGABEIRAS" },
                    { value: "SÃO RAIMUNDO DO DOCA BEZERRA", label: "SÃO RAIMUNDO DO DOCA BEZERRA" },
                    { value: "SÃO ROBERTO", label: "SÃO ROBERTO" },
                    { value: "SÃO VICENTE FERRER", label: "SÃO VICENTE FERRER" },
                    { value: "SATUBINHA", label: "SATUBINHA" },
                    { value: "SENADOR ALEXANDRE COSTA", label: "SENADOR ALEXANDRE COSTA" },
                    { value: "SENADOR LA ROCQUE", label: "SENADOR LA ROCQUE" },
                    { value: "SERRANO DO MARANHÃO", label: "SERRANO DO MARANHÃO" },
                    { value: "SÍTIO NOVO", label: "SÍTIO NOVO" },
                    { value: "SUCUPIRA DO NORTE", label: "SUCUPIRA DO NORTE" },
                    { value: "SUCUPIRA DO RIACHÃO", label: "SUCUPIRA DO RIACHÃO" },
                    { value: "TASSO FRAGOSO", label: "TASSO FRAGOSO" },
                    { value: "TIMBIRAS", label: "TIMBIRAS" },
                    { value: "TIMON", label: "TIMON" },
                    { value: "TRIZIDELA DO VALE", label: "TRIZIDELA DO VALE" },
                    { value: "TUFILÂNDIA", label: "TUFILÂNDIA" },
                    { value: "TUNTUM", label: "TUNTUM" },
                    { value: "TURIAÇU", label: "TURIAÇU" },
                    { value: "TURILÂNDIA", label: "TURILÂNDIA" },
                    { value: "TUTÓIA", label: "TUTÓIA" },
                    { value: "URBANO SANTOS", label: "URBANO SANTOS" },
                    { value: "VARGEM GRANDE", label: "VARGEM GRANDE" },
                    { value: "VIANA", label: "VIANA" },
                    { value: "VILA NOVA DOS MARTÍRIOS", label: "VILA NOVA DOS MARTÍRIOS" },
                    { value: "VITÓRIA DO MEARIM", label: "VITÓRIA DO MEARIM" },
                    { value: "VITORINO FREIRE", label: "VITORINO FREIRE" },
                    { value: "ZÉ DOCA", label: "ZÉ DOCA" },
               ]
               break;

          case "MATO GROSSO":
               CITIES = [
                    { value: "ACORIZAL", label: "ACORIZAL" },
                    { value: "ÁGUA BOA", label: "ÁGUA BOA" },
                    { value: "ALTA FLORESTA", label: "ALTA FLORESTA" },
                    { value: "ALTO ARAGUAIA", label: "ALTO ARAGUAIA" },
                    { value: "ALTO BOA VISTA", label: "ALTO BOA VISTA" },
                    { value: "ALTO GARÇAS", label: "ALTO GARÇAS" },
                    { value: "ALTO PARAGUAI", label: "ALTO PARAGUAI" },
                    { value: "ALTO TAQUARI", label: "ALTO TAQUARI" },
                    { value: "APIACÁS", label: "APIACÁS" },
                    { value: "ARAGUAIANA", label: "ARAGUAIANA" },
                    { value: "ARAGUAINHA", label: "ARAGUAINHA" },
                    { value: "ARAPUTANGA", label: "ARAPUTANGA" },
                    { value: "ARENÁPOLIS", label: "ARENÁPOLIS" },
                    { value: "ARIPUANÃ", label: "ARIPUANÃ" },
                    { value: "BARÃO DE MELGAÇO", label: "BARÃO DE MELGAÇO" },
                    { value: "BARRA DO BUGRES", label: "BARRA DO BUGRES" },
                    { value: "BARRA DO GARÇAS", label: "BARRA DO GARÇAS" },
                    { value: "BOM JESUS DO ARAGUAIA", label: "BOM JESUS DO ARAGUAIA" },
                    { value: "BRASNORTE", label: "BRASNORTE" },
                    { value: "CÁCERES", label: "CÁCERES" },
                    { value: "CAMPINÁPOLIS", label: "CAMPINÁPOLIS" },
                    { value: "CAMPO NOVO DO PARECIS", label: "CAMPO NOVO DO PARECIS" },
                    { value: "CAMPO VERDE", label: "CAMPO VERDE" },
                    { value: "CAMPOS DE JÚLIO", label: "CAMPOS DE JÚLIO" },
                    { value: "CANABRAVA DO NORTE", label: "CANABRAVA DO NORTE" },
                    { value: "CANARANA", label: "CANARANA" },
                    { value: "CARLINDA", label: "CARLINDA" },
                    { value: "CASTANHEIRA", label: "CASTANHEIRA" },
                    { value: "CHAPADA DOS GUIMARÃES", label: "CHAPADA DOS GUIMARÃES" },
                    { value: "CLÁUDIA", label: "CLÁUDIA" },
                    { value: "COCALINHO", label: "COCALINHO" },
                    { value: "COLÍDER", label: "COLÍDER" },
                    { value: "COLNIZA", label: "COLNIZA" },
                    { value: "COMODORO", label: "COMODORO" },
                    { value: "CONFRESA", label: "CONFRESA" },
                    { value: "CONQUISTA D'OESTE", label: "CONQUISTA D'OESTE" },
                    { value: "COTRIGUAÇU", label: "COTRIGUAÇU" },
                    { value: "CUIABÁ", label: "CUIABÁ" },
                    { value: "CURVELÂNDIA", label: "CURVELÂNDIA" },
                    { value: "DENISE", label: "DENISE" },
                    { value: "DIAMANTINO", label: "DIAMANTINO" },
                    { value: "DOM AQUINO", label: "DOM AQUINO" },
                    { value: "FELIZ NATAL", label: "FELIZ NATAL" },
                    { value: "FIGUEIRÓPOLIS D'OESTE", label: "FIGUEIRÓPOLIS D'OESTE" },
                    { value: "GAÚCHA DO NORTE", label: "GAÚCHA DO NORTE" },
                    { value: "GENERAL CARNEIRO", label: "GENERAL CARNEIRO" },
                    { value: "GLÓRIA D'OESTE", label: "GLÓRIA D'OESTE" },
                    { value: "GUARANTÃ DO NORTE", label: "GUARANTÃ DO NORTE" },
                    { value: "GUIRATINGA", label: "GUIRATINGA" },
                    { value: "INDIAVAÍ", label: "INDIAVAÍ" },
                    { value: "IPIRANGA DO NORTE", label: "IPIRANGA DO NORTE" },
                    { value: "ITANHANGÁ", label: "ITANHANGÁ" },
                    { value: "ITAÚBA", label: "ITAÚBA" },
                    { value: "ITIQUIRA", label: "ITIQUIRA" },
                    { value: "JACIARA", label: "JACIARA" },
                    { value: "JANGADA", label: "JANGADA" },
                    { value: "JAURU", label: "JAURU" },
                    { value: "JUARA", label: "JUARA" },
                    { value: "JUÍNA", label: "JUÍNA" },
                    { value: "JURUENA", label: "JURUENA" },
                    { value: "JUSCIMEIRA", label: "JUSCIMEIRA" },
                    { value: "LAMBARI D'OESTE", label: "LAMBARI D'OESTE" },
                    { value: "LUCAS DO RIO VERDE", label: "LUCAS DO RIO VERDE" },
                    { value: "LUCIARA", label: "LUCIARA" },
                    { value: "MARCELÂNDIA", label: "MARCELÂNDIA" },
                    { value: "MATUPÁ", label: "MATUPÁ" },
                    { value: "MIRASSOL D'OESTE", label: "MIRASSOL D'OESTE" },
                    { value: "NOBRES", label: "NOBRES" },
                    { value: "NORTELÂNDIA", label: "NORTELÂNDIA" },
                    { value: "NOSSA SENHORA DO LIVRAMENTO", label: "NOSSA SENHORA DO LIVRAMENTO" },
                    { value: "NOVA BANDEIRANTES", label: "NOVA BANDEIRANTES" },
                    { value: "NOVA BRASILÂNDIA", label: "NOVA BRASILÂNDIA" },
                    { value: "NOVA CANAÃ DO NORTE", label: "NOVA CANAÃ DO NORTE" },
                    { value: "NOVA GUARITA", label: "NOVA GUARITA" },
                    { value: "NOVA LACERDA", label: "NOVA LACERDA" },
                    { value: "NOVA MARILÂNDIA", label: "NOVA MARILÂNDIA" },
                    { value: "NOVA MARINGÁ", label: "NOVA MARINGÁ" },
                    { value: "NOVA MONTE VERDE", label: "NOVA MONTE VERDE" },
                    { value: "NOVA MUTUM", label: "NOVA MUTUM" },
                    { value: "NOVA NAZARÉ", label: "NOVA NAZARÉ" },
                    { value: "NOVA OLÍMPIA", label: "NOVA OLÍMPIA" },
                    { value: "NOVA SANTA HELENA", label: "NOVA SANTA HELENA" },
                    { value: "NOVA UBIRATÃ", label: "NOVA UBIRATÃ" },
                    { value: "NOVA XAVANTINA", label: "NOVA XAVANTINA" },
                    { value: "NOVO HORIZONTE DO NORTE", label: "NOVO HORIZONTE DO NORTE" },
                    { value: "NOVO MUNDO", label: "NOVO MUNDO" },
                    { value: "NOVO SANTO ANTÔNIO", label: "NOVO SANTO ANTÔNIO" },
                    { value: "NOVO SÃO JOAQUIM", label: "NOVO SÃO JOAQUIM" },
                    { value: "PARANAÍTA", label: "PARANAÍTA" },
                    { value: "PARANATINGA", label: "PARANATINGA" },
                    { value: "PEDRA PRETA", label: "PEDRA PRETA" },
                    { value: "PEIXOTO DE AZEVEDO", label: "PEIXOTO DE AZEVEDO" },
                    { value: "PLANALTO DA SERRA", label: "PLANALTO DA SERRA" },
                    { value: "POCONÉ", label: "POCONÉ" },
                    { value: "PONTAL DO ARAGUAIA", label: "PONTAL DO ARAGUAIA" },
                    { value: "PONTE BRANCA", label: "PONTE BRANCA" },
                    { value: "PONTES E LACERDA", label: "PONTES E LACERDA" },
                    { value: "PORTO ALEGRE DO NORTE", label: "PORTO ALEGRE DO NORTE" },
                    { value: "PORTO DOS GAÚCHOS", label: "PORTO DOS GAÚCHOS" },
                    { value: "PORTO ESPERIDIÃO", label: "PORTO ESPERIDIÃO" },
                    { value: "PORTO ESTRELA", label: "PORTO ESTRELA" },
                    { value: "POXORÉO", label: "POXORÉO" },
                    { value: "PRIMAVERA DO LESTE", label: "PRIMAVERA DO LESTE" },
                    { value: "QUERÊNCIA", label: "QUERÊNCIA" },
                    { value: "RESERVA DO CABAÇAL", label: "RESERVA DO CABAÇAL" },
                    { value: "RIBEIRÃO CASCALHEIRA", label: "RIBEIRÃO CASCALHEIRA" },
                    { value: "RIBEIRÃOZINHO", label: "RIBEIRÃOZINHO" },
                    { value: "RIO BRANCO", label: "RIO BRANCO" },
                    { value: "RONDOLÂNDIA", label: "RONDOLÂNDIA" },
                    { value: "RONDONÓPOLIS", label: "RONDONÓPOLIS" },
                    { value: "ROSÁRIO OESTE", label: "ROSÁRIO OESTE" },
                    { value: "SALTO DO CÉU", label: "SALTO DO CÉU" },
                    { value: "SANTA CARMEM", label: "SANTA CARMEM" },
                    { value: "SANTA CRUZ DO XINGU", label: "SANTA CRUZ DO XINGU" },
                    { value: "SANTA RITA DO TRIVELATO", label: "SANTA RITA DO TRIVELATO" },
                    { value: "SANTA TEREZINHA", label: "SANTA TEREZINHA" },
                    { value: "SANTO AFONSO", label: "SANTO AFONSO" },
                    { value: "SANTO ANTÔNIO DO LESTE", label: "SANTO ANTÔNIO DO LESTE" },
                    { value: "SANTO ANTÔNIO DO LEVERGER", label: "SANTO ANTÔNIO DO LEVERGER" },
                    { value: "SÃO FÉLIX DO ARAGUAIA", label: "SÃO FÉLIX DO ARAGUAIA" },
                    { value: "SÃO JOSÉ DO POVO", label: "SÃO JOSÉ DO POVO" },
                    { value: "SÃO JOSÉ DO RIO CLARO", label: "SÃO JOSÉ DO RIO CLARO" },
                    { value: "SÃO JOSÉ DO XINGU", label: "SÃO JOSÉ DO XINGU" },
                    { value: "SÃO JOSÉ DOS QUATRO MARCOS", label: "SÃO JOSÉ DOS QUATRO MARCOS" },
                    { value: "SÃO PEDRO DA CIPA", label: "SÃO PEDRO DA CIPA" },
                    { value: "SAPEZAL", label: "SAPEZAL" },
                    { value: "SERRA NOVA DOURADA", label: "SERRA NOVA DOURADA" },
                    { value: "SINOP", label: "SINOP" },
                    { value: "SORRISO", label: "SORRISO" },
                    { value: "TABAPORÃ", label: "TABAPORÃ" },
                    { value: "TANGARÁ DA SERRA", label: "TANGARÁ DA SERRA" },
                    { value: "TAPURAH", label: "TAPURAH" },
                    { value: "TERRA NOVA DO NORTE", label: "TERRA NOVA DO NORTE" },
                    { value: "TESOURO", label: "TESOURO" },
                    { value: "TORIXORÉU", label: "TORIXORÉU" },
                    { value: "UNIÃO DO SUL", label: "UNIÃO DO SUL" },
                    { value: "VALE DE SÃO DOMINGOS", label: "VALE DE SÃO DOMINGOS" },
                    { value: "VÁRZEA GRANDE", label: "VÁRZEA GRANDE" },
                    { value: "VERA", label: "VERA" },
                    { value: "VILA BELA DA SANTÍSSIMA TRINDADE", label: "VILA BELA DA SANTÍSSIMA TRINDADE" },
                    { value: "VILA RICA", label: "VILA RICA" },
               ]
               break;

          case "MATO GROSSO DO SUL":
               CITIES = [
                    { value: "ÁGUA CLARA", label: "ÁGUA CLARA" },
                    { value: "ALCINÓPOLIS", label: "ALCINÓPOLIS" },
                    { value: "AMAMBAI", label: "AMAMBAI" },
                    { value: "ANASTÁCIO", label: "ANASTÁCIO" },
                    { value: "ANAURILÂNDIA", label: "ANAURILÂNDIA" },
                    { value: "ANGÉLICA", label: "ANGÉLICA" },
                    { value: "ANTÔNIO JOÃO", label: "ANTÔNIO JOÃO" },
                    { value: "APARECIDA DO TABOADO", label: "APARECIDA DO TABOADO" },
                    { value: "AQUIDAUANA", label: "AQUIDAUANA" },
                    { value: "ARAL MOREIRA", label: "ARAL MOREIRA" },
                    { value: "BANDEIRANTES", label: "BANDEIRANTES" },
                    { value: "BATAGUASSU", label: "BATAGUASSU" },
                    { value: "BATAYPORÃ", label: "BATAYPORÃ" },
                    { value: "BELA VISTA", label: "BELA VISTA" },
                    { value: "BODOQUENA", label: "BODOQUENA" },
                    { value: "BONITO", label: "BONITO" },
                    { value: "BRASILÂNDIA", label: "BRASILÂNDIA" },
                    { value: "CAARAPÓ", label: "CAARAPÓ" },
                    { value: "CAMAPUÃ", label: "CAMAPUÃ" },
                    { value: "CAMPO GRANDE", label: "CAMPO GRANDE" },
                    { value: "CARACOL", label: "CARACOL" },
                    { value: "CASSILÂNDIA", label: "CASSILÂNDIA" },
                    { value: "CHAPADÃO DO SUL", label: "CHAPADÃO DO SUL" },
                    { value: "CORGUINHO", label: "CORGUINHO" },
                    { value: "CORONEL SAPUCAIA", label: "CORONEL SAPUCAIA" },
                    { value: "CORUMBÁ", label: "CORUMBÁ" },
                    { value: "COSTA RICA", label: "COSTA RICA" },
                    { value: "COXIM", label: "COXIM" },
                    { value: "DEODÁPOLIS", label: "DEODÁPOLIS" },
                    { value: "DOIS IRMÃOS DO BURITI", label: "DOIS IRMÃOS DO BURITI" },
                    { value: "DOURADINA", label: "DOURADINA" },
                    { value: "DOURADOS", label: "DOURADOS" },
                    { value: "ELDORADO", label: "ELDORADO" },
                    { value: "FÁTIMA DO SUL", label: "FÁTIMA DO SUL" },
                    { value: "FIGUEIRÃO", label: "FIGUEIRÃO" },
                    { value: "GLÓRIA DE DOURADOS", label: "GLÓRIA DE DOURADOS" },
                    { value: "GUIA LOPES DA LAGUNA", label: "GUIA LOPES DA LAGUNA" },
                    { value: "IGUATEMI", label: "IGUATEMI" },
                    { value: "INOCÊNCIA", label: "INOCÊNCIA" },
                    { value: "ITAPORÃ", label: "ITAPORÃ" },
                    { value: "ITAQUIRAÍ", label: "ITAQUIRAÍ" },
                    { value: "IVINHEMA", label: "IVINHEMA" },
                    { value: "JAPORÃ", label: "JAPORÃ" },
                    { value: "JARAGUARI", label: "JARAGUARI" },
                    { value: "JARDIM", label: "JARDIM" },
                    { value: "JATEÍ", label: "JATEÍ" },
                    { value: "JUTI", label: "JUTI" },
                    { value: "LADÁRIO", label: "LADÁRIO" },
                    { value: "LAGUNA CARAPÃ", label: "LAGUNA CARAPÃ" },
                    { value: "MARACAJU", label: "MARACAJU" },
                    { value: "MIRANDA", label: "MIRANDA" },
                    { value: "MUNDO NOVO", label: "MUNDO NOVO" },
                    { value: "NAVIRAÍ", label: "NAVIRAÍ" },
                    { value: "NIOAQUE", label: "NIOAQUE" },
                    { value: "NOVA ALVORADA DO SUL", label: "NOVA ALVORADA DO SUL" },
                    { value: "NOVA ANDRADINA", label: "NOVA ANDRADINA" },
                    { value: "NOVO HORIZONTE DO SUL", label: "NOVO HORIZONTE DO SUL" },
                    { value: "PARAÍSO DAS ÁGUAS", label: "PARAÍSO DAS ÁGUAS" },
                    { value: "PARANAÍBA", label: "PARANAÍBA" },
                    { value: "PARANHOS", label: "PARANHOS" },
                    { value: "PEDRO GOMES", label: "PEDRO GOMES" },
                    { value: "PONTA PORÃ", label: "PONTA PORÃ" },
                    { value: "PORTO MURTINHO", label: "PORTO MURTINHO" },
                    { value: "RIBAS DO RIO PARDO", label: "RIBAS DO RIO PARDO" },
                    { value: "RIO BRILHANTE", label: "RIO BRILHANTE" },
                    { value: "RIO NEGRO", label: "RIO NEGRO" },
                    { value: "RIO VERDE DE MATO GROSSO", label: "RIO VERDE DE MATO GROSSO" },
                    { value: "ROCHEDO", label: "ROCHEDO" },
                    { value: "SANTA RITA DO PARDO", label: "SANTA RITA DO PARDO" },
                    { value: "SÃO GABRIEL DO OESTE", label: "SÃO GABRIEL DO OESTE" },
                    { value: "SELVÍRIA", label: "SELVÍRIA" },
                    { value: "SETE QUEDAS", label: "SETE QUEDAS" },
                    { value: "SIDROLÂNDIA", label: "SIDROLÂNDIA" },
                    { value: "SONORA", label: "SONORA" },
                    { value: "TACURU", label: "TACURU" },
                    { value: "TAQUARUSSU", label: "TAQUARUSSU" },
                    { value: "TERENOS", label: "TERENOS" },
                    { value: "TRÊS LAGOAS", label: "TRÊS LAGOAS" },
                    { value: "VICENTINA", label: "VICENTINA" },
               ]
               break;

          case "MINAS GERAIS":
               CITIES = [
                    { value: "ABADIA DOS DOURADOS", label: "ABADIA DOS DOURADOS" },
                    { value: "ABAETÉ", label: "ABAETÉ" },
                    { value: "ABRE CAMPO", label: "ABRE CAMPO" },
                    { value: "ACAIACA", label: "ACAIACA" },
                    { value: "AÇUCENA", label: "AÇUCENA" },
                    { value: "ÁGUA BOA", label: "ÁGUA BOA" },
                    { value: "ÁGUA COMPRIDA", label: "ÁGUA COMPRIDA" },
                    { value: "AGUANIL", label: "AGUANIL" },
                    { value: "ÁGUAS FORMOSAS", label: "ÁGUAS FORMOSAS" },
                    { value: "ÁGUAS VERMELHAS", label: "ÁGUAS VERMELHAS" },
                    { value: "AIMORÉS", label: "AIMORÉS" },
                    { value: "AIURUOCA", label: "AIURUOCA" },
                    { value: "ALAGOA", label: "ALAGOA" },
                    { value: "ALBERTINA", label: "ALBERTINA" },
                    { value: "ALÉM PARAÍBA", label: "ALÉM PARAÍBA" },
                    { value: "ALFENAS", label: "ALFENAS" },
                    { value: "ALFREDO VASCONCELOS", label: "ALFREDO VASCONCELOS" },
                    { value: "ALMENARA", label: "ALMENARA" },
                    { value: "ALPERCATA", label: "ALPERCATA" },
                    { value: "ALPINÓPOLIS", label: "ALPINÓPOLIS" },
                    { value: "ALTEROSA", label: "ALTEROSA" },
                    { value: "ALTO CAPARAÓ", label: "ALTO CAPARAÓ" },
                    { value: "ALTO JEQUITIBÁ", label: "ALTO JEQUITIBÁ" },
                    { value: "ALTO RIO DOCE", label: "ALTO RIO DOCE" },
                    { value: "ALVARENGA", label: "ALVARENGA" },
                    { value: "ALVINÓPOLIS", label: "ALVINÓPOLIS" },
                    { value: "ALVORADA DE MINAS", label: "ALVORADA DE MINAS" },
                    { value: "AMPARO DO SERRA", label: "AMPARO DO SERRA" },
                    { value: "ANDRADAS", label: "ANDRADAS" },
                    { value: "ANDRELÂNDIA", label: "ANDRELÂNDIA" },
                    { value: "ANGELÂNDIA", label: "ANGELÂNDIA" },
                    { value: "ANTÔNIO CARLOS", label: "ANTÔNIO CARLOS" },
                    { value: "ANTÔNIO DIAS", label: "ANTÔNIO DIAS" },
                    { value: "ANTÔNIO PRADO DE MINAS", label: "ANTÔNIO PRADO DE MINAS" },
                    { value: "ARAÇAÍ", label: "ARAÇAÍ" },
                    { value: "ARACITABA", label: "ARACITABA" },
                    { value: "ARAÇUAÍ", label: "ARAÇUAÍ" },
                    { value: "ARAGUARI", label: "ARAGUARI" },
                    { value: "ARANTINA", label: "ARANTINA" },
                    { value: "ARAPONGA", label: "ARAPONGA" },
                    { value: "ARAPORÃ", label: "ARAPORÃ" },
                    { value: "ARAPUÁ", label: "ARAPUÁ" },
                    { value: "ARAÚJOS", label: "ARAÚJOS" },
                    { value: "ARAXÁ", label: "ARAXÁ" },
                    { value: "ARCEBURGO", label: "ARCEBURGO" },
                    { value: "ARCOS", label: "ARCOS" },
                    { value: "AREADO", label: "AREADO" },
                    { value: "ARGIRITA", label: "ARGIRITA" },
                    { value: "ARICANDUVA", label: "ARICANDUVA" },
                    { value: "ARINOS", label: "ARINOS" },
                    { value: "ASTOLFO DUTRA", label: "ASTOLFO DUTRA" },
                    { value: "ATALÉIA", label: "ATALÉIA" },
                    { value: "AUGUSTO DE LIMA", label: "AUGUSTO DE LIMA" },
                    { value: "BAEPENDI", label: "BAEPENDI" },
                    { value: "BALDIM", label: "BALDIM" },
                    { value: "BAMBUÍ", label: "BAMBUÍ" },
                    { value: "BANDEIRA", label: "BANDEIRA" },
                    { value: "BANDEIRA DO SUL", label: "BANDEIRA DO SUL" },
                    { value: "BARÃO DE COCAIS", label: "BARÃO DE COCAIS" },
                    { value: "BARÃO DE MONTE ALTO", label: "BARÃO DE MONTE ALTO" },
                    { value: "BARBACENA", label: "BARBACENA" },
                    { value: "BARRA LONGA", label: "BARRA LONGA" },
                    { value: "BARROSO", label: "BARROSO" },
                    { value: "BELA VISTA DE MINAS", label: "BELA VISTA DE MINAS" },
                    { value: "BELMIRO BRAGA", label: "BELMIRO BRAGA" },
                    { value: "BELO HORIZONTE", label: "BELO HORIZONTE" },
                    { value: "BELO ORIENTE", label: "BELO ORIENTE" },
                    { value: "BELO VALE", label: "BELO VALE" },
                    { value: "BERILO", label: "BERILO" },
                    { value: "BERIZAL", label: "BERIZAL" },
                    { value: "BERTÓPOLIS", label: "BERTÓPOLIS" },
                    { value: "BETIM", label: "BETIM" },
                    { value: "BIAS FORTES", label: "BIAS FORTES" },
                    { value: "BICAS", label: "BICAS" },
                    { value: "BIQUINHAS", label: "BIQUINHAS" },
                    { value: "BOA ESPERANÇA", label: "BOA ESPERANÇA" },
                    { value: "BOCAINA DE MINAS", label: "BOCAINA DE MINAS" },
                    { value: "BOCAIÚVA", label: "BOCAIÚVA" },
                    { value: "BOM DESPACHO", label: "BOM DESPACHO" },
                    { value: "BOM JARDIM DE MINAS", label: "BOM JARDIM DE MINAS" },
                    { value: "BOM JESUS DA PENHA", label: "BOM JESUS DA PENHA" },
                    { value: "BOM JESUS DO AMPARO", label: "BOM JESUS DO AMPARO" },
                    { value: "BOM JESUS DO GALHO", label: "BOM JESUS DO GALHO" },
                    { value: "BOM REPOUSO", label: "BOM REPOUSO" },
                    { value: "BOM SUCESSO", label: "BOM SUCESSO" },
                    { value: "BONFIM", label: "BONFIM" },
                    { value: "BONFINÓPOLIS DE MINAS", label: "BONFINÓPOLIS DE MINAS" },
                    { value: "BONITO DE MINAS", label: "BONITO DE MINAS" },
                    { value: "BORDA DA MATA", label: "BORDA DA MATA" },
                    { value: "BOTELHOS", label: "BOTELHOS" },
                    { value: "BOTUMIRIM", label: "BOTUMIRIM" },
                    { value: "BRÁS PIRES", label: "BRÁS PIRES" },
                    { value: "BRASILÂNDIA DE MINAS", label: "BRASILÂNDIA DE MINAS" },
                    { value: "BRASÍLIA DE MINAS", label: "BRASÍLIA DE MINAS" },
                    { value: "BRAÚNAS", label: "BRAÚNAS" },
                    { value: "BRAZÓPOLIS", label: "BRAZÓPOLIS" },
                    { value: "BRUMADINHO", label: "BRUMADINHO" },
                    { value: "BUENO BRANDÃO", label: "BUENO BRANDÃO" },
                    { value: "BUENÓPOLIS", label: "BUENÓPOLIS" },
                    { value: "BUGRE", label: "BUGRE" },
                    { value: "BURITIS", label: "BURITIS" },
                    { value: "BURITIZEIRO", label: "BURITIZEIRO" },
                    { value: "CABECEIRA GRANDE", label: "CABECEIRA GRANDE" },
                    { value: "CABO VERDE", label: "CABO VERDE" },
                    { value: "CACHOEIRA DA PRATA", label: "CACHOEIRA DA PRATA" },
                    { value: "CACHOEIRA DE MINAS", label: "CACHOEIRA DE MINAS" },
                    { value: "CACHOEIRA DE PAJEÚ", label: "CACHOEIRA DE PAJEÚ" },
                    { value: "CACHOEIRA DOURADA", label: "CACHOEIRA DOURADA" },
                    { value: "CAETANÓPOLIS", label: "CAETANÓPOLIS" },
                    { value: "CAETÉ", label: "CAETÉ" },
                    { value: "CAIANA", label: "CAIANA" },
                    { value: "CAJURI", label: "CAJURI" },
                    { value: "CALDAS", label: "CALDAS" },
                    { value: "CAMACHO", label: "CAMACHO" },
                    { value: "CAMANDUCAIA", label: "CAMANDUCAIA" },
                    { value: "CAMBUÍ", label: "CAMBUÍ" },
                    { value: "CAMBUQUIRA", label: "CAMBUQUIRA" },
                    { value: "CAMPANÁRIO", label: "CAMPANÁRIO" },
                    { value: "CAMPANHA", label: "CAMPANHA" },
                    { value: "CAMPESTRE", label: "CAMPESTRE" },
                    { value: "CAMPINA VERDE", label: "CAMPINA VERDE" },
                    { value: "CAMPO AZUL", label: "CAMPO AZUL" },
                    { value: "CAMPO BELO", label: "CAMPO BELO" },
                    { value: "CAMPO DO MEIO", label: "CAMPO DO MEIO" },
                    { value: "CAMPO FLORIDO", label: "CAMPO FLORIDO" },
                    { value: "CAMPOS ALTOS", label: "CAMPOS ALTOS" },
                    { value: "CAMPOS GERAIS", label: "CAMPOS GERAIS" },
                    { value: "CANA VERDE", label: "CANA VERDE" },
                    { value: "CANAÃ", label: "CANAÃ" },
                    { value: "CANÁPOLIS", label: "CANÁPOLIS" },
                    { value: "CANDEIAS", label: "CANDEIAS" },
                    { value: "CANTAGALO", label: "CANTAGALO" },
                    { value: "CAPARAÓ", label: "CAPARAÓ" },
                    { value: "CAPELA NOVA", label: "CAPELA NOVA" },
                    { value: "CAPELINHA", label: "CAPELINHA" },
                    { value: "CAPETINGA", label: "CAPETINGA" },
                    { value: "CAPIM BRANCO", label: "CAPIM BRANCO" },
                    { value: "CAPINÓPOLIS", label: "CAPINÓPOLIS" },
                    { value: "CAPITÃO ANDRADE", label: "CAPITÃO ANDRADE" },
                    { value: "CAPITÃO ENÉAS", label: "CAPITÃO ENÉAS" },
                    { value: "CAPITÓLIO", label: "CAPITÓLIO" },
                    { value: "CAPUTIRA", label: "CAPUTIRA" },
                    { value: "CARAÍ", label: "CARAÍ" },
                    { value: "CARANAÍBA", label: "CARANAÍBA" },
                    { value: "CARANDAÍ", label: "CARANDAÍ" },
                    { value: "CARANGOLA", label: "CARANGOLA" },
                    { value: "CARATINGA", label: "CARATINGA" },
                    { value: "CARBONITA", label: "CARBONITA" },
                    { value: "CAREAÇU", label: "CAREAÇU" },
                    { value: "CARLOS CHAGAS", label: "CARLOS CHAGAS" },
                    { value: "CARMÉSIA", label: "CARMÉSIA" },
                    { value: "CARMO DA CACHOEIRA", label: "CARMO DA CACHOEIRA" },
                    { value: "CARMO DA MATA", label: "CARMO DA MATA" },
                    { value: "CARMO DE MINAS", label: "CARMO DE MINAS" },
                    { value: "CARMO DO CAJURU", label: "CARMO DO CAJURU" },
                    { value: "CARMO DO PARANAÍBA", label: "CARMO DO PARANAÍBA" },
                    { value: "CARMO DO RIO CLARO", label: "CARMO DO RIO CLARO" },
                    { value: "CARMÓPOLIS DE MINAS", label: "CARMÓPOLIS DE MINAS" },
                    { value: "CARNEIRINHO", label: "CARNEIRINHO" },
                    { value: "CARRANCAS", label: "CARRANCAS" },
                    { value: "CARVALHÓPOLIS", label: "CARVALHÓPOLIS" },
                    { value: "CARVALHOS", label: "CARVALHOS" },
                    { value: "CASA GRANDE", label: "CASA GRANDE" },
                    { value: "CASCALHO RICO", label: "CASCALHO RICO" },
                    { value: "CÁSSIA", label: "CÁSSIA" },
                    { value: "CATAGUASES", label: "CATAGUASES" },
                    { value: "CATAS ALTAS", label: "CATAS ALTAS" },
                    { value: "CATAS ALTAS DA NORUEGA", label: "CATAS ALTAS DA NORUEGA" },
                    { value: "CATUJI", label: "CATUJI" },
                    { value: "CATUTI", label: "CATUTI" },
                    { value: "CAXAMBU", label: "CAXAMBU" },
                    { value: "CEDRO DO ABAETÉ", label: "CEDRO DO ABAETÉ" },
                    { value: "CENTRAL DE MINAS", label: "CENTRAL DE MINAS" },
                    { value: "CENTRALINA", label: "CENTRALINA" },
                    { value: "CHÁCARA", label: "CHÁCARA" },
                    { value: "CHALÉ", label: "CHALÉ" },
                    { value: "CHAPADA DO NORTE", label: "CHAPADA DO NORTE" },
                    { value: "CHAPADA GAÚCHA", label: "CHAPADA GAÚCHA" },
                    { value: "CHIADOR", label: "CHIADOR" },
                    { value: "CIPOTÂNEA", label: "CIPOTÂNEA" },
                    { value: "CLARAVAL", label: "CLARAVAL" },
                    { value: "CLARO DOS POÇÕES", label: "CLARO DOS POÇÕES" },
                    { value: "CLÁUDIO", label: "CLÁUDIO" },
                    { value: "COIMBRA", label: "COIMBRA" },
                    { value: "COLUNA", label: "COLUNA" },
                    { value: "COMENDADOR GOMES", label: "COMENDADOR GOMES" },
                    { value: "COMERCINHO", label: "COMERCINHO" },
                    { value: "CONCEIÇÃO DA APARECIDA", label: "CONCEIÇÃO DA APARECIDA" },
                    { value: "CONCEIÇÃO DA BARRA DE MINAS", label: "CONCEIÇÃO DA BARRA DE MINAS" },
                    { value: "CONCEIÇÃO DAS ALAGOAS", label: "CONCEIÇÃO DAS ALAGOAS" },
                    { value: "CONCEIÇÃO DAS PEDRAS", label: "CONCEIÇÃO DAS PEDRAS" },
                    { value: "CONCEIÇÃO DE IPANEMA", label: "CONCEIÇÃO DE IPANEMA" },
                    { value: "CONCEIÇÃO DO MATO DENTRO", label: "CONCEIÇÃO DO MATO DENTRO" },
                    { value: "CONCEIÇÃO DO PARÁ", label: "CONCEIÇÃO DO PARÁ" },
                    { value: "CONCEIÇÃO DO RIO VERDE", label: "CONCEIÇÃO DO RIO VERDE" },
                    { value: "CONCEIÇÃO DOS OUROS", label: "CONCEIÇÃO DOS OUROS" },
                    { value: "CÔNEGO MARINHO", label: "CÔNEGO MARINHO" },
                    { value: "CONFINS", label: "CONFINS" },
                    { value: "CONGONHAL", label: "CONGONHAL" },
                    { value: "CONGONHAS", label: "CONGONHAS" },
                    { value: "CONGONHAS DO NORTE", label: "CONGONHAS DO NORTE" },
                    { value: "CONQUISTA", label: "CONQUISTA" },
                    { value: "CONSELHEIRO LAFAIETE", label: "CONSELHEIRO LAFAIETE" },
                    { value: "CONSELHEIRO PENA", label: "CONSELHEIRO PENA" },
                    { value: "CONSOLAÇÃO", label: "CONSOLAÇÃO" },
                    { value: "CONTAGEM", label: "CONTAGEM" },
                    { value: "COQUEIRAL", label: "COQUEIRAL" },
                    { value: "CORAÇÃO DE JESUS", label: "CORAÇÃO DE JESUS" },
                    { value: "CORDISBURGO", label: "CORDISBURGO" },
                    { value: "CORDISLÂNDIA", label: "CORDISLÂNDIA" },
                    { value: "CORINTO", label: "CORINTO" },
                    { value: "COROACI", label: "COROACI" },
                    { value: "COROMANDEL", label: "COROMANDEL" },
                    { value: "CORONEL FABRICIANO", label: "CORONEL FABRICIANO" },
                    { value: "CORONEL MURTA", label: "CORONEL MURTA" },
                    { value: "CORONEL PACHECO", label: "CORONEL PACHECO" },
                    { value: "CORONEL XAVIER CHAVES", label: "CORONEL XAVIER CHAVES" },
                    { value: "CÓRREGO DANTA", label: "CÓRREGO DANTA" },
                    { value: "CÓRREGO DO BOM JESUS", label: "CÓRREGO DO BOM JESUS" },
                    { value: "CÓRREGO FUNDO", label: "CÓRREGO FUNDO" },
                    { value: "CÓRREGO NOVO", label: "CÓRREGO NOVO" },
                    { value: "COUTO DE MAGALHÃES DE MINAS", label: "COUTO DE MAGALHÃES DE MINAS" },
                    { value: "CRISÓLITA", label: "CRISÓLITA" },
                    { value: "CRISTAIS", label: "CRISTAIS" },
                    { value: "CRISTÁLIA", label: "CRISTÁLIA" },
                    { value: "CRISTIANO OTONI", label: "CRISTIANO OTONI" },
                    { value: "CRISTINA", label: "CRISTINA" },
                    { value: "CRUCILÂNDIA", label: "CRUCILÂNDIA" },
                    { value: "CRUZEIRO DA FORTALEZA", label: "CRUZEIRO DA FORTALEZA" },
                    { value: "CRUZÍLIA", label: "CRUZÍLIA" },
                    { value: "CUPARAQUE", label: "CUPARAQUE" },
                    { value: "CURRAL DE DENTRO", label: "CURRAL DE DENTRO" },
                    { value: "CURVELO", label: "CURVELO" },
                    { value: "DATAS", label: "DATAS" },
                    { value: "DELFIM MOREIRA", label: "DELFIM MOREIRA" },
                    { value: "DELFINÓPOLIS", label: "DELFINÓPOLIS" },
                    { value: "DELTA", label: "DELTA" },
                    { value: "DESCOBERTO", label: "DESCOBERTO" },
                    { value: "DESTERRO DE ENTRE RIOS", label: "DESTERRO DE ENTRE RIOS" },
                    { value: "DESTERRO DO MELO", label: "DESTERRO DO MELO" },
                    { value: "DIAMANTINA", label: "DIAMANTINA" },
                    { value: "DIOGO DE VASCONCELOS", label: "DIOGO DE VASCONCELOS" },
                    { value: "DIONÍSIO", label: "DIONÍSIO" },
                    { value: "DIVINÉSIA", label: "DIVINÉSIA" },
                    { value: "DIVINO", label: "DIVINO" },
                    { value: "DIVINO DAS LARANJEIRAS", label: "DIVINO DAS LARANJEIRAS" },
                    { value: "DIVINOLÂNDIA DE MINAS", label: "DIVINOLÂNDIA DE MINAS" },
                    { value: "DIVINÓPOLIS", label: "DIVINÓPOLIS" },
                    { value: "DIVISA ALEGRE", label: "DIVISA ALEGRE" },
                    { value: "DIVISA NOVA", label: "DIVISA NOVA" },
                    { value: "DIVISÓPOLIS", label: "DIVISÓPOLIS" },
                    { value: "DOM BOSCO", label: "DOM BOSCO" },
                    { value: "DOM CAVATI", label: "DOM CAVATI" },
                    { value: "DOM JOAQUIM", label: "DOM JOAQUIM" },
                    { value: "DOM SILVÉRIO", label: "DOM SILVÉRIO" },
                    { value: "DOM VIÇOSO", label: "DOM VIÇOSO" },
                    { value: "DONA EUSÉBIA", label: "DONA EUSÉBIA" },
                    { value: "DORES DE CAMPOS", label: "DORES DE CAMPOS" },
                    { value: "DORES DE GUANHÃES", label: "DORES DE GUANHÃES" },
                    { value: "DORES DO INDAIÁ", label: "DORES DO INDAIÁ" },
                    { value: "DORES DO TURVO", label: "DORES DO TURVO" },
                    { value: "DORESÓPOLIS", label: "DORESÓPOLIS" },
                    { value: "DOURADOQUARA", label: "DOURADOQUARA" },
                    { value: "DURANDÉ", label: "DURANDÉ" },
                    { value: "ELÓI MENDES", label: "ELÓI MENDES" },
                    { value: "ENGENHEIRO CALDAS", label: "ENGENHEIRO CALDAS" },
                    { value: "ENGENHEIRO NAVARRO", label: "ENGENHEIRO NAVARRO" },
                    { value: "ENTRE FOLHAS", label: "ENTRE FOLHAS" },
                    { value: "ENTRE RIOS DE MINAS", label: "ENTRE RIOS DE MINAS" },
                    { value: "ERVÁLIA", label: "ERVÁLIA" },
                    { value: "ESMERALDAS", label: "ESMERALDAS" },
                    { value: "ESPERA FELIZ", label: "ESPERA FELIZ" },
                    { value: "ESPINOSA", label: "ESPINOSA" },
                    { value: "ESPÍRITO SANTO DO DOURADO", label: "ESPÍRITO SANTO DO DOURADO" },
                    { value: "ESTIVA", label: "ESTIVA" },
                    { value: "ESTRELA DALVA", label: "ESTRELA DALVA" },
                    { value: "ESTRELA DO INDAIÁ", label: "ESTRELA DO INDAIÁ" },
                    { value: "ESTRELA DO SUL", label: "ESTRELA DO SUL" },
                    { value: "EUGENÓPOLIS", label: "EUGENÓPOLIS" },
                    { value: "EWBANK DA CÂMARA", label: "EWBANK DA CÂMARA" },
                    { value: "EXTREMA", label: "EXTREMA" },
                    { value: "FAMA", label: "FAMA" },
                    { value: "FARIA LEMOS", label: "FARIA LEMOS" },
                    { value: "FELÍCIO DOS SANTOS", label: "FELÍCIO DOS SANTOS" },
                    { value: "FELISBURGO", label: "FELISBURGO" },
                    { value: "FELIXLÂNDIA", label: "FELIXLÂNDIA" },
                    { value: "FERNANDES TOURINHO", label: "FERNANDES TOURINHO" },
                    { value: "FERROS", label: "FERROS" },
                    { value: "FERVEDOURO", label: "FERVEDOURO" },
                    { value: "FLORESTAL", label: "FLORESTAL" },
                    { value: "FORMIGA", label: "FORMIGA" },
                    { value: "FORMOSO", label: "FORMOSO" },
                    { value: "FORTALEZA DE MINAS", label: "FORTALEZA DE MINAS" },
                    { value: "FORTUNA DE MINAS", label: "FORTUNA DE MINAS" },
                    { value: "FRANCISCO BADARÓ", label: "FRANCISCO BADARÓ" },
                    { value: "FRANCISCO DUMONT", label: "FRANCISCO DUMONT" },
                    { value: "FRANCISCO SÁ", label: "FRANCISCO SÁ" },
                    { value: "FRANCISCÓPOLIS", label: "FRANCISCÓPOLIS" },
                    { value: "FREI GASPAR", label: "FREI GASPAR" },
                    { value: "FREI INOCÊNCIO", label: "FREI INOCÊNCIO" },
                    { value: "FREI LAGONEGRO", label: "FREI LAGONEGRO" },
                    { value: "FRONTEIRA", label: "FRONTEIRA" },
                    { value: "FRONTEIRA DOS VALES", label: "FRONTEIRA DOS VALES" },
                    { value: "FRUTA DE LEITE", label: "FRUTA DE LEITE" },
                    { value: "FRUTAL", label: "FRUTAL" },
                    { value: "FUNILÂNDIA", label: "FUNILÂNDIA" },
                    { value: "GALILÉIA", label: "GALILÉIA" },
                    { value: "GAMELEIRAS", label: "GAMELEIRAS" },
                    { value: "GLAUCILÂNDIA", label: "GLAUCILÂNDIA" },
                    { value: "GOIABEIRA", label: "GOIABEIRA" },
                    { value: "GOIANÁ", label: "GOIANÁ" },
                    { value: "GONÇALVES", label: "GONÇALVES" },
                    { value: "GONZAGA", label: "GONZAGA" },
                    { value: "GOUVEIA", label: "GOUVEIA" },
                    { value: "GOVERNADOR VALADARES", label: "GOVERNADOR VALADARES" },
                    { value: "GRÃO MOGOL", label: "GRÃO MOGOL" },
                    { value: "GRUPIARA", label: "GRUPIARA" },
                    { value: "GUANHÃES", label: "GUANHÃES" },
                    { value: "GUAPÉ", label: "GUAPÉ" },
                    { value: "GUARACIABA", label: "GUARACIABA" },
                    { value: "GUARACIAMA", label: "GUARACIAMA" },
                    { value: "GUARANÉSIA", label: "GUARANÉSIA" },
                    { value: "GUARANI", label: "GUARANI" },
                    { value: "GUARARÁ", label: "GUARARÁ" },
                    { value: "GUARDA-MOR", label: "GUARDA-MOR" },
                    { value: "GUAXUPÉ", label: "GUAXUPÉ" },
                    { value: "GUIDOVAL", label: "GUIDOVAL" },
                    { value: "GUIMARÂNIA", label: "GUIMARÂNIA" },
                    { value: "GUIRICEMA", label: "GUIRICEMA" },
                    { value: "GURINHATÃ", label: "GURINHATÃ" },
                    { value: "HELIODORA", label: "HELIODORA" },
                    { value: "IAPU", label: "IAPU" },
                    { value: "IBERTIOGA", label: "IBERTIOGA" },
                    { value: "IBIÁ", label: "IBIÁ" },
                    { value: "IBIAÍ", label: "IBIAÍ" },
                    { value: "IBIRACATU", label: "IBIRACATU" },
                    { value: "IBIRACI", label: "IBIRACI" },
                    { value: "IBIRITÉ", label: "IBIRITÉ" },
                    { value: "IBITIÚRA DE MINAS", label: "IBITIÚRA DE MINAS" },
                    { value: "IBITURUNA", label: "IBITURUNA" },
                    { value: "ICARAÍ DE MINAS", label: "ICARAÍ DE MINAS" },
                    { value: "IGARAPÉ", label: "IGARAPÉ" },
                    { value: "IGARATINGA", label: "IGARATINGA" },
                    { value: "IGUATAMA", label: "IGUATAMA" },
                    { value: "IJACI", label: "IJACI" },
                    { value: "ILICÍNEA", label: "ILICÍNEA" },
                    { value: "IMBÉ DE MINAS", label: "IMBÉ DE MINAS" },
                    { value: "INCONFIDENTES", label: "INCONFIDENTES" },
                    { value: "INDAIABIRA", label: "INDAIABIRA" },
                    { value: "INDIANÓPOLIS", label: "INDIANÓPOLIS" },
                    { value: "INGAÍ", label: "INGAÍ" },
                    { value: "INHAPIM", label: "INHAPIM" },
                    { value: "INHAÚMA", label: "INHAÚMA" },
                    { value: "INIMUTABA", label: "INIMUTABA" },
                    { value: "IPABA", label: "IPABA" },
                    { value: "IPANEMA", label: "IPANEMA" },
                    { value: "IPATINGA", label: "IPATINGA" },
                    { value: "IPIAÇU", label: "IPIAÇU" },
                    { value: "IPUIÚNA", label: "IPUIÚNA" },
                    { value: "IRAÍ DE MINAS", label: "IRAÍ DE MINAS" },
                    { value: "ITABIRA", label: "ITABIRA" },
                    { value: "ITABIRINHA", label: "ITABIRINHA" },
                    { value: "ITABIRITO", label: "ITABIRITO" },
                    { value: "ITACAMBIRA", label: "ITACAMBIRA" },
                    { value: "ITACARAMBI", label: "ITACARAMBI" },
                    { value: "ITAGUARA", label: "ITAGUARA" },
                    { value: "ITAIPÉ", label: "ITAIPÉ" },
                    { value: "ITAJUBÁ", label: "ITAJUBÁ" },
                    { value: "ITAMARANDIBA", label: "ITAMARANDIBA" },
                    { value: "ITAMARATI DE MINAS", label: "ITAMARATI DE MINAS" },
                    { value: "ITAMBACURI", label: "ITAMBACURI" },
                    { value: "ITAMBÉ DO MATO DENTRO", label: "ITAMBÉ DO MATO DENTRO" },
                    { value: "ITAMOGI", label: "ITAMOGI" },
                    { value: "ITAMONTE", label: "ITAMONTE" },
                    { value: "ITANHANDU", label: "ITANHANDU" },
                    { value: "ITANHOMI", label: "ITANHOMI" },
                    { value: "ITAOBIM", label: "ITAOBIM" },
                    { value: "ITAPAGIPE", label: "ITAPAGIPE" },
                    { value: "ITAPECERICA", label: "ITAPECERICA" },
                    { value: "ITAPEVA", label: "ITAPEVA" },
                    { value: "ITATIAIUÇU", label: "ITATIAIUÇU" },
                    { value: "ITAÚ DE MINAS", label: "ITAÚ DE MINAS" },
                    { value: "ITAÚNA", label: "ITAÚNA" },
                    { value: "ITAVERAVA", label: "ITAVERAVA" },
                    { value: "ITINGA", label: "ITINGA" },
                    { value: "ITUETA", label: "ITUETA" },
                    { value: "ITUIUTABA", label: "ITUIUTABA" },
                    { value: "ITUMIRIM", label: "ITUMIRIM" },
                    { value: "ITURAMA", label: "ITURAMA" },
                    { value: "ITUTINGA", label: "ITUTINGA" },
                    { value: "JABOTICATUBAS", label: "JABOTICATUBAS" },
                    { value: "JACINTO", label: "JACINTO" },
                    { value: "JACUÍ", label: "JACUÍ" },
                    { value: "JACUTINGA", label: "JACUTINGA" },
                    { value: "JAGUARAÇU", label: "JAGUARAÇU" },
                    { value: "JAÍBA", label: "JAÍBA" },
                    { value: "JAMPRUCA", label: "JAMPRUCA" },
                    { value: "JANAÚBA", label: "JANAÚBA" },
                    { value: "JANUÁRIA", label: "JANUÁRIA" },
                    { value: "JAPARAÍBA", label: "JAPARAÍBA" },
                    { value: "JAPONVAR", label: "JAPONVAR" },
                    { value: "JECEABA", label: "JECEABA" },
                    { value: "JENIPAPO DE MINAS", label: "JENIPAPO DE MINAS" },
                    { value: "JEQUERI", label: "JEQUERI" },
                    { value: "JEQUITAÍ", label: "JEQUITAÍ" },
                    { value: "JEQUITIBÁ", label: "JEQUITIBÁ" },
                    { value: "JEQUITINHONHA", label: "JEQUITINHONHA" },
                    { value: "JESUÂNIA", label: "JESUÂNIA" },
                    { value: "JOAÍMA", label: "JOAÍMA" },
                    { value: "JOANÉSIA", label: "JOANÉSIA" },
                    { value: "JOÃO MONLEVADE", label: "JOÃO MONLEVADE" },
                    { value: "JOÃO PINHEIRO", label: "JOÃO PINHEIRO" },
                    { value: "JOAQUIM FELÍCIO", label: "JOAQUIM FELÍCIO" },
                    { value: "JORDÂNIA", label: "JORDÂNIA" },
                    { value: "JOSÉ GONÇALVES DE MINAS", label: "JOSÉ GONÇALVES DE MINAS" },
                    { value: "JOSÉ RAYDAN", label: "JOSÉ RAYDAN" },
                    { value: "JOSENÓPOLIS", label: "JOSENÓPOLIS" },
                    { value: "JUATUBA", label: "JUATUBA" },
                    { value: "JUIZ DE FORA", label: "JUIZ DE FORA" },
                    { value: "JURAMENTO", label: "JURAMENTO" },
                    { value: "JURUAIA", label: "JURUAIA" },
                    { value: "JUVENÍLIA", label: "JUVENÍLIA" },
                    { value: "LADAINHA", label: "LADAINHA" },
                    { value: "LAGAMAR", label: "LAGAMAR" },
                    { value: "LAGOA DA PRATA", label: "LAGOA DA PRATA" },
                    { value: "LAGOA DOS PATOS", label: "LAGOA DOS PATOS" },
                    { value: "LAGOA DOURADA", label: "LAGOA DOURADA" },
                    { value: "LAGOA FORMOSA", label: "LAGOA FORMOSA" },
                    { value: "LAGOA GRANDE", label: "LAGOA GRANDE" },
                    { value: "LAGOA SANTA", label: "LAGOA SANTA" },
                    { value: "LAJINHA", label: "LAJINHA" },
                    { value: "LAMBARI", label: "LAMBARI" },
                    { value: "LAMIM", label: "LAMIM" },
                    { value: "LARANJAL", label: "LARANJAL" },
                    { value: "LASSANCE", label: "LASSANCE" },
                    { value: "LAVRAS", label: "LAVRAS" },
                    { value: "LEANDRO FERREIRA", label: "LEANDRO FERREIRA" },
                    { value: "LEME DO PRADO", label: "LEME DO PRADO" },
                    { value: "LEOPOLDINA", label: "LEOPOLDINA" },
                    { value: "LIBERDADE", label: "LIBERDADE" },
                    { value: "LIMA DUARTE", label: "LIMA DUARTE" },
                    { value: "LIMEIRA DO OESTE", label: "LIMEIRA DO OESTE" },
                    { value: "LONTRA", label: "LONTRA" },
                    { value: "LUISBURGO", label: "LUISBURGO" },
                    { value: "LUISLÂNDIA", label: "LUISLÂNDIA" },
                    { value: "LUMINÁRIAS", label: "LUMINÁRIAS" },
                    { value: "LUZ", label: "LUZ" },
                    { value: "MACHACALIS", label: "MACHACALIS" },
                    { value: "MACHADO", label: "MACHADO" },
                    { value: "MADRE DE DEUS DE MINAS", label: "MADRE DE DEUS DE MINAS" },
                    { value: "MALACACHETA", label: "MALACACHETA" },
                    { value: "MAMONAS", label: "MAMONAS" },
                    { value: "MANGA", label: "MANGA" },
                    { value: "MANHUAÇU", label: "MANHUAÇU" },
                    { value: "MANHUMIRIM", label: "MANHUMIRIM" },
                    { value: "MANTENA", label: "MANTENA" },
                    { value: "MAR DE ESPANHA", label: "MAR DE ESPANHA" },
                    { value: "MARAVILHAS", label: "MARAVILHAS" },
                    { value: "MARIA DA FÉ", label: "MARIA DA FÉ" },
                    { value: "MARIANA", label: "MARIANA" },
                    { value: "MARILAC", label: "MARILAC" },
                    { value: "MÁRIO CAMPOS", label: "MÁRIO CAMPOS" },
                    { value: "MARIPÁ DE MINAS", label: "MARIPÁ DE MINAS" },
                    { value: "MARLIÉRIA", label: "MARLIÉRIA" },
                    { value: "MARMELÓPOLIS", label: "MARMELÓPOLIS" },
                    { value: "MARTINHO CAMPOS", label: "MARTINHO CAMPOS" },
                    { value: "MARTINS SOARES", label: "MARTINS SOARES" },
                    { value: "MATA VERDE", label: "MATA VERDE" },
                    { value: "MATERLÂNDIA", label: "MATERLÂNDIA" },
                    { value: "MATEUS LEME", label: "MATEUS LEME" },
                    { value: "MATHIAS LOBATO", label: "MATHIAS LOBATO" },
                    { value: "MATIAS BARBOSA", label: "MATIAS BARBOSA" },
                    { value: "MATIAS CARDOSO", label: "MATIAS CARDOSO" },
                    { value: "MATIPÓ", label: "MATIPÓ" },
                    { value: "MATO VERDE", label: "MATO VERDE" },
                    { value: "MATOZINHOS", label: "MATOZINHOS" },
                    { value: "MATUTINA", label: "MATUTINA" },
                    { value: "MEDEIROS", label: "MEDEIROS" },
                    { value: "MEDINA", label: "MEDINA" },
                    { value: "MENDES PIMENTEL", label: "MENDES PIMENTEL" },
                    { value: "MERCÊS", label: "MERCÊS" },
                    { value: "MESQUITA", label: "MESQUITA" },
                    { value: "MINAS NOVAS", label: "MINAS NOVAS" },
                    { value: "MINDURI", label: "MINDURI" },
                    { value: "MIRABELA", label: "MIRABELA" },
                    { value: "MIRADOURO", label: "MIRADOURO" },
                    { value: "MIRAÍ", label: "MIRAÍ" },
                    { value: "MIRAVÂNIA", label: "MIRAVÂNIA" },
                    { value: "MOEDA", label: "MOEDA" },
                    { value: "MOEMA", label: "MOEMA" },
                    { value: "MONJOLOS", label: "MONJOLOS" },
                    { value: "MONSENHOR PAULO", label: "MONSENHOR PAULO" },
                    { value: "MONTALVÂNIA", label: "MONTALVÂNIA" },
                    { value: "MONTE ALEGRE DE MINAS", label: "MONTE ALEGRE DE MINAS" },
                    { value: "MONTE AZUL", label: "MONTE AZUL" },
                    { value: "MONTE BELO", label: "MONTE BELO" },
                    { value: "MONTE CARMELO", label: "MONTE CARMELO" },
                    { value: "MONTE FORMOSO", label: "MONTE FORMOSO" },
                    { value: "MONTE SANTO DE MINAS", label: "MONTE SANTO DE MINAS" },
                    { value: "MONTE SIÃO", label: "MONTE SIÃO" },
                    { value: "MONTES CLAROS", label: "MONTES CLAROS" },
                    { value: "MONTEZUMA", label: "MONTEZUMA" },
                    { value: "MORADA NOVA DE MINAS", label: "MORADA NOVA DE MINAS" },
                    { value: "MORRO DA GARÇA", label: "MORRO DA GARÇA" },
                    { value: "MORRO DO PILAR", label: "MORRO DO PILAR" },
                    { value: "MUNHOZ", label: "MUNHOZ" },
                    { value: "MURIAÉ", label: "MURIAÉ" },
                    { value: "MUTUM", label: "MUTUM" },
                    { value: "MUZAMBINHO", label: "MUZAMBINHO" },
                    { value: "NACIP RAYDAN", label: "NACIP RAYDAN" },
                    { value: "NANUQUE", label: "NANUQUE" },
                    { value: "NAQUE", label: "NAQUE" },
                    { value: "NATALÂNDIA", label: "NATALÂNDIA" },
                    { value: "NATÉRCIA", label: "NATÉRCIA" },
                    { value: "NAZARENO", label: "NAZARENO" },
                    { value: "NEPOMUCENO", label: "NEPOMUCENO" },
                    { value: "NINHEIRA", label: "NINHEIRA" },
                    { value: "NOVA BELÉM", label: "NOVA BELÉM" },
                    { value: "NOVA ERA", label: "NOVA ERA" },
                    { value: "NOVA LIMA", label: "NOVA LIMA" },
                    { value: "NOVA MÓDICA", label: "NOVA MÓDICA" },
                    { value: "NOVA PONTE", label: "NOVA PONTE" },
                    { value: "NOVA PORTEIRINHA", label: "NOVA PORTEIRINHA" },
                    { value: "NOVA RESENDE", label: "NOVA RESENDE" },
                    { value: "NOVA SERRANA", label: "NOVA SERRANA" },
                    { value: "NOVA UNIÃO", label: "NOVA UNIÃO" },
                    { value: "NOVO CRUZEIRO", label: "NOVO CRUZEIRO" },
                    { value: "NOVO ORIENTE DE MINAS", label: "NOVO ORIENTE DE MINAS" },
                    { value: "NOVORIZONTE", label: "NOVORIZONTE" },
                    { value: "OLARIA", label: "OLARIA" },
                    { value: "OLHOS-D'ÁGUA", label: "OLHOS-D'ÁGUA" },
                    { value: "OLÍMPIO NORONHA", label: "OLÍMPIO NORONHA" },
                    { value: "OLIVEIRA", label: "OLIVEIRA" },
                    { value: "OLIVEIRA FORTES", label: "OLIVEIRA FORTES" },
                    { value: "ONÇA DE PITANGUI", label: "ONÇA DE PITANGUI" },
                    { value: "ORATÓRIOS", label: "ORATÓRIOS" },
                    { value: "ORIZÂNIA", label: "ORIZÂNIA" },
                    { value: "OURO BRANCO", label: "OURO BRANCO" },
                    { value: "OURO FINO", label: "OURO FINO" },
                    { value: "OURO PRETO", label: "OURO PRETO" },
                    { value: "OURO VERDE DE MINAS", label: "OURO VERDE DE MINAS" },
                    { value: "PADRE CARVALHO", label: "PADRE CARVALHO" },
                    { value: "PADRE PARAÍSO", label: "PADRE PARAÍSO" },
                    { value: "PAI PEDRO", label: "PAI PEDRO" },
                    { value: "PAINEIRAS", label: "PAINEIRAS" },
                    { value: "PAINS", label: "PAINS" },
                    { value: "PAIVA", label: "PAIVA" },
                    { value: "PALMA", label: "PALMA" },
                    { value: "PALMÓPOLIS", label: "PALMÓPOLIS" },
                    { value: "PAPAGAIOS", label: "PAPAGAIOS" },
                    { value: "PARÁ DE MINAS", label: "PARÁ DE MINAS" },
                    { value: "PARACATU", label: "PARACATU" },
                    { value: "PARAGUAÇU", label: "PARAGUAÇU" },
                    { value: "PARAISÓPOLIS", label: "PARAISÓPOLIS" },
                    { value: "PARAOPEBA", label: "PARAOPEBA" },
                    { value: "PASSA QUATRO", label: "PASSA QUATRO" },
                    { value: "PASSA TEMPO", label: "PASSA TEMPO" },
                    { value: "PASSA-VINTE", label: "PASSA-VINTE" },
                    { value: "PASSABÉM", label: "PASSABÉM" },
                    { value: "PASSOS", label: "PASSOS" },
                    { value: "PATIS", label: "PATIS" },
                    { value: "PATOS DE MINAS", label: "PATOS DE MINAS" },
                    { value: "PATROCÍNIO", label: "PATROCÍNIO" },
                    { value: "PATROCÍNIO DO MURIAÉ", label: "PATROCÍNIO DO MURIAÉ" },
                    { value: "PAULA CÂNDIDO", label: "PAULA CÂNDIDO" },
                    { value: "PAULISTAS", label: "PAULISTAS" },
                    { value: "PAVÃO", label: "PAVÃO" },
                    { value: "PEÇANHA", label: "PEÇANHA" },
                    { value: "PEDRA AZUL", label: "PEDRA AZUL" },
                    { value: "PEDRA BONITA", label: "PEDRA BONITA" },
                    { value: "PEDRA DO ANTA", label: "PEDRA DO ANTA" },
                    { value: "PEDRA DO INDAIÁ", label: "PEDRA DO INDAIÁ" },
                    { value: "PEDRA DOURADA", label: "PEDRA DOURADA" },
                    { value: "PEDRALVA", label: "PEDRALVA" },
                    { value: "PEDRAS DE MARIA DA CRUZ", label: "PEDRAS DE MARIA DA CRUZ" },
                    { value: "PEDRINÓPOLIS", label: "PEDRINÓPOLIS" },
                    { value: "PEDRO LEOPOLDO", label: "PEDRO LEOPOLDO" },
                    { value: "PEDRO TEIXEIRA", label: "PEDRO TEIXEIRA" },
                    { value: "PEQUERI", label: "PEQUERI" },
                    { value: "PEQUI", label: "PEQUI" },
                    { value: "PERDIGÃO", label: "PERDIGÃO" },
                    { value: "PERDIZES", label: "PERDIZES" },
                    { value: "PERDÕES", label: "PERDÕES" },
                    { value: "PERIQUITO", label: "PERIQUITO" },
                    { value: "PESCADOR", label: "PESCADOR" },
                    { value: "PIAU", label: "PIAU" },
                    { value: "PIEDADE DE CARATINGA", label: "PIEDADE DE CARATINGA" },
                    { value: "PIEDADE DE PONTE NOVA", label: "PIEDADE DE PONTE NOVA" },
                    { value: "PIEDADE DO RIO GRANDE", label: "PIEDADE DO RIO GRANDE" },
                    { value: "PIEDADE DOS GERAIS", label: "PIEDADE DOS GERAIS" },
                    { value: "PIMENTA", label: "PIMENTA" },
                    { value: "PINGO-D'ÁGUA", label: "PINGO-D'ÁGUA" },
                    { value: "PINTÓPOLIS", label: "PINTÓPOLIS" },
                    { value: "PIRACEMA", label: "PIRACEMA" },
                    { value: "PIRAJUBA", label: "PIRAJUBA" },
                    { value: "PIRANGA", label: "PIRANGA" },
                    { value: "PIRANGUÇU", label: "PIRANGUÇU" },
                    { value: "PIRANGUINHO", label: "PIRANGUINHO" },
                    { value: "PIRAPETINGA", label: "PIRAPETINGA" },
                    { value: "PIRAPORA", label: "PIRAPORA" },
                    { value: "PIRAÚBA", label: "PIRAÚBA" },
                    { value: "PITANGUI", label: "PITANGUI" },
                    { value: "PIUMHI", label: "PIUMHI" },
                    { value: "PLANURA", label: "PLANURA" },
                    { value: "POÇO FUNDO", label: "POÇO FUNDO" },
                    { value: "POÇOS DE CALDAS", label: "POÇOS DE CALDAS" },
                    { value: "POCRANE", label: "POCRANE" },
                    { value: "POMPÉU", label: "POMPÉU" },
                    { value: "PONTE NOVA", label: "PONTE NOVA" },
                    { value: "PONTO CHIQUE", label: "PONTO CHIQUE" },
                    { value: "PONTO DOS VOLANTES", label: "PONTO DOS VOLANTES" },
                    { value: "PORTEIRINHA", label: "PORTEIRINHA" },
                    { value: "PORTO FIRME", label: "PORTO FIRME" },
                    { value: "POTÉ", label: "POTÉ" },
                    { value: "POUSO ALEGRE", label: "POUSO ALEGRE" },
                    { value: "POUSO ALTO", label: "POUSO ALTO" },
                    { value: "PRADOS", label: "PRADOS" },
                    { value: "PRATA", label: "PRATA" },
                    { value: "PRATÁPOLIS", label: "PRATÁPOLIS" },
                    { value: "PRATINHA", label: "PRATINHA" },
                    { value: "PRESIDENTE BERNARDES", label: "PRESIDENTE BERNARDES" },
                    { value: "PRESIDENTE JUSCELINO", label: "PRESIDENTE JUSCELINO" },
                    { value: "PRESIDENTE KUBITSCHEK", label: "PRESIDENTE KUBITSCHEK" },
                    { value: "PRESIDENTE OLEGÁRIO", label: "PRESIDENTE OLEGÁRIO" },
                    { value: "PRUDENTE DE MORAIS", label: "PRUDENTE DE MORAIS" },
                    { value: "QUARTEL GERAL", label: "QUARTEL GERAL" },
                    { value: "QUELUZITO", label: "QUELUZITO" },
                    { value: "RAPOSOS", label: "RAPOSOS" },
                    { value: "RAUL SOARES", label: "RAUL SOARES" },
                    { value: "RECREIO", label: "RECREIO" },
                    { value: "REDUTO", label: "REDUTO" },
                    { value: "RESENDE COSTA", label: "RESENDE COSTA" },
                    { value: "RESPLENDOR", label: "RESPLENDOR" },
                    { value: "RESSAQUINHA", label: "RESSAQUINHA" },
                    { value: "RIACHINHO", label: "RIACHINHO" },
                    { value: "RIACHO DOS MACHADOS", label: "RIACHO DOS MACHADOS" },
                    { value: "RIBEIRÃO DAS NEVES", label: "RIBEIRÃO DAS NEVES" },
                    { value: "RIBEIRÃO VERMELHO", label: "RIBEIRÃO VERMELHO" },
                    { value: "RIO ACIMA", label: "RIO ACIMA" },
                    { value: "RIO CASCA", label: "RIO CASCA" },
                    { value: "RIO DO PRADO", label: "RIO DO PRADO" },
                    { value: "RIO DOCE", label: "RIO DOCE" },
                    { value: "RIO ESPERA", label: "RIO ESPERA" },
                    { value: "RIO MANSO", label: "RIO MANSO" },
                    { value: "RIO NOVO", label: "RIO NOVO" },
                    { value: "RIO PARANAÍBA", label: "RIO PARANAÍBA" },
                    { value: "RIO PARDO DE MINAS", label: "RIO PARDO DE MINAS" },
                    { value: "RIO PIRACICABA", label: "RIO PIRACICABA" },
                    { value: "RIO POMBA", label: "RIO POMBA" },
                    { value: "RIO PRETO", label: "RIO PRETO" },
                    { value: "RIO VERMELHO", label: "RIO VERMELHO" },
                    { value: "RITÁPOLIS", label: "RITÁPOLIS" },
                    { value: "ROCHEDO DE MINAS", label: "ROCHEDO DE MINAS" },
                    { value: "RODEIRO", label: "RODEIRO" },
                    { value: "ROMARIA", label: "ROMARIA" },
                    { value: "ROSÁRIO DA LIMEIRA", label: "ROSÁRIO DA LIMEIRA" },
                    { value: "RUBELITA", label: "RUBELITA" },
                    { value: "RUBIM", label: "RUBIM" },
                    { value: "SABARÁ", label: "SABARÁ" },
                    { value: "SABINÓPOLIS", label: "SABINÓPOLIS" },
                    { value: "SACRAMENTO", label: "SACRAMENTO" },
                    { value: "SALINAS", label: "SALINAS" },
                    { value: "SALTO DA DIVISA", label: "SALTO DA DIVISA" },
                    { value: "SANTA BÁRBARA", label: "SANTA BÁRBARA" },
                    { value: "SANTA BÁRBARA DO LESTE", label: "SANTA BÁRBARA DO LESTE" },
                    { value: "SANTA BÁRBARA DO MONTE VERDE", label: "SANTA BÁRBARA DO MONTE VERDE" },
                    { value: "SANTA BÁRBARA DO TUGÚRIO", label: "SANTA BÁRBARA DO TUGÚRIO" },
                    { value: "SANTA CRUZ DE MINAS", label: "SANTA CRUZ DE MINAS" },
                    { value: "SANTA CRUZ DE SALINAS", label: "SANTA CRUZ DE SALINAS" },
                    { value: "SANTA CRUZ DO ESCALVADO", label: "SANTA CRUZ DO ESCALVADO" },
                    { value: "SANTA EFIGÊNIA DE MINAS", label: "SANTA EFIGÊNIA DE MINAS" },
                    { value: "SANTA FÉ DE MINAS", label: "SANTA FÉ DE MINAS" },
                    { value: "SANTA HELENA DE MINAS", label: "SANTA HELENA DE MINAS" },
                    { value: "SANTA JULIANA", label: "SANTA JULIANA" },
                    { value: "SANTA LUZIA", label: "SANTA LUZIA" },
                    { value: "SANTA MARGARIDA", label: "SANTA MARGARIDA" },
                    { value: "SANTA MARIA DE ITABIRA", label: "SANTA MARIA DE ITABIRA" },
                    { value: "SANTA MARIA DO SALTO", label: "SANTA MARIA DO SALTO" },
                    { value: "SANTA MARIA DO SUAÇUÍ", label: "SANTA MARIA DO SUAÇUÍ" },
                    { value: "SANTA RITA DE CALDAS", label: "SANTA RITA DE CALDAS" },
                    { value: "SANTA RITA DE IBITIPOCA", label: "SANTA RITA DE IBITIPOCA" },
                    { value: "SANTA RITA DE JACUTINGA", label: "SANTA RITA DE JACUTINGA" },
                    { value: "SANTA RITA DE MINAS", label: "SANTA RITA DE MINAS" },
                    { value: "SANTA RITA DO ITUETO", label: "SANTA RITA DO ITUETO" },
                    { value: "SANTA RITA DO SAPUCAÍ", label: "SANTA RITA DO SAPUCAÍ" },
                    { value: "SANTA ROSA DA SERRA", label: "SANTA ROSA DA SERRA" },
                    { value: "SANTA VITÓRIA", label: "SANTA VITÓRIA" },
                    { value: "SANTANA DA VARGEM", label: "SANTANA DA VARGEM" },
                    { value: "SANTANA DE CATAGUASES", label: "SANTANA DE CATAGUASES" },
                    { value: "SANTANA DE PIRAPAMA", label: "SANTANA DE PIRAPAMA" },
                    { value: "SANTANA DO DESERTO", label: "SANTANA DO DESERTO" },
                    { value: "SANTANA DO GARAMBÉU", label: "SANTANA DO GARAMBÉU" },
                    { value: "SANTANA DO JACARÉ", label: "SANTANA DO JACARÉ" },
                    { value: "SANTANA DO MANHUAÇU", label: "SANTANA DO MANHUAÇU" },
                    { value: "SANTANA DO PARAÍSO", label: "SANTANA DO PARAÍSO" },
                    { value: "SANTANA DO RIACHO", label: "SANTANA DO RIACHO" },
                    { value: "SANTANA DOS MONTES", label: "SANTANA DOS MONTES" },
                    { value: "SANTO ANTÔNIO DO AMPARO", label: "SANTO ANTÔNIO DO AMPARO" },
                    { value: "SANTO ANTÔNIO DO AVENTUREIRO", label: "SANTO ANTÔNIO DO AVENTUREIRO" },
                    { value: "SANTO ANTÔNIO DO GRAMA", label: "SANTO ANTÔNIO DO GRAMA" },
                    { value: "SANTO ANTÔNIO DO ITAMBÉ", label: "SANTO ANTÔNIO DO ITAMBÉ" },
                    { value: "SANTO ANTÔNIO DO JACINTO", label: "SANTO ANTÔNIO DO JACINTO" },
                    { value: "SANTO ANTÔNIO DO MONTE", label: "SANTO ANTÔNIO DO MONTE" },
                    { value: "SANTO ANTÔNIO DO RETIRO", label: "SANTO ANTÔNIO DO RETIRO" },
                    { value: "SANTO ANTÔNIO DO RIO ABAIXO", label: "SANTO ANTÔNIO DO RIO ABAIXO" },
                    { value: "SANTO HIPÓLITO", label: "SANTO HIPÓLITO" },
                    { value: "SANTOS DUMONT", label: "SANTOS DUMONT" },
                    { value: "SÃO BENTO ABADE", label: "SÃO BENTO ABADE" },
                    { value: "SÃO BRÁS DO SUAÇUÍ", label: "SÃO BRÁS DO SUAÇUÍ" },
                    { value: "SÃO DOMINGOS DAS DORES", label: "SÃO DOMINGOS DAS DORES" },
                    { value: "SÃO DOMINGOS DO PRATA", label: "SÃO DOMINGOS DO PRATA" },
                    { value: "SÃO FÉLIX DE MINAS", label: "SÃO FÉLIX DE MINAS" },
                    { value: "SÃO FRANCISCO", label: "SÃO FRANCISCO" },
                    { value: "SÃO FRANCISCO DE PAULA", label: "SÃO FRANCISCO DE PAULA" },
                    { value: "SÃO FRANCISCO DE SALES", label: "SÃO FRANCISCO DE SALES" },
                    { value: "SÃO FRANCISCO DO GLÓRIA", label: "SÃO FRANCISCO DO GLÓRIA" },
                    { value: "SÃO GERALDO", label: "SÃO GERALDO" },
                    { value: "SÃO GERALDO DA PIEDADE", label: "SÃO GERALDO DA PIEDADE" },
                    { value: "SÃO GERALDO DO BAIXIO", label: "SÃO GERALDO DO BAIXIO" },
                    { value: "SÃO GONÇALO DO ABAETÉ", label: "SÃO GONÇALO DO ABAETÉ" },
                    { value: "SÃO GONÇALO DO PARÁ", label: "SÃO GONÇALO DO PARÁ" },
                    { value: "SÃO GONÇALO DO RIO ABAIXO", label: "SÃO GONÇALO DO RIO ABAIXO" },
                    { value: "SÃO GONÇALO DO RIO PRETO", label: "SÃO GONÇALO DO RIO PRETO" },
                    { value: "SÃO GONÇALO DO SAPUCAÍ", label: "SÃO GONÇALO DO SAPUCAÍ" },
                    { value: "SÃO GOTARDO", label: "SÃO GOTARDO" },
                    { value: "SÃO JOÃO BATISTA DO GLÓRIA", label: "SÃO JOÃO BATISTA DO GLÓRIA" },
                    { value: "SÃO JOÃO DA LAGOA", label: "SÃO JOÃO DA LAGOA" },
                    { value: "SÃO JOÃO DA MATA", label: "SÃO JOÃO DA MATA" },
                    { value: "SÃO JOÃO DA PONTE", label: "SÃO JOÃO DA PONTE" },
                    { value: "SÃO JOÃO DAS MISSÕES", label: "SÃO JOÃO DAS MISSÕES" },
                    { value: "SÃO JOÃO DEL REI", label: "SÃO JOÃO DEL REI" },
                    { value: "SÃO JOÃO DO MANHUAÇU", label: "SÃO JOÃO DO MANHUAÇU" },
                    { value: "SÃO JOÃO DO MANTENINHA", label: "SÃO JOÃO DO MANTENINHA" },
                    { value: "SÃO JOÃO DO ORIENTE", label: "SÃO JOÃO DO ORIENTE" },
                    { value: "SÃO JOÃO DO PACUÍ", label: "SÃO JOÃO DO PACUÍ" },
                    { value: "SÃO JOÃO DO PARAÍSO", label: "SÃO JOÃO DO PARAÍSO" },
                    { value: "SÃO JOÃO EVANGELISTA", label: "SÃO JOÃO EVANGELISTA" },
                    { value: "SÃO JOÃO NEPOMUCENO", label: "SÃO JOÃO NEPOMUCENO" },
                    { value: "SÃO JOAQUIM DE BICAS", label: "SÃO JOAQUIM DE BICAS" },
                    { value: "SÃO JOSÉ DA BARRA", label: "SÃO JOSÉ DA BARRA" },
                    { value: "SÃO JOSÉ DA LAPA", label: "SÃO JOSÉ DA LAPA" },
                    { value: "SÃO JOSÉ DA SAFIRA", label: "SÃO JOSÉ DA SAFIRA" },
                    { value: "SÃO JOSÉ DA VARGINHA", label: "SÃO JOSÉ DA VARGINHA" },
                    { value: "SÃO JOSÉ DO ALEGRE", label: "SÃO JOSÉ DO ALEGRE" },
                    { value: "SÃO JOSÉ DO DIVINO", label: "SÃO JOSÉ DO DIVINO" },
                    { value: "SÃO JOSÉ DO GOIABAL", label: "SÃO JOSÉ DO GOIABAL" },
                    { value: "SÃO JOSÉ DO JACURI", label: "SÃO JOSÉ DO JACURI" },
                    { value: "SÃO JOSÉ DO MANTIMENTO", label: "SÃO JOSÉ DO MANTIMENTO" },
                    { value: "SÃO LOURENÇO", label: "SÃO LOURENÇO" },
                    { value: "SÃO MIGUEL DO ANTA", label: "SÃO MIGUEL DO ANTA" },
                    { value: "SÃO PEDRO DA UNIÃO", label: "SÃO PEDRO DA UNIÃO" },
                    { value: "SÃO PEDRO DO SUAÇUÍ", label: "SÃO PEDRO DO SUAÇUÍ" },
                    { value: "SÃO PEDRO DOS FERROS", label: "SÃO PEDRO DOS FERROS" },
                    { value: "SÃO ROMÃO", label: "SÃO ROMÃO" },
                    { value: "SÃO ROQUE DE MINAS", label: "SÃO ROQUE DE MINAS" },
                    { value: "SÃO SEBASTIÃO DA BELA VISTA", label: "SÃO SEBASTIÃO DA BELA VISTA" },
                    { value: "SÃO SEBASTIÃO DA VARGEM ALEGRE", label: "SÃO SEBASTIÃO DA VARGEM ALEGRE" },
                    { value: "SÃO SEBASTIÃO DO ANTA", label: "SÃO SEBASTIÃO DO ANTA" },
                    { value: "SÃO SEBASTIÃO DO MARANHÃO", label: "SÃO SEBASTIÃO DO MARANHÃO" },
                    { value: "SÃO SEBASTIÃO DO OESTE", label: "SÃO SEBASTIÃO DO OESTE" },
                    { value: "SÃO SEBASTIÃO DO PARAÍSO", label: "SÃO SEBASTIÃO DO PARAÍSO" },
                    { value: "SÃO SEBASTIÃO DO RIO PRETO", label: "SÃO SEBASTIÃO DO RIO PRETO" },
                    { value: "SÃO SEBASTIÃO DO RIO VERDE", label: "SÃO SEBASTIÃO DO RIO VERDE" },
                    { value: "SÃO THOMÉ DAS LETRAS", label: "SÃO THOMÉ DAS LETRAS" },
                    { value: "SÃO TIAGO", label: "SÃO TIAGO" },
                    { value: "SÃO TOMÁS DE AQUINO", label: "SÃO TOMÁS DE AQUINO" },
                    { value: "SÃO VICENTE DE MINAS", label: "SÃO VICENTE DE MINAS" },
                    { value: "SAPUCAÍ-MIRIM", label: "SAPUCAÍ-MIRIM" },
                    { value: "SARDOÁ", label: "SARDOÁ" },
                    { value: "SARZEDO", label: "SARZEDO" },
                    { value: "SEM-PEIXE", label: "SEM-PEIXE" },
                    { value: "SENADOR AMARAL", label: "SENADOR AMARAL" },
                    { value: "SENADOR CORTES", label: "SENADOR CORTES" },
                    { value: "SENADOR FIRMINO", label: "SENADOR FIRMINO" },
                    { value: "SENADOR JOSÉ BENTO", label: "SENADOR JOSÉ BENTO" },
                    { value: "SENADOR MODESTINO GONÇALVES", label: "SENADOR MODESTINO GONÇALVES" },
                    { value: "SENHORA DE OLIVEIRA", label: "SENHORA DE OLIVEIRA" },
                    { value: "SENHORA DO PORTO", label: "SENHORA DO PORTO" },
                    { value: "SENHORA DOS REMÉDIOS", label: "SENHORA DOS REMÉDIOS" },
                    { value: "SERICITA", label: "SERICITA" },
                    { value: "SERITINGA", label: "SERITINGA" },
                    { value: "SERRA AZUL DE MINAS", label: "SERRA AZUL DE MINAS" },
                    { value: "SERRA DA SAUDADE", label: "SERRA DA SAUDADE" },
                    { value: "SERRA DO SALITRE", label: "SERRA DO SALITRE" },
                    { value: "SERRA DOS AIMORÉS", label: "SERRA DOS AIMORÉS" },
                    { value: "SERRANIA", label: "SERRANIA" },
                    { value: "SERRANÓPOLIS DE MINAS", label: "SERRANÓPOLIS DE MINAS" },
                    { value: "SERRANOS", label: "SERRANOS" },
                    { value: "SERRO", label: "SERRO" },
                    { value: "SETE LAGOAS", label: "SETE LAGOAS" },
                    { value: "SETUBINHA", label: "SETUBINHA" },
                    { value: "SILVEIRÂNIA", label: "SILVEIRÂNIA" },
                    { value: "SILVIANÓPOLIS", label: "SILVIANÓPOLIS" },
                    { value: "SIMÃO PEREIRA", label: "SIMÃO PEREIRA" },
                    { value: "SIMONÉSIA", label: "SIMONÉSIA" },
                    { value: "SOBRÁLIA", label: "SOBRÁLIA" },
                    { value: "SOLEDADE DE MINAS", label: "SOLEDADE DE MINAS" },
                    { value: "TABULEIRO", label: "TABULEIRO" },
                    { value: "TAIOBEIRAS", label: "TAIOBEIRAS" },
                    { value: "TAPARUBA", label: "TAPARUBA" },
                    { value: "TAPIRA", label: "TAPIRA" },
                    { value: "TAPIRAÍ", label: "TAPIRAÍ" },
                    { value: "TAQUARAÇU DE MINAS", label: "TAQUARAÇU DE MINAS" },
                    { value: "TARUMIRIM", label: "TARUMIRIM" },
                    { value: "TEIXEIRAS", label: "TEIXEIRAS" },
                    { value: "TEÓFILO OTONI", label: "TEÓFILO OTONI" },
                    { value: "TIMÓTEO", label: "TIMÓTEO" },
                    { value: "TIRADENTES", label: "TIRADENTES" },
                    { value: "TIROS", label: "TIROS" },
                    { value: "TOCANTINS", label: "TOCANTINS" },
                    { value: "TOCOS DO MOJI", label: "TOCOS DO MOJI" },
                    { value: "TOLEDO", label: "TOLEDO" },
                    { value: "TOMBOS", label: "TOMBOS" },
                    { value: "TRÊS CORAÇÕES", label: "TRÊS CORAÇÕES" },
                    { value: "TRÊS MARIAS", label: "TRÊS MARIAS" },
                    { value: "TRÊS PONTAS", label: "TRÊS PONTAS" },
                    { value: "TUMIRITINGA", label: "TUMIRITINGA" },
                    { value: "TUPACIGUARA", label: "TUPACIGUARA" },
                    { value: "TURMALINA", label: "TURMALINA" },
                    { value: "TURVOLÂNDIA", label: "TURVOLÂNDIA" },
                    { value: "UBÁ", label: "UBÁ" },
                    { value: "UBAÍ", label: "UBAÍ" },
                    { value: "UBAPORANGA", label: "UBAPORANGA" },
                    { value: "UBERABA", label: "UBERABA" },
                    { value: "UBERLÂNDIA", label: "UBERLÂNDIA" },
                    { value: "UMBURATIBA", label: "UMBURATIBA" },
                    { value: "UNAÍ", label: "UNAÍ" },
                    { value: "UNIÃO DE MINAS", label: "UNIÃO DE MINAS" },
                    { value: "URUANA DE MINAS", label: "URUANA DE MINAS" },
                    { value: "URUCÂNIA", label: "URUCÂNIA" },
                    { value: "URUCUIA", label: "URUCUIA" },
                    { value: "VARGEM ALEGRE", label: "VARGEM ALEGRE" },
                    { value: "VARGEM BONITA", label: "VARGEM BONITA" },
                    { value: "VARGEM GRANDE DO RIO PARDO", label: "VARGEM GRANDE DO RIO PARDO" },
                    { value: "VARGINHA", label: "VARGINHA" },
                    { value: "VARJÃO DE MINAS", label: "VARJÃO DE MINAS" },
                    { value: "VÁRZEA DA PALMA", label: "VÁRZEA DA PALMA" },
                    { value: "VARZELÂNDIA", label: "VARZELÂNDIA" },
                    { value: "VAZANTE", label: "VAZANTE" },
                    { value: "VERDELÂNDIA", label: "VERDELÂNDIA" },
                    { value: "VEREDINHA", label: "VEREDINHA" },
                    { value: "VERÍSSIMO", label: "VERÍSSIMO" },
                    { value: "VERMELHO NOVO", label: "VERMELHO NOVO" },
                    { value: "VESPASIANO", label: "VESPASIANO" },
                    { value: "VIÇOSA", label: "VIÇOSA" },
                    { value: "VIEIRAS", label: "VIEIRAS" },
                    { value: "VIRGEM DA LAPA", label: "VIRGEM DA LAPA" },
                    { value: "VIRGÍNIA", label: "VIRGÍNIA" },
                    { value: "VIRGINÓPOLIS", label: "VIRGINÓPOLIS" },
                    { value: "VIRGOLÂNDIA", label: "VIRGOLÂNDIA" },
                    { value: "VISCONDE DO RIO BRANCO", label: "VISCONDE DO RIO BRANCO" },
                    { value: "VOLTA GRANDE", label: "VOLTA GRANDE" },
                    { value: "WENCESLAU BRAZ", label: "WENCESLAU BRAZ" },
               ]
               break;

          case "PARÁ":
               CITIES = [
                    { value: "ABAETETUBA", label: "ABAETETUBA" },
                    { value: "ABEL FIGUEIREDO", label: "ABEL FIGUEIREDO" },
                    { value: "ACARÁ", label: "ACARÁ" },
                    { value: "AFUÁ", label: "AFUÁ" },
                    { value: "ÁGUA AZUL DO NORTE", label: "ÁGUA AZUL DO NORTE" },
                    { value: "ALENQUER", label: "ALENQUER" },
                    { value: "ALMEIRIM", label: "ALMEIRIM" },
                    { value: "ALTAMIRA", label: "ALTAMIRA" },
                    { value: "ANAJÁS", label: "ANAJÁS" },
                    { value: "ANANINDEUA", label: "ANANINDEUA" },
                    { value: "ANAPU", label: "ANAPU" },
                    { value: "AUGUSTO CORRÊA", label: "AUGUSTO CORRÊA" },
                    { value: "AURORA DO PARÁ", label: "AURORA DO PARÁ" },
                    { value: "AVEIRO", label: "AVEIRO" },
                    { value: "BAGRE", label: "BAGRE" },
                    { value: "BAIÃO", label: "BAIÃO" },
                    { value: "BANNACH", label: "BANNACH" },
                    { value: "BARCARENA", label: "BARCARENA" },
                    { value: "BELÉM", label: "BELÉM" },
                    { value: "BELTERRA", label: "BELTERRA" },
                    { value: "BENEVIDES", label: "BENEVIDES" },
                    { value: "BOM JESUS DO TOCANTINS", label: "BOM JESUS DO TOCANTINS" },
                    { value: "BONITO", label: "BONITO" },
                    { value: "BRAGANÇA", label: "BRAGANÇA" },
                    { value: "BRASIL NOVO", label: "BRASIL NOVO" },
                    { value: "BREJO GRANDE DO ARAGUAIA", label: "BREJO GRANDE DO ARAGUAIA" },
                    { value: "BREU BRANCO", label: "BREU BRANCO" },
                    { value: "BREVES", label: "BREVES" },
                    { value: "BUJARU", label: "BUJARU" },
                    { value: "CACHOEIRA DO ARARI", label: "CACHOEIRA DO ARARI" },
                    { value: "CACHOEIRA DO PIRIÁ", label: "CACHOEIRA DO PIRIÁ" },
                    { value: "CAMETÁ", label: "CAMETÁ" },
                    { value: "CANAÃ DOS CARAJÁS", label: "CANAÃ DOS CARAJÁS" },
                    { value: "CAPANEMA", label: "CAPANEMA" },
                    { value: "CAPITÃO POÇO", label: "CAPITÃO POÇO" },
                    { value: "CASTANHAL", label: "CASTANHAL" },
                    { value: "CHAVES", label: "CHAVES" },
                    { value: "COLARES", label: "COLARES" },
                    { value: "CONCEIÇÃO DO ARAGUAIA", label: "CONCEIÇÃO DO ARAGUAIA" },
                    { value: "CONCÓRDIA DO PARÁ", label: "CONCÓRDIA DO PARÁ" },
                    { value: "CUMARU DO NORTE", label: "CUMARU DO NORTE" },
                    { value: "CURIONÓPOLIS", label: "CURIONÓPOLIS" },
                    { value: "CURRALINHO", label: "CURRALINHO" },
                    { value: "CURUÁ", label: "CURUÁ" },
                    { value: "CURUÇÁ", label: "CURUÇÁ" },
                    { value: "DOM ELISEU", label: "DOM ELISEU" },
                    { value: "ELDORADO DOS CARAJÁS", label: "ELDORADO DOS CARAJÁS" },
                    { value: "FARO", label: "FARO" },
                    { value: "FLORESTA DO ARAGUAIA", label: "FLORESTA DO ARAGUAIA" },
                    { value: "GARRAFÃO DO NORTE", label: "GARRAFÃO DO NORTE" },
                    { value: "GOIANÉSIA DO PARÁ", label: "GOIANÉSIA DO PARÁ" },
                    { value: "GURUPÁ", label: "GURUPÁ" },
                    { value: "IGARAPÉ-AÇU", label: "IGARAPÉ-AÇU" },
                    { value: "IGARAPÉ-MIRI", label: "IGARAPÉ-MIRI" },
                    { value: "INHANGAPI", label: "INHANGAPI" },
                    { value: "IPIXUNA DO PARÁ", label: "IPIXUNA DO PARÁ" },
                    { value: "IRITUIA", label: "IRITUIA" },
                    { value: "ITAITUBA", label: "ITAITUBA" },
                    { value: "ITUPIRANGA", label: "ITUPIRANGA" },
                    { value: "JACAREACANGA", label: "JACAREACANGA" },
                    { value: "JACUNDÁ", label: "JACUNDÁ" },
                    { value: "JURUTI", label: "JURUTI" },
                    { value: "LIMOEIRO DO AJURU", label: "LIMOEIRO DO AJURU" },
                    { value: "MÃE DO RIO", label: "MÃE DO RIO" },
                    { value: "MAGALHÃES BARATA", label: "MAGALHÃES BARATA" },
                    { value: "MARABÁ", label: "MARABÁ" },
                    { value: "MARACANÃ", label: "MARACANÃ" },
                    { value: "MARAPANIM", label: "MARAPANIM" },
                    { value: "MARITUBA", label: "MARITUBA" },
                    { value: "MEDICILÂNDIA", label: "MEDICILÂNDIA" },
                    { value: "MELGAÇO", label: "MELGAÇO" },
                    { value: "MOCAJUBA", label: "MOCAJUBA" },
                    { value: "MOJU", label: "MOJU" },
                    { value: "MOJUÍ DOS CAMPOS", label: "MOJUÍ DOS CAMPOS" },
                    { value: "MONTE ALEGRE", label: "MONTE ALEGRE" },
                    { value: "MUANÁ", label: "MUANÁ" },
                    { value: "NOVA ESPERANÇA DO PIRIÁ", label: "NOVA ESPERANÇA DO PIRIÁ" },
                    { value: "NOVA IPIXUNA", label: "NOVA IPIXUNA" },
                    { value: "NOVA TIMBOTEUA", label: "NOVA TIMBOTEUA" },
                    { value: "NOVO PROGRESSO", label: "NOVO PROGRESSO" },
                    { value: "NOVO REPARTIMENTO", label: "NOVO REPARTIMENTO" },
                    { value: "ÓBIDOS", label: "ÓBIDOS" },
                    { value: "OEIRAS DO PARÁ", label: "OEIRAS DO PARÁ" },
                    { value: "ORIXIMINÁ", label: "ORIXIMINÁ" },
                    { value: "OURÉM", label: "OURÉM" },
                    { value: "OURILÂNDIA DO NORTE", label: "OURILÂNDIA DO NORTE" },
                    { value: "PACAJÁ", label: "PACAJÁ" },
                    { value: "PALESTINA DO PARÁ", label: "PALESTINA DO PARÁ" },
                    { value: "PARAGOMINAS", label: "PARAGOMINAS" },
                    { value: "PARAUAPEBAS", label: "PARAUAPEBAS" },
                    { value: "PAU D'ARCO", label: "PAU D'ARCO" },
                    { value: "PEIXE-BOI", label: "PEIXE-BOI" },
                    { value: "PIÇARRA", label: "PIÇARRA" },
                    { value: "PLACAS", label: "PLACAS" },
                    { value: "PONTA DE PEDRAS", label: "PONTA DE PEDRAS" },
                    { value: "PORTEL", label: "PORTEL" },
                    { value: "PORTO DE MOZ", label: "PORTO DE MOZ" },
                    { value: "PRAINHA", label: "PRAINHA" },
                    { value: "PRIMAVERA", label: "PRIMAVERA" },
                    { value: "QUATIPURU", label: "QUATIPURU" },
                    { value: "REDENÇÃO", label: "REDENÇÃO" },
                    { value: "RIO MARIA", label: "RIO MARIA" },
                    { value: "RONDON DO PARÁ", label: "RONDON DO PARÁ" },
                    { value: "RURÓPOLIS", label: "RURÓPOLIS" },
                    { value: "SALINÓPOLIS", label: "SALINÓPOLIS" },
                    { value: "SALVATERRA", label: "SALVATERRA" },
                    { value: "SANTA BÁRBARA DO PARÁ", label: "SANTA BÁRBARA DO PARÁ" },
                    { value: "SANTA CRUZ DO ARARI", label: "SANTA CRUZ DO ARARI" },
                    { value: "SANTA ISABEL DO PARÁ", label: "SANTA ISABEL DO PARÁ" },
                    { value: "SANTA LUZIA DO PARÁ", label: "SANTA LUZIA DO PARÁ" },
                    { value: "SANTA MARIA DAS BARREIRAS", label: "SANTA MARIA DAS BARREIRAS" },
                    { value: "SANTA MARIA DO PARÁ", label: "SANTA MARIA DO PARÁ" },
                    { value: "SANTANA DO ARAGUAIA", label: "SANTANA DO ARAGUAIA" },
                    { value: "SANTARÉM", label: "SANTARÉM" },
                    { value: "SANTARÉM NOVO", label: "SANTARÉM NOVO" },
                    { value: "SANTO ANTÔNIO DO TAUÁ", label: "SANTO ANTÔNIO DO TAUÁ" },
                    { value: "SÃO CAETANO DE ODIVELAS", label: "SÃO CAETANO DE ODIVELAS" },
                    { value: "SÃO DOMINGOS DO ARAGUAIA", label: "SÃO DOMINGOS DO ARAGUAIA" },
                    { value: "SÃO DOMINGOS DO CAPIM", label: "SÃO DOMINGOS DO CAPIM" },
                    { value: "SÃO FÉLIX DO XINGU", label: "SÃO FÉLIX DO XINGU" },
                    { value: "SÃO FRANCISCO DO PARÁ", label: "SÃO FRANCISCO DO PARÁ" },
                    { value: "SÃO GERALDO DO ARAGUAIA", label: "SÃO GERALDO DO ARAGUAIA" },
                    { value: "SÃO JOÃO DA PONTA", label: "SÃO JOÃO DA PONTA" },
                    { value: "SÃO JOÃO DE PIRABAS", label: "SÃO JOÃO DE PIRABAS" },
                    { value: "SÃO JOÃO DO ARAGUAIA", label: "SÃO JOÃO DO ARAGUAIA" },
                    { value: "SÃO MIGUEL DO GUAMÁ", label: "SÃO MIGUEL DO GUAMÁ" },
                    { value: "SÃO SEBASTIÃO DA BOA VISTA", label: "SÃO SEBASTIÃO DA BOA VISTA" },
                    { value: "SAPUCAIA", label: "SAPUCAIA" },
                    { value: "SENADOR JOSÉ PORFÍRIO", label: "SENADOR JOSÉ PORFÍRIO" },
                    { value: "SOURE", label: "SOURE" },
                    { value: "TAILÂNDIA", label: "TAILÂNDIA" },
                    { value: "TERRA ALTA", label: "TERRA ALTA" },
                    { value: "TERRA SANTA", label: "TERRA SANTA" },
                    { value: "TOMÉ-AÇU", label: "TOMÉ-AÇU" },
                    { value: "TRACUATEUA", label: "TRACUATEUA" },
                    { value: "TRAIRÃO", label: "TRAIRÃO" },
                    { value: "TUCUMÃ", label: "TUCUMÃ" },
                    { value: "TUCURUÍ", label: "TUCURUÍ" },
                    { value: "ULIANÓPOLIS", label: "ULIANÓPOLIS" },
                    { value: "URUARÁ", label: "URUARÁ" },
                    { value: "VIGIA", label: "VIGIA" },
                    { value: "VISEU", label: "VISEU" },
                    { value: "VITÓRIA DO XINGU", label: "VITÓRIA DO XINGU" },
                    { value: "XINGUARA", label: "XINGUARA" },
               ]
               break;

          case "PARAÍBA":
               CITIES = [
                    { value: "ÁGUA BRANCA", label: "ÁGUA BRANCA" },
                    { value: "AGUIAR", label: "AGUIAR" },
                    { value: "ALAGOA GRANDE", label: "ALAGOA GRANDE" },
                    { value: "ALAGOA NOVA", label: "ALAGOA NOVA" },
                    { value: "ALAGOINHA", label: "ALAGOINHA" },
                    { value: "ALCANTIL", label: "ALCANTIL" },
                    { value: "ALGODÃO DE JANDAÍRA", label: "ALGODÃO DE JANDAÍRA" },
                    { value: "ALHANDRA", label: "ALHANDRA" },
                    { value: "AMPARO", label: "AMPARO" },
                    { value: "APARECIDA", label: "APARECIDA" },
                    { value: "ARAÇAGI", label: "ARAÇAGI" },
                    { value: "ARARA", label: "ARARA" },
                    { value: "ARARUNA", label: "ARARUNA" },
                    { value: "AREIA", label: "AREIA" },
                    { value: "AREIA DE BARAÚNAS", label: "AREIA DE BARAÚNAS" },
                    { value: "AREIAL", label: "AREIAL" },
                    { value: "AROEIRAS", label: "AROEIRAS" },
                    { value: "ASSUNÇÃO", label: "ASSUNÇÃO" },
                    { value: "BAÍA DA TRAIÇÃO", label: "BAÍA DA TRAIÇÃO" },
                    { value: "BANANEIRAS", label: "BANANEIRAS" },
                    { value: "BARAÚNA", label: "BARAÚNA" },
                    { value: "BARRA DE SANTA ROSA", label: "BARRA DE SANTA ROSA" },
                    { value: "BARRA DE SANTANA", label: "BARRA DE SANTANA" },
                    { value: "BARRA DE SÃO MIGUEL", label: "BARRA DE SÃO MIGUEL" },
                    { value: "BAYEUX", label: "BAYEUX" },
                    { value: "BELÉM", label: "BELÉM" },
                    { value: "BELÉM DO BREJO DO CRUZ", label: "BELÉM DO BREJO DO CRUZ" },
                    { value: "BERNARDINO BATISTA", label: "BERNARDINO BATISTA" },
                    { value: "BOA VENTURA", label: "BOA VENTURA" },
                    { value: "BOA VISTA", label: "BOA VISTA" },
                    { value: "BOM JESUS", label: "BOM JESUS" },
                    { value: "BOM SUCESSO", label: "BOM SUCESSO" },
                    { value: "BONITO DE SANTA FÉ", label: "BONITO DE SANTA FÉ" },
                    { value: "BOQUEIRÃO", label: "BOQUEIRÃO" },
                    { value: "BORBOREMA", label: "BORBOREMA" },
                    { value: "BREJO DO CRUZ", label: "BREJO DO CRUZ" },
                    { value: "BREJO DOS SANTOS", label: "BREJO DOS SANTOS" },
                    { value: "CAAPORÃ", label: "CAAPORÃ" },
                    { value: "CABACEIRAS", label: "CABACEIRAS" },
                    { value: "CABEDELO", label: "CABEDELO" },
                    { value: "CACHOEIRA DOS ÍNDIOS", label: "CACHOEIRA DOS ÍNDIOS" },
                    { value: "CACIMBA DE AREIA", label: "CACIMBA DE AREIA" },
                    { value: "CACIMBA DE DENTRO", label: "CACIMBA DE DENTRO" },
                    { value: "CACIMBAS", label: "CACIMBAS" },
                    { value: "CAIÇARA", label: "CAIÇARA" },
                    { value: "CAJAZEIRAS", label: "CAJAZEIRAS" },
                    { value: "CAJAZEIRINHAS", label: "CAJAZEIRINHAS" },
                    { value: "CALDAS BRANDÃO", label: "CALDAS BRANDÃO" },
                    { value: "CAMALAÚ", label: "CAMALAÚ" },
                    { value: "CAMPINA GRANDE", label: "CAMPINA GRANDE" },
                    { value: "CAPIM", label: "CAPIM" },
                    { value: "CARAÚBAS", label: "CARAÚBAS" },
                    { value: "CARRAPATEIRA", label: "CARRAPATEIRA" },
                    { value: "CASSERENGUE", label: "CASSERENGUE" },
                    { value: "CATINGUEIRA", label: "CATINGUEIRA" },
                    { value: "CATOLÉ DO ROCHA", label: "CATOLÉ DO ROCHA" },
                    { value: "CATURITÉ", label: "CATURITÉ" },
                    { value: "CONCEIÇÃO", label: "CONCEIÇÃO" },
                    { value: "CONDADO", label: "CONDADO" },
                    { value: "CONDE", label: "CONDE" },
                    { value: "CONGO", label: "CONGO" },
                    { value: "COREMAS", label: "COREMAS" },
                    { value: "COXIXOLA", label: "COXIXOLA" },
                    { value: "CRUZ DO ESPÍRITO SANTO", label: "CRUZ DO ESPÍRITO SANTO" },
                    { value: "CUBATI", label: "CUBATI" },
                    { value: "CUITÉ", label: "CUITÉ" },
                    { value: "CUITÉ DE MAMANGUAPE", label: "CUITÉ DE MAMANGUAPE" },
                    { value: "CUITEGI", label: "CUITEGI" },
                    { value: "CURRAL DE CIMA", label: "CURRAL DE CIMA" },
                    { value: "CURRAL VELHO", label: "CURRAL VELHO" },
                    { value: "DAMIÃO", label: "DAMIÃO" },
                    { value: "DESTERRO", label: "DESTERRO" },
                    { value: "DIAMANTE", label: "DIAMANTE" },
                    { value: "DONA INÊS", label: "DONA INÊS" },
                    { value: "DUAS ESTRADAS", label: "DUAS ESTRADAS" },
                    { value: "EMAS", label: "EMAS" },
                    { value: "ESPERANÇA", label: "ESPERANÇA" },
                    { value: "FAGUNDES", label: "FAGUNDES" },
                    { value: "FREI MARTINHO", label: "FREI MARTINHO" },
                    { value: "GADO BRAVO", label: "GADO BRAVO" },
                    { value: "GUARABIRA", label: "GUARABIRA" },
                    { value: "GURINHÉM", label: "GURINHÉM" },
                    { value: "GURJÃO", label: "GURJÃO" },
                    { value: "IBIARA", label: "IBIARA" },
                    { value: "IGARACY", label: "IGARACY" },
                    { value: "IMACULADA", label: "IMACULADA" },
                    { value: "INGÁ", label: "INGÁ" },
                    { value: "ITABAIANA", label: "ITABAIANA" },
                    { value: "ITAPORANGA", label: "ITAPORANGA" },
                    { value: "ITAPOROROCA", label: "ITAPOROROCA" },
                    { value: "ITATUBA", label: "ITATUBA" },
                    { value: "JACARAÚ", label: "JACARAÚ" },
                    { value: "JERICÓ", label: "JERICÓ" },
                    { value: "JOÃO PESSOA", label: "JOÃO PESSOA" },
                    { value: "JOCA CLAUDINO", label: "JOCA CLAUDINO" },
                    { value: "JUAREZ TÁVORA", label: "JUAREZ TÁVORA" },
                    { value: "JUAZEIRINHO", label: "JUAZEIRINHO" },
                    { value: "JUNCO DO SERIDÓ", label: "JUNCO DO SERIDÓ" },
                    { value: "JURIPIRANGA", label: "JURIPIRANGA" },
                    { value: "JURU", label: "JURU" },
                    { value: "LAGOA", label: "LAGOA" },
                    { value: "LAGOA DE DENTRO", label: "LAGOA DE DENTRO" },
                    { value: "LAGOA SECA", label: "LAGOA SECA" },
                    { value: "LASTRO", label: "LASTRO" },
                    { value: "LIVRAMENTO", label: "LIVRAMENTO" },
                    { value: "RADOURO", label: "RADOURO" },
                    { value: "LUCENA", label: "LUCENA" },
                    { value: "MÃE D'ÁGUA", label: "MÃE D'ÁGUA" },
                    { value: "MALTA", label: "MALTA" },
                    { value: "MAMANGUAPE", label: "MAMANGUAPE" },
                    { value: "MANAÍRA", label: "MANAÍRA" },
                    { value: "MARCAÇÃO", label: "MARCAÇÃO" },
                    { value: "MARI", label: "MARI" },
                    { value: "MARIZÓPOLIS", label: "MARIZÓPOLIS" },
                    { value: "MASSARANDUBA", label: "MASSARANDUBA" },
                    { value: "MATARACA", label: "MATARACA" },
                    { value: "MATINHAS", label: "MATINHAS" },
                    { value: "MATO GROSSO", label: "MATO GROSSO" },
                    { value: "MATURÉIA", label: "MATURÉIA" },
                    { value: "MOGEIRO", label: "MOGEIRO" },
                    { value: "MONTADAS", label: "MONTADAS" },
                    { value: "MONTE HOREBE", label: "MONTE HOREBE" },
                    { value: "MONTEIRO", label: "MONTEIRO" },
                    { value: "MULUNGU", label: "MULUNGU" },
                    { value: "NATUBA", label: "NATUBA" },
                    { value: "NAZAREZINHO", label: "NAZAREZINHO" },
                    { value: "NOVA FLORESTA", label: "NOVA FLORESTA" },
                    { value: "NOVA OLINDA", label: "NOVA OLINDA" },
                    { value: "NOVA PALMEIRA", label: "NOVA PALMEIRA" },
                    { value: "OLHO D'ÁGUA", label: "OLHO D'ÁGUA" },
                    { value: "OLIVEDOS", label: "OLIVEDOS" },
                    { value: "OURO VELHO", label: "OURO VELHO" },
                    { value: "PARARI", label: "PARARI" },
                    { value: "PASSAGEM", label: "PASSAGEM" },
                    { value: "PATOS", label: "PATOS" },
                    { value: "PAULISTA", label: "PAULISTA" },
                    { value: "PEDRA BRANCA", label: "PEDRA BRANCA" },
                    { value: "PEDRA LAVRADA", label: "PEDRA LAVRADA" },
                    { value: "PEDRAS DE FOGO", label: "PEDRAS DE FOGO" },
                    { value: "PEDRO RÉGIS", label: "PEDRO RÉGIS" },
                    { value: "PIANCÓ", label: "PIANCÓ" },
                    { value: "PICUÍ", label: "PICUÍ" },
                    { value: "PILAR", label: "PILAR" },
                    { value: "PILÕES", label: "PILÕES" },
                    { value: "PILÕEZINHOS", label: "PILÕEZINHOS" },
                    { value: "PIRPIRITUBA", label: "PIRPIRITUBA" },
                    { value: "PITIMBU", label: "PITIMBU" },
                    { value: "POCINHOS", label: "POCINHOS" },
                    { value: "POÇO DANTAS", label: "POÇO DANTAS" },
                    { value: "POÇO DE JOSÉ DE MOURA", label: "POÇO DE JOSÉ DE MOURA" },
                    { value: "POMBAL", label: "POMBAL" },
                    { value: "PRATA", label: "PRATA" },
                    { value: "PRINCESA ISABEL", label: "PRINCESA ISABEL" },
                    { value: "PUXINANÃ", label: "PUXINANÃ" },
                    { value: "QUEIMADAS", label: "QUEIMADAS" },
                    { value: "QUIXABÁ", label: "QUIXABÁ" },
                    { value: "REMÍGIO", label: "REMÍGIO" },
                    { value: "RIACHÃO", label: "RIACHÃO" },
                    { value: "RIACHÃO DO BACAMARTE", label: "RIACHÃO DO BACAMARTE" },
                    { value: "RIACHÃO DO POÇO", label: "RIACHÃO DO POÇO" },
                    { value: "RIACHO DE SANTO ANTÔNIO", label: "RIACHO DE SANTO ANTÔNIO" },
                    { value: "RIACHO DOS CAVALOS", label: "RIACHO DOS CAVALOS" },
                    { value: "RIO TINTO", label: "RIO TINTO" },
                    { value: "SALGADINHO", label: "SALGADINHO" },
                    { value: "SALGADO DE SÃO FÉLIX", label: "SALGADO DE SÃO FÉLIX" },
                    { value: "SANTA CECÍLIA", label: "SANTA CECÍLIA" },
                    { value: "SANTA CRUZ", label: "SANTA CRUZ" },
                    { value: "SANTA HELENA", label: "SANTA HELENA" },
                    { value: "SANTA INÊS", label: "SANTA INÊS" },
                    { value: "SANTA LUZIA", label: "SANTA LUZIA" },
                    { value: "SANTA RITA", label: "SANTA RITA" },
                    { value: "SANTA TERESINHA", label: "SANTA TERESINHA" },
                    { value: "SANTANA DE MANGUEIRA", label: "SANTANA DE MANGUEIRA" },
                    { value: "SANTANA DOS GARROTES", label: "SANTANA DOS GARROTES" },
                    { value: "SANTO ANDRÉ", label: "SANTO ANDRÉ" },
                    { value: "SÃO BENTINHO", label: "SÃO BENTINHO" },
                    { value: "SÃO BENTO", label: "SÃO BENTO" },
                    { value: "SÃO DOMINGOS", label: "SÃO DOMINGOS" },
                    { value: "SÃO DOMINGOS DO CARIRI", label: "SÃO DOMINGOS DO CARIRI" },
                    { value: "SÃO FRANCISCO", label: "SÃO FRANCISCO" },
                    { value: "SÃO JOÃO DO CARIRI", label: "SÃO JOÃO DO CARIRI" },
                    { value: "SÃO JOÃO DO RIO DO PEIXE", label: "SÃO JOÃO DO RIO DO PEIXE" },
                    { value: "SÃO JOÃO DO TIGRE", label: "SÃO JOÃO DO TIGRE" },
                    { value: "SÃO JOSÉ DA LAGOA TAPADA", label: "SÃO JOSÉ DA LAGOA TAPADA" },
                    { value: "SÃO JOSÉ DE CAIANA", label: "SÃO JOSÉ DE CAIANA" },
                    { value: "SÃO JOSÉ DE ESPINHARAS", label: "SÃO JOSÉ DE ESPINHARAS" },
                    { value: "SÃO JOSÉ DE PIRANHAS", label: "SÃO JOSÉ DE PIRANHAS" },
                    { value: "SÃO JOSÉ DE PRINCESA", label: "SÃO JOSÉ DE PRINCESA" },
                    { value: "SÃO JOSÉ DO BONFIM", label: "SÃO JOSÉ DO BONFIM" },
                    { value: "SÃO JOSÉ DO BREJO DO CRUZ", label: "SÃO JOSÉ DO BREJO DO CRUZ" },
                    { value: "SÃO JOSÉ DO SABUGI", label: "SÃO JOSÉ DO SABUGI" },
                    { value: "SÃO JOSÉ DOS CORDEIROS", label: "SÃO JOSÉ DOS CORDEIROS" },
                    { value: "SÃO JOSÉ DOS RAMOS", label: "SÃO JOSÉ DOS RAMOS" },
                    { value: "SÃO MAMEDE", label: "SÃO MAMEDE" },
                    { value: "SÃO MIGUEL DE TAIPU", label: "SÃO MIGUEL DE TAIPU" },
                    { value: "SÃO SEBASTIÃO DE LAGOA DE ROÇA", label: "SÃO SEBASTIÃO DE LAGOA DE ROÇA" },
                    { value: "SÃO SEBASTIÃO DO UMBUZEIRO", label: "SÃO SEBASTIÃO DO UMBUZEIRO" },
                    { value: "SÃO VICENTE DO SERIDÓ", label: "SÃO VICENTE DO SERIDÓ" },
                    { value: "SAPÉ", label: "SAPÉ" },
                    { value: "SERRA BRANCA", label: "SERRA BRANCA" },
                    { value: "SERRA DA RAIZ", label: "SERRA DA RAIZ" },
                    { value: "SERRA GRANDE", label: "SERRA GRANDE" },
                    { value: "SERRA REDONDA", label: "SERRA REDONDA" },
                    { value: "SERRARIA", label: "SERRARIA" },
                    { value: "SERTÃOZINHO", label: "SERTÃOZINHO" },
                    { value: "SOBRADO", label: "SOBRADO" },
                    { value: "SOLÂNEA", label: "SOLÂNEA" },
                    { value: "SOLEDADE", label: "SOLEDADE" },
                    { value: "SOSSÊGO", label: "SOSSÊGO" },
                    { value: "SOUSA", label: "SOUSA" },
                    { value: "SUMÉ", label: "SUMÉ" },
                    { value: "TACIMA", label: "TACIMA" },
                    { value: "TAPEROÁ", label: "TAPEROÁ" },
                    { value: "TAVARES", label: "TAVARES" },
                    { value: "TEIXEIRA", label: "TEIXEIRA" },
                    { value: "TENÓRIO", label: "TENÓRIO" },
                    { value: "TRIUNFO", label: "TRIUNFO" },
                    { value: "UIRAÚNA", label: "UIRAÚNA" },
                    { value: "UMBUZEIRO", label: "UMBUZEIRO" },
                    { value: "VÁRZEA", label: "VÁRZEA" },
                    { value: "VIEIRÓPOLIS", label: "VIEIRÓPOLIS" },
                    { value: "VISTA SERRANA", label: "VISTA SERRANA" },
                    { value: "ZABELÊ", label: "ZABELÊ" },
               ]
               break;

          case "PARANÁ":
               CITIES = [
                    { value: "ABATIÁ", label: "ABATIÁ" },
                    { value: "ADRIANÓPOLIS", label: "ADRIANÓPOLIS" },
                    { value: "AGUDOS DO SUL", label: "AGUDOS DO SUL" },
                    { value: "ALMIRANTE TAMANDARÉ", label: "ALMIRANTE TAMANDARÉ" },
                    { value: "ALTAMIRA DO PARANÁ", label: "ALTAMIRA DO PARANÁ" },
                    { value: "ALTO PARAÍSO", label: "ALTO PARAÍSO" },
                    { value: "ALTO PARANÁ", label: "ALTO PARANÁ" },
                    { value: "ALTO PIQUIRI", label: "ALTO PIQUIRI" },
                    { value: "ALTÔNIA", label: "ALTÔNIA" },
                    { value: "ALVORADA DO SUL", label: "ALVORADA DO SUL" },
                    { value: "AMAPORÃ", label: "AMAPORÃ" },
                    { value: "AMPÉRE", label: "AMPÉRE" },
                    { value: "ANAHY", label: "ANAHY" },
                    { value: "ANDIRÁ", label: "ANDIRÁ" },
                    { value: "ÂNGULO", label: "ÂNGULO" },
                    { value: "ANTONINA", label: "ANTONINA" },
                    { value: "ANTÔNIO OLINTO", label: "ANTÔNIO OLINTO" },
                    { value: "APUCARANA", label: "APUCARANA" },
                    { value: "ARAPONGAS", label: "ARAPONGAS" },
                    { value: "ARAPOTI", label: "ARAPOTI" },
                    { value: "ARAPUÃ", label: "ARAPUÃ" },
                    { value: "ARARUNA", label: "ARARUNA" },
                    { value: "ARAUCÁRIA", label: "ARAUCÁRIA" },
                    { value: "ARIRANHA DO IVAÍ", label: "ARIRANHA DO IVAÍ" },
                    { value: "ASSAÍ", label: "ASSAÍ" },
                    { value: "ASSIS CHATEAUBRIAND", label: "ASSIS CHATEAUBRIAND" },
                    { value: "ASTORGA", label: "ASTORGA" },
                    { value: "ATALAIA", label: "ATALAIA" },
                    { value: "BALSA NOVA", label: "BALSA NOVA" },
                    { value: "BANDEIRANTES", label: "BANDEIRANTES" },
                    { value: "BARBOSA FERRAZ", label: "BARBOSA FERRAZ" },
                    { value: "BARRA DO JACARÉ", label: "BARRA DO JACARÉ" },
                    { value: "BARRACÃO", label: "BARRACÃO" },
                    { value: "BELA VISTA DA CAROBA", label: "BELA VISTA DA CAROBA" },
                    { value: "BELA VISTA DO PARAÍSO", label: "BELA VISTA DO PARAÍSO" },
                    { value: "BITURUNA", label: "BITURUNA" },
                    { value: "BOA ESPERANÇA", label: "BOA ESPERANÇA" },
                    { value: "BOA ESPERANÇA DO IGUAÇU", label: "BOA ESPERANÇA DO IGUAÇU" },
                    { value: "BOA VENTURA DE SÃO ROQUE", label: "BOA VENTURA DE SÃO ROQUE" },
                    { value: "BOA VISTA DA APARECIDA", label: "BOA VISTA DA APARECIDA" },
                    { value: "BOCAIÚVA DO SUL", label: "BOCAIÚVA DO SUL" },
                    { value: "BOM JESUS DO SUL", label: "BOM JESUS DO SUL" },
                    { value: "BOM SUCESSO", label: "BOM SUCESSO" },
                    { value: "BOM SUCESSO DO SUL", label: "BOM SUCESSO DO SUL" },
                    { value: "BORRAZÓPOLIS", label: "BORRAZÓPOLIS" },
                    { value: "BRAGANEY", label: "BRAGANEY" },
                    { value: "BRASILÂNDIA DO SUL", label: "BRASILÂNDIA DO SUL" },
                    { value: "CAFEARA", label: "CAFEARA" },
                    { value: "CAFELÂNDIA", label: "CAFELÂNDIA" },
                    { value: "CAFEZAL DO SUL", label: "CAFEZAL DO SUL" },
                    { value: "CALIFÓRNIA", label: "CALIFÓRNIA" },
                    { value: "CAMBARÁ", label: "CAMBARÁ" },
                    { value: "CAMBÉ", label: "CAMBÉ" },
                    { value: "CAMBIRA", label: "CAMBIRA" },
                    { value: "CAMPINA DA LAGOA", label: "CAMPINA DA LAGOA" },
                    { value: "CAMPINA DO SIMÃO", label: "CAMPINA DO SIMÃO" },
                    { value: "CAMPINA GRANDE DO SUL", label: "CAMPINA GRANDE DO SUL" },
                    { value: "CAMPO BONITO", label: "CAMPO BONITO" },
                    { value: "CAMPO DO TENENTE", label: "CAMPO DO TENENTE" },
                    { value: "CAMPO LARGO", label: "CAMPO LARGO" },
                    { value: "CAMPO MAGRO", label: "CAMPO MAGRO" },
                    { value: "CAMPO MOURÃO", label: "CAMPO MOURÃO" },
                    { value: "CÂNDIDO DE ABREU", label: "CÂNDIDO DE ABREU" },
                    { value: "CANDÓI", label: "CANDÓI" },
                    { value: "CANTAGALO", label: "CANTAGALO" },
                    { value: "CAPANEMA", label: "CAPANEMA" },
                    { value: "CAPITÃO LEÔNIDAS MARQUES", label: "CAPITÃO LEÔNIDAS MARQUES" },
                    { value: "CARAMBEÍ", label: "CARAMBEÍ" },
                    { value: "CARLÓPOLIS", label: "CARLÓPOLIS" },
                    { value: "CASCAVEL", label: "CASCAVEL" },
                    { value: "CASTRO", label: "CASTRO" },
                    { value: "CATANDUVAS", label: "CATANDUVAS" },
                    { value: "CENTENÁRIO DO SUL", label: "CENTENÁRIO DO SUL" },
                    { value: "CERRO AZUL", label: "CERRO AZUL" },
                    { value: "CÉU AZUL", label: "CÉU AZUL" },
                    { value: "CHOPINZINHO", label: "CHOPINZINHO" },
                    { value: "CIANORTE", label: "CIANORTE" },
                    { value: "CIDADE GAÚCHA", label: "CIDADE GAÚCHA" },
                    { value: "CLEVELÂNDIA", label: "CLEVELÂNDIA" },
                    { value: "COLOMBO", label: "COLOMBO" },
                    { value: "COLORADO", label: "COLORADO" },
                    { value: "CONGONHINHAS", label: "CONGONHINHAS" },
                    { value: "CONSELHEIRO MAIRINCK", label: "CONSELHEIRO MAIRINCK" },
                    { value: "CONTENDA", label: "CONTENDA" },
                    { value: "CORBÉLIA", label: "CORBÉLIA" },
                    { value: "CORNÉLIO PROCÓPIO", label: "CORNÉLIO PROCÓPIO" },
                    { value: "CORONEL DOMINGOS SOARES", label: "CORONEL DOMINGOS SOARES" },
                    { value: "CORONEL VIVIDA", label: "CORONEL VIVIDA" },
                    { value: "CORUMBATAÍ DO SUL", label: "CORUMBATAÍ DO SUL" },
                    { value: "CRUZ MACHADO", label: "CRUZ MACHADO" },
                    { value: "CRUZEIRO DO IGUAÇU", label: "CRUZEIRO DO IGUAÇU" },
                    { value: "CRUZEIRO DO OESTE", label: "CRUZEIRO DO OESTE" },
                    { value: "CRUZEIRO DO SUL", label: "CRUZEIRO DO SUL" },
                    { value: "CRUZMALTINA", label: "CRUZMALTINA" },
                    { value: "CURITIBA", label: "CURITIBA" },
                    { value: "CURIÚVA", label: "CURIÚVA" },
                    { value: "DIAMANTE D'OESTE", label: "DIAMANTE D'OESTE" },
                    { value: "DIAMANTE DO NORTE", label: "DIAMANTE DO NORTE" },
                    { value: "DIAMANTE DO SUL", label: "DIAMANTE DO SUL" },
                    { value: "DOIS VIZINHOS", label: "DOIS VIZINHOS" },
                    { value: "DOURADINA", label: "DOURADINA" },
                    { value: "DOUTOR CAMARGO", label: "DOUTOR CAMARGO" },
                    { value: "DOUTOR ULYSSES", label: "DOUTOR ULYSSES" },
                    { value: "ENÉAS MARQUES", label: "ENÉAS MARQUES" },
                    { value: "ENGENHEIRO BELTRÃO", label: "ENGENHEIRO BELTRÃO" },
                    { value: "ENTRE RIOS DO OESTE", label: "ENTRE RIOS DO OESTE" },
                    { value: "ESPERANÇA NOVA", label: "ESPERANÇA NOVA" },
                    { value: "ESPIGÃO ALTO DO IGUAÇU", label: "ESPIGÃO ALTO DO IGUAÇU" },
                    { value: "FAROL", label: "FAROL" },
                    { value: "FAXINAL", label: "FAXINAL" },
                    { value: "FAZENDA RIO GRANDE", label: "FAZENDA RIO GRANDE" },
                    { value: "FÊNIX", label: "FÊNIX" },
                    { value: "FERNANDES PINHEIRO", label: "FERNANDES PINHEIRO" },
                    { value: "FIGUEIRA", label: "FIGUEIRA" },
                    { value: "FLOR DA SERRA DO SUL", label: "FLOR DA SERRA DO SUL" },
                    { value: "FLORAÍ", label: "FLORAÍ" },
                    { value: "FLORESTA", label: "FLORESTA" },
                    { value: "FLORESTÓPOLIS", label: "FLORESTÓPOLIS" },
                    { value: "FLÓRIDA", label: "FLÓRIDA" },
                    { value: "FORMOSA DO OESTE", label: "FORMOSA DO OESTE" },
                    { value: "FOZ DO IGUAÇU", label: "FOZ DO IGUAÇU" },
                    { value: "FOZ DO JORDÃO", label: "FOZ DO JORDÃO" },
                    { value: "FRANCISCO ALVES", label: "FRANCISCO ALVES" },
                    { value: "FRANCISCO BELTRÃO", label: "FRANCISCO BELTRÃO" },
                    { value: "GENERAL CARNEIRO", label: "GENERAL CARNEIRO" },
                    { value: "GODOY MOREIRA", label: "GODOY MOREIRA" },
                    { value: "GOIOERÊ", label: "GOIOERÊ" },
                    { value: "GOIOXIM", label: "GOIOXIM" },
                    { value: "GRANDES RIOS", label: "GRANDES RIOS" },
                    { value: "GUAÍRA", label: "GUAÍRA" },
                    { value: "GUAIRAÇÁ", label: "GUAIRAÇÁ" },
                    { value: "GUAMIRANGA", label: "GUAMIRANGA" },
                    { value: "GUAPIRAMA", label: "GUAPIRAMA" },
                    { value: "GUAPOREMA", label: "GUAPOREMA" },
                    { value: "GUARACI", label: "GUARACI" },
                    { value: "GUARANIAÇU", label: "GUARANIAÇU" },
                    { value: "GUARAPUAVA", label: "GUARAPUAVA" },
                    { value: "GUARAQUEÇABA", label: "GUARAQUEÇABA" },
                    { value: "GUARATUBA", label: "GUARATUBA" },
                    { value: "HONÓRIO SERPA", label: "HONÓRIO SERPA" },
                    { value: "IBAITI", label: "IBAITI" },
                    { value: "IBEMA", label: "IBEMA" },
                    { value: "IBIPORÃ", label: "IBIPORÃ" },
                    { value: "ICARAÍMA", label: "ICARAÍMA" },
                    { value: "IGUARAÇU", label: "IGUARAÇU" },
                    { value: "IGUATU", label: "IGUATU" },
                    { value: "IMBAÚ", label: "IMBAÚ" },
                    { value: "IMBITUVA", label: "IMBITUVA" },
                    { value: "INÁCIO MARTINS", label: "INÁCIO MARTINS" },
                    { value: "INAJÁ", label: "INAJÁ" },
                    { value: "INDIANÓPOLIS", label: "INDIANÓPOLIS" },
                    { value: "IPIRANGA", label: "IPIRANGA" },
                    { value: "IPORÃ", label: "IPORÃ" },
                    { value: "IRACEMA DO OESTE", label: "IRACEMA DO OESTE" },
                    { value: "IRATI", label: "IRATI" },
                    { value: "IRETAMA", label: "IRETAMA" },
                    { value: "ITAGUAJÉ", label: "ITAGUAJÉ" },
                    { value: "ITAIPULÂNDIA", label: "ITAIPULÂNDIA" },
                    { value: "ITAMBARACÁ", label: "ITAMBARACÁ" },
                    { value: "ITAMBÉ", label: "ITAMBÉ" },
                    { value: "ITAPEJARA D'OESTE", label: "ITAPEJARA D'OESTE" },
                    { value: "ITAPERUÇU", label: "ITAPERUÇU" },
                    { value: "ITAÚNA DO SUL", label: "ITAÚNA DO SUL" },
                    { value: "IVAÍ", label: "IVAÍ" },
                    { value: "IVAIPORÃ", label: "IVAIPORÃ" },
                    { value: "IVATÉ", label: "IVATÉ" },
                    { value: "IVATUBA", label: "IVATUBA" },
                    { value: "JABOTI", label: "JABOTI" },
                    { value: "JACAREZINHO", label: "JACAREZINHO" },
                    { value: "JAGUAPITÃ", label: "JAGUAPITÃ" },
                    { value: "JAGUARIAÍVA", label: "JAGUARIAÍVA" },
                    { value: "JANDAIA DO SUL", label: "JANDAIA DO SUL" },
                    { value: "JANIÓPOLIS", label: "JANIÓPOLIS" },
                    { value: "JAPIRA", label: "JAPIRA" },
                    { value: "JAPURÁ", label: "JAPURÁ" },
                    { value: "JARDIM ALEGRE", label: "JARDIM ALEGRE" },
                    { value: "JARDIM OLINDA", label: "JARDIM OLINDA" },
                    { value: "JATAIZINHO", label: "JATAIZINHO" },
                    { value: "JESUÍTAS", label: "JESUÍTAS" },
                    { value: "JOAQUIM TÁVORA", label: "JOAQUIM TÁVORA" },
                    { value: "JUNDIAÍ DO SUL", label: "JUNDIAÍ DO SUL" },
                    { value: "JURANDA", label: "JURANDA" },
                    { value: "JUSSARA", label: "JUSSARA" },
                    { value: "KALORÉ", label: "KALORÉ" },
                    { value: "LAPA", label: "LAPA" },
                    { value: "LARANJAL", label: "LARANJAL" },
                    { value: "LARANJEIRAS DO SUL", label: "LARANJEIRAS DO SUL" },
                    { value: "LEÓPOLIS", label: "LEÓPOLIS" },
                    { value: "LIDIANÓPOLIS", label: "LIDIANÓPOLIS" },
                    { value: "LINDOESTE", label: "LINDOESTE" },
                    { value: "LOANDA", label: "LOANDA" },
                    { value: "LOBATO", label: "LOBATO" },
                    { value: "LONDRINA", label: "LONDRINA" },
                    { value: "LUIZIANA", label: "LUIZIANA" },
                    { value: "LUNARDELLI", label: "LUNARDELLI" },
                    { value: "LUPIONÓPOLIS", label: "LUPIONÓPOLIS" },
                    { value: "MALLET", label: "MALLET" },
                    { value: "MAMBORÊ", label: "MAMBORÊ" },
                    { value: "MANDAGUAÇU", label: "MANDAGUAÇU" },
                    { value: "MANDAGUARI", label: "MANDAGUARI" },
                    { value: "MANDIRITUBA", label: "MANDIRITUBA" },
                    { value: "MANFRINÓPOLIS", label: "MANFRINÓPOLIS" },
                    { value: "MANGUEIRINHA", label: "MANGUEIRINHA" },
                    { value: "MANOEL RIBAS", label: "MANOEL RIBAS" },
                    { value: "MARECHAL CÂNDIDO RONDON", label: "MARECHAL CÂNDIDO RONDON" },
                    { value: "MARIA HELENA", label: "MARIA HELENA" },
                    { value: "MARIALVA", label: "MARIALVA" },
                    { value: "MARILÂNDIA DO SUL", label: "MARILÂNDIA DO SUL" },
                    { value: "MARILENA", label: "MARILENA" },
                    { value: "MARILUZ", label: "MARILUZ" },
                    { value: "MARINGÁ", label: "MARINGÁ" },
                    { value: "MARIÓPOLIS", label: "MARIÓPOLIS" },
                    { value: "MARIPÁ", label: "MARIPÁ" },
                    { value: "MARMELEIRO", label: "MARMELEIRO" },
                    { value: "MARQUINHO", label: "MARQUINHO" },
                    { value: "MARUMBI", label: "MARUMBI" },
                    { value: "MATELÂNDIA", label: "MATELÂNDIA" },
                    { value: "MATINHOS", label: "MATINHOS" },
                    { value: "MATO RICO", label: "MATO RICO" },
                    { value: "MAUÁ DA SERRA", label: "MAUÁ DA SERRA" },
                    { value: "MEDIANEIRA", label: "MEDIANEIRA" },
                    { value: "MERCEDES", label: "MERCEDES" },
                    { value: "MIRADOR", label: "MIRADOR" },
                    { value: "MIRASELVA", label: "MIRASELVA" },
                    { value: "MISSAL", label: "MISSAL" },
                    { value: "MOREIRA SALES", label: "MOREIRA SALES" },
                    { value: "MORRETES", label: "MORRETES" },
                    { value: "MUNHOZ DE MELO", label: "MUNHOZ DE MELO" },
                    { value: "NOSSA SENHORA DAS GRAÇAS", label: "NOSSA SENHORA DAS GRAÇAS" },
                    { value: "NOVA ALIANÇA DO IVAÍ", label: "NOVA ALIANÇA DO IVAÍ" },
                    { value: "NOVA AMÉRICA DA COLINA", label: "NOVA AMÉRICA DA COLINA" },
                    { value: "NOVA AURORA", label: "NOVA AURORA" },
                    { value: "NOVA CANTU", label: "NOVA CANTU" },
                    { value: "NOVA ESPERANÇA", label: "NOVA ESPERANÇA" },
                    { value: "NOVA ESPERANÇA DO SUDOESTE", label: "NOVA ESPERANÇA DO SUDOESTE" },
                    { value: "NOVA FÁTIMA", label: "NOVA FÁTIMA" },
                    { value: "NOVA LARANJEIRAS", label: "NOVA LARANJEIRAS" },
                    { value: "NOVA LONDRINA", label: "NOVA LONDRINA" },
                    { value: "NOVA OLÍMPIA", label: "NOVA OLÍMPIA" },
                    { value: "NOVA PRATA DO IGUAÇU", label: "NOVA PRATA DO IGUAÇU" },
                    { value: "NOVA SANTA BÁRBARA", label: "NOVA SANTA BÁRBARA" },
                    { value: "NOVA SANTA ROSA", label: "NOVA SANTA ROSA" },
                    { value: "NOVA TEBAS", label: "NOVA TEBAS" },
                    { value: "NOVO ITACOLOMI", label: "NOVO ITACOLOMI" },
                    { value: "ORTIGUEIRA", label: "ORTIGUEIRA" },
                    { value: "OURIZONA", label: "OURIZONA" },
                    { value: "OURO VERDE DO OESTE", label: "OURO VERDE DO OESTE" },
                    { value: "PAIÇANDU", label: "PAIÇANDU" },
                    { value: "PALMAS", label: "PALMAS" },
                    { value: "PALMEIRA", label: "PALMEIRA" },
                    { value: "PALMITAL", label: "PALMITAL" },
                    { value: "PALOTINA", label: "PALOTINA" },
                    { value: "PARAÍSO DO NORTE", label: "PARAÍSO DO NORTE" },
                    { value: "PARANACITY", label: "PARANACITY" },
                    { value: "PARANAGUÁ", label: "PARANAGUÁ" },
                    { value: "PARANAPOEMA", label: "PARANAPOEMA" },
                    { value: "PARANAVAÍ", label: "PARANAVAÍ" },
                    { value: "PATO BRAGADO", label: "PATO BRAGADO" },
                    { value: "PATO BRANCO", label: "PATO BRANCO" },
                    { value: "PAULA FREITAS", label: "PAULA FREITAS" },
                    { value: "PAULO FRONTIN", label: "PAULO FRONTIN" },
                    { value: "PEABIRU", label: "PEABIRU" },
                    { value: "PEROBAL", label: "PEROBAL" },
                    { value: "PÉROLA", label: "PÉROLA" },
                    { value: "PÉROLA D'OESTE", label: "PÉROLA D'OESTE" },
                    { value: "PIÊN", label: "PIÊN" },
                    { value: "PINHAIS", label: "PINHAIS" },
                    { value: "PINHAL DE SÃO BENTO", label: "PINHAL DE SÃO BENTO" },
                    { value: "PINHALÃO", label: "PINHALÃO" },
                    { value: "PINHÃO", label: "PINHÃO" },
                    { value: "PIRAÍ DO SUL", label: "PIRAÍ DO SUL" },
                    { value: "PIRAQUARA", label: "PIRAQUARA" },
                    { value: "PITANGA", label: "PITANGA" },
                    { value: "PITANGUEIRAS", label: "PITANGUEIRAS" },
                    { value: "PLANALTINA DO PARANÁ", label: "PLANALTINA DO PARANÁ" },
                    { value: "PLANALTO", label: "PLANALTO" },
                    { value: "PONTA GROSSA", label: "PONTA GROSSA" },
                    { value: "PONTAL DO PARANÁ", label: "PONTAL DO PARANÁ" },
                    { value: "PORECATU", label: "PORECATU" },
                    { value: "PORTO AMAZONAS", label: "PORTO AMAZONAS" },
                    { value: "PORTO BARREIRO", label: "PORTO BARREIRO" },
                    { value: "PORTO RICO", label: "PORTO RICO" },
                    { value: "PORTO VITÓRIA", label: "PORTO VITÓRIA" },
                    { value: "PRADO FERREIRA", label: "PRADO FERREIRA" },
                    { value: "PRANCHITA", label: "PRANCHITA" },
                    { value: "PRESIDENTE CASTELO BRANCO", label: "PRESIDENTE CASTELO BRANCO" },
                    { value: "PRIMEIRO DE MAIO", label: "PRIMEIRO DE MAIO" },
                    { value: "PRUDENTÓPOLIS", label: "PRUDENTÓPOLIS" },
                    { value: "QUARTO CENTENÁRIO", label: "QUARTO CENTENÁRIO" },
                    { value: "QUATIGUÁ", label: "QUATIGUÁ" },
                    { value: "QUATRO BARRAS", label: "QUATRO BARRAS" },
                    { value: "QUATRO PONTES", label: "QUATRO PONTES" },
                    { value: "QUEDAS DO IGUAÇU", label: "QUEDAS DO IGUAÇU" },
                    { value: "QUERÊNCIA DO NORTE", label: "QUERÊNCIA DO NORTE" },
                    { value: "QUINTA DO SOL", label: "QUINTA DO SOL" },
                    { value: "QUITANDINHA", label: "QUITANDINHA" },
                    { value: "RAMILÂNDIA", label: "RAMILÂNDIA" },
                    { value: "RANCHO ALEGRE", label: "RANCHO ALEGRE" },
                    { value: "RANCHO ALEGRE D'OESTE", label: "RANCHO ALEGRE D'OESTE" },
                    { value: "REALEZA", label: "REALEZA" },
                    { value: "REBOUÇAS", label: "REBOUÇAS" },
                    { value: "RENASCENÇA", label: "RENASCENÇA" },
                    { value: "RESERVA", label: "RESERVA" },
                    { value: "RESERVA DO IGUAÇU", label: "RESERVA DO IGUAÇU" },
                    { value: "RIBEIRÃO CLARO", label: "RIBEIRÃO CLARO" },
                    { value: "RIBEIRÃO DO PINHAL", label: "RIBEIRÃO DO PINHAL" },
                    { value: "RIO AZUL", label: "RIO AZUL" },
                    { value: "RIO BOM", label: "RIO BOM" },
                    { value: "RIO BONITO DO IGUAÇU", label: "RIO BONITO DO IGUAÇU" },
                    { value: "RIO BRANCO DO IVAÍ", label: "RIO BRANCO DO IVAÍ" },
                    { value: "RIO BRANCO DO SUL", label: "RIO BRANCO DO SUL" },
                    { value: "RIO NEGRO", label: "RIO NEGRO" },
                    { value: "ROLÂNDIA", label: "ROLÂNDIA" },
                    { value: "RONCADOR", label: "RONCADOR" },
                    { value: "RONDON", label: "RONDON" },
                    { value: "ROSÁRIO DO IVAÍ", label: "ROSÁRIO DO IVAÍ" },
                    { value: "SABÁUDIA", label: "SABÁUDIA" },
                    { value: "SALGADO FILHO", label: "SALGADO FILHO" },
                    { value: "SALTO DO ITARARÉ", label: "SALTO DO ITARARÉ" },
                    { value: "SALTO DO LONTRA", label: "SALTO DO LONTRA" },
                    { value: "SANTA AMÉLIA", label: "SANTA AMÉLIA" },
                    { value: "SANTA CECÍLIA DO PAVÃO", label: "SANTA CECÍLIA DO PAVÃO" },
                    { value: "SANTA CRUZ DE MONTE CASTELO", label: "SANTA CRUZ DE MONTE CASTELO" },
                    { value: "SANTA FÉ", label: "SANTA FÉ" },
                    { value: "SANTA HELENA", label: "SANTA HELENA" },
                    { value: "SANTA INÊS", label: "SANTA INÊS" },
                    { value: "SANTA ISABEL DO IVAÍ", label: "SANTA ISABEL DO IVAÍ" },
                    { value: "SANTA IZABEL DO OESTE", label: "SANTA IZABEL DO OESTE" },
                    { value: "SANTA LÚCIA", label: "SANTA LÚCIA" },
                    { value: "SANTA MARIA DO OESTE", label: "SANTA MARIA DO OESTE" },
                    { value: "SANTA MARIANA", label: "SANTA MARIANA" },
                    { value: "SANTA MÔNICA", label: "SANTA MÔNICA" },
                    { value: "SANTA TEREZA DO OESTE", label: "SANTA TEREZA DO OESTE" },
                    { value: "SANTA TEREZINHA DE ITAIPU", label: "SANTA TEREZINHA DE ITAIPU" },
                    { value: "SANTANA DO ITARARÉ", label: "SANTANA DO ITARARÉ" },
                    { value: "SANTO ANTÔNIO DA PLATINA", label: "SANTO ANTÔNIO DA PLATINA" },
                    { value: "SANTO ANTÔNIO DO CAIUÁ", label: "SANTO ANTÔNIO DO CAIUÁ" },
                    { value: "SANTO ANTÔNIO DO PARAÍSO", label: "SANTO ANTÔNIO DO PARAÍSO" },
                    { value: "SANTO ANTÔNIO DO SUDOESTE", label: "SANTO ANTÔNIO DO SUDOESTE" },
                    { value: "SANTO INÁCIO", label: "SANTO INÁCIO" },
                    { value: "SÃO CARLOS DO IVAÍ", label: "SÃO CARLOS DO IVAÍ" },
                    { value: "SÃO JERÔNIMO DA SERRA", label: "SÃO JERÔNIMO DA SERRA" },
                    { value: "SÃO JOÃO", label: "SÃO JOÃO" },
                    { value: "SÃO JOÃO DO CAIUÁ", label: "SÃO JOÃO DO CAIUÁ" },
                    { value: "SÃO JOÃO DO IVAÍ", label: "SÃO JOÃO DO IVAÍ" },
                    { value: "SÃO JOÃO DO TRIUNFO", label: "SÃO JOÃO DO TRIUNFO" },
                    { value: "SÃO JORGE D'OESTE", label: "SÃO JORGE D'OESTE" },
                    { value: "SÃO JORGE DO IVAÍ", label: "SÃO JORGE DO IVAÍ" },
                    { value: "SÃO JORGE DO PATROCÍNIO", label: "SÃO JORGE DO PATROCÍNIO" },
                    { value: "SÃO JOSÉ DA BOA VISTA", label: "SÃO JOSÉ DA BOA VISTA" },
                    { value: "SÃO JOSÉ DAS PALMEIRAS", label: "SÃO JOSÉ DAS PALMEIRAS" },
                    { value: "SÃO JOSÉ DOS PINHAIS", label: "SÃO JOSÉ DOS PINHAIS" },
                    { value: "SÃO MANOEL DO PARANÁ", label: "SÃO MANOEL DO PARANÁ" },
                    { value: "SÃO MATEUS DO SUL", label: "SÃO MATEUS DO SUL" },
                    { value: "SÃO MIGUEL DO IGUAÇU", label: "SÃO MIGUEL DO IGUAÇU" },
                    { value: "SÃO PEDRO DO IGUAÇU", label: "SÃO PEDRO DO IGUAÇU" },
                    { value: "SÃO PEDRO DO IVAÍ", label: "SÃO PEDRO DO IVAÍ" },
                    { value: "SÃO PEDRO DO PARANÁ", label: "SÃO PEDRO DO PARANÁ" },
                    { value: "SÃO SEBASTIÃO DA AMOREIRA", label: "SÃO SEBASTIÃO DA AMOREIRA" },
                    { value: "SÃO TOMÉ", label: "SÃO TOMÉ" },
                    { value: "SAPOPEMA", label: "SAPOPEMA" },
                    { value: "SARANDI", label: "SARANDI" },
                    { value: "SAUDADE DO IGUAÇU", label: "SAUDADE DO IGUAÇU" },
                    { value: "SENGÉS", label: "SENGÉS" },
                    { value: "SERRANÓPOLIS DO IGUAÇU", label: "SERRANÓPOLIS DO IGUAÇU" },
                    { value: "SERTANEJA", label: "SERTANEJA" },
                    { value: "SERTANÓPOLIS", label: "SERTANÓPOLIS" },
                    { value: "SIQUEIRA CAMPOS", label: "SIQUEIRA CAMPOS" },
                    { value: "SULINA", label: "SULINA" },
                    { value: "TAMARANA", label: "TAMARANA" },
                    { value: "TAMBOARA", label: "TAMBOARA" },
                    { value: "TAPEJARA", label: "TAPEJARA" },
                    { value: "TAPIRA", label: "TAPIRA" },
                    { value: "TEIXEIRA SOARES", label: "TEIXEIRA SOARES" },
                    { value: "TELÊMACO BORBA", label: "TELÊMACO BORBA" },
                    { value: "TERRA BOA", label: "TERRA BOA" },
                    { value: "TERRA RICA", label: "TERRA RICA" },
                    { value: "TERRA ROXA", label: "TERRA ROXA" },
                    { value: "TIBAGI", label: "TIBAGI" },
                    { value: "TIJUCAS DO SUL", label: "TIJUCAS DO SUL" },
                    { value: "TOLEDO", label: "TOLEDO" },
                    { value: "TOMAZINA", label: "TOMAZINA" },
                    { value: "TRÊS BARRAS DO PARANÁ", label: "TRÊS BARRAS DO PARANÁ" },
                    { value: "TUNAS DO PARANÁ", label: "TUNAS DO PARANÁ" },
                    { value: "TUNEIRAS DO OESTE", label: "TUNEIRAS DO OESTE" },
                    { value: "TUPÃSSI", label: "TUPÃSSI" },
                    { value: "TURVO", label: "TURVO" },
                    { value: "UBIRATÃ", label: "UBIRATÃ" },
                    { value: "UMUARAMA", label: "UMUARAMA" },
                    { value: "UNIÃO DA VITÓRIA", label: "UNIÃO DA VITÓRIA" },
                    { value: "UNIFLOR", label: "UNIFLOR" },
                    { value: "URAÍ", label: "URAÍ" },
                    { value: "VENTANIA", label: "VENTANIA" },
                    { value: "VERA CRUZ DO OESTE", label: "VERA CRUZ DO OESTE" },
                    { value: "VERÊ", label: "VERÊ" },
                    { value: "VIRMOND", label: "VIRMOND" },
                    { value: "VITORINO", label: "VITORINO" },
                    { value: "WENCESLAU BRAZ", label: "WENCESLAU BRAZ" },
                    { value: "XAMBRÊ", label: "XAMBRÊ" },
               ]
               break;

          case "PERNAMBUCO":
               CITIES = [
                    { value: "ABREU E LIMA", label: "ABREU E LIMA" },
                    { value: "AFOGADOS DA INGAZEIRA", label: "AFOGADOS DA INGAZEIRA" },
                    { value: "AFRÂNIO", label: "AFRÂNIO" },
                    { value: "AGRESTINA", label: "AGRESTINA" },
                    { value: "ÁGUA PRETA", label: "ÁGUA PRETA" },
                    { value: "ÁGUAS BELAS", label: "ÁGUAS BELAS" },
                    { value: "ALAGOINHA", label: "ALAGOINHA" },
                    { value: "ALIANÇA", label: "ALIANÇA" },
                    { value: "ALTINHO", label: "ALTINHO" },
                    { value: "AMARAJI", label: "AMARAJI" },
                    { value: "ANGELIM", label: "ANGELIM" },
                    { value: "ARAÇOIABA", label: "ARAÇOIABA" },
                    { value: "ARARIPINA", label: "ARARIPINA" },
                    { value: "ARCOVERDE", label: "ARCOVERDE" },
                    { value: "BARRA DE GUABIRABA", label: "BARRA DE GUABIRABA" },
                    { value: "BARREIROS", label: "BARREIROS" },
                    { value: "BELÉM DE MARIA", label: "BELÉM DE MARIA" },
                    { value: "BELÉM DO SÃO FRANCISCO", label: "BELÉM DO SÃO FRANCISCO" },
                    { value: "BELO JARDIM", label: "BELO JARDIM" },
                    { value: "BETÂNIA", label: "BETÂNIA" },
                    { value: "BEZERROS", label: "BEZERROS" },
                    { value: "BODOCÓ", label: "BODOCÓ" },
                    { value: "BOM CONSELHO", label: "BOM CONSELHO" },
                    { value: "BOM JARDIM", label: "BOM JARDIM" },
                    { value: "BONITO", label: "BONITO" },
                    { value: "BREJÃO", label: "BREJÃO" },
                    { value: "BREJINHO", label: "BREJINHO" },
                    { value: "BREJO DA MADRE DE DEUS", label: "BREJO DA MADRE DE DEUS" },
                    { value: "BUENOS AIRES", label: "BUENOS AIRES" },
                    { value: "BUÍQUE", label: "BUÍQUE" },
                    { value: "CABO DE SANTO AGOSTINHO", label: "CABO DE SANTO AGOSTINHO" },
                    { value: "CABROBÓ", label: "CABROBÓ" },
                    { value: "CACHOEIRINHA", label: "CACHOEIRINHA" },
                    { value: "CAETÉS", label: "CAETÉS" },
                    { value: "CALÇADO", label: "CALÇADO" },
                    { value: "CALUMBI", label: "CALUMBI" },
                    { value: "CAMARAGIBE", label: "CAMARAGIBE" },
                    { value: "CAMOCIM DE SÃO FÉLIX", label: "CAMOCIM DE SÃO FÉLIX" },
                    { value: "CAMUTANGA", label: "CAMUTANGA" },
                    { value: "CANHOTINHO", label: "CANHOTINHO" },
                    { value: "CAPOEIRAS", label: "CAPOEIRAS" },
                    { value: "CARNAÍBA", label: "CARNAÍBA" },
                    { value: "CARNAUBEIRA DA PENHA", label: "CARNAUBEIRA DA PENHA" },
                    { value: "CARPINA", label: "CARPINA" },
                    { value: "CARUARU", label: "CARUARU" },
                    { value: "CASINHAS", label: "CASINHAS" },
                    { value: "CATENDE", label: "CATENDE" },
                    { value: "CEDRO", label: "CEDRO" },
                    { value: "CHÃ DE ALEGRIA", label: "CHÃ DE ALEGRIA" },
                    { value: "CHÃ GRANDE", label: "CHÃ GRANDE" },
                    { value: "CONDADO", label: "CONDADO" },
                    { value: "CORRENTES", label: "CORRENTES" },
                    { value: "CORTÊS", label: "CORTÊS" },
                    { value: "CUMARU", label: "CUMARU" },
                    { value: "CUPIRA", label: "CUPIRA" },
                    { value: "CUSTÓDIA", label: "CUSTÓDIA" },
                    { value: "DORMENTES", label: "DORMENTES" },
                    { value: "ESCADA", label: "ESCADA" },
                    { value: "EXU", label: "EXU" },
                    { value: "FEIRA NOVA", label: "FEIRA NOVA" },
                    { value: "FERNANDO DE NORONHA", label: "FERNANDO DE NORONHA" },
                    { value: "FERREIROS", label: "FERREIROS" },
                    { value: "FLORES", label: "FLORES" },
                    { value: "FLORESTA", label: "FLORESTA" },
                    { value: "FREI MIGUELINHO", label: "FREI MIGUELINHO" },
                    { value: "GAMELEIRA", label: "GAMELEIRA" },
                    { value: "GARANHUNS", label: "GARANHUNS" },
                    { value: "GLÓRIA DO GOITÁ", label: "GLÓRIA DO GOITÁ" },
                    { value: "GOIANA", label: "GOIANA" },
                    { value: "GRANITO", label: "GRANITO" },
                    { value: "GRAVATÁ", label: "GRAVATÁ" },
                    { value: "IATI", label: "IATI" },
                    { value: "IBIMIRIM", label: "IBIMIRIM" },
                    { value: "IBIRAJUBA", label: "IBIRAJUBA" },
                    { value: "IGARASSU", label: "IGARASSU" },
                    { value: "IGUARACI", label: "IGUARACI" },
                    { value: "ILHA DE ITAMARACÁ", label: "ILHA DE ITAMARACÁ" },
                    { value: "INAJÁ", label: "INAJÁ" },
                    { value: "INGAZEIRA", label: "INGAZEIRA" },
                    { value: "IPOJUCA", label: "IPOJUCA" },
                    { value: "IPUBI", label: "IPUBI" },
                    { value: "ITACURUBA", label: "ITACURUBA" },
                    { value: "ITAÍBA", label: "ITAÍBA" },
                    { value: "ITAMBÉ", label: "ITAMBÉ" },
                    { value: "ITAPETIM", label: "ITAPETIM" },
                    { value: "ITAPISSUMA", label: "ITAPISSUMA" },
                    { value: "ITAQUITINGA", label: "ITAQUITINGA" },
                    { value: "JABOATÃO DOS GUARARAPES", label: "JABOATÃO DOS GUARARAPES" },
                    { value: "JAQUEIRA", label: "JAQUEIRA" },
                    { value: "JATAÚBA", label: "JATAÚBA" },
                    { value: "JATOBÁ", label: "JATOBÁ" },
                    { value: "JOÃO ALFREDO", label: "JOÃO ALFREDO" },
                    { value: "JOAQUIM NABUCO", label: "JOAQUIM NABUCO" },
                    { value: "JUCATI", label: "JUCATI" },
                    { value: "JUPI", label: "JUPI" },
                    { value: "JUREMA", label: "JUREMA" },
                    { value: "LAGOA DE ITAENGA", label: "LAGOA DE ITAENGA" },
                    { value: "LAGOA DO CARRO", label: "LAGOA DO CARRO" },
                    { value: "LAGOA DO OURO", label: "LAGOA DO OURO" },
                    { value: "LAGOA DOS GATOS", label: "LAGOA DOS GATOS" },
                    { value: "LAGOA GRANDE", label: "LAGOA GRANDE" },
                    { value: "LAJEDO", label: "LAJEDO" },
                    { value: "LIMOEIRO", label: "LIMOEIRO" },
                    { value: "MACAPARANA", label: "MACAPARANA" },
                    { value: "MACHADOS", label: "MACHADOS" },
                    { value: "MANARI", label: "MANARI" },
                    { value: "MARAIAL", label: "MARAIAL" },
                    { value: "MIRANDIBA", label: "MIRANDIBA" },
                    { value: "MOREILÂNDIA", label: "MOREILÂNDIA" },
                    { value: "MORENO", label: "MORENO" },
                    { value: "NAZARÉ DA MATA", label: "NAZARÉ DA MATA" },
                    { value: "OLINDA", label: "OLINDA" },
                    { value: "OROBÓ", label: "OROBÓ" },
                    { value: "OROCÓ", label: "OROCÓ" },
                    { value: "OURICURI", label: "OURICURI" },
                    { value: "PALMARES", label: "PALMARES" },
                    { value: "PALMEIRINA", label: "PALMEIRINA" },
                    { value: "PANELAS", label: "PANELAS" },
                    { value: "PARANATAMA", label: "PARANATAMA" },
                    { value: "PARNAMIRIM", label: "PARNAMIRIM" },
                    { value: "PASSIRA", label: "PASSIRA" },
                    { value: "PAUDALHO", label: "PAUDALHO" },
                    { value: "PAULISTA", label: "PAULISTA" },
                    { value: "PEDRA", label: "PEDRA" },
                    { value: "PESQUEIRA", label: "PESQUEIRA" },
                    { value: "PETROLÂNDIA", label: "PETROLÂNDIA" },
                    { value: "PETROLINA", label: "PETROLINA" },
                    { value: "POÇÃO", label: "POÇÃO" },
                    { value: "POMBOS", label: "POMBOS" },
                    { value: "PRIMAVERA", label: "PRIMAVERA" },
                    { value: "QUIPAPÁ", label: "QUIPAPÁ" },
                    { value: "QUIXABA", label: "QUIXABA" },
                    { value: "RECIFE", label: "RECIFE" },
                    { value: "RIACHO DAS ALMAS", label: "RIACHO DAS ALMAS" },
                    { value: "RIBEIRÃO", label: "RIBEIRÃO" },
                    { value: "RIO FORMOSO", label: "RIO FORMOSO" },
                    { value: "SAIRÉ", label: "SAIRÉ" },
                    { value: "SALGADINHO", label: "SALGADINHO" },
                    { value: "SALGUEIRO", label: "SALGUEIRO" },
                    { value: "SALOÁ", label: "SALOÁ" },
                    { value: "SANHARÓ", label: "SANHARÓ" },
                    { value: "SANTA CRUZ", label: "SANTA CRUZ" },
                    { value: "SANTA CRUZ DA BAIXA VERDE", label: "SANTA CRUZ DA BAIXA VERDE" },
                    { value: "SANTA CRUZ DO CAPIBARIBE", label: "SANTA CRUZ DO CAPIBARIBE" },
                    { value: "SANTA FILOMENA", label: "SANTA FILOMENA" },
                    { value: "SANTA MARIA DA BOA VISTA", label: "SANTA MARIA DA BOA VISTA" },
                    { value: "SANTA MARIA DO CAMBUCÁ", label: "SANTA MARIA DO CAMBUCÁ" },
                    { value: "SANTA TEREZINHA", label: "SANTA TEREZINHA" },
                    { value: "SÃO BENEDITO DO SUL", label: "SÃO BENEDITO DO SUL" },
                    { value: "SÃO BENTO DO UNA", label: "SÃO BENTO DO UNA" },
                    { value: "SÃO CAITANO", label: "SÃO CAITANO" },
                    { value: "SÃO JOÃO", label: "SÃO JOÃO" },
                    { value: "SÃO JOAQUIM DO MONTE", label: "SÃO JOAQUIM DO MONTE" },
                    { value: "SÃO JOSÉ DA COROA GRANDE", label: "SÃO JOSÉ DA COROA GRANDE" },
                    { value: "SÃO JOSÉ DO BELMONTE", label: "SÃO JOSÉ DO BELMONTE" },
                    { value: "SÃO JOSÉ DO EGITO", label: "SÃO JOSÉ DO EGITO" },
                    { value: "SÃO LOURENÇO DA MATA", label: "SÃO LOURENÇO DA MATA" },
                    { value: "SÃO VICENTE FERRER", label: "SÃO VICENTE FERRER" },
                    { value: "SERRA TALHADA", label: "SERRA TALHADA" },
                    { value: "SERRITA", label: "SERRITA" },
                    { value: "SERTÂNIA", label: "SERTÂNIA" },
                    { value: "SIRINHAÉM", label: "SIRINHAÉM" },
                    { value: "SOLIDÃO", label: "SOLIDÃO" },
                    { value: "SURUBIM", label: "SURUBIM" },
                    { value: "TABIRA", label: "TABIRA" },
                    { value: "TACAIMBÓ", label: "TACAIMBÓ" },
                    { value: "TACARATU", label: "TACARATU" },
                    { value: "TAMANDARÉ", label: "TAMANDARÉ" },
                    { value: "TAQUARITINGA DO NORTE", label: "TAQUARITINGA DO NORTE" },
                    { value: "TEREZINHA", label: "TEREZINHA" },
                    { value: "TERRA NOVA", label: "TERRA NOVA" },
                    { value: "TIMBAÚBA", label: "TIMBAÚBA" },
                    { value: "TORITAMA", label: "TORITAMA" },
                    { value: "TRACUNHAÉM", label: "TRACUNHAÉM" },
                    { value: "TRINDADE", label: "TRINDADE" },
                    { value: "TRIUNFO", label: "TRIUNFO" },
                    { value: "TUPANATINGA", label: "TUPANATINGA" },
                    { value: "TUPARETAMA", label: "TUPARETAMA" },
                    { value: "VENTUROSA", label: "VENTUROSA" },
                    { value: "VERDEJANTE", label: "VERDEJANTE" },
                    { value: "VERTENTE DO LÉRIO", label: "VERTENTE DO LÉRIO" },
                    { value: "VERTENTES", label: "VERTENTES" },
                    { value: "VICÊNCIA", label: "VICÊNCIA" },
                    { value: "VITÓRIA DE SANTO ANTÃO", label: "VITÓRIA DE SANTO ANTÃO" },
                    { value: "XEXÉU", label: "XEXÉU" },
               ]
               break;

          case "PIAUÍ":
               CITIES = [
                    { value: "ACAUÃ", label: "ACAUÃ" },
                    { value: "AGRICOLÂNDIA", label: "AGRICOLÂNDIA" },
                    { value: "ÁGUA BRANCA", label: "ÁGUA BRANCA" },
                    { value: "ALAGOINHA DO PIAUÍ", label: "ALAGOINHA DO PIAUÍ" },
                    { value: "ALEGRETE DO PIAUÍ", label: "ALEGRETE DO PIAUÍ" },
                    { value: "ALTO LONGÁ", label: "ALTO LONGÁ" },
                    { value: "ALTOS", label: "ALTOS" },
                    { value: "ALVORADA DO GURGUÉIA", label: "ALVORADA DO GURGUÉIA" },
                    { value: "AMARANTE", label: "AMARANTE" },
                    { value: "ANGICAL DO PIAUÍ", label: "ANGICAL DO PIAUÍ" },
                    { value: "ANÍSIO DE ABREU", label: "ANÍSIO DE ABREU" },
                    { value: "ANTÔNIO ALMEIDA", label: "ANTÔNIO ALMEIDA" },
                    { value: "AROAZES", label: "AROAZES" },
                    { value: "AROEIRAS DO ITAIM", label: "AROEIRAS DO ITAIM" },
                    { value: "ARRAIAL", label: "ARRAIAL" },
                    { value: "ASSUNÇÃO DO PIAUÍ", label: "ASSUNÇÃO DO PIAUÍ" },
                    { value: "AVELINO LOPES", label: "AVELINO LOPES" },
                    { value: "BAIXA GRANDE DO RIBEIRO", label: "BAIXA GRANDE DO RIBEIRO" },
                    { value: "BARRA D'ALCÂNTARA", label: "BARRA D'ALCÂNTARA" },
                    { value: "BARRAS", label: "BARRAS" },
                    { value: "BARREIRAS DO PIAUÍ", label: "BARREIRAS DO PIAUÍ" },
                    { value: "BARRO DURO", label: "BARRO DURO" },
                    { value: "BATALHA", label: "BATALHA" },
                    { value: "BELA VISTA DO PIAUÍ", label: "BELA VISTA DO PIAUÍ" },
                    { value: "BELÉM DO PIAUÍ", label: "BELÉM DO PIAUÍ" },
                    { value: "BENEDITINOS", label: "BENEDITINOS" },
                    { value: "BERTOLÍNIA", label: "BERTOLÍNIA" },
                    { value: "BETÂNIA DO PIAUÍ", label: "BETÂNIA DO PIAUÍ" },
                    { value: "BOA HORA", label: "BOA HORA" },
                    { value: "BOCAINA", label: "BOCAINA" },
                    { value: "BOM JESUS", label: "BOM JESUS" },
                    { value: "BOM PRINCÍPIO DO PIAUÍ", label: "BOM PRINCÍPIO DO PIAUÍ" },
                    { value: "BONFIM DO PIAUÍ", label: "BONFIM DO PIAUÍ" },
                    { value: "BOQUEIRÃO DO PIAUÍ", label: "BOQUEIRÃO DO PIAUÍ" },
                    { value: "BRASILEIRA", label: "BRASILEIRA" },
                    { value: "BREJO DO PIAUÍ", label: "BREJO DO PIAUÍ" },
                    { value: "BURITI DOS LOPES", label: "BURITI DOS LOPES" },
                    { value: "BURITI DOS MONTES", label: "BURITI DOS MONTES" },
                    { value: "CABECEIRAS DO PIAUÍ", label: "CABECEIRAS DO PIAUÍ" },
                    { value: "CAJAZEIRAS DO PIAUÍ", label: "CAJAZEIRAS DO PIAUÍ" },
                    { value: "CAJUEIRO DA PRAIA", label: "CAJUEIRO DA PRAIA" },
                    { value: "CALDEIRÃO GRANDE DO PIAUÍ", label: "CALDEIRÃO GRANDE DO PIAUÍ" },
                    { value: "CAMPINAS DO PIAUÍ", label: "CAMPINAS DO PIAUÍ" },
                    { value: "CAMPO ALEGRE DO FIDALGO", label: "CAMPO ALEGRE DO FIDALGO" },
                    { value: "CAMPO GRANDE DO PIAUÍ", label: "CAMPO GRANDE DO PIAUÍ" },
                    { value: "CAMPO LARGO DO PIAUÍ", label: "CAMPO LARGO DO PIAUÍ" },
                    { value: "CAMPO MAIOR", label: "CAMPO MAIOR" },
                    { value: "CANAVIEIRA", label: "CANAVIEIRA" },
                    { value: "CANTO DO BURITI", label: "CANTO DO BURITI" },
                    { value: "CAPITÃO DE CAMPOS", label: "CAPITÃO DE CAMPOS" },
                    { value: "CAPITÃO GERVÁSIO OLIVEIRA", label: "CAPITÃO GERVÁSIO OLIVEIRA" },
                    { value: "CARACOL", label: "CARACOL" },
                    { value: "CARAÚBAS DO PIAUÍ", label: "CARAÚBAS DO PIAUÍ" },
                    { value: "CARIDADE DO PIAUÍ", label: "CARIDADE DO PIAUÍ" },
                    { value: "CASTELO DO PIAUÍ", label: "CASTELO DO PIAUÍ" },
                    { value: "CAXINGÓ", label: "CAXINGÓ" },
                    { value: "COCAL", label: "COCAL" },
                    { value: "COCAL DE TELHA", label: "COCAL DE TELHA" },
                    { value: "COCAL DOS ALVES", label: "COCAL DOS ALVES" },
                    { value: "COIVARAS", label: "COIVARAS" },
                    { value: "COLÔNIA DO GURGUÉIA", label: "COLÔNIA DO GURGUÉIA" },
                    { value: "COLÔNIA DO PIAUÍ", label: "COLÔNIA DO PIAUÍ" },
                    { value: "CONCEIÇÃO DO CANINDÉ", label: "CONCEIÇÃO DO CANINDÉ" },
                    { value: "CORONEL JOSÉ DIAS", label: "CORONEL JOSÉ DIAS" },
                    { value: "CORRENTE", label: "CORRENTE" },
                    { value: "CRISTALÂNDIA DO PIAUÍ", label: "CRISTALÂNDIA DO PIAUÍ" },
                    { value: "CRISTINO CASTRO", label: "CRISTINO CASTRO" },
                    { value: "CURIMATÁ", label: "CURIMATÁ" },
                    { value: "CURRAIS", label: "CURRAIS" },
                    { value: "CURRAL NOVO DO PIAUÍ", label: "CURRAL NOVO DO PIAUÍ" },
                    { value: "CURRALINHOS", label: "CURRALINHOS" },
                    { value: "DEMERVAL LOBÃO", label: "DEMERVAL LOBÃO" },
                    { value: "DIRCEU ARCOVERDE", label: "DIRCEU ARCOVERDE" },
                    { value: "DOM EXPEDITO LOPES", label: "DOM EXPEDITO LOPES" },
                    { value: "DOM INOCÊNCIO", label: "DOM INOCÊNCIO" },
                    { value: "DOMINGOS MOURÃO", label: "DOMINGOS MOURÃO" },
                    { value: "ELESBÃO VELOSO", label: "ELESBÃO VELOSO" },
                    { value: "ELISEU MARTINS", label: "ELISEU MARTINS" },
                    { value: "ESPERANTINA", label: "ESPERANTINA" },
                    { value: "FARTURA DO PIAUÍ", label: "FARTURA DO PIAUÍ" },
                    { value: "FLORES DO PIAUÍ", label: "FLORES DO PIAUÍ" },
                    { value: "FLORESTA DO PIAUÍ", label: "FLORESTA DO PIAUÍ" },
                    { value: "FLORIANO", label: "FLORIANO" },
                    { value: "FRANCINÓPOLIS", label: "FRANCINÓPOLIS" },
                    { value: "FRANCISCO AYRES", label: "FRANCISCO AYRES" },
                    { value: "FRANCISCO MACEDO", label: "FRANCISCO MACEDO" },
                    { value: "FRANCISCO SANTOS", label: "FRANCISCO SANTOS" },
                    { value: "FRONTEIRAS", label: "FRONTEIRAS" },
                    { value: "GEMINIANO", label: "GEMINIANO" },
                    { value: "GILBUÉS", label: "GILBUÉS" },
                    { value: "GUADALUPE", label: "GUADALUPE" },
                    { value: "GUARIBAS", label: "GUARIBAS" },
                    { value: "HUGO NAPOLEÃO", label: "HUGO NAPOLEÃO" },
                    { value: "ILHA GRANDE", label: "ILHA GRANDE" },
                    { value: "INHUMA", label: "INHUMA" },
                    { value: "IPIRANGA DO PIAUÍ", label: "IPIRANGA DO PIAUÍ" },
                    { value: "ISAÍAS COELHO", label: "ISAÍAS COELHO" },
                    { value: "ITAINÓPOLIS", label: "ITAINÓPOLIS" },
                    { value: "ITAUEIRA", label: "ITAUEIRA" },
                    { value: "JACOBINA DO PIAUÍ", label: "JACOBINA DO PIAUÍ" },
                    { value: "JAICÓS", label: "JAICÓS" },
                    { value: "JARDIM DO MULATO", label: "JARDIM DO MULATO" },
                    { value: "JATOBÁ DO PIAUÍ", label: "JATOBÁ DO PIAUÍ" },
                    { value: "JERUMENHA", label: "JERUMENHA" },
                    { value: "JOÃO COSTA", label: "JOÃO COSTA" },
                    { value: "JOAQUIM PIRES", label: "JOAQUIM PIRES" },
                    { value: "JOCA MARQUES", label: "JOCA MARQUES" },
                    { value: "JOSÉ DE FREITAS", label: "JOSÉ DE FREITAS" },
                    { value: "JUAZEIRO DO PIAUÍ", label: "JUAZEIRO DO PIAUÍ" },
                    { value: "JÚLIO BORGES", label: "JÚLIO BORGES" },
                    { value: "JUREMA", label: "JUREMA" },
                    { value: "LAGOA ALEGRE", label: "LAGOA ALEGRE" },
                    { value: "LAGOA DE SÃO FRANCISCO", label: "LAGOA DE SÃO FRANCISCO" },
                    { value: "LAGOA DO BARRO DO PIAUÍ", label: "LAGOA DO BARRO DO PIAUÍ" },
                    { value: "LAGOA DO PIAUÍ", label: "LAGOA DO PIAUÍ" },
                    { value: "LAGOA DO SÍTIO", label: "LAGOA DO SÍTIO" },
                    { value: "LAGOINHA DO PIAUÍ", label: "LAGOINHA DO PIAUÍ" },
                    { value: "LANDRI SALES", label: "LANDRI SALES" },
                    { value: "LUÍS CORREIA", label: "LUÍS CORREIA" },
                    { value: "LUZILÂNDIA", label: "LUZILÂNDIA" },
                    { value: "MADEIRO", label: "MADEIRO" },
                    { value: "MANOEL EMÍDIO", label: "MANOEL EMÍDIO" },
                    { value: "MARCOLÂNDIA", label: "MARCOLÂNDIA" },
                    { value: "MARCOS PARENTE", label: "MARCOS PARENTE" },
                    { value: "MASSAPÊ DO PIAUÍ", label: "MASSAPÊ DO PIAUÍ" },
                    { value: "MATIAS OLÍMPIO", label: "MATIAS OLÍMPIO" },
                    { value: "MIGUEL ALVES", label: "MIGUEL ALVES" },
                    { value: "MIGUEL LEÃO", label: "MIGUEL LEÃO" },
                    { value: "MILTON BRANDÃO", label: "MILTON BRANDÃO" },
                    { value: "MONSENHOR GIL", label: "MONSENHOR GIL" },
                    { value: "MONSENHOR HIPÓLITO", label: "MONSENHOR HIPÓLITO" },
                    { value: "MONTE ALEGRE DO PIAUÍ", label: "MONTE ALEGRE DO PIAUÍ" },
                    { value: "MORRO CABEÇA NO TEMPO", label: "MORRO CABEÇA NO TEMPO" },
                    { value: "MORRO DO CHAPÉU DO PIAUÍ", label: "MORRO DO CHAPÉU DO PIAUÍ" },
                    { value: "MURICI DOS PORTELAS", label: "MURICI DOS PORTELAS" },
                    { value: "NAZARÉ DO PIAUÍ", label: "NAZARÉ DO PIAUÍ" },
                    { value: "NAZÁRIA", label: "NAZÁRIA" },
                    { value: "NOSSA SENHORA DE NAZARÉ", label: "NOSSA SENHORA DE NAZARÉ" },
                    { value: "NOSSA SENHORA DOS REMÉDIOS", label: "NOSSA SENHORA DOS REMÉDIOS" },
                    { value: "NOVA SANTA RITA", label: "NOVA SANTA RITA" },
                    { value: "NOVO ORIENTE DO PIAUÍ", label: "NOVO ORIENTE DO PIAUÍ" },
                    { value: "NOVO SANTO ANTÔNIO", label: "NOVO SANTO ANTÔNIO" },
                    { value: "OEIRAS", label: "OEIRAS" },
                    { value: "OLHO D'ÁGUA DO PIAUÍ", label: "OLHO D'ÁGUA DO PIAUÍ" },
                    { value: "PADRE MARCOS", label: "PADRE MARCOS" },
                    { value: "PAES LANDIM", label: "PAES LANDIM" },
                    { value: "PAJEÚ DO PIAUÍ", label: "PAJEÚ DO PIAUÍ" },
                    { value: "PALMEIRA DO PIAUÍ", label: "PALMEIRA DO PIAUÍ" },
                    { value: "PALMEIRAIS", label: "PALMEIRAIS" },
                    { value: "PAQUETÁ", label: "PAQUETÁ" },
                    { value: "PARNAGUÁ", label: "PARNAGUÁ" },
                    { value: "PARNAÍBA", label: "PARNAÍBA" },
                    { value: "PASSAGEM FRANCA DO PIAUÍ", label: "PASSAGEM FRANCA DO PIAUÍ" },
                    { value: "PATOS DO PIAUÍ", label: "PATOS DO PIAUÍ" },
                    { value: "PAU D'ARCO DO PIAUÍ", label: "PAU D'ARCO DO PIAUÍ" },
                    { value: "PAULISTANA", label: "PAULISTANA" },
                    { value: "PAVUSSU", label: "PAVUSSU" },
                    { value: "PEDRO II", label: "PEDRO II" },
                    { value: "PEDRO LAURENTINO", label: "PEDRO LAURENTINO" },
                    { value: "PICOS", label: "PICOS" },
                    { value: "PIMENTEIRAS", label: "PIMENTEIRAS" },
                    { value: "PIO IX", label: "PIO IX" },
                    { value: "PIRACURUCA", label: "PIRACURUCA" },
                    { value: "PIRIPIRI", label: "PIRIPIRI" },
                    { value: "PORTO", label: "PORTO" },
                    { value: "PORTO ALEGRE DO PIAUÍ", label: "PORTO ALEGRE DO PIAUÍ" },
                    { value: "PRATA DO PIAUÍ", label: "PRATA DO PIAUÍ" },
                    { value: "QUEIMADA NOVA", label: "QUEIMADA NOVA" },
                    { value: "REDENÇÃO DO GURGUÉIA", label: "REDENÇÃO DO GURGUÉIA" },
                    { value: "REGENERAÇÃO", label: "REGENERAÇÃO" },
                    { value: "RIACHO FRIO", label: "RIACHO FRIO" },
                    { value: "RIBEIRA DO PIAUÍ", label: "RIBEIRA DO PIAUÍ" },
                    { value: "RIBEIRO GONÇALVES", label: "RIBEIRO GONÇALVES" },
                    { value: "RIO GRANDE DO PIAUÍ", label: "RIO GRANDE DO PIAUÍ" },
                    { value: "SANTA CRUZ DO PIAUÍ", label: "SANTA CRUZ DO PIAUÍ" },
                    { value: "SANTA CRUZ DOS MILAGRES", label: "SANTA CRUZ DOS MILAGRES" },
                    { value: "SANTA FILOMENA", label: "SANTA FILOMENA" },
                    { value: "SANTA LUZ", label: "SANTA LUZ" },
                    { value: "SANTA ROSA DO PIAUÍ", label: "SANTA ROSA DO PIAUÍ" },
                    { value: "SANTANA DO PIAUÍ", label: "SANTANA DO PIAUÍ" },
                    { value: "SANTO ANTÔNIO DE LISBOA", label: "SANTO ANTÔNIO DE LISBOA" },
                    { value: "SANTO ANTÔNIO DOS MILAGRES", label: "SANTO ANTÔNIO DOS MILAGRES" },
                    { value: "SANTO INÁCIO DO PIAUÍ", label: "SANTO INÁCIO DO PIAUÍ" },
                    { value: "SÃO BRAZ DO PIAUÍ", label: "SÃO BRAZ DO PIAUÍ" },
                    { value: "SÃO FÉLIX DO PIAUÍ", label: "SÃO FÉLIX DO PIAUÍ" },
                    { value: "SÃO FRANCISCO DE ASSIS DO PIAUÍ", label: "SÃO FRANCISCO DE ASSIS DO PIAUÍ" },
                    { value: "SÃO FRANCISCO DO PIAUÍ", label: "SÃO FRANCISCO DO PIAUÍ" },
                    { value: "SÃO GONÇALO DO GURGUÉIA", label: "SÃO GONÇALO DO GURGUÉIA" },
                    { value: "SÃO GONÇALO DO PIAUÍ", label: "SÃO GONÇALO DO PIAUÍ" },
                    { value: "SÃO JOÃO DA CANABRAVA", label: "SÃO JOÃO DA CANABRAVA" },
                    { value: "SÃO JOÃO DA FRONTEIRA", label: "SÃO JOÃO DA FRONTEIRA" },
                    { value: "SÃO JOÃO DA SERRA", label: "SÃO JOÃO DA SERRA" },
                    { value: "SÃO JOÃO DA VARJOTA", label: "SÃO JOÃO DA VARJOTA" },
                    { value: "SÃO JOÃO DO ARRAIAL", label: "SÃO JOÃO DO ARRAIAL" },
                    { value: "SÃO JOÃO DO PIAUÍ", label: "SÃO JOÃO DO PIAUÍ" },
                    { value: "SÃO JOSÉ DO DIVINO", label: "SÃO JOSÉ DO DIVINO" },
                    { value: "SÃO JOSÉ DO PEIXE", label: "SÃO JOSÉ DO PEIXE" },
                    { value: "SÃO JOSÉ DO PIAUÍ", label: "SÃO JOSÉ DO PIAUÍ" },
                    { value: "SÃO JULIÃO", label: "SÃO JULIÃO" },
                    { value: "SÃO LOURENÇO DO PIAUÍ", label: "SÃO LOURENÇO DO PIAUÍ" },
                    { value: "SÃO LUIS DO PIAUÍ", label: "SÃO LUIS DO PIAUÍ" },
                    { value: "SÃO MIGUEL DA BAIXA GRANDE", label: "SÃO MIGUEL DA BAIXA GRANDE" },
                    { value: "SÃO MIGUEL DO FIDALGO", label: "SÃO MIGUEL DO FIDALGO" },
                    { value: "SÃO MIGUEL DO TAPUIO", label: "SÃO MIGUEL DO TAPUIO" },
                    { value: "SÃO PEDRO DO PIAUÍ", label: "SÃO PEDRO DO PIAUÍ" },
                    { value: "SÃO RAIMUNDO NONATO", label: "SÃO RAIMUNDO NONATO" },
                    { value: "SEBASTIÃO BARROS", label: "SEBASTIÃO BARROS" },
                    { value: "SEBASTIÃO LEAL", label: "SEBASTIÃO LEAL" },
                    { value: "SIGEFREDO PACHECO", label: "SIGEFREDO PACHECO" },
                    { value: "SIMÕES", label: "SIMÕES" },
                    { value: "SIMPLÍCIO MENDES", label: "SIMPLÍCIO MENDES" },
                    { value: "SOCORRO DO PIAUÍ", label: "SOCORRO DO PIAUÍ" },
                    { value: "SUSSUAPARA", label: "SUSSUAPARA" },
                    { value: "TAMBORIL DO PIAUÍ", label: "TAMBORIL DO PIAUÍ" },
                    { value: "TANQUE DO PIAUÍ", label: "TANQUE DO PIAUÍ" },
                    { value: "TERESINA", label: "TERESINA" },
                    { value: "UNIÃO", label: "UNIÃO" },
                    { value: "URUÇUÍ", label: "URUÇUÍ" },
                    { value: "VALENÇA DO PIAUÍ", label: "VALENÇA DO PIAUÍ" },
                    { value: "VÁRZEA BRANCA", label: "VÁRZEA BRANCA" },
                    { value: "VÁRZEA GRANDE", label: "VÁRZEA GRANDE" },
                    { value: "VERA MENDES", label: "VERA MENDES" },
                    { value: "VILA NOVA DO PIAUÍ", label: "VILA NOVA DO PIAUÍ" },
                    { value: "WALL FERRAZ", label: "WALL FERRAZ" },
               ]
               break;

          case "RIO DE JANEIRO":
               CITIES = [
                    { value: "ANGRA DOS REIS", label: "ANGRA DOS REIS" },
                    { value: "APERIBÉ", label: "APERIBÉ" },
                    { value: "ARARUAMA", label: "ARARUAMA" },
                    { value: "AREAL", label: "AREAL" },
                    { value: "ARMAÇÃO DOS BÚZIOS", label: "ARMAÇÃO DOS BÚZIOS" },
                    { value: "ARRAIAL DO CABO", label: "ARRAIAL DO CABO" },
                    { value: "BARRA DO PIRAÍ", label: "BARRA DO PIRAÍ" },
                    { value: "BARRA MANSA", label: "BARRA MANSA" },
                    { value: "BELFORD ROXO", label: "BELFORD ROXO" },
                    { value: "BOM JARDIM", label: "BOM JARDIM" },
                    { value: "BOM JESUS DO ITABAPOANA", label: "BOM JESUS DO ITABAPOANA" },
                    { value: "CABO FRIO", label: "CABO FRIO" },
                    { value: "CACHOEIRAS DE MACACU", label: "CACHOEIRAS DE MACACU" },
                    { value: "CAMBUCI", label: "CAMBUCI" },
                    { value: "CAMPOS DOS GOYTACAZES", label: "CAMPOS DOS GOYTACAZES" },
                    { value: "CANTAGALO", label: "CANTAGALO" },
                    { value: "CARAPEBUS", label: "CARAPEBUS" },
                    { value: "CARDOSO MOREIRA", label: "CARDOSO MOREIRA" },
                    { value: "CARMO", label: "CARMO" },
                    { value: "CASIMIRO DE ABREU", label: "CASIMIRO DE ABREU" },
                    { value: "COMENDADOR LEVY GASPARIAN", label: "COMENDADOR LEVY GASPARIAN" },
                    { value: "CONCEIÇÃO DE MACABU", label: "CONCEIÇÃO DE MACABU" },
                    { value: "CORDEIRO", label: "CORDEIRO" },
                    { value: "DUAS BARRAS", label: "DUAS BARRAS" },
                    { value: "DUQUE DE CAXIAS", label: "DUQUE DE CAXIAS" },
                    { value: "ENGENHEIRO PAULO DE FRONTIN", label: "ENGENHEIRO PAULO DE FRONTIN" },
                    { value: "GUAPIMIRIM", label: "GUAPIMIRIM" },
                    { value: "IGUABA GRANDE", label: "IGUABA GRANDE" },
                    { value: "ITABORAÍ", label: "ITABORAÍ" },
                    { value: "ITAGUAÍ", label: "ITAGUAÍ" },
                    { value: "ITALVA", label: "ITALVA" },
                    { value: "ITAOCARA", label: "ITAOCARA" },
                    { value: "ITAPERUNA", label: "ITAPERUNA" },
                    { value: "ITATIAIA", label: "ITATIAIA" },
                    { value: "JAPERI", label: "JAPERI" },
                    { value: "LAJE DO MURIAÉ", label: "LAJE DO MURIAÉ" },
                    { value: "MACAÉ", label: "MACAÉ" },
                    { value: "MACUCO", label: "MACUCO" },
                    { value: "MAGÉ", label: "MAGÉ" },
                    { value: "MANGARATIBA", label: "MANGARATIBA" },
                    { value: "MARICÁ", label: "MARICÁ" },
                    { value: "MENDES", label: "MENDES" },
                    { value: "MESQUITA", label: "MESQUITA" },
                    { value: "MIGUEL PEREIRA", label: "MIGUEL PEREIRA" },
                    { value: "MIRACEMA", label: "MIRACEMA" },
                    { value: "NATIVIDADE", label: "NATIVIDADE" },
                    { value: "NILÓPOLIS", label: "NILÓPOLIS" },
                    { value: "NITERÓI", label: "NITERÓI" },
                    { value: "NOVA FRIBURGO", label: "NOVA FRIBURGO" },
                    { value: "NOVA IGUAÇU", label: "NOVA IGUAÇU" },
                    { value: "PARACAMBI", label: "PARACAMBI" },
                    { value: "PARAÍBA DO SUL", label: "PARAÍBA DO SUL" },
                    { value: "PARATY", label: "PARATY" },
                    { value: "PATY DO ALFERES", label: "PATY DO ALFERES" },
                    { value: "PETRÓPOLIS", label: "PETRÓPOLIS" },
                    { value: "PINHEIRAL", label: "PINHEIRAL" },
                    { value: "PIRAÍ", label: "PIRAÍ" },
                    { value: "PORCIÚNCULA", label: "PORCIÚNCULA" },
                    { value: "PORTO REAL", label: "PORTO REAL" },
                    { value: "QUATIS", label: "QUATIS" },
                    { value: "QUEIMADOS", label: "QUEIMADOS" },
                    { value: "QUISSAMÃ", label: "QUISSAMÃ" },
                    { value: "RESENDE", label: "RESENDE" },
                    { value: "RIO BONITO", label: "RIO BONITO" },
                    { value: "RIO CLARO", label: "RIO CLARO" },
                    { value: "RIO DAS FLORES", label: "RIO DAS FLORES" },
                    { value: "RIO DAS OSTRAS", label: "RIO DAS OSTRAS" },
                    { value: "RIO DE JANEIRO", label: "RIO DE JANEIRO" },
                    { value: "SANTA MARIA MADALENA", label: "SANTA MARIA MADALENA" },
                    { value: "SANTO ANTÔNIO DE PÁDUA", label: "SANTO ANTÔNIO DE PÁDUA" },
                    { value: "SÃO FIDÉLIS", label: "SÃO FIDÉLIS" },
                    { value: "SÃO FRANCISCO DE ITABAPOANA", label: "SÃO FRANCISCO DE ITABAPOANA" },
                    { value: "SÃO GONÇALO", label: "SÃO GONÇALO" },
                    { value: "SÃO JOÃO DA BARRA", label: "SÃO JOÃO DA BARRA" },
                    { value: "SÃO JOÃO DE MERITI", label: "SÃO JOÃO DE MERITI" },
                    { value: "SÃO JOSÉ DE UBÁ", label: "SÃO JOSÉ DE UBÁ" },
                    { value: "SÃO JOSÉ DO VALE DO RIO PRETO", label: "SÃO JOSÉ DO VALE DO RIO PRETO" },
                    { value: "SÃO PEDRO DA ALDEIA", label: "SÃO PEDRO DA ALDEIA" },
                    { value: "SÃO SEBASTIÃO DO ALTO", label: "SÃO SEBASTIÃO DO ALTO" },
                    { value: "SAPUCAIA", label: "SAPUCAIA" },
                    { value: "SAQUAREMA", label: "SAQUAREMA" },
                    { value: "SEROPÉDICA", label: "SEROPÉDICA" },
                    { value: "SILVA JARDIM", label: "SILVA JARDIM" },
                    { value: "SUMIDOURO", label: "SUMIDOURO" },
                    { value: "TANGUÁ", label: "TANGUÁ" },
                    { value: "TERESÓPOLIS", label: "TERESÓPOLIS" },
                    { value: "TRAJANO DE MORAES", label: "TRAJANO DE MORAES" },
                    { value: "TRÊS RIOS", label: "TRÊS RIOS" },
                    { value: "VALENÇA", label: "VALENÇA" },
                    { value: "VARRE-SAI", label: "VARRE-SAI" },
                    { value: "VASSOURAS", label: "VASSOURAS" },
                    { value: "VOLTA REDONDA", label: "VOLTA REDONDA" },
               ]
               break;

          case "RIO GRANDE DO NORTE":
               CITIES = [
                    { value: "ACARI", label: "ACARI" },
                    { value: "AÇU", label: "AÇU" },
                    { value: "AFONSO BEZERRA", label: "AFONSO BEZERRA" },
                    { value: "ÁGUA NOVA", label: "ÁGUA NOVA" },
                    { value: "ALEXANDRIA", label: "ALEXANDRIA" },
                    { value: "ALMINO AFONSO", label: "ALMINO AFONSO" },
                    { value: "ALTO DO RODRIGUES", label: "ALTO DO RODRIGUES" },
                    { value: "ANGICOS", label: "ANGICOS" },
                    { value: "ANTÔNIO MARTINS", label: "ANTÔNIO MARTINS" },
                    { value: "APODI", label: "APODI" },
                    { value: "AREIA BRANCA", label: "AREIA BRANCA" },
                    { value: "ARÊS", label: "ARÊS" },
                    { value: "AUGUSTO SEVERO", label: "AUGUSTO SEVERO" },
                    { value: "BAÍA FORMOSA", label: "BAÍA FORMOSA" },
                    { value: "BARAÚNA", label: "BARAÚNA" },
                    { value: "BARCELONA", label: "BARCELONA" },
                    { value: "BENTO FERNANDES", label: "BENTO FERNANDES" },
                    { value: "BODÓ", label: "BODÓ" },
                    { value: "BOM JESUS", label: "BOM JESUS" },
                    { value: "BREJINHO", label: "BREJINHO" },
                    { value: "CAIÇARA DO NORTE", label: "CAIÇARA DO NORTE" },
                    { value: "CAIÇARA DO RIO DO VENTO", label: "CAIÇARA DO RIO DO VENTO" },
                    { value: "CAICÓ", label: "CAICÓ" },
                    { value: "CAMPO REDONDO", label: "CAMPO REDONDO" },
                    { value: "CANGUARETAMA", label: "CANGUARETAMA" },
                    { value: "CARAÚBAS", label: "CARAÚBAS" },
                    { value: "CARNAÚBA DOS DANTAS", label: "CARNAÚBA DOS DANTAS" },
                    { value: "CARNAUBAIS", label: "CARNAUBAIS" },
                    { value: "CEARÁ-MIRIM", label: "CEARÁ-MIRIM" },
                    { value: "CERRO CORÁ", label: "CERRO CORÁ" },
                    { value: "CORONEL EZEQUIEL", label: "CORONEL EZEQUIEL" },
                    { value: "CORONEL JOÃO PESSOA", label: "CORONEL JOÃO PESSOA" },
                    { value: "CRUZETA", label: "CRUZETA" },
                    { value: "CURRAIS NOVOS", label: "CURRAIS NOVOS" },
                    { value: "DOUTOR SEVERIANO", label: "DOUTOR SEVERIANO" },
                    { value: "ENCANTO", label: "ENCANTO" },
                    { value: "EQUADOR", label: "EQUADOR" },
                    { value: "ESPÍRITO SANTO", label: "ESPÍRITO SANTO" },
                    { value: "EXTREMOZ", label: "EXTREMOZ" },
                    { value: "FELIPE GUERRA", label: "FELIPE GUERRA" },
                    { value: "FERNANDO PEDROZA", label: "FERNANDO PEDROZA" },
                    { value: "FLORÂNIA", label: "FLORÂNIA" },
                    { value: "FRANCISCO DANTAS", label: "FRANCISCO DANTAS" },
                    { value: "FRUTUOSO GOMES", label: "FRUTUOSO GOMES" },
                    { value: "GALINHOS", label: "GALINHOS" },
                    { value: "GOIANINHA", label: "GOIANINHA" },
                    { value: "GOVERNADOR DIX-SEPT ROSADO", label: "GOVERNADOR DIX-SEPT ROSADO" },
                    { value: "GROSSOS", label: "GROSSOS" },
                    { value: "GUAMARÉ", label: "GUAMARÉ" },
                    { value: "IELMO MARINHO", label: "IELMO MARINHO" },
                    { value: "IPANGUAÇU", label: "IPANGUAÇU" },
                    { value: "IPUEIRA", label: "IPUEIRA" },
                    { value: "ITAJÁ", label: "ITAJÁ" },
                    { value: "ITAÚ", label: "ITAÚ" },
                    { value: "JAÇANÃ", label: "JAÇANÃ" },
                    { value: "JANDAÍRA", label: "JANDAÍRA" },
                    { value: "JANDUÍS", label: "JANDUÍS" },
                    { value: "JANUÁRIO CICCO", label: "JANUÁRIO CICCO" },
                    { value: "JAPI", label: "JAPI" },
                    { value: "JARDIM DE ANGICOS", label: "JARDIM DE ANGICOS" },
                    { value: "JARDIM DE PIRANHAS", label: "JARDIM DE PIRANHAS" },
                    { value: "JARDIM DO SERIDÓ", label: "JARDIM DO SERIDÓ" },
                    { value: "JOÃO CÂMARA", label: "JOÃO CÂMARA" },
                    { value: "JOÃO DIAS", label: "JOÃO DIAS" },
                    { value: "JOSÉ DA PENHA", label: "JOSÉ DA PENHA" },
                    { value: "JUCURUTU", label: "JUCURUTU" },
                    { value: "JUNDIÁ", label: "JUNDIÁ" },
                    { value: "LAGOA D'ANTA", label: "LAGOA D'ANTA" },
                    { value: "LAGOA DE PEDRAS", label: "LAGOA DE PEDRAS" },
                    { value: "LAGOA DE VELHOS", label: "LAGOA DE VELHOS" },
                    { value: "LAGOA NOVA", label: "LAGOA NOVA" },
                    { value: "LAGOA SALGADA", label: "LAGOA SALGADA" },
                    { value: "LAJES", label: "LAJES" },
                    { value: "LAJES PINTADAS", label: "LAJES PINTADAS" },
                    { value: "LUCRÉCIA", label: "LUCRÉCIA" },
                    { value: "LUÍS GOMES", label: "LUÍS GOMES" },
                    { value: "MACAÍBA", label: "MACAÍBA" },
                    { value: "MACAU", label: "MACAU" },
                    { value: "MAJOR SALES", label: "MAJOR SALES" },
                    { value: "MARCELINO VIEIRA", label: "MARCELINO VIEIRA" },
                    { value: "MARTINS", label: "MARTINS" },
                    { value: "MAXARANGUAPE", label: "MAXARANGUAPE" },
                    { value: "MESSIAS TARGINO", label: "MESSIAS TARGINO" },
                    { value: "MONTANHAS", label: "MONTANHAS" },
                    { value: "MONTE ALEGRE", label: "MONTE ALEGRE" },
                    { value: "MONTE DAS GAMELEIRAS", label: "MONTE DAS GAMELEIRAS" },
                    { value: "MOSSORÓ", label: "MOSSORÓ" },
                    { value: "NATAL", label: "NATAL" },
                    { value: "NÍSIA FLORESTA", label: "NÍSIA FLORESTA" },
                    { value: "NOVA CRUZ", label: "NOVA CRUZ" },
                    { value: "OLHO-D'ÁGUA DO BORGES", label: "OLHO-D'ÁGUA DO BORGES" },
                    { value: "OURO BRANCO", label: "OURO BRANCO" },
                    { value: "PARANÁ", label: "PARANÁ" },
                    { value: "PARAÚ", label: "PARAÚ" },
                    { value: "PARAZINHO", label: "PARAZINHO" },
                    { value: "PARELHAS", label: "PARELHAS" },
                    { value: "PARNAMIRIM", label: "PARNAMIRIM" },
                    { value: "PASSA E FICA", label: "PASSA E FICA" },
                    { value: "PASSAGEM", label: "PASSAGEM" },
                    { value: "PATU", label: "PATU" },
                    { value: "PAU DOS FERROS", label: "PAU DOS FERROS" },
                    { value: "PEDRA GRANDE", label: "PEDRA GRANDE" },
                    { value: "PEDRA PRETA", label: "PEDRA PRETA" },
                    { value: "PEDRO AVELINO", label: "PEDRO AVELINO" },
                    { value: "PEDRO VELHO", label: "PEDRO VELHO" },
                    { value: "PENDÊNCIAS", label: "PENDÊNCIAS" },
                    { value: "PILÕES", label: "PILÕES" },
                    { value: "POÇO BRANCO", label: "POÇO BRANCO" },
                    { value: "PORTALEGRE", label: "PORTALEGRE" },
                    { value: "PORTO DO MANGUE", label: "PORTO DO MANGUE" },
                    { value: "PRESIDENTE JUSCELINO", label: "PRESIDENTE JUSCELINO" },
                    { value: "PUREZA", label: "PUREZA" },
                    { value: "RAFAEL FERNANDES", label: "RAFAEL FERNANDES" },
                    { value: "RAFAEL GODEIRO", label: "RAFAEL GODEIRO" },
                    { value: "RIACHO DA CRUZ", label: "RIACHO DA CRUZ" },
                    { value: "RIACHO DE SANTANA", label: "RIACHO DE SANTANA" },
                    { value: "RIACHUELO", label: "RIACHUELO" },
                    { value: "RIO DO FOGO", label: "RIO DO FOGO" },
                    { value: "RODOLFO FERNANDES", label: "RODOLFO FERNANDES" },
                    { value: "RUY BARBOSA", label: "RUY BARBOSA" },
                    { value: "SANTA CRUZ", label: "SANTA CRUZ" },
                    { value: "SANTA MARIA", label: "SANTA MARIA" },
                    { value: "SANTANA DO MATOS", label: "SANTANA DO MATOS" },
                    { value: "SANTANA DO SERIDÓ", label: "SANTANA DO SERIDÓ" },
                    { value: "SANTO ANTÔNIO", label: "SANTO ANTÔNIO" },
                    { value: "SÃO BENTO DO NORTE", label: "SÃO BENTO DO NORTE" },
                    { value: "SÃO BENTO DO TRAIRÍ", label: "SÃO BENTO DO TRAIRÍ" },
                    { value: "SÃO FERNANDO", label: "SÃO FERNANDO" },
                    { value: "SÃO FRANCISCO DO OESTE", label: "SÃO FRANCISCO DO OESTE" },
                    { value: "SÃO GONÇALO DO AMARANTE", label: "SÃO GONÇALO DO AMARANTE" },
                    { value: "SÃO JOÃO DO SABUGI", label: "SÃO JOÃO DO SABUGI" },
                    { value: "SÃO JOSÉ DE MIPIBU", label: "SÃO JOSÉ DE MIPIBU" },
                    { value: "SÃO JOSÉ DO CAMPESTRE", label: "SÃO JOSÉ DO CAMPESTRE" },
                    { value: "SÃO JOSÉ DO SERIDÓ", label: "SÃO JOSÉ DO SERIDÓ" },
                    { value: "SÃO MIGUEL", label: "SÃO MIGUEL" },
                    { value: "SÃO MIGUEL DO GOSTOSO", label: "SÃO MIGUEL DO GOSTOSO" },
                    { value: "SÃO PAULO DO POTENGI", label: "SÃO PAULO DO POTENGI" },
                    { value: "SÃO PEDRO", label: "SÃO PEDRO" },
                    { value: "SÃO RAFAEL", label: "SÃO RAFAEL" },
                    { value: "SÃO TOMÉ", label: "SÃO TOMÉ" },
                    { value: "SÃO VICENTE", label: "SÃO VICENTE" },
                    { value: "SENADOR ELÓI DE SOUZA", label: "SENADOR ELÓI DE SOUZA" },
                    { value: "SENADOR GEORGINO AVELINO", label: "SENADOR GEORGINO AVELINO" },
                    { value: "SERRA DE SÃO BENTO", label: "SERRA DE SÃO BENTO" },
                    { value: "SERRA DO MEL", label: "SERRA DO MEL" },
                    { value: "SERRA NEGRA DO NORTE", label: "SERRA NEGRA DO NORTE" },
                    { value: "SERRINHA", label: "SERRINHA" },
                    { value: "SERRINHA DOS PINTOS", label: "SERRINHA DOS PINTOS" },
                    { value: "SEVERIANO MELO", label: "SEVERIANO MELO" },
                    { value: "SÍTIO NOVO", label: "SÍTIO NOVO" },
                    { value: "TABOLEIRO GRANDE", label: "TABOLEIRO GRANDE" },
                    { value: "TAIPU", label: "TAIPU" },
                    { value: "TANGARÁ", label: "TANGARÁ" },
                    { value: "TENENTE ANANIAS", label: "TENENTE ANANIAS" },
                    { value: "TENENTE LAURENTINO CRUZ", label: "TENENTE LAURENTINO CRUZ" },
                    { value: "TIBAU", label: "TIBAU" },
                    { value: "TIBAU DO SUL", label: "TIBAU DO SUL" },
                    { value: "TIMBAÚBA DOS BATISTAS", label: "TIMBAÚBA DOS BATISTAS" },
                    { value: "TOUROS", label: "TOUROS" },
                    { value: "TRIUNFO POTIGUAR", label: "TRIUNFO POTIGUAR" },
                    { value: "UMARIZAL", label: "UMARIZAL" },
                    { value: "UPANEMA", label: "UPANEMA" },
                    { value: "VÁRZEA", label: "VÁRZEA" },
                    { value: "VENHA-VER", label: "VENHA-VER" },
                    { value: "VERA CRUZ", label: "VERA CRUZ" },
                    { value: "VIÇOSA", label: "VIÇOSA" },
                    { value: "VILA FLOR", label: "VILA FLOR" },
               ]
               break;

          case "RIO GRANDE DO SUL":
               CITIES = [
                    { value: "ACEGUÁ", label: "ACEGUÁ" },
                    { value: "ÁGUA SANTA", label: "ÁGUA SANTA" },
                    { value: "AGUDO", label: "AGUDO" },
                    { value: "AJURICABA", label: "AJURICABA" },
                    { value: "ALECRIM", label: "ALECRIM" },
                    { value: "ALEGRETE", label: "ALEGRETE" },
                    { value: "ALEGRIA", label: "ALEGRIA" },
                    { value: "ALMIRANTE TAMANDARÉ DO SUL", label: "ALMIRANTE TAMANDARÉ DO SUL" },
                    { value: "ALPESTRE", label: "ALPESTRE" },
                    { value: "ALTO ALEGRE", label: "ALTO ALEGRE" },
                    { value: "ALTO FELIZ", label: "ALTO FELIZ" },
                    { value: "ALVORADA", label: "ALVORADA" },
                    { value: "AMARAL FERRADOR", label: "AMARAL FERRADOR" },
                    { value: "AMETISTA DO SUL", label: "AMETISTA DO SUL" },
                    { value: "ANDRÉ DA ROCHA", label: "ANDRÉ DA ROCHA" },
                    { value: "ANTA GORDA", label: "ANTA GORDA" },
                    { value: "ANTÔNIO PRADO", label: "ANTÔNIO PRADO" },
                    { value: "ARAMBARÉ", label: "ARAMBARÉ" },
                    { value: "ARARICÁ", label: "ARARICÁ" },
                    { value: "ARATIBA", label: "ARATIBA" },
                    { value: "ARROIO DO MEIO", label: "ARROIO DO MEIO" },
                    { value: "ARROIO DO PADRE", label: "ARROIO DO PADRE" },
                    { value: "ARROIO DO SAL", label: "ARROIO DO SAL" },
                    { value: "ARROIO DO TIGRE", label: "ARROIO DO TIGRE" },
                    { value: "ARROIO DOS RATOS", label: "ARROIO DOS RATOS" },
                    { value: "ARROIO GRANDE", label: "ARROIO GRANDE" },
                    { value: "ARVOREZINHA", label: "ARVOREZINHA" },
                    { value: "AUGUSTO PESTANA", label: "AUGUSTO PESTANA" },
                    { value: "ÁUREA", label: "ÁUREA" },
                    { value: "BAGÉ", label: "BAGÉ" },
                    { value: "BALNEÁRIO PINHAL", label: "BALNEÁRIO PINHAL" },
                    { value: "BARÃO", label: "BARÃO" },
                    { value: "BARÃO DE COTEGIPE", label: "BARÃO DE COTEGIPE" },
                    { value: "BARÃO DO TRIUNFO", label: "BARÃO DO TRIUNFO" },
                    { value: "BARRA DO GUARITA", label: "BARRA DO GUARITA" },
                    { value: "BARRA DO QUARAÍ", label: "BARRA DO QUARAÍ" },
                    { value: "BARRA DO RIBEIRO", label: "BARRA DO RIBEIRO" },
                    { value: "BARRA DO RIO AZUL", label: "BARRA DO RIO AZUL" },
                    { value: "BARRA FUNDA", label: "BARRA FUNDA" },
                    { value: "BARRACÃO", label: "BARRACÃO" },
                    { value: "BARROS CASSAL", label: "BARROS CASSAL" },
                    { value: "BENJAMIN CONSTANT DO SUL", label: "BENJAMIN CONSTANT DO SUL" },
                    { value: "BENTO GONÇALVES", label: "BENTO GONÇALVES" },
                    { value: "BOA VISTA DAS MISSÕES", label: "BOA VISTA DAS MISSÕES" },
                    { value: "BOA VISTA DO BURICÁ", label: "BOA VISTA DO BURICÁ" },
                    { value: "BOA VISTA DO CADEADO", label: "BOA VISTA DO CADEADO" },
                    { value: "BOA VISTA DO INCRA", label: "BOA VISTA DO INCRA" },
                    { value: "BOA VISTA DO SUL", label: "BOA VISTA DO SUL" },
                    { value: "BOM JESUS", label: "BOM JESUS" },
                    { value: "BOM PRINCÍPIO", label: "BOM PRINCÍPIO" },
                    { value: "BOM PROGRESSO", label: "BOM PROGRESSO" },
                    { value: "BOM RETIRO DO SUL", label: "BOM RETIRO DO SUL" },
                    { value: "BOQUEIRÃO DO LEÃO", label: "BOQUEIRÃO DO LEÃO" },
                    { value: "BOSSOROCA", label: "BOSSOROCA" },
                    { value: "BOZANO", label: "BOZANO" },
                    { value: "BRAGA", label: "BRAGA" },
                    { value: "BROCHIER", label: "BROCHIER" },
                    { value: "BUTIÁ", label: "BUTIÁ" },
                    { value: "CAÇAPAVA DO SUL", label: "CAÇAPAVA DO SUL" },
                    { value: "CACEQUI", label: "CACEQUI" },
                    { value: "CACHOEIRA DO SUL", label: "CACHOEIRA DO SUL" },
                    { value: "CACHOEIRINHA", label: "CACHOEIRINHA" },
                    { value: "CACIQUE DOBLE", label: "CACIQUE DOBLE" },
                    { value: "CAIBATÉ", label: "CAIBATÉ" },
                    { value: "CAIÇARA", label: "CAIÇARA" },
                    { value: "CAMAQUÃ", label: "CAMAQUÃ" },
                    { value: "CAMARGO", label: "CAMARGO" },
                    { value: "CAMBARÁ DO SUL", label: "CAMBARÁ DO SUL" },
                    { value: "CAMPESTRE DA SERRA", label: "CAMPESTRE DA SERRA" },
                    { value: "CAMPINA DAS MISSÕES", label: "CAMPINA DAS MISSÕES" },
                    { value: "CAMPINAS DO SUL", label: "CAMPINAS DO SUL" },
                    { value: "CAMPO BOM", label: "CAMPO BOM" },
                    { value: "CAMPO NOVO", label: "CAMPO NOVO" },
                    { value: "CAMPOS BORGES", label: "CAMPOS BORGES" },
                    { value: "CANDELÁRIA", label: "CANDELÁRIA" },
                    { value: "CÂNDIDO GODÓI", label: "CÂNDIDO GODÓI" },
                    { value: "CANDIOTA", label: "CANDIOTA" },
                    { value: "CANELA", label: "CANELA" },
                    { value: "CANGUÇU", label: "CANGUÇU" },
                    { value: "CANOAS", label: "CANOAS" },
                    { value: "CANUDOS DO VALE", label: "CANUDOS DO VALE" },
                    { value: "CAPÃO BONITO DO SUL", label: "CAPÃO BONITO DO SUL" },
                    { value: "CAPÃO DA CANOA", label: "CAPÃO DA CANOA" },
                    { value: "CAPÃO DO CIPÓ", label: "CAPÃO DO CIPÓ" },
                    { value: "CAPÃO DO LEÃO", label: "CAPÃO DO LEÃO" },
                    { value: "CAPELA DE SANTANA", label: "CAPELA DE SANTANA" },
                    { value: "CAPITÃO", label: "CAPITÃO" },
                    { value: "CAPIVARI DO SUL", label: "CAPIVARI DO SUL" },
                    { value: "CARAÁ", label: "CARAÁ" },
                    { value: "CARAZINHO", label: "CARAZINHO" },
                    { value: "CARLOS BARBOSA", label: "CARLOS BARBOSA" },
                    { value: "CARLOS GOMES", label: "CARLOS GOMES" },
                    { value: "CASCA", label: "CASCA" },
                    { value: "CASEIROS", label: "CASEIROS" },
                    { value: "CATUÍPE", label: "CATUÍPE" },
                    { value: "CAXIAS DO SUL", label: "CAXIAS DO SUL" },
                    { value: "CENTENÁRIO", label: "CENTENÁRIO" },
                    { value: "CERRITO", label: "CERRITO" },
                    { value: "CERRO BRANCO", label: "CERRO BRANCO" },
                    { value: "CERRO GRANDE", label: "CERRO GRANDE" },
                    { value: "CERRO GRANDE DO SUL", label: "CERRO GRANDE DO SUL" },
                    { value: "CERRO LARGO", label: "CERRO LARGO" },
                    { value: "CHAPADA", label: "CHAPADA" },
                    { value: "CHARQUEADAS", label: "CHARQUEADAS" },
                    { value: "CHARRUA", label: "CHARRUA" },
                    { value: "CHIAPETTA", label: "CHIAPETTA" },
                    { value: "CHUÍ", label: "CHUÍ" },
                    { value: "CHUVISCA", label: "CHUVISCA" },
                    { value: "CIDREIRA", label: "CIDREIRA" },
                    { value: "CIRÍACO", label: "CIRÍACO" },
                    { value: "COLINAS", label: "COLINAS" },
                    { value: "COLORADO", label: "COLORADO" },
                    { value: "CONDOR", label: "CONDOR" },
                    { value: "CONSTANTINA", label: "CONSTANTINA" },
                    { value: "COQUEIRO BAIXO", label: "COQUEIRO BAIXO" },
                    { value: "COQUEIROS DO SUL", label: "COQUEIROS DO SUL" },
                    { value: "CORONEL BARROS", label: "CORONEL BARROS" },
                    { value: "CORONEL BICACO", label: "CORONEL BICACO" },
                    { value: "CORONEL PILAR", label: "CORONEL PILAR" },
                    { value: "COTIPORÃ", label: "COTIPORÃ" },
                    { value: "COXILHA", label: "COXILHA" },
                    { value: "CRISSIUMAL", label: "CRISSIUMAL" },
                    { value: "CRISTAL", label: "CRISTAL" },
                    { value: "CRISTAL DO SUL", label: "CRISTAL DO SUL" },
                    { value: "CRUZ ALTA", label: "CRUZ ALTA" },
                    { value: "CRUZALTENSE", label: "CRUZALTENSE" },
                    { value: "CRUZEIRO DO SUL", label: "CRUZEIRO DO SUL" },
                    { value: "DAVID CANABARRO", label: "DAVID CANABARRO" },
                    { value: "DERRUBADAS", label: "DERRUBADAS" },
                    { value: "DEZESSEIS DE NOVEMBRO", label: "DEZESSEIS DE NOVEMBRO" },
                    { value: "DILERMANDO DE AGUIAR", label: "DILERMANDO DE AGUIAR" },
                    { value: "DOIS IRMÃOS", label: "DOIS IRMÃOS" },
                    { value: "DOIS IRMÃOS DAS MISSÕES", label: "DOIS IRMÃOS DAS MISSÕES" },
                    { value: "DOIS LAJEADOS", label: "DOIS LAJEADOS" },
                    { value: "DOM FELICIANO", label: "DOM FELICIANO" },
                    { value: "DOM PEDRITO", label: "DOM PEDRITO" },
                    { value: "DOM PEDRO DE ALCÂNTARA", label: "DOM PEDRO DE ALCÂNTARA" },
                    { value: "DONA FRANCISCA", label: "DONA FRANCISCA" },
                    { value: "DOUTOR MAURÍCIO CARDOSO", label: "DOUTOR MAURÍCIO CARDOSO" },
                    { value: "DOUTOR RICARDO", label: "DOUTOR RICARDO" },
                    { value: "ELDORADO DO SUL", label: "ELDORADO DO SUL" },
                    { value: "ENCANTADO", label: "ENCANTADO" },
                    { value: "ENCRUZILHADA DO SUL", label: "ENCRUZILHADA DO SUL" },
                    { value: "ENGENHO VELHO", label: "ENGENHO VELHO" },
                    { value: "ENTRE RIOS DO SUL", label: "ENTRE RIOS DO SUL" },
                    { value: "ENTRE-IJUÍS", label: "ENTRE-IJUÍS" },
                    { value: "EREBANGO", label: "EREBANGO" },
                    { value: "ERECHIM", label: "ERECHIM" },
                    { value: "ERNESTINA", label: "ERNESTINA" },
                    { value: "ERVAL GRANDE", label: "ERVAL GRANDE" },
                    { value: "ERVAL SECO", label: "ERVAL SECO" },
                    { value: "ESMERALDA", label: "ESMERALDA" },
                    { value: "ESPERANÇA DO SUL", label: "ESPERANÇA DO SUL" },
                    { value: "ESPUMOSO", label: "ESPUMOSO" },
                    { value: "ESTAÇÃO", label: "ESTAÇÃO" },
                    { value: "ESTÂNCIA VELHA", label: "ESTÂNCIA VELHA" },
                    { value: "ESTEIO", label: "ESTEIO" },
                    { value: "ESTRELA", label: "ESTRELA" },
                    { value: "ESTRELA VELHA", label: "ESTRELA VELHA" },
                    { value: "EUGÊNIO DE CASTRO", label: "EUGÊNIO DE CASTRO" },
                    { value: "FAGUNDES VARELA", label: "FAGUNDES VARELA" },
                    { value: "FARROUPILHA", label: "FARROUPILHA" },
                    { value: "FAXINAL DO SOTURNO", label: "FAXINAL DO SOTURNO" },
                    { value: "FAXINALZINHO", label: "FAXINALZINHO" },
                    { value: "FAZENDA VILANOVA", label: "FAZENDA VILANOVA" },
                    { value: "FELIZ", label: "FELIZ" },
                    { value: "FLORES DA CUNHA", label: "FLORES DA CUNHA" },
                    { value: "FLORIANO PEIXOTO", label: "FLORIANO PEIXOTO" },
                    { value: "FONTOURA XAVIER", label: "FONTOURA XAVIER" },
                    { value: "FORMIGUEIRO", label: "FORMIGUEIRO" },
                    { value: "FORQUETINHA", label: "FORQUETINHA" },
                    { value: "FORTALEZA DOS VALOS", label: "FORTALEZA DOS VALOS" },
                    { value: "FREDERICO WESTPHALEN", label: "FREDERICO WESTPHALEN" },
                    { value: "GARIBALDI", label: "GARIBALDI" },
                    { value: "GARRUCHOS", label: "GARRUCHOS" },
                    { value: "GAURAMA", label: "GAURAMA" },
                    { value: "GENERAL CÂMARA", label: "GENERAL CÂMARA" },
                    { value: "GENTIL", label: "GENTIL" },
                    { value: "GETÚLIO VARGAS", label: "GETÚLIO VARGAS" },
                    { value: "GIRUÁ", label: "GIRUÁ" },
                    { value: "GLORINHA", label: "GLORINHA" },
                    { value: "GRAMADO", label: "GRAMADO" },
                    { value: "GRAMADO DOS LOUREIROS", label: "GRAMADO DOS LOUREIROS" },
                    { value: "GRAMADO XAVIER", label: "GRAMADO XAVIER" },
                    { value: "GRAVATAÍ", label: "GRAVATAÍ" },
                    { value: "GUABIJU", label: "GUABIJU" },
                    { value: "GUAÍBA", label: "GUAÍBA" },
                    { value: "GUAPORÉ", label: "GUAPORÉ" },
                    { value: "GUARANI DAS MISSÕES", label: "GUARANI DAS MISSÕES" },
                    { value: "HARMONIA", label: "HARMONIA" },
                    { value: "HERVAL", label: "HERVAL" },
                    { value: "HERVEIRAS", label: "HERVEIRAS" },
                    { value: "HORIZONTINA", label: "HORIZONTINA" },
                    { value: "HULHA NEGRA", label: "HULHA NEGRA" },
                    { value: "HUMAITÁ", label: "HUMAITÁ" },
                    { value: "IBARAMA", label: "IBARAMA" },
                    { value: "IBIAÇÁ", label: "IBIAÇÁ" },
                    { value: "IBIRAIARAS", label: "IBIRAIARAS" },
                    { value: "IBIRAPUITÃ", label: "IBIRAPUITÃ" },
                    { value: "IBIRUBÁ", label: "IBIRUBÁ" },
                    { value: "IGREJINHA", label: "IGREJINHA" },
                    { value: "IJUÍ", label: "IJUÍ" },
                    { value: "ILÓPOLIS", label: "ILÓPOLIS" },
                    { value: "IMBÉ", label: "IMBÉ" },
                    { value: "IMIGRANTE", label: "IMIGRANTE" },
                    { value: "INDEPENDÊNCIA", label: "INDEPENDÊNCIA" },
                    { value: "INHACORÁ", label: "INHACORÁ" },
                    { value: "IPÊ", label: "IPÊ" },
                    { value: "IPIRANGA DO SUL", label: "IPIRANGA DO SUL" },
                    { value: "IRAÍ", label: "IRAÍ" },
                    { value: "ITAARA", label: "ITAARA" },
                    { value: "ITACURUBI", label: "ITACURUBI" },
                    { value: "ITAPUCA", label: "ITAPUCA" },
                    { value: "ITAQUI", label: "ITAQUI" },
                    { value: "ITATI", label: "ITATI" },
                    { value: "ITATIBA DO SUL", label: "ITATIBA DO SUL" },
                    { value: "IVORÁ", label: "IVORÁ" },
                    { value: "IVOTI", label: "IVOTI" },
                    { value: "JABOTICABA", label: "JABOTICABA" },
                    { value: "JACUIZINHO", label: "JACUIZINHO" },
                    { value: "JACUTINGA", label: "JACUTINGA" },
                    { value: "JAGUARÃO", label: "JAGUARÃO" },
                    { value: "JAGUARI", label: "JAGUARI" },
                    { value: "JAQUIRANA", label: "JAQUIRANA" },
                    { value: "JARI", label: "JARI" },
                    { value: "JÓIA", label: "JÓIA" },
                    { value: "JÚLIO DE CASTILHOS", label: "JÚLIO DE CASTILHOS" },
                    { value: "LAGOA BONITA DO SUL", label: "LAGOA BONITA DO SUL" },
                    { value: "LAGOA DOS TRÊS CANTOS", label: "LAGOA DOS TRÊS CANTOS" },
                    { value: "LAGOA VERMELHA", label: "LAGOA VERMELHA" },
                    { value: "LAGOÃO", label: "LAGOÃO" },
                    { value: "LAJEADO", label: "LAJEADO" },
                    { value: "LAJEADO DO BUGRE", label: "LAJEADO DO BUGRE" },
                    { value: "LAVRAS DO SUL", label: "LAVRAS DO SUL" },
                    { value: "LIBERATO SALZANO", label: "LIBERATO SALZANO" },
                    { value: "LINDOLFO COLLOR", label: "LINDOLFO COLLOR" },
                    { value: "LINHA NOVA", label: "LINHA NOVA" },
                    { value: "MAÇAMBARÁ", label: "MAÇAMBARÁ" },
                    { value: "MACHADINHO", label: "MACHADINHO" },
                    { value: "MAMPITUBA", label: "MAMPITUBA" },
                    { value: "MANOEL VIANA", label: "MANOEL VIANA" },
                    { value: "MAQUINÉ", label: "MAQUINÉ" },
                    { value: "MARATÁ", label: "MARATÁ" },
                    { value: "MARAU", label: "MARAU" },
                    { value: "MARCELINO RAMOS", label: "MARCELINO RAMOS" },
                    { value: "MARIANA PIMENTEL", label: "MARIANA PIMENTEL" },
                    { value: "MARIANO MORO", label: "MARIANO MORO" },
                    { value: "MARQUES DE SOUZA", label: "MARQUES DE SOUZA" },
                    { value: "MATA", label: "MATA" },
                    { value: "MATO CASTELHANO", label: "MATO CASTELHANO" },
                    { value: "MATO LEITÃO", label: "MATO LEITÃO" },
                    { value: "MATO QUEIMADO", label: "MATO QUEIMADO" },
                    { value: "MAXIMILIANO DE ALMEIDA", label: "MAXIMILIANO DE ALMEIDA" },
                    { value: "MINAS DO LEÃO", label: "MINAS DO LEÃO" },
                    { value: "MIRAGUAÍ", label: "MIRAGUAÍ" },
                    { value: "MONTAURI", label: "MONTAURI" },
                    { value: "MONTE ALEGRE DOS CAMPOS", label: "MONTE ALEGRE DOS CAMPOS" },
                    { value: "MONTE BELO DO SUL", label: "MONTE BELO DO SUL" },
                    { value: "MONTENEGRO", label: "MONTENEGRO" },
                    { value: "MORMAÇO", label: "MORMAÇO" },
                    { value: "MORRINHOS DO SUL", label: "MORRINHOS DO SUL" },
                    { value: "MORRO REDONDO", label: "MORRO REDONDO" },
                    { value: "MORRO REUTER", label: "MORRO REUTER" },
                    { value: "MOSTARDAS", label: "MOSTARDAS" },
                    { value: "MUÇUM", label: "MUÇUM" },
                    { value: "MUITOS CAPÕES", label: "MUITOS CAPÕES" },
                    { value: "MULITERNO", label: "MULITERNO" },
                    { value: "NÃO-ME-TOQUE", label: "NÃO-ME-TOQUE" },
                    { value: "NICOLAU VERGUEIRO", label: "NICOLAU VERGUEIRO" },
                    { value: "NONOAI", label: "NONOAI" },
                    { value: "NOVA ALVORADA", label: "NOVA ALVORADA" },
                    { value: "NOVA ARAÇÁ", label: "NOVA ARAÇÁ" },
                    { value: "NOVA BASSANO", label: "NOVA BASSANO" },
                    { value: "NOVA BOA VISTA", label: "NOVA BOA VISTA" },
                    { value: "NOVA BRÉSCIA", label: "NOVA BRÉSCIA" },
                    { value: "NOVA CANDELÁRIA", label: "NOVA CANDELÁRIA" },
                    { value: "NOVA ESPERANÇA DO SUL", label: "NOVA ESPERANÇA DO SUL" },
                    { value: "NOVA HARTZ", label: "NOVA HARTZ" },
                    { value: "NOVA PÁDUA", label: "NOVA PÁDUA" },
                    { value: "NOVA PALMA", label: "NOVA PALMA" },
                    { value: "NOVA PETRÓPOLIS", label: "NOVA PETRÓPOLIS" },
                    { value: "NOVA PRATA", label: "NOVA PRATA" },
                    { value: "NOVA RAMADA", label: "NOVA RAMADA" },
                    { value: "NOVA ROMA DO SUL", label: "NOVA ROMA DO SUL" },
                    { value: "NOVA SANTA RITA", label: "NOVA SANTA RITA" },
                    { value: "NOVO BARREIRO", label: "NOVO BARREIRO" },
                    { value: "NOVO CABRAIS", label: "NOVO CABRAIS" },
                    { value: "NOVO HAMBURGO", label: "NOVO HAMBURGO" },
                    { value: "NOVO MACHADO", label: "NOVO MACHADO" },
                    { value: "NOVO TIRADENTES", label: "NOVO TIRADENTES" },
                    { value: "NOVO XINGU", label: "NOVO XINGU" },
                    { value: "OSÓRIO", label: "OSÓRIO" },
                    { value: "PAIM FILHO", label: "PAIM FILHO" },
                    { value: "PALMARES DO SUL", label: "PALMARES DO SUL" },
                    { value: "PALMEIRA DAS MISSÕES", label: "PALMEIRA DAS MISSÕES" },
                    { value: "PALMITINHO", label: "PALMITINHO" },
                    { value: "PANAMBI", label: "PANAMBI" },
                    { value: "PANTANO GRANDE", label: "PANTANO GRANDE" },
                    { value: "PARAÍ", label: "PARAÍ" },
                    { value: "PARAÍSO DO SUL", label: "PARAÍSO DO SUL" },
                    { value: "PARECI NOVO", label: "PARECI NOVO" },
                    { value: "PAROBÉ", label: "PAROBÉ" },
                    { value: "PASSA SETE", label: "PASSA SETE" },
                    { value: "PASSO DO SOBRADO", label: "PASSO DO SOBRADO" },
                    { value: "PASSO FUNDO", label: "PASSO FUNDO" },
                    { value: "PAULO BENTO", label: "PAULO BENTO" },
                    { value: "PAVERAMA", label: "PAVERAMA" },
                    { value: "PEDRAS ALTAS", label: "PEDRAS ALTAS" },
                    { value: "PEDRO OSÓRIO", label: "PEDRO OSÓRIO" },
                    { value: "PEJUÇARA", label: "PEJUÇARA" },
                    { value: "PELOTAS", label: "PELOTAS" },
                    { value: "PICADA CAFÉ", label: "PICADA CAFÉ" },
                    { value: "PINHAL", label: "PINHAL" },
                    { value: "PINHAL DA SERRA", label: "PINHAL DA SERRA" },
                    { value: "PINHAL GRANDE", label: "PINHAL GRANDE" },
                    { value: "PINHEIRINHO DO VALE", label: "PINHEIRINHO DO VALE" },
                    { value: "PINHEIRO MACHADO", label: "PINHEIRO MACHADO" },
                    { value: "PINTO BANDEIRA", label: "PINTO BANDEIRA" },
                    { value: "PIRAPÓ", label: "PIRAPÓ" },
                    { value: "PIRATINI", label: "PIRATINI" },
                    { value: "PLANALTO", label: "PLANALTO" },
                    { value: "POÇO DAS ANTAS", label: "POÇO DAS ANTAS" },
                    { value: "PONTÃO", label: "PONTÃO" },
                    { value: "PONTE PRETA", label: "PONTE PRETA" },
                    { value: "PORTÃO", label: "PORTÃO" },
                    { value: "PORTO ALEGRE", label: "PORTO ALEGRE" },
                    { value: "PORTO LUCENA", label: "PORTO LUCENA" },
                    { value: "PORTO MAUÁ", label: "PORTO MAUÁ" },
                    { value: "PORTO VERA CRUZ", label: "PORTO VERA CRUZ" },
                    { value: "PORTO XAVIER", label: "PORTO XAVIER" },
                    { value: "POUSO NOVO", label: "POUSO NOVO" },
                    { value: "PRESIDENTE LUCENA", label: "PRESIDENTE LUCENA" },
                    { value: "PROGRESSO", label: "PROGRESSO" },
                    { value: "PROTÁSIO ALVES", label: "PROTÁSIO ALVES" },
                    { value: "PUTINGA", label: "PUTINGA" },
                    { value: "QUARAÍ", label: "QUARAÍ" },
                    { value: "QUATRO IRMÃOS", label: "QUATRO IRMÃOS" },
                    { value: "QUEVEDOS", label: "QUEVEDOS" },
                    { value: "QUINZE DE NOVEMBRO", label: "QUINZE DE NOVEMBRO" },
                    { value: "REDENTORA", label: "REDENTORA" },
                    { value: "RELVADO", label: "RELVADO" },
                    { value: "RESTINGA SECA", label: "RESTINGA SECA" },
                    { value: "RIO DOS ÍNDIOS", label: "RIO DOS ÍNDIOS" },
                    { value: "RIO GRANDE", label: "RIO GRANDE" },
                    { value: "RIO PARDO", label: "RIO PARDO" },
                    { value: "RIOZINHO", label: "RIOZINHO" },
                    { value: "ROCA SALES", label: "ROCA SALES" },
                    { value: "RODEIO BONITO", label: "RODEIO BONITO" },
                    { value: "ROLADOR", label: "ROLADOR" },
                    { value: "ROLANTE", label: "ROLANTE" },
                    { value: "RONDA ALTA", label: "RONDA ALTA" },
                    { value: "RONDINHA", label: "RONDINHA" },
                    { value: "ROQUE GONZALES", label: "ROQUE GONZALES" },
                    { value: "ROSÁRIO DO SUL", label: "ROSÁRIO DO SUL" },
                    { value: "SAGRADA FAMÍLIA", label: "SAGRADA FAMÍLIA" },
                    { value: "SALDANHA MARINHO", label: "SALDANHA MARINHO" },
                    { value: "SALTO DO JACUÍ", label: "SALTO DO JACUÍ" },
                    { value: "SALVADOR DAS MISSÕES", label: "SALVADOR DAS MISSÕES" },
                    { value: "SALVADOR DO SUL", label: "SALVADOR DO SUL" },
                    { value: "SANANDUVA", label: "SANANDUVA" },
                    { value: "SANT'ANA DO LIVRAMENTO", label: "SANT'ANA DO LIVRAMENTO" },
                    { value: "SANTA BÁRBARA DO SUL", label: "SANTA BÁRBARA DO SUL" },
                    { value: "SANTA CECÍLIA DO SUL", label: "SANTA CECÍLIA DO SUL" },
                    { value: "SANTA CLARA DO SUL", label: "SANTA CLARA DO SUL" },
                    { value: "SANTA CRUZ DO SUL", label: "SANTA CRUZ DO SUL" },
                    { value: "SANTA MARGARIDA DO SUL", label: "SANTA MARGARIDA DO SUL" },
                    { value: "SANTA MARIA", label: "SANTA MARIA" },
                    { value: "SANTA MARIA DO HERVAL", label: "SANTA MARIA DO HERVAL" },
                    { value: "SANTA ROSA", label: "SANTA ROSA" },
                    { value: "SANTA TEREZA", label: "SANTA TEREZA" },
                    { value: "SANTA VITÓRIA DO PALMAR", label: "SANTA VITÓRIA DO PALMAR" },
                    { value: "SANTANA DA BOA VISTA", label: "SANTANA DA BOA VISTA" },
                    { value: "SANTIAGO", label: "SANTIAGO" },
                    { value: "SANTO ÂNGELO", label: "SANTO ÂNGELO" },
                    { value: "SANTO ANTÔNIO DA PATRULHA", label: "SANTO ANTÔNIO DA PATRULHA" },
                    { value: "SANTO ANTÔNIO DAS MISSÕES", label: "SANTO ANTÔNIO DAS MISSÕES" },
                    { value: "SANTO ANTÔNIO DO PALMA", label: "SANTO ANTÔNIO DO PALMA" },
                    { value: "SANTO ANTÔNIO DO PLANALTO", label: "SANTO ANTÔNIO DO PLANALTO" },
                    { value: "SANTO AUGUSTO", label: "SANTO AUGUSTO" },
                    { value: "SANTO CRISTO", label: "SANTO CRISTO" },
                    { value: "SANTO EXPEDITO DO SUL", label: "SANTO EXPEDITO DO SUL" },
                    { value: "SÃO BORJA", label: "SÃO BORJA" },
                    { value: "SÃO DOMINGOS DO SUL", label: "SÃO DOMINGOS DO SUL" },
                    { value: "SÃO FRANCISCO DE ASSIS", label: "SÃO FRANCISCO DE ASSIS" },
                    { value: "SÃO FRANCISCO DE PAULA", label: "SÃO FRANCISCO DE PAULA" },
                    { value: "SÃO GABRIEL", label: "SÃO GABRIEL" },
                    { value: "SÃO JERÔNIMO", label: "SÃO JERÔNIMO" },
                    { value: "SÃO JOÃO DA URTIGA", label: "SÃO JOÃO DA URTIGA" },
                    { value: "SÃO JOÃO DO POLÊSINE", label: "SÃO JOÃO DO POLÊSINE" },
                    { value: "SÃO JORGE", label: "SÃO JORGE" },
                    { value: "SÃO JOSÉ DAS MISSÕES", label: "SÃO JOSÉ DAS MISSÕES" },
                    { value: "SÃO JOSÉ DO HERVAL", label: "SÃO JOSÉ DO HERVAL" },
                    { value: "SÃO JOSÉ DO HORTÊNCIO", label: "SÃO JOSÉ DO HORTÊNCIO" },
                    { value: "SÃO JOSÉ DO INHACORÁ", label: "SÃO JOSÉ DO INHACORÁ" },
                    { value: "SÃO JOSÉ DO NORTE", label: "SÃO JOSÉ DO NORTE" },
                    { value: "SÃO JOSÉ DO OURO", label: "SÃO JOSÉ DO OURO" },
                    { value: "SÃO JOSÉ DO SUL", label: "SÃO JOSÉ DO SUL" },
                    { value: "SÃO JOSÉ DOS AUSENTES", label: "SÃO JOSÉ DOS AUSENTES" },
                    { value: "SÃO LEOPOLDO", label: "SÃO LEOPOLDO" },
                    { value: "SÃO LOURENÇO DO SUL", label: "SÃO LOURENÇO DO SUL" },
                    { value: "SÃO LUIZ GONZAGA", label: "SÃO LUIZ GONZAGA" },
                    { value: "SÃO MARCOS", label: "SÃO MARCOS" },
                    { value: "SÃO MARTINHO", label: "SÃO MARTINHO" },
                    { value: "SÃO MARTINHO DA SERRA", label: "SÃO MARTINHO DA SERRA" },
                    { value: "SÃO MIGUEL DAS MISSÕES", label: "SÃO MIGUEL DAS MISSÕES" },
                    { value: "SÃO NICOLAU", label: "SÃO NICOLAU" },
                    { value: "SÃO PAULO DAS MISSÕES", label: "SÃO PAULO DAS MISSÕES" },
                    { value: "SÃO PEDRO DA SERRA", label: "SÃO PEDRO DA SERRA" },
                    { value: "SÃO PEDRO DAS MISSÕES", label: "SÃO PEDRO DAS MISSÕES" },
                    { value: "SÃO PEDRO DO BUTIÁ", label: "SÃO PEDRO DO BUTIÁ" },
                    { value: "SÃO PEDRO DO SUL", label: "SÃO PEDRO DO SUL" },
                    { value: "SÃO SEBASTIÃO DO CAÍ", label: "SÃO SEBASTIÃO DO CAÍ" },
                    { value: "SÃO SEPÉ", label: "SÃO SEPÉ" },
                    { value: "SÃO VALENTIM", label: "SÃO VALENTIM" },
                    { value: "SÃO VALENTIM DO SUL", label: "SÃO VALENTIM DO SUL" },
                    { value: "SÃO VALÉRIO DO SUL", label: "SÃO VALÉRIO DO SUL" },
                    { value: "SÃO VENDELINO", label: "SÃO VENDELINO" },
                    { value: "SÃO VICENTE DO SUL", label: "SÃO VICENTE DO SUL" },
                    { value: "SAPIRANGA", label: "SAPIRANGA" },
                    { value: "SAPUCAIA DO SUL", label: "SAPUCAIA DO SUL" },
                    { value: "SARANDI", label: "SARANDI" },
                    { value: "SEBERI", label: "SEBERI" },
                    { value: "SEDE NOVA", label: "SEDE NOVA" },
                    { value: "SEGREDO", label: "SEGREDO" },
                    { value: "SELBACH", label: "SELBACH" },
                    { value: "SENADOR SALGADO FILHO", label: "SENADOR SALGADO FILHO" },
                    { value: "SENTINELA DO SUL", label: "SENTINELA DO SUL" },
                    { value: "SERAFINA CORRÊA", label: "SERAFINA CORRÊA" },
                    { value: "SÉRIO", label: "SÉRIO" },
                    { value: "SERTÃO", label: "SERTÃO" },
                    { value: "SERTÃO SANTANA", label: "SERTÃO SANTANA" },
                    { value: "SETE DE SETEMBRO", label: "SETE DE SETEMBRO" },
                    { value: "SEVERIANO DE ALMEIDA", label: "SEVERIANO DE ALMEIDA" },
                    { value: "SILVEIRA MARTINS", label: "SILVEIRA MARTINS" },
                    { value: "SINIMBU", label: "SINIMBU" },
                    { value: "SOBRADINHO", label: "SOBRADINHO" },
                    { value: "SOLEDADE", label: "SOLEDADE" },
                    { value: "TABAÍ", label: "TABAÍ" },
                    { value: "TAPEJARA", label: "TAPEJARA" },
                    { value: "TAPERA", label: "TAPERA" },
                    { value: "TAPES", label: "TAPES" },
                    { value: "TAQUARA", label: "TAQUARA" },
                    { value: "TAQUARI", label: "TAQUARI" },
                    { value: "TAQUARUÇU DO SUL", label: "TAQUARUÇU DO SUL" },
                    { value: "TAVARES", label: "TAVARES" },
                    { value: "TENENTE PORTELA", label: "TENENTE PORTELA" },
                    { value: "TERRA DE AREIA", label: "TERRA DE AREIA" },
                    { value: "TEUTÔNIA", label: "TEUTÔNIA" },
                    { value: "TIO HUGO", label: "TIO HUGO" },
                    { value: "TIRADENTES DO SUL", label: "TIRADENTES DO SUL" },
                    { value: "TOROPI", label: "TOROPI" },
                    { value: "TORRES", label: "TORRES" },
                    { value: "TRAMANDAÍ", label: "TRAMANDAÍ" },
                    { value: "TRAVESSEIRO", label: "TRAVESSEIRO" },
                    { value: "TRÊS ARROIOS", label: "TRÊS ARROIOS" },
                    { value: "TRÊS CACHOEIRAS", label: "TRÊS CACHOEIRAS" },
                    { value: "TRÊS COROAS", label: "TRÊS COROAS" },
                    { value: "TRÊS DE MAIO", label: "TRÊS DE MAIO" },
                    { value: "TRÊS FORQUILHAS", label: "TRÊS FORQUILHAS" },
                    { value: "TRÊS PALMEIRAS", label: "TRÊS PALMEIRAS" },
                    { value: "TRÊS PASSOS", label: "TRÊS PASSOS" },
                    { value: "TRINDADE DO SUL", label: "TRINDADE DO SUL" },
                    { value: "TRIUNFO", label: "TRIUNFO" },
                    { value: "TUCUNDUVA", label: "TUCUNDUVA" },
                    { value: "TUNAS", label: "TUNAS" },
                    { value: "TUPANCI DO SUL", label: "TUPANCI DO SUL" },
                    { value: "TUPANCIRETÃ", label: "TUPANCIRETÃ" },
                    { value: "TUPANDI", label: "TUPANDI" },
                    { value: "TUPARENDI", label: "TUPARENDI" },
                    { value: "TURUÇU", label: "TURUÇU" },
                    { value: "UBIRETAMA", label: "UBIRETAMA" },
                    { value: "UNIÃO DA SERRA", label: "UNIÃO DA SERRA" },
                    { value: "UNISTALDA", label: "UNISTALDA" },
                    { value: "URUGUAIANA", label: "URUGUAIANA" },
                    { value: "VACARIA", label: "VACARIA" },
                    { value: "VALE DO SOL", label: "VALE DO SOL" },
                    { value: "VALE REAL", label: "VALE REAL" },
                    { value: "VALE VERDE", label: "VALE VERDE" },
                    { value: "VANINI", label: "VANINI" },
                    { value: "VENÂNCIO AIRES", label: "VENÂNCIO AIRES" },
                    { value: "VERA CRUZ", label: "VERA CRUZ" },
                    { value: "VERANÓPOLIS", label: "VERANÓPOLIS" },
                    { value: "VESPASIANO CORREA", label: "VESPASIANO CORREA" },
                    { value: "VIADUTOS", label: "VIADUTOS" },
                    { value: "VIAMÃO", label: "VIAMÃO" },
                    { value: "VICENTE DUTRA", label: "VICENTE DUTRA" },
                    { value: "VICTOR GRAEFF", label: "VICTOR GRAEFF" },
                    { value: "VILA FLORES", label: "VILA FLORES" },
                    { value: "VILA LÂNGARO", label: "VILA LÂNGARO" },
                    { value: "VILA MARIA", label: "VILA MARIA" },
                    { value: "VILA NOVA DO SUL", label: "VILA NOVA DO SUL" },
                    { value: "VISTA ALEGRE", label: "VISTA ALEGRE" },
                    { value: "VISTA ALEGRE DO PRATA", label: "VISTA ALEGRE DO PRATA" },
                    { value: "VISTA GAÚCHA", label: "VISTA GAÚCHA" },
                    { value: "VITÓRIA DAS MISSÕES", label: "VITÓRIA DAS MISSÕES" },
                    { value: "WESTFALIA", label: "WESTFALIA" },
                    { value: "XANGRI-LÁ", label: "XANGRI-LÁ" },
               ]
               break;

          case "RONDÔNIA":
               CITIES = [
                    { value: "ALTA FLORESTA D'OESTE", label: "ALTA FLORESTA D'OESTE" },
                    { value: "ALTO ALEGRE DOS PARECIS", label: "ALTO ALEGRE DOS PARECIS" },
                    { value: "ALTO PARAÍSO", label: "ALTO PARAÍSO" },
                    { value: "ALVORADA D'OESTE", label: "ALVORADA D'OESTE" },
                    { value: "ARIQUEMES", label: "ARIQUEMES" },
                    { value: "BURITIS", label: "BURITIS" },
                    { value: "CABIXI", label: "CABIXI" },
                    { value: "CACAULÂNDIA", label: "CACAULÂNDIA" },
                    { value: "CACOAL", label: "CACOAL" },
                    { value: "CAMPO NOVO DE RONDÔNIA", label: "CAMPO NOVO DE RONDÔNIA" },
                    { value: "CANDEIAS DO JAMARI", label: "CANDEIAS DO JAMARI" },
                    { value: "CASTANHEIRAS", label: "CASTANHEIRAS" },
                    { value: "CEREJEIRAS", label: "CEREJEIRAS" },
                    { value: "CHUPINGUAIA", label: "CHUPINGUAIA" },
                    { value: "COLORADO DO OESTE", label: "COLORADO DO OESTE" },
                    { value: "CORUMBIARA", label: "CORUMBIARA" },
                    { value: "COSTA MARQUES", label: "COSTA MARQUES" },
                    { value: "CUJUBIM", label: "CUJUBIM" },
                    { value: "ESPIGÃO D'OESTE", label: "ESPIGÃO D'OESTE" },
                    { value: "GOVERNADOR JORGE TEIXEIRA", label: "GOVERNADOR JORGE TEIXEIRA" },
                    { value: "GUAJARÁ-MIRIM", label: "GUAJARÁ-MIRIM" },
                    { value: "ITAPUÃ DO OESTE", label: "ITAPUÃ DO OESTE" },
                    { value: "JARU", label: "JARU" },
                    { value: "JI-PARANÁ", label: "JI-PARANÁ" },
                    { value: "MACHADINHO D'OESTE", label: "MACHADINHO D'OESTE" },
                    { value: "MINISTRO ANDREAZZA", label: "MINISTRO ANDREAZZA" },
                    { value: "MIRANTE DA SERRA", label: "MIRANTE DA SERRA" },
                    { value: "MONTE NEGRO", label: "MONTE NEGRO" },
                    { value: "NOVA BRASILÂNDIA D'OESTE", label: "NOVA BRASILÂNDIA D'OESTE" },
                    { value: "NOVA MAMORÉ", label: "NOVA MAMORÉ" },
                    { value: "NOVA UNIÃO", label: "NOVA UNIÃO" },
                    { value: "NOVO HORIZONTE DO OESTE", label: "NOVO HORIZONTE DO OESTE" },
                    { value: "OURO PRETO DO OESTE", label: "OURO PRETO DO OESTE" },
                    { value: "PARECIS", label: "PARECIS" },
                    { value: "PIMENTA BUENO", label: "PIMENTA BUENO" },
                    { value: "PIMENTEIRAS DO OESTE", label: "PIMENTEIRAS DO OESTE" },
                    { value: "PORTO VELHO", label: "PORTO VELHO" },
                    { value: "PRESIDENTE MÉDICI", label: "PRESIDENTE MÉDICI" },
                    { value: "PRIMAVERA DE RONDÔNIA", label: "PRIMAVERA DE RONDÔNIA" },
                    { value: "RIO CRESPO", label: "RIO CRESPO" },
                    { value: "ROLIM DE MOURA", label: "ROLIM DE MOURA" },
                    { value: "SANTA LUZIA D'OESTE", label: "SANTA LUZIA D'OESTE" },
                    { value: "SÃO FELIPE D'OESTE", label: "SÃO FELIPE D'OESTE" },
                    { value: "SÃO FRANCISCO DO GUAPORÉ", label: "SÃO FRANCISCO DO GUAPORÉ" },
                    { value: "SÃO MIGUEL DO GUAPORÉ", label: "SÃO MIGUEL DO GUAPORÉ" },
                    { value: "SERINGUEIRAS", label: "SERINGUEIRAS" },
                    { value: "TEIXEIRÓPOLIS", label: "TEIXEIRÓPOLIS" },
                    { value: "THEOBROMA", label: "THEOBROMA" },
                    { value: "URUPÁ", label: "URUPÁ" },
                    { value: "VALE DO ANARI", label: "VALE DO ANARI" },
                    { value: "VALE DO PARAÍSO", label: "VALE DO PARAÍSO" },
                    { value: "VILHENA", label: "VILHENA" },
               ]
               break;

          case "RORAIMA":
               CITIES = [
                    { value: "ALTO ALEGRE", label: "ALTO ALEGRE" },
                    { value: "AMAJARI", label: "AMAJARI" },
                    { value: "BOA VISTA", label: "BOA VISTA" },
                    { value: "BONFIM", label: "BONFIM" },
                    { value: "CANTÁ", label: "CANTÁ" },
                    { value: "CARACARAÍ", label: "CARACARAÍ" },
                    { value: "CAROEBE", label: "CAROEBE" },
                    { value: "IRACEMA", label: "IRACEMA" },
                    { value: "MUCAJAÍ", label: "MUCAJAÍ" },
                    { value: "NORMANDIA", label: "NORMANDIA" },
                    { value: "PACARAIMA", label: "PACARAIMA" },
                    { value: "RORAINÓPOLIS", label: "RORAINÓPOLIS" },
                    { value: "SÃO JOÃO DA BALIZA", label: "SÃO JOÃO DA BALIZA" },
                    { value: "SÃO LUIZ", label: "SÃO LUIZ" },
                    { value: "UIRAMUTÃ", label: "UIRAMUTÃ" },
               ]
               break;

          case "SANTA CATARINA":
               CITIES = [
                    { value: "ABDON BATISTA", label: "ABDON BATISTA" },
                    { value: "ABELARDO LUZ", label: "ABELARDO LUZ" },
                    { value: "AGROLÂNDIA", label: "AGROLÂNDIA" },
                    { value: "AGRONÔMICA", label: "AGRONÔMICA" },
                    { value: "ÁGUA DOCE", label: "ÁGUA DOCE" },
                    { value: "ÁGUAS DE CHAPECÓ", label: "ÁGUAS DE CHAPECÓ" },
                    { value: "ÁGUAS FRIAS", label: "ÁGUAS FRIAS" },
                    { value: "ÁGUAS MORNAS", label: "ÁGUAS MORNAS" },
                    { value: "ALFREDO WAGNER", label: "ALFREDO WAGNER" },
                    { value: "ALTO BELA VISTA", label: "ALTO BELA VISTA" },
                    { value: "ANCHIETA", label: "ANCHIETA" },
                    { value: "ANGELINA", label: "ANGELINA" },
                    { value: "ANITA GARIBALDI", label: "ANITA GARIBALDI" },
                    { value: "ANITÁPOLIS", label: "ANITÁPOLIS" },
                    { value: "ANTÔNIO CARLOS", label: "ANTÔNIO CARLOS" },
                    { value: "APIÚNA", label: "APIÚNA" },
                    { value: "ARABUTÃ", label: "ARABUTÃ" },
                    { value: "ARAQUARI", label: "ARAQUARI" },
                    { value: "ARARANGUÁ", label: "ARARANGUÁ" },
                    { value: "ARMAZÉM", label: "ARMAZÉM" },
                    { value: "ARROIO TRINTA", label: "ARROIO TRINTA" },
                    { value: "ARVOREDO", label: "ARVOREDO" },
                    { value: "ASCURRA", label: "ASCURRA" },
                    { value: "ATALANTA", label: "ATALANTA" },
                    { value: "AURORA", label: "AURORA" },
                    { value: "BALNEÁRIO ARROIO DO SILVA", label: "BALNEÁRIO ARROIO DO SILVA" },
                    { value: "BALNEÁRIO BARRA DO SUL", label: "BALNEÁRIO BARRA DO SUL" },
                    { value: "BALNEÁRIO CAMBORIÚ", label: "BALNEÁRIO CAMBORIÚ" },
                    { value: "BALNEÁRIO GAIVOTA", label: "BALNEÁRIO GAIVOTA" },
                    { value: "BALNEÁRIO PIÇARRAS", label: "BALNEÁRIO PIÇARRAS" },
                    { value: "BALNEÁRIO RINCÃO", label: "BALNEÁRIO RINCÃO" },
                    { value: "BANDEIRANTE", label: "BANDEIRANTE" },
                    { value: "BARRA BONITA", label: "BARRA BONITA" },
                    { value: "BARRA VELHA", label: "BARRA VELHA" },
                    { value: "BELA VISTA DO TOLDO", label: "BELA VISTA DO TOLDO" },
                    { value: "BELMONTE", label: "BELMONTE" },
                    { value: "BENEDITO NOVO", label: "BENEDITO NOVO" },
                    { value: "BIGUAÇU", label: "BIGUAÇU" },
                    { value: "BLUMENAU", label: "BLUMENAU" },
                    { value: "BOCAINA DO SUL", label: "BOCAINA DO SUL" },
                    { value: "BOM JARDIM DA SERRA", label: "BOM JARDIM DA SERRA" },
                    { value: "BOM JESUS", label: "BOM JESUS" },
                    { value: "BOM JESUS DO OESTE", label: "BOM JESUS DO OESTE" },
                    { value: "BOM RETIRO", label: "BOM RETIRO" },
                    { value: "BOMBINHAS", label: "BOMBINHAS" },
                    { value: "BOTUVERÁ", label: "BOTUVERÁ" },
                    { value: "BRAÇO DO NORTE", label: "BRAÇO DO NORTE" },
                    { value: "BRAÇO DO TROMBUDO", label: "BRAÇO DO TROMBUDO" },
                    { value: "BRUNÓPOLIS", label: "BRUNÓPOLIS" },
                    { value: "BRUSQUE", label: "BRUSQUE" },
                    { value: "CAÇADOR", label: "CAÇADOR" },
                    { value: "CAIBI", label: "CAIBI" },
                    { value: "CALMON", label: "CALMON" },
                    { value: "CAMBORIÚ", label: "CAMBORIÚ" },
                    { value: "CAMPO ALEGRE", label: "CAMPO ALEGRE" },
                    { value: "CAMPO BELO DO SUL", label: "CAMPO BELO DO SUL" },
                    { value: "CAMPO ERÊ", label: "CAMPO ERÊ" },
                    { value: "CAMPOS NOVOS", label: "CAMPOS NOVOS" },
                    { value: "CANELINHA", label: "CANELINHA" },
                    { value: "CANOINHAS", label: "CANOINHAS" },
                    { value: "CAPÃO ALTO", label: "CAPÃO ALTO" },
                    { value: "CAPINZAL", label: "CAPINZAL" },
                    { value: "CAPIVARI DE BAIXO", label: "CAPIVARI DE BAIXO" },
                    { value: "CATANDUVAS", label: "CATANDUVAS" },
                    { value: "CAXAMBU DO SUL", label: "CAXAMBU DO SUL" },
                    { value: "CELSO RAMOS", label: "CELSO RAMOS" },
                    { value: "CERRO NEGRO", label: "CERRO NEGRO" },
                    { value: "CHAPADÃO DO LAGEADO", label: "CHAPADÃO DO LAGEADO" },
                    { value: "CHAPECÓ", label: "CHAPECÓ" },
                    { value: "COCAL DO SUL", label: "COCAL DO SUL" },
                    { value: "CONCÓRDIA", label: "CONCÓRDIA" },
                    { value: "CORDILHEIRA ALTA", label: "CORDILHEIRA ALTA" },
                    { value: "CORONEL FREITAS", label: "CORONEL FREITAS" },
                    { value: "CORONEL MARTINS", label: "CORONEL MARTINS" },
                    { value: "CORREIA PINTO", label: "CORREIA PINTO" },
                    { value: "CORUPÁ", label: "CORUPÁ" },
                    { value: "CRICIÚMA", label: "CRICIÚMA" },
                    { value: "CUNHA PORÃ", label: "CUNHA PORÃ" },
                    { value: "CUNHATAÍ", label: "CUNHATAÍ" },
                    { value: "CURITIBANOS", label: "CURITIBANOS" },
                    { value: "DESCANSO", label: "DESCANSO" },
                    { value: "DIONÍSIO CERQUEIRA", label: "DIONÍSIO CERQUEIRA" },
                    { value: "DONA EMMA", label: "DONA EMMA" },
                    { value: "DOUTOR PEDRINHO", label: "DOUTOR PEDRINHO" },
                    { value: "ENTRE RIOS", label: "ENTRE RIOS" },
                    { value: "ERMO", label: "ERMO" },
                    { value: "ERVAL VELHO", label: "ERVAL VELHO" },
                    { value: "FAXINAL DOS GUEDES", label: "FAXINAL DOS GUEDES" },
                    { value: "FLOR DO SERTÃO", label: "FLOR DO SERTÃO" },
                    { value: "FLORIANÓPOLIS", label: "FLORIANÓPOLIS" },
                    { value: "FORMOSA DO SUL", label: "FORMOSA DO SUL" },
                    { value: "FORQUILHINHA", label: "FORQUILHINHA" },
                    { value: "FRAIBURGO", label: "FRAIBURGO" },
                    { value: "FREI ROGÉRIO", label: "FREI ROGÉRIO" },
                    { value: "GALVÃO", label: "GALVÃO" },
                    { value: "GAROPABA", label: "GAROPABA" },
                    { value: "GARUVA", label: "GARUVA" },
                    { value: "GASPAR", label: "GASPAR" },
                    { value: "GOVERNADOR CELSO RAMOS", label: "GOVERNADOR CELSO RAMOS" },
                    { value: "GRÃO PARÁ", label: "GRÃO PARÁ" },
                    { value: "GRAVATAL", label: "GRAVATAL" },
                    { value: "GUABIRUBA", label: "GUABIRUBA" },
                    { value: "GUARACIABA", label: "GUARACIABA" },
                    { value: "GUARAMIRIM", label: "GUARAMIRIM" },
                    { value: "GUARUJÁ DO SUL", label: "GUARUJÁ DO SUL" },
                    { value: "GUATAMBÚ", label: "GUATAMBÚ" },
                    { value: "HERVAL D'OESTE", label: "HERVAL D'OESTE" },
                    { value: "IBIAM", label: "IBIAM" },
                    { value: "IBICARÉ", label: "IBICARÉ" },
                    { value: "IBIRAMA", label: "IBIRAMA" },
                    { value: "IÇARA", label: "IÇARA" },
                    { value: "ILHOTA", label: "ILHOTA" },
                    { value: "IMARUÍ", label: "IMARUÍ" },
                    { value: "IMBITUBA", label: "IMBITUBA" },
                    { value: "IMBUIA", label: "IMBUIA" },
                    { value: "INDAIAL", label: "INDAIAL" },
                    { value: "IOMERÊ", label: "IOMERÊ" },
                    { value: "IPIRA", label: "IPIRA" },
                    { value: "IPORÃ DO OESTE", label: "IPORÃ DO OESTE" },
                    { value: "IPUAÇU", label: "IPUAÇU" },
                    { value: "IPUMIRIM", label: "IPUMIRIM" },
                    { value: "IRACEMINHA", label: "IRACEMINHA" },
                    { value: "IRANI", label: "IRANI" },
                    { value: "IRATI", label: "IRATI" },
                    { value: "IRINEÓPOLIS", label: "IRINEÓPOLIS" },
                    { value: "ITÁ", label: "ITÁ" },
                    { value: "ITAIÓPOLIS", label: "ITAIÓPOLIS" },
                    { value: "ITAJAÍ", label: "ITAJAÍ" },
                    { value: "ITAPEMA", label: "ITAPEMA" },
                    { value: "ITAPIRANGA", label: "ITAPIRANGA" },
                    { value: "ITAPOÁ", label: "ITAPOÁ" },
                    { value: "ITUPORANGA", label: "ITUPORANGA" },
                    { value: "JABORÁ", label: "JABORÁ" },
                    { value: "JACINTO MACHADO", label: "JACINTO MACHADO" },
                    { value: "JAGUARUNA", label: "JAGUARUNA" },
                    { value: "JARAGUÁ DO SUL", label: "JARAGUÁ DO SUL" },
                    { value: "JARDINÓPOLIS", label: "JARDINÓPOLIS" },
                    { value: "JOAÇABA", label: "JOAÇABA" },
                    { value: "JOINVILLE", label: "JOINVILLE" },
                    { value: "JOSÉ BOITEUX", label: "JOSÉ BOITEUX" },
                    { value: "JUPIÁ", label: "JUPIÁ" },
                    { value: "LACERDÓPOLIS", label: "LACERDÓPOLIS" },
                    { value: "LAGES", label: "LAGES" },
                    { value: "LAGUNA", label: "LAGUNA" },
                    { value: "LAJEADO GRANDE", label: "LAJEADO GRANDE" },
                    { value: "LAURENTINO", label: "LAURENTINO" },
                    { value: "LAURO MULLER", label: "LAURO MULLER" },
                    { value: "LEBON RÉGIS", label: "LEBON RÉGIS" },
                    { value: "LEOBERTO LEAL", label: "LEOBERTO LEAL" },
                    { value: "LINDÓIA DO SUL", label: "LINDÓIA DO SUL" },
                    { value: "LONTRAS", label: "LONTRAS" },
                    { value: "LUIZ ALVES", label: "LUIZ ALVES" },
                    { value: "LUZERNA", label: "LUZERNA" },
                    { value: "MACIEIRA", label: "MACIEIRA" },
                    { value: "MAFRA", label: "MAFRA" },
                    { value: "MAJOR GERCINO", label: "MAJOR GERCINO" },
                    { value: "MAJOR VIEIRA", label: "MAJOR VIEIRA" },
                    { value: "MARACAJÁ", label: "MARACAJÁ" },
                    { value: "MARAVILHA", label: "MARAVILHA" },
                    { value: "MAREMA", label: "MAREMA" },
                    { value: "MASSARANDUBA", label: "MASSARANDUBA" },
                    { value: "MATOS COSTA", label: "MATOS COSTA" },
                    { value: "MELEIRO", label: "MELEIRO" },
                    { value: "MIRIM DOCE", label: "MIRIM DOCE" },
                    { value: "MODELO", label: "MODELO" },
                    { value: "MONDAÍ", label: "MONDAÍ" },
                    { value: "MONTE CARLO", label: "MONTE CARLO" },
                    { value: "MONTE CASTELO", label: "MONTE CASTELO" },
                    { value: "MORRO DA FUMAÇA", label: "MORRO DA FUMAÇA" },
                    { value: "MORRO GRANDE", label: "MORRO GRANDE" },
                    { value: "NAVEGANTES", label: "NAVEGANTES" },
                    { value: "NOVA ERECHIM", label: "NOVA ERECHIM" },
                    { value: "NOVA ITABERABA", label: "NOVA ITABERABA" },
                    { value: "NOVA TRENTO", label: "NOVA TRENTO" },
                    { value: "NOVA VENEZA", label: "NOVA VENEZA" },
                    { value: "NOVO HORIZONTE", label: "NOVO HORIZONTE" },
                    { value: "ORLEANS", label: "ORLEANS" },
                    { value: "OTACÍLIO COSTA", label: "OTACÍLIO COSTA" },
                    { value: "OURO", label: "OURO" },
                    { value: "OURO VERDE", label: "OURO VERDE" },
                    { value: "PAIAL", label: "PAIAL" },
                    { value: "PAINEL", label: "PAINEL" },
                    { value: "PALHOÇA", label: "PALHOÇA" },
                    { value: "PALMA SOLA", label: "PALMA SOLA" },
                    { value: "PALMEIRA", label: "PALMEIRA" },
                    { value: "PALMITOS", label: "PALMITOS" },
                    { value: "PAPANDUVA", label: "PAPANDUVA" },
                    { value: "PARAÍSO", label: "PARAÍSO" },
                    { value: "PASSO DE TORRES", label: "PASSO DE TORRES" },
                    { value: "PASSOS MAIA", label: "PASSOS MAIA" },
                    { value: "PAULO LOPES", label: "PAULO LOPES" },
                    { value: "PEDRAS GRANDES", label: "PEDRAS GRANDES" },
                    { value: "PENHA", label: "PENHA" },
                    { value: "PERITIBA", label: "PERITIBA" },
                    { value: "PESCARIA BRAVA", label: "PESCARIA BRAVA" },
                    { value: "PETROLÂNDIA", label: "PETROLÂNDIA" },
                    { value: "PINHALZINHO", label: "PINHALZINHO" },
                    { value: "PINHEIRO PRETO", label: "PINHEIRO PRETO" },
                    { value: "PIRATUBA", label: "PIRATUBA" },
                    { value: "PLANALTO ALEGRE", label: "PLANALTO ALEGRE" },
                    { value: "POMERODE", label: "POMERODE" },
                    { value: "PONTE ALTA", label: "PONTE ALTA" },
                    { value: "PONTE ALTA DO NORTE", label: "PONTE ALTA DO NORTE" },
                    { value: "PONTE SERRADA", label: "PONTE SERRADA" },
                    { value: "PORTO BELO", label: "PORTO BELO" },
                    { value: "PORTO UNIÃO", label: "PORTO UNIÃO" },
                    { value: "POUSO REDONDO", label: "POUSO REDONDO" },
                    { value: "PRAIA GRANDE", label: "PRAIA GRANDE" },
                    { value: "PRESIDENTE CASTELLO BRANCO", label: "PRESIDENTE CASTELLO BRANCO" },
                    { value: "PRESIDENTE GETÚLIO", label: "PRESIDENTE GETÚLIO" },
                    { value: "PRESIDENTE NEREU", label: "PRESIDENTE NEREU" },
                    { value: "PRINCESA", label: "PRINCESA" },
                    { value: "QUILOMBO", label: "QUILOMBO" },
                    { value: "RANCHO QUEIMADO", label: "RANCHO QUEIMADO" },
                    { value: "RIO DAS ANTAS", label: "RIO DAS ANTAS" },
                    { value: "RIO DO CAMPO", label: "RIO DO CAMPO" },
                    { value: "RIO DO OESTE", label: "RIO DO OESTE" },
                    { value: "RIO DO SUL", label: "RIO DO SUL" },
                    { value: "RIO DOS CEDROS", label: "RIO DOS CEDROS" },
                    { value: "RIO FORTUNA", label: "RIO FORTUNA" },
                    { value: "RIO NEGRINHO", label: "RIO NEGRINHO" },
                    { value: "RIO RUFINO", label: "RIO RUFINO" },
                    { value: "RIQUEZA", label: "RIQUEZA" },
                    { value: "RODEIO", label: "RODEIO" },
                    { value: "ROMELÂNDIA", label: "ROMELÂNDIA" },
                    { value: "SALETE", label: "SALETE" },
                    { value: "SALTINHO", label: "SALTINHO" },
                    { value: "SALTO VELOSO", label: "SALTO VELOSO" },
                    { value: "SANGÃO", label: "SANGÃO" },
                    { value: "SANTA CECÍLIA", label: "SANTA CECÍLIA" },
                    { value: "SANTA HELENA", label: "SANTA HELENA" },
                    { value: "SANTA ROSA DE LIMA", label: "SANTA ROSA DE LIMA" },
                    { value: "SANTA ROSA DO SUL", label: "SANTA ROSA DO SUL" },
                    { value: "SANTA TEREZINHA", label: "SANTA TEREZINHA" },
                    { value: "SANTA TEREZINHA DO PROGRESSO", label: "SANTA TEREZINHA DO PROGRESSO" },
                    { value: "SANTIAGO DO SUL", label: "SANTIAGO DO SUL" },
                    { value: "SANTO AMARO DA IMPERATRIZ", label: "SANTO AMARO DA IMPERATRIZ" },
                    { value: "SÃO BENTO DO SUL", label: "SÃO BENTO DO SUL" },
                    { value: "SÃO BERNARDINO", label: "SÃO BERNARDINO" },
                    { value: "SÃO BONIFÁCIO", label: "SÃO BONIFÁCIO" },
                    { value: "SÃO CARLOS", label: "SÃO CARLOS" },
                    { value: "SÃO CRISTOVÃO DO SUL", label: "SÃO CRISTOVÃO DO SUL" },
                    { value: "SÃO DOMINGOS", label: "SÃO DOMINGOS" },
                    { value: "SÃO FRANCISCO DO SUL", label: "SÃO FRANCISCO DO SUL" },
                    { value: "SÃO JOÃO BATISTA", label: "SÃO JOÃO BATISTA" },
                    { value: "SÃO JOÃO DO ITAPERIÚ", label: "SÃO JOÃO DO ITAPERIÚ" },
                    { value: "SÃO JOÃO DO OESTE", label: "SÃO JOÃO DO OESTE" },
                    { value: "SÃO JOÃO DO SUL", label: "SÃO JOÃO DO SUL" },
                    { value: "SÃO JOAQUIM", label: "SÃO JOAQUIM" },
                    { value: "SÃO JOSÉ", label: "SÃO JOSÉ" },
                    { value: "SÃO JOSÉ DO CEDRO", label: "SÃO JOSÉ DO CEDRO" },
                    { value: "SÃO JOSÉ DO CERRITO", label: "SÃO JOSÉ DO CERRITO" },
                    { value: "SÃO LOURENÇO DO OESTE", label: "SÃO LOURENÇO DO OESTE" },
                    { value: "SÃO LUDGERO", label: "SÃO LUDGERO" },
                    { value: "SÃO MARTINHO", label: "SÃO MARTINHO" },
                    { value: "SÃO MIGUEL DA BOA VISTA", label: "SÃO MIGUEL DA BOA VISTA" },
                    { value: "SÃO MIGUEL DO OESTE", label: "SÃO MIGUEL DO OESTE" },
                    { value: "SÃO PEDRO DE ALCÂNTARA", label: "SÃO PEDRO DE ALCÂNTARA" },
                    { value: "SAUDADES", label: "SAUDADES" },
                    { value: "SCHROEDER", label: "SCHROEDER" },
                    { value: "SEARA", label: "SEARA" },
                    { value: "SERRA ALTA", label: "SERRA ALTA" },
                    { value: "SIDERÓPOLIS", label: "SIDERÓPOLIS" },
                    { value: "SOMBRIO", label: "SOMBRIO" },
                    { value: "SUL BRASIL", label: "SUL BRASIL" },
                    { value: "TAIÓ", label: "TAIÓ" },
                    { value: "TANGARÁ", label: "TANGARÁ" },
                    { value: "TIGRINHOS", label: "TIGRINHOS" },
                    { value: "TIJUCAS", label: "TIJUCAS" },
                    { value: "TIMBÉ DO SUL", label: "TIMBÉ DO SUL" },
                    { value: "TIMBÓ", label: "TIMBÓ" },
                    { value: "TIMBÓ GRANDE", label: "TIMBÓ GRANDE" },
                    { value: "TRÊS BARRAS", label: "TRÊS BARRAS" },
                    { value: "TREVISO", label: "TREVISO" },
                    { value: "TREZE DE MAIO", label: "TREZE DE MAIO" },
                    { value: "TREZE TÍLIAS", label: "TREZE TÍLIAS" },
                    { value: "TROMBUDO CENTRAL", label: "TROMBUDO CENTRAL" },
                    { value: "TUBARÃO", label: "TUBARÃO" },
                    { value: "TUNÁPOLIS", label: "TUNÁPOLIS" },
                    { value: "TURVO", label: "TURVO" },
                    { value: "UNIÃO DO OESTE", label: "UNIÃO DO OESTE" },
                    { value: "URUBICI", label: "URUBICI" },
                    { value: "URUPEMA", label: "URUPEMA" },
                    { value: "URUSSANGA", label: "URUSSANGA" },
                    { value: "VARGEÃO", label: "VARGEÃO" },
                    { value: "VARGEM", label: "VARGEM" },
                    { value: "VARGEM BONITA", label: "VARGEM BONITA" },
                    { value: "VIDAL RAMOS", label: "VIDAL RAMOS" },
                    { value: "VIDEIRA", label: "VIDEIRA" },
                    { value: "VITOR MEIRELES", label: "VITOR MEIRELES" },
                    { value: "WITMARSUM", label: "WITMARSUM" },
                    { value: "XANXERÊ", label: "XANXERÊ" },
                    { value: "XAVANTINA", label: "XAVANTINA" },
                    { value: "XAXIM", label: "XAXIM" },
                    { value: "ZORTÉA", label: "ZORTÉA" },
               ]
               break;

          case "SÃO PAULO":
               CITIES = [
                    { value: "ADAMANTINA", label: "ADAMANTINA" },
                    { value: "ADOLFO", label: "ADOLFO" },
                    { value: "AGUAÍ", label: "AGUAÍ" },
                    { value: "ÁGUAS DA PRATA", label: "ÁGUAS DA PRATA" },
                    { value: "ÁGUAS DE LINDÓIA", label: "ÁGUAS DE LINDÓIA" },
                    { value: "ÁGUAS DE SANTA BÁRBARA", label: "ÁGUAS DE SANTA BÁRBARA" },
                    { value: "ÁGUAS DE SÃO PEDRO", label: "ÁGUAS DE SÃO PEDRO" },
                    { value: "AGUDOS", label: "AGUDOS" },
                    { value: "ALAMBARI", label: "ALAMBARI" },
                    { value: "ALFREDO MARCONDES", label: "ALFREDO MARCONDES" },
                    { value: "ALTAIR", label: "ALTAIR" },
                    { value: "ALTINÓPOLIS", label: "ALTINÓPOLIS" },
                    { value: "ALTO ALEGRE", label: "ALTO ALEGRE" },
                    { value: "ALUMÍNIO", label: "ALUMÍNIO" },
                    { value: "ÁLVARES FLORENCE", label: "ÁLVARES FLORENCE" },
                    { value: "ÁLVARES MACHADO", label: "ÁLVARES MACHADO" },
                    { value: "ÁLVARO DE CARVALHO", label: "ÁLVARO DE CARVALHO" },
                    { value: "ALVINLÂNDIA", label: "ALVINLÂNDIA" },
                    { value: "AMERICANA", label: "AMERICANA" },
                    { value: "AMÉRICO BRASILIENSE", label: "AMÉRICO BRASILIENSE" },
                    { value: "AMÉRICO DE CAMPOS", label: "AMÉRICO DE CAMPOS" },
                    { value: "AMPARO", label: "AMPARO" },
                    { value: "ANALÂNDIA", label: "ANALÂNDIA" },
                    { value: "ANDRADINA", label: "ANDRADINA" },
                    { value: "ANGATUBA", label: "ANGATUBA" },
                    { value: "ANHEMBI", label: "ANHEMBI" },
                    { value: "ANHUMAS", label: "ANHUMAS" },
                    { value: "APARECIDA", label: "APARECIDA" },
                    { value: "APARECIDA D'OESTE", label: "APARECIDA D'OESTE" },
                    { value: "APIAÍ", label: "APIAÍ" },
                    { value: "ARAÇARIGUAMA", label: "ARAÇARIGUAMA" },
                    { value: "ARAÇATUBA", label: "ARAÇATUBA" },
                    { value: "ARAÇOIABA DA SERRA", label: "ARAÇOIABA DA SERRA" },
                    { value: "ARAMINA", label: "ARAMINA" },
                    { value: "ARANDU", label: "ARANDU" },
                    { value: "ARAPEÍ", label: "ARAPEÍ" },
                    { value: "ARARAQUARA", label: "ARARAQUARA" },
                    { value: "ARARAS", label: "ARARAS" },
                    { value: "ARCO-ÍRIS", label: "ARCO-ÍRIS" },
                    { value: "AREALVA", label: "AREALVA" },
                    { value: "AREIAS", label: "AREIAS" },
                    { value: "AREIÓPOLIS", label: "AREIÓPOLIS" },
                    { value: "ARIRANHA", label: "ARIRANHA" },
                    { value: "ARTUR NOGUEIRA", label: "ARTUR NOGUEIRA" },
                    { value: "ARUJÁ", label: "ARUJÁ" },
                    { value: "ASPÁSIA", label: "ASPÁSIA" },
                    { value: "ASSIS", label: "ASSIS" },
                    { value: "ATIBAIA", label: "ATIBAIA" },
                    { value: "AURIFLAMA", label: "AURIFLAMA" },
                    { value: "AVAÍ", label: "AVAÍ" },
                    { value: "AVANHANDAVA", label: "AVANHANDAVA" },
                    { value: "AVARÉ", label: "AVARÉ" },
                    { value: "BADY BASSITT", label: "BADY BASSITT" },
                    { value: "BALBINOS", label: "BALBINOS" },
                    { value: "BÁLSAMO", label: "BÁLSAMO" },
                    { value: "BANANAL", label: "BANANAL" },
                    { value: "BARÃO DE ANTONINA", label: "BARÃO DE ANTONINA" },
                    { value: "BARBOSA", label: "BARBOSA" },
                    { value: "BARIRI", label: "BARIRI" },
                    { value: "BARRA BONITA", label: "BARRA BONITA" },
                    { value: "BARRA DO CHAPÉU", label: "BARRA DO CHAPÉU" },
                    { value: "BARRA DO TURVO", label: "BARRA DO TURVO" },
                    { value: "BARRETOS", label: "BARRETOS" },
                    { value: "BARRINHA", label: "BARRINHA" },
                    { value: "BARUERI", label: "BARUERI" },
                    { value: "BASTOS", label: "BASTOS" },
                    { value: "BATATAIS", label: "BATATAIS" },
                    { value: "BAURU", label: "BAURU" },
                    { value: "BEBEDOURO", label: "BEBEDOURO" },
                    { value: "BENTO DE ABREU", label: "BENTO DE ABREU" },
                    { value: "BERNARDINO DE CAMPOS", label: "BERNARDINO DE CAMPOS" },
                    { value: "BERTIOGA", label: "BERTIOGA" },
                    { value: "BILAC", label: "BILAC" },
                    { value: "BIRIGUI", label: "BIRIGUI" },
                    { value: "BIRITIBA-MIRIM", label: "BIRITIBA-MIRIM" },
                    { value: "BOA ESPERANÇA DO SUL", label: "BOA ESPERANÇA DO SUL" },
                    { value: "BOCAINA", label: "BOCAINA" },
                    { value: "BOFETE", label: "BOFETE" },
                    { value: "BOITUVA", label: "BOITUVA" },
                    { value: "BOM JESUS DOS PERDÕES", label: "BOM JESUS DOS PERDÕES" },
                    { value: "BOM SUCESSO DE ITARARÉ", label: "BOM SUCESSO DE ITARARÉ" },
                    { value: "BORÁ", label: "BORÁ" },
                    { value: "BORACÉIA", label: "BORACÉIA" },
                    { value: "BORBOREMA", label: "BORBOREMA" },
                    { value: "BOREBI", label: "BOREBI" },
                    { value: "BOTUCATU", label: "BOTUCATU" },
                    { value: "BRAGANÇA PAULISTA", label: "BRAGANÇA PAULISTA" },
                    { value: "BRAÚNA", label: "BRAÚNA" },
                    { value: "BREJO ALEGRE", label: "BREJO ALEGRE" },
                    { value: "BRODOWSKI", label: "BRODOWSKI" },
                    { value: "BROTAS", label: "BROTAS" },
                    { value: "BURI", label: "BURI" },
                    { value: "BURITAMA", label: "BURITAMA" },
                    { value: "BURITIZAL", label: "BURITIZAL" },
                    { value: "CABRÁLIA PAULISTA", label: "CABRÁLIA PAULISTA" },
                    { value: "CABREÚVA", label: "CABREÚVA" },
                    { value: "CAÇAPAVA", label: "CAÇAPAVA" },
                    { value: "CACHOEIRA PAULISTA", label: "CACHOEIRA PAULISTA" },
                    { value: "CACONDE", label: "CACONDE" },
                    { value: "CAFELÂNDIA", label: "CAFELÂNDIA" },
                    { value: "CAIABU", label: "CAIABU" },
                    { value: "CAIEIRAS", label: "CAIEIRAS" },
                    { value: "CAIUÁ", label: "CAIUÁ" },
                    { value: "CAJAMAR", label: "CAJAMAR" },
                    { value: "CAJATI", label: "CAJATI" },
                    { value: "CAJOBI", label: "CAJOBI" },
                    { value: "CAJURU", label: "CAJURU" },
                    { value: "CAMPINA DO MONTE ALEGRE", label: "CAMPINA DO MONTE ALEGRE" },
                    { value: "CAMPINAS", label: "CAMPINAS" },
                    { value: "CAMPO LIMPO PAULISTA", label: "CAMPO LIMPO PAULISTA" },
                    { value: "CAMPOS DO JORDÃO", label: "CAMPOS DO JORDÃO" },
                    { value: "CAMPOS NOVOS PAULISTA", label: "CAMPOS NOVOS PAULISTA" },
                    { value: "CANANÉIA", label: "CANANÉIA" },
                    { value: "CANAS", label: "CANAS" },
                    { value: "CÂNDIDO MOTA", label: "CÂNDIDO MOTA" },
                    { value: "CÂNDIDO RODRIGUES", label: "CÂNDIDO RODRIGUES" },
                    { value: "CANITAR", label: "CANITAR" },
                    { value: "CAPÃO BONITO", label: "CAPÃO BONITO" },
                    { value: "CAPELA DO ALTO", label: "CAPELA DO ALTO" },
                    { value: "CAPIVARI", label: "CAPIVARI" },
                    { value: "CARAGUATATUBA", label: "CARAGUATATUBA" },
                    { value: "CARAPICUÍBA", label: "CARAPICUÍBA" },
                    { value: "CARDOSO", label: "CARDOSO" },
                    { value: "CASA BRANCA", label: "CASA BRANCA" },
                    { value: "CÁSSIA DOS COQUEIROS", label: "CÁSSIA DOS COQUEIROS" },
                    { value: "CASTILHO", label: "CASTILHO" },
                    { value: "CATANDUVA", label: "CATANDUVA" },
                    { value: "CATIGUÁ", label: "CATIGUÁ" },
                    { value: "CEDRAL", label: "CEDRAL" },
                    { value: "CERQUEIRA CÉSAR", label: "CERQUEIRA CÉSAR" },
                    { value: "CERQUILHO", label: "CERQUILHO" },
                    { value: "CESÁRIO LANGE", label: "CESÁRIO LANGE" },
                    { value: "CHARQUEADA", label: "CHARQUEADA" },
                    { value: "CHAVANTES", label: "CHAVANTES" },
                    { value: "CLEMENTINA", label: "CLEMENTINA" },
                    { value: "COLINA", label: "COLINA" },
                    { value: "COLÔMBIA", label: "COLÔMBIA" },
                    { value: "CONCHAL", label: "CONCHAL" },
                    { value: "CONCHAS", label: "CONCHAS" },
                    { value: "CORDEIRÓPOLIS", label: "CORDEIRÓPOLIS" },
                    { value: "COROADOS", label: "COROADOS" },
                    { value: "CORONEL MACEDO", label: "CORONEL MACEDO" },
                    { value: "CORUMBATAÍ", label: "CORUMBATAÍ" },
                    { value: "COSMÓPOLIS", label: "COSMÓPOLIS" },
                    { value: "COSMORAMA", label: "COSMORAMA" },
                    { value: "COTIA", label: "COTIA" },
                    { value: "CRAVINHOS", label: "CRAVINHOS" },
                    { value: "CRISTAIS PAULISTA", label: "CRISTAIS PAULISTA" },
                    { value: "CRUZÁLIA", label: "CRUZÁLIA" },
                    { value: "CRUZEIRO", label: "CRUZEIRO" },
                    { value: "CUBATÃO", label: "CUBATÃO" },
                    { value: "CUNHA", label: "CUNHA" },
                    { value: "DESCALVADO", label: "DESCALVADO" },
                    { value: "DIADEMA", label: "DIADEMA" },
                    { value: "DIRCE REIS", label: "DIRCE REIS" },
                    { value: "DIVINOLÂNDIA", label: "DIVINOLÂNDIA" },
                    { value: "DOBRADA", label: "DOBRADA" },
                    { value: "DOIS CÓRREGOS", label: "DOIS CÓRREGOS" },
                    { value: "DOLCINÓPOLIS", label: "DOLCINÓPOLIS" },
                    { value: "DOURADO", label: "DOURADO" },
                    { value: "DRACENA", label: "DRACENA" },
                    { value: "DUARTINA", label: "DUARTINA" },
                    { value: "DUMONT", label: "DUMONT" },
                    { value: "ECHAPORÃ", label: "ECHAPORÃ" },
                    { value: "ELDORADO", label: "ELDORADO" },
                    { value: "ELIAS FAUSTO", label: "ELIAS FAUSTO" },
                    { value: "ELISIÁRIO", label: "ELISIÁRIO" },
                    { value: "EMBAÚBA", label: "EMBAÚBA" },
                    { value: "EMBU DAS ARTES", label: "EMBU DAS ARTES" },
                    { value: "EMBU-GUAÇU", label: "EMBU-GUAÇU" },
                    { value: "EMILIANÓPOLIS", label: "EMILIANÓPOLIS" },
                    { value: "ENGENHEIRO COELHO", label: "ENGENHEIRO COELHO" },
                    { value: "ESPÍRITO SANTO DO PINHAL", label: "ESPÍRITO SANTO DO PINHAL" },
                    { value: "ESPÍRITO SANTO DO TURVO", label: "ESPÍRITO SANTO DO TURVO" },
                    { value: "ESTIVA GERBI", label: "ESTIVA GERBI" },
                    { value: "ESTRELA D'OESTE", label: "ESTRELA D'OESTE" },
                    { value: "ESTRELA DO NORTE", label: "ESTRELA DO NORTE" },
                    { value: "EUCLIDES DA CUNHA PAULISTA", label: "EUCLIDES DA CUNHA PAULISTA" },
                    { value: "FARTURA", label: "FARTURA" },
                    { value: "FERNANDO PRESTES", label: "FERNANDO PRESTES" },
                    { value: "FERNANDÓPOLIS", label: "FERNANDÓPOLIS" },
                    { value: "FERNÃO", label: "FERNÃO" },
                    { value: "FERRAZ DE VASCONCELOS", label: "FERRAZ DE VASCONCELOS" },
                    { value: "FLORA RICA", label: "FLORA RICA" },
                    { value: "FLOREAL", label: "FLOREAL" },
                    { value: "FLÓRIDA PAULISTA", label: "FLÓRIDA PAULISTA" },
                    { value: "FLORÍNIA", label: "FLORÍNIA" },
                    { value: "FRANCA", label: "FRANCA" },
                    { value: "FRANCISCO MORATO", label: "FRANCISCO MORATO" },
                    { value: "FRANCO DA ROCHA", label: "FRANCO DA ROCHA" },
                    { value: "GABRIEL MONTEIRO", label: "GABRIEL MONTEIRO" },
                    { value: "GÁLIA", label: "GÁLIA" },
                    { value: "GARÇA", label: "GARÇA" },
                    { value: "GASTÃO VIDIGAL", label: "GASTÃO VIDIGAL" },
                    { value: "GAVIÃO PEIXOTO", label: "GAVIÃO PEIXOTO" },
                    { value: "GENERAL SALGADO", label: "GENERAL SALGADO" },
                    { value: "GETULINA", label: "GETULINA" },
                    { value: "GLICÉRIO", label: "GLICÉRIO" },
                    { value: "GUAIÇARA", label: "GUAIÇARA" },
                    { value: "GUAIMBÊ", label: "GUAIMBÊ" },
                    { value: "GUAÍRA", label: "GUAÍRA" },
                    { value: "GUAPIAÇU", label: "GUAPIAÇU" },
                    { value: "GUAPIARA", label: "GUAPIARA" },
                    { value: "GUARÁ", label: "GUARÁ" },
                    { value: "GUARAÇAÍ", label: "GUARAÇAÍ" },
                    { value: "GUARACI", label: "GUARACI" },
                    { value: "GUARANI D'OESTE", label: "GUARANI D'OESTE" },
                    { value: "GUARANTÃ", label: "GUARANTÃ" },
                    { value: "GUARARAPES", label: "GUARARAPES" },
                    { value: "GUARAREMA", label: "GUARAREMA" },
                    { value: "GUARATINGUETÁ", label: "GUARATINGUETÁ" },
                    { value: "GUAREÍ", label: "GUAREÍ" },
                    { value: "GUARIBA", label: "GUARIBA" },
                    { value: "GUARUJÁ", label: "GUARUJÁ" },
                    { value: "GUARULHOS", label: "GUARULHOS" },
                    { value: "GUATAPARÁ", label: "GUATAPARÁ" },
                    { value: "GUZOLÂNDIA", label: "GUZOLÂNDIA" },
                    { value: "HERCULÂNDIA", label: "HERCULÂNDIA" },
                    { value: "HOLAMBRA", label: "HOLAMBRA" },
                    { value: "HORTOLÂNDIA", label: "HORTOLÂNDIA" },
                    { value: "IACANGA", label: "IACANGA" },
                    { value: "IACRI", label: "IACRI" },
                    { value: "IARAS", label: "IARAS" },
                    { value: "IBATÉ", label: "IBATÉ" },
                    { value: "IBIRÁ", label: "IBIRÁ" },
                    { value: "IBIRAREMA", label: "IBIRAREMA" },
                    { value: "IBITINGA", label: "IBITINGA" },
                    { value: "IBIÚNA", label: "IBIÚNA" },
                    { value: "ICÉM", label: "ICÉM" },
                    { value: "IEPÊ", label: "IEPÊ" },
                    { value: "IGARAÇU DO TIETÊ", label: "IGARAÇU DO TIETÊ" },
                    { value: "IGARAPAVA", label: "IGARAPAVA" },
                    { value: "IGARATÁ", label: "IGARATÁ" },
                    { value: "IGUAPE", label: "IGUAPE" },
                    { value: "ILHA COMPRIDA", label: "ILHA COMPRIDA" },
                    { value: "ILHA SOLTEIRA", label: "ILHA SOLTEIRA" },
                    { value: "ILHABELA", label: "ILHABELA" },
                    { value: "INDAIATUBA", label: "INDAIATUBA" },
                    { value: "INDIANA", label: "INDIANA" },
                    { value: "INDIAPORÃ", label: "INDIAPORÃ" },
                    { value: "INÚBIA PAULISTA", label: "INÚBIA PAULISTA" },
                    { value: "IPAUSSU", label: "IPAUSSU" },
                    { value: "IPERÓ", label: "IPERÓ" },
                    { value: "IPEÚNA", label: "IPEÚNA" },
                    { value: "IPIGUÁ", label: "IPIGUÁ" },
                    { value: "IPORANGA", label: "IPORANGA" },
                    { value: "IPUÃ", label: "IPUÃ" },
                    { value: "IRACEMÁPOLIS", label: "IRACEMÁPOLIS" },
                    { value: "IRAPUÃ", label: "IRAPUÃ" },
                    { value: "IRAPURU", label: "IRAPURU" },
                    { value: "ITABERÁ", label: "ITABERÁ" },
                    { value: "ITAÍ", label: "ITAÍ" },
                    { value: "ITAJOBI", label: "ITAJOBI" },
                    { value: "ITAJU", label: "ITAJU" },
                    { value: "ITANHAÉM", label: "ITANHAÉM" },
                    { value: "ITAÓCA", label: "ITAÓCA" },
                    { value: "ITAPECERICA DA SERRA", label: "ITAPECERICA DA SERRA" },
                    { value: "ITAPETININGA", label: "ITAPETININGA" },
                    { value: "ITAPEVA", label: "ITAPEVA" },
                    { value: "ITAPEVI", label: "ITAPEVI" },
                    { value: "ITAPIRA", label: "ITAPIRA" },
                    { value: "ITAPIRAPUÃ PAULISTA", label: "ITAPIRAPUÃ PAULISTA" },
                    { value: "ITÁPOLIS", label: "ITÁPOLIS" },
                    { value: "ITAPORANGA", label: "ITAPORANGA" },
                    { value: "ITAPUÍ", label: "ITAPUÍ" },
                    { value: "ITAPURA", label: "ITAPURA" },
                    { value: "ITAQUAQUECETUBA", label: "ITAQUAQUECETUBA" },
                    { value: "ITARARÉ", label: "ITARARÉ" },
                    { value: "ITARIRI", label: "ITARIRI" },
                    { value: "ITATIBA", label: "ITATIBA" },
                    { value: "ITATINGA", label: "ITATINGA" },
                    { value: "ITIRAPINA", label: "ITIRAPINA" },
                    { value: "ITIRAPUÃ", label: "ITIRAPUÃ" },
                    { value: "ITOBI", label: "ITOBI" },
                    { value: "ITU", label: "ITU" },
                    { value: "ITUPEVA", label: "ITUPEVA" },
                    { value: "ITUVERAVA", label: "ITUVERAVA" },
                    { value: "JABORANDI", label: "JABORANDI" },
                    { value: "JABOTICABAL", label: "JABOTICABAL" },
                    { value: "JACAREÍ", label: "JACAREÍ" },
                    { value: "JACI", label: "JACI" },
                    { value: "JACUPIRANGA", label: "JACUPIRANGA" },
                    { value: "JAGUARIÚNA", label: "JAGUARIÚNA" },
                    { value: "JALES", label: "JALES" },
                    { value: "JAMBEIRO", label: "JAMBEIRO" },
                    { value: "JANDIRA", label: "JANDIRA" },
                    { value: "JARDINÓPOLIS", label: "JARDINÓPOLIS" },
                    { value: "JARINU", label: "JARINU" },
                    { value: "JAÚ", label: "JAÚ" },
                    { value: "JERIQUARA", label: "JERIQUARA" },
                    { value: "JOANÓPOLIS", label: "JOANÓPOLIS" },
                    { value: "JOÃO RAMALHO", label: "JOÃO RAMALHO" },
                    { value: "JOSÉ BONIFÁCIO", label: "JOSÉ BONIFÁCIO" },
                    { value: "JÚLIO MESQUITA", label: "JÚLIO MESQUITA" },
                    { value: "JUMIRIM", label: "JUMIRIM" },
                    { value: "JUNDIAÍ", label: "JUNDIAÍ" },
                    { value: "JUNQUEIRÓPOLIS", label: "JUNQUEIRÓPOLIS" },
                    { value: "JUQUIÁ", label: "JUQUIÁ" },
                    { value: "JUQUITIBA", label: "JUQUITIBA" },
                    { value: "LAGOINHA", label: "LAGOINHA" },
                    { value: "LARANJAL PAULISTA", label: "LARANJAL PAULISTA" },
                    { value: "LAVÍNIA", label: "LAVÍNIA" },
                    { value: "LAVRINHAS", label: "LAVRINHAS" },
                    { value: "LEME", label: "LEME" },
                    { value: "LENÇÓIS PAULISTA", label: "LENÇÓIS PAULISTA" },
                    { value: "LIMEIRA", label: "LIMEIRA" },
                    { value: "LINDÓIA", label: "LINDÓIA" },
                    { value: "LINS", label: "LINS" },
                    { value: "LORENA", label: "LORENA" },
                    { value: "LOURDES", label: "LOURDES" },
                    { value: "LOUVEIRA", label: "LOUVEIRA" },
                    { value: "LUCÉLIA", label: "LUCÉLIA" },
                    { value: "LUCIANÓPOLIS", label: "LUCIANÓPOLIS" },
                    { value: "LUÍS ANTÔNIO", label: "LUÍS ANTÔNIO" },
                    { value: "LUIZIÂNIA", label: "LUIZIÂNIA" },
                    { value: "LUPÉRCIO", label: "LUPÉRCIO" },
                    { value: "LUTÉCIA", label: "LUTÉCIA" },
                    { value: "MACATUBA", label: "MACATUBA" },
                    { value: "MACAUBAL", label: "MACAUBAL" },
                    { value: "MACEDÔNIA", label: "MACEDÔNIA" },
                    { value: "MAGDA", label: "MAGDA" },
                    { value: "MAIRINQUE", label: "MAIRINQUE" },
                    { value: "MAIRIPORÃ", label: "MAIRIPORÃ" },
                    { value: "MANDURI", label: "MANDURI" },
                    { value: "MARABÁ PAULISTA", label: "MARABÁ PAULISTA" },
                    { value: "MARACAÍ", label: "MARACAÍ" },
                    { value: "MARAPOAMA", label: "MARAPOAMA" },
                    { value: "MARIÁPOLIS", label: "MARIÁPOLIS" },
                    { value: "MARÍLIA", label: "MARÍLIA" },
                    { value: "MARINÓPOLIS", label: "MARINÓPOLIS" },
                    { value: "MARTINÓPOLIS", label: "MARTINÓPOLIS" },
                    { value: "MATÃO", label: "MATÃO" },
                    { value: "MAUÁ", label: "MAUÁ" },
                    { value: "MENDONÇA", label: "MENDONÇA" },
                    { value: "MERIDIANO", label: "MERIDIANO" },
                    { value: "MESÓPOLIS", label: "MESÓPOLIS" },
                    { value: "MIGUELÓPOLIS", label: "MIGUELÓPOLIS" },
                    { value: "MINEIROS DO TIETÊ", label: "MINEIROS DO TIETÊ" },
                    { value: "MIRA ESTRELA", label: "MIRA ESTRELA" },
                    { value: "MIRACATU", label: "MIRACATU" },
                    { value: "MIRANDÓPOLIS", label: "MIRANDÓPOLIS" },
                    { value: "MIRANTE DO PARANAPANEMA", label: "MIRANTE DO PARANAPANEMA" },
                    { value: "MIRASSOL", label: "MIRASSOL" },
                    { value: "MIRASSOLÂNDIA", label: "MIRASSOLÂNDIA" },
                    { value: "MOCOCA", label: "MOCOCA" },
                    { value: "MOGI DAS CRUZES", label: "MOGI DAS CRUZES" },
                    { value: "MOGI GUAÇU", label: "MOGI GUAÇU" },
                    { value: "MOJI MIRIM", label: "MOJI MIRIM" },
                    { value: "MOMBUCA", label: "MOMBUCA" },
                    { value: "MONÇÕES", label: "MONÇÕES" },
                    { value: "MONGAGUÁ", label: "MONGAGUÁ" },
                    { value: "MONTE ALEGRE DO SUL", label: "MONTE ALEGRE DO SUL" },
                    { value: "MONTE ALTO", label: "MONTE ALTO" },
                    { value: "MONTE APRAZÍVEL", label: "MONTE APRAZÍVEL" },
                    { value: "MONTE AZUL PAULISTA", label: "MONTE AZUL PAULISTA" },
                    { value: "MONTE CASTELO", label: "MONTE CASTELO" },
                    { value: "MONTE MOR", label: "MONTE MOR" },
                    { value: "MONTEIRO LOBATO", label: "MONTEIRO LOBATO" },
                    { value: "MORRO AGUDO", label: "MORRO AGUDO" },
                    { value: "MORUNGABA", label: "MORUNGABA" },
                    { value: "MOTUCA", label: "MOTUCA" },
                    { value: "MURUTINGA DO SUL", label: "MURUTINGA DO SUL" },
                    { value: "NANTES", label: "NANTES" },
                    { value: "NARANDIBA", label: "NARANDIBA" },
                    { value: "NATIVIDADE DA SERRA", label: "NATIVIDADE DA SERRA" },
                    { value: "NAZARÉ PAULISTA", label: "NAZARÉ PAULISTA" },
                    { value: "NEVES PAULISTA", label: "NEVES PAULISTA" },
                    { value: "NHANDEARA", label: "NHANDEARA" },
                    { value: "NIPOÃ", label: "NIPOÃ" },
                    { value: "NOVA ALIANÇA", label: "NOVA ALIANÇA" },
                    { value: "NOVA CAMPINA", label: "NOVA CAMPINA" },
                    { value: "NOVA CANAÃ PAULISTA", label: "NOVA CANAÃ PAULISTA" },
                    { value: "NOVA CASTILHO", label: "NOVA CASTILHO" },
                    { value: "NOVA EUROPA", label: "NOVA EUROPA" },
                    { value: "NOVA GRANADA", label: "NOVA GRANADA" },
                    { value: "NOVA GUATAPORANGA", label: "NOVA GUATAPORANGA" },
                    { value: "NOVA INDEPENDÊNCIA", label: "NOVA INDEPENDÊNCIA" },
                    { value: "NOVA LUZITÂNIA", label: "NOVA LUZITÂNIA" },
                    { value: "NOVA ODESSA", label: "NOVA ODESSA" },
                    { value: "NOVAIS", label: "NOVAIS" },
                    { value: "NOVO HORIZONTE", label: "NOVO HORIZONTE" },
                    { value: "NUPORANGA", label: "NUPORANGA" },
                    { value: "OCAUÇU", label: "OCAUÇU" },
                    { value: "ÓLEO", label: "ÓLEO" },
                    { value: "OLÍMPIA", label: "OLÍMPIA" },
                    { value: "ONDA VERDE", label: "ONDA VERDE" },
                    { value: "ORIENTE", label: "ORIENTE" },
                    { value: "ORINDIÚVA", label: "ORINDIÚVA" },
                    { value: "ORLÂNDIA", label: "ORLÂNDIA" },
                    { value: "OSASCO", label: "OSASCO" },
                    { value: "OSCAR BRESSANE", label: "OSCAR BRESSANE" },
                    { value: "OSVALDO CRUZ", label: "OSVALDO CRUZ" },
                    { value: "OURINHOS", label: "OURINHOS" },
                    { value: "OURO VERDE", label: "OURO VERDE" },
                    { value: "OUROESTE", label: "OUROESTE" },
                    { value: "PACAEMBU", label: "PACAEMBU" },
                    { value: "PALESTINA", label: "PALESTINA" },
                    { value: "PALMARES PAULISTA", label: "PALMARES PAULISTA" },
                    { value: "PALMEIRA D'OESTE", label: "PALMEIRA D'OESTE" },
                    { value: "PALMITAL", label: "PALMITAL" },
                    { value: "PANORAMA", label: "PANORAMA" },
                    { value: "PARAGUAÇU PAULISTA", label: "PARAGUAÇU PAULISTA" },
                    { value: "PARAIBUNA", label: "PARAIBUNA" },
                    { value: "PARAÍSO", label: "PARAÍSO" },
                    { value: "PARANAPANEMA", label: "PARANAPANEMA" },
                    { value: "PARANAPUÃ", label: "PARANAPUÃ" },
                    { value: "PARAPUÃ", label: "PARAPUÃ" },
                    { value: "PARDINHO", label: "PARDINHO" },
                    { value: "PARIQUERA-AÇU", label: "PARIQUERA-AÇU" },
                    { value: "PARISI", label: "PARISI" },
                    { value: "PATROCÍNIO PAULISTA", label: "PATROCÍNIO PAULISTA" },
                    { value: "PAULICÉIA", label: "PAULICÉIA" },
                    { value: "PAULÍNIA", label: "PAULÍNIA" },
                    { value: "PAULISTÂNIA", label: "PAULISTÂNIA" },
                    { value: "PAULO DE FARIA", label: "PAULO DE FARIA" },
                    { value: "PEDERNEIRAS", label: "PEDERNEIRAS" },
                    { value: "PEDRA BELA", label: "PEDRA BELA" },
                    { value: "PEDRANÓPOLIS", label: "PEDRANÓPOLIS" },
                    { value: "PEDREGULHO", label: "PEDREGULHO" },
                    { value: "PEDREIRA", label: "PEDREIRA" },
                    { value: "PEDRINHAS PAULISTA", label: "PEDRINHAS PAULISTA" },
                    { value: "PEDRO DE TOLEDO", label: "PEDRO DE TOLEDO" },
                    { value: "PENÁPOLIS", label: "PENÁPOLIS" },
                    { value: "PEREIRA BARRETO", label: "PEREIRA BARRETO" },
                    { value: "PEREIRAS", label: "PEREIRAS" },
                    { value: "PERUÍBE", label: "PERUÍBE" },
                    { value: "PIACATU", label: "PIACATU" },
                    { value: "PIEDADE", label: "PIEDADE" },
                    { value: "PILAR DO SUL", label: "PILAR DO SUL" },
                    { value: "PINDAMONHANGABA", label: "PINDAMONHANGABA" },
                    { value: "PINDORAMA", label: "PINDORAMA" },
                    { value: "PINHALZINHO", label: "PINHALZINHO" },
                    { value: "PIQUEROBI", label: "PIQUEROBI" },
                    { value: "PIQUETE", label: "PIQUETE" },
                    { value: "PIRACAIA", label: "PIRACAIA" },
                    { value: "PIRACICABA", label: "PIRACICABA" },
                    { value: "PIRAJU", label: "PIRAJU" },
                    { value: "PIRAJUÍ", label: "PIRAJUÍ" },
                    { value: "PIRANGI", label: "PIRANGI" },
                    { value: "PIRAPORA DO BOM JESUS", label: "PIRAPORA DO BOM JESUS" },
                    { value: "PIRAPOZINHO", label: "PIRAPOZINHO" },
                    { value: "PIRASSUNUNGA", label: "PIRASSUNUNGA" },
                    { value: "PIRATININGA", label: "PIRATININGA" },
                    { value: "PITANGUEIRAS", label: "PITANGUEIRAS" },
                    { value: "PLANALTO", label: "PLANALTO" },
                    { value: "PLATINA", label: "PLATINA" },
                    { value: "POÁ", label: "POÁ" },
                    { value: "POLONI", label: "POLONI" },
                    { value: "POMPÉIA", label: "POMPÉIA" },
                    { value: "PONGAÍ", label: "PONGAÍ" },
                    { value: "PONTAL", label: "PONTAL" },
                    { value: "PONTALINDA", label: "PONTALINDA" },
                    { value: "PONTES GESTAL", label: "PONTES GESTAL" },
                    { value: "POPULINA", label: "POPULINA" },
                    { value: "PORANGABA", label: "PORANGABA" },
                    { value: "PORTO FELIZ", label: "PORTO FELIZ" },
                    { value: "PORTO FERREIRA", label: "PORTO FERREIRA" },
                    { value: "POTIM", label: "POTIM" },
                    { value: "POTIRENDABA", label: "POTIRENDABA" },
                    { value: "PRACINHA", label: "PRACINHA" },
                    { value: "PRADÓPOLIS", label: "PRADÓPOLIS" },
                    { value: "PRAIA GRANDE", label: "PRAIA GRANDE" },
                    { value: "PRATÂNIA", label: "PRATÂNIA" },
                    { value: "PRESIDENTE ALVES", label: "PRESIDENTE ALVES" },
                    { value: "PRESIDENTE BERNARDES", label: "PRESIDENTE BERNARDES" },
                    { value: "PRESIDENTE EPITÁCIO", label: "PRESIDENTE EPITÁCIO" },
                    { value: "PRESIDENTE PRUDENTE", label: "PRESIDENTE PRUDENTE" },
                    { value: "PRESIDENTE VENCESLAU", label: "PRESIDENTE VENCESLAU" },
                    { value: "PROMISSÃO", label: "PROMISSÃO" },
                    { value: "QUADRA", label: "QUADRA" },
                    { value: "QUATÁ", label: "QUATÁ" },
                    { value: "QUEIROZ", label: "QUEIROZ" },
                    { value: "QUELUZ", label: "QUELUZ" },
                    { value: "QUINTANA", label: "QUINTANA" },
                    { value: "RAFARD", label: "RAFARD" },
                    { value: "RANCHARIA", label: "RANCHARIA" },
                    { value: "REDENÇÃO DA SERRA", label: "REDENÇÃO DA SERRA" },
                    { value: "REGENTE FEIJÓ", label: "REGENTE FEIJÓ" },
                    { value: "REGINÓPOLIS", label: "REGINÓPOLIS" },
                    { value: "REGISTRO", label: "REGISTRO" },
                    { value: "RESTINGA", label: "RESTINGA" },
                    { value: "RIBEIRA", label: "RIBEIRA" },
                    { value: "RIBEIRÃO BONITO", label: "RIBEIRÃO BONITO" },
                    { value: "RIBEIRÃO BRANCO", label: "RIBEIRÃO BRANCO" },
                    { value: "RIBEIRÃO CORRENTE", label: "RIBEIRÃO CORRENTE" },
                    { value: "RIBEIRÃO DO SUL", label: "RIBEIRÃO DO SUL" },
                    { value: "RIBEIRÃO DOS ÍNDIOS", label: "RIBEIRÃO DOS ÍNDIOS" },
                    { value: "RIBEIRÃO GRANDE", label: "RIBEIRÃO GRANDE" },
                    { value: "RIBEIRÃO PIRES", label: "RIBEIRÃO PIRES" },
                    { value: "RIBEIRÃO PRETO", label: "RIBEIRÃO PRETO" },
                    { value: "RIFAINA", label: "RIFAINA" },
                    { value: "RINCÃO", label: "RINCÃO" },
                    { value: "RINÓPOLIS", label: "RINÓPOLIS" },
                    { value: "RIO CLARO", label: "RIO CLARO" },
                    { value: "RIO DAS PEDRAS", label: "RIO DAS PEDRAS" },
                    { value: "RIO GRANDE DA SERRA", label: "RIO GRANDE DA SERRA" },
                    { value: "RIOLÂNDIA", label: "RIOLÂNDIA" },
                    { value: "RIVERSUL", label: "RIVERSUL" },
                    { value: "ROSANA", label: "ROSANA" },
                    { value: "ROSEIRA", label: "ROSEIRA" },
                    { value: "RUBIÁCEA", label: "RUBIÁCEA" },
                    { value: "RUBINÉIA", label: "RUBINÉIA" },
                    { value: "SABINO", label: "SABINO" },
                    { value: "SAGRES", label: "SAGRES" },
                    { value: "SALES", label: "SALES" },
                    { value: "SALES OLIVEIRA", label: "SALES OLIVEIRA" },
                    { value: "SALESÓPOLIS", label: "SALESÓPOLIS" },
                    { value: "SALMOURÃO", label: "SALMOURÃO" },
                    { value: "SALTINHO", label: "SALTINHO" },
                    { value: "SALTO", label: "SALTO" },
                    { value: "SALTO DE PIRAPORA", label: "SALTO DE PIRAPORA" },
                    { value: "SALTO GRANDE", label: "SALTO GRANDE" },
                    { value: "SANDOVALINA", label: "SANDOVALINA" },
                    { value: "SANTA ADÉLIA", label: "SANTA ADÉLIA" },
                    { value: "SANTA ALBERTINA", label: "SANTA ALBERTINA" },
                    { value: "SANTA BÁRBARA D'OESTE", label: "SANTA BÁRBARA D'OESTE" },
                    { value: "SANTA BRANCA", label: "SANTA BRANCA" },
                    { value: "SANTA CLARA D'OESTE", label: "SANTA CLARA D'OESTE" },
                    { value: "SANTA CRUZ DA CONCEIÇÃO", label: "SANTA CRUZ DA CONCEIÇÃO" },
                    { value: "SANTA CRUZ DA ESPERANÇA", label: "SANTA CRUZ DA ESPERANÇA" },
                    { value: "SANTA CRUZ DAS PALMEIRAS", label: "SANTA CRUZ DAS PALMEIRAS" },
                    { value: "SANTA CRUZ DO RIO PARDO", label: "SANTA CRUZ DO RIO PARDO" },
                    { value: "SANTA ERNESTINA", label: "SANTA ERNESTINA" },
                    { value: "SANTA FÉ DO SUL", label: "SANTA FÉ DO SUL" },
                    { value: "SANTA GERTRUDES", label: "SANTA GERTRUDES" },
                    { value: "SANTA ISABEL", label: "SANTA ISABEL" },
                    { value: "SANTA LÚCIA", label: "SANTA LÚCIA" },
                    { value: "SANTA MARIA DA SERRA", label: "SANTA MARIA DA SERRA" },
                    { value: "SANTA MERCEDES", label: "SANTA MERCEDES" },
                    { value: "SANTA RITA D'OESTE", label: "SANTA RITA D'OESTE" },
                    { value: "SANTA RITA DO PASSA QUATRO", label: "SANTA RITA DO PASSA QUATRO" },
                    { value: "SANTA ROSA DE VITERBO", label: "SANTA ROSA DE VITERBO" },
                    { value: "SANTA SALETE", label: "SANTA SALETE" },
                    { value: "SANTANA DA PONTE PENSA", label: "SANTANA DA PONTE PENSA" },
                    { value: "SANTANA DE PARNAÍBA", label: "SANTANA DE PARNAÍBA" },
                    { value: "SANTO ANASTÁCIO", label: "SANTO ANASTÁCIO" },
                    { value: "SANTO ANDRÉ", label: "SANTO ANDRÉ" },
                    { value: "SANTO ANTÔNIO DA ALEGRIA", label: "SANTO ANTÔNIO DA ALEGRIA" },
                    { value: "SANTO ANTÔNIO DE POSSE", label: "SANTO ANTÔNIO DE POSSE" },
                    { value: "SANTO ANTÔNIO DO ARACANGUÁ", label: "SANTO ANTÔNIO DO ARACANGUÁ" },
                    { value: "SANTO ANTÔNIO DO JARDIM", label: "SANTO ANTÔNIO DO JARDIM" },
                    { value: "SANTO ANTÔNIO DO PINHAL", label: "SANTO ANTÔNIO DO PINHAL" },
                    { value: "SANTO EXPEDITO", label: "SANTO EXPEDITO" },
                    { value: "SANTÓPOLIS DO AGUAPEÍ", label: "SANTÓPOLIS DO AGUAPEÍ" },
                    { value: "SANTOS", label: "SANTOS" },
                    { value: "SÃO BENTO DO SAPUCAÍ", label: "SÃO BENTO DO SAPUCAÍ" },
                    { value: "SÃO BERNARDO DO CAMPO", label: "SÃO BERNARDO DO CAMPO" },
                    { value: "SÃO CAETANO DO SUL", label: "SÃO CAETANO DO SUL" },
                    { value: "SÃO CARLOS", label: "SÃO CARLOS" },
                    { value: "SÃO FRANCISCO", label: "SÃO FRANCISCO" },
                    { value: "SÃO JOÃO DA BOA VISTA", label: "SÃO JOÃO DA BOA VISTA" },
                    { value: "SÃO JOÃO DAS DUAS PONTES", label: "SÃO JOÃO DAS DUAS PONTES" },
                    { value: "SÃO JOÃO DE IRACEMA", label: "SÃO JOÃO DE IRACEMA" },
                    { value: "SÃO JOÃO DO PAU D'ALHO", label: "SÃO JOÃO DO PAU D'ALHO" },
                    { value: "SÃO JOAQUIM DA BARRA", label: "SÃO JOAQUIM DA BARRA" },
                    { value: "SÃO JOSÉ DA BELA VISTA", label: "SÃO JOSÉ DA BELA VISTA" },
                    { value: "SÃO JOSÉ DO BARREIRO", label: "SÃO JOSÉ DO BARREIRO" },
                    { value: "SÃO JOSÉ DO RIO PARDO", label: "SÃO JOSÉ DO RIO PARDO" },
                    { value: "SÃO JOSÉ DO RIO PRETO", label: "SÃO JOSÉ DO RIO PRETO" },
                    { value: "SÃO JOSÉ DOS CAMPOS", label: "SÃO JOSÉ DOS CAMPOS" },
                    { value: "SÃO LOURENÇO DA SERRA", label: "SÃO LOURENÇO DA SERRA" },
                    { value: "SÃO LUÍS DO PARAITINGA", label: "SÃO LUÍS DO PARAITINGA" },
                    { value: "SÃO MANUEL", label: "SÃO MANUEL" },
                    { value: "SÃO MIGUEL ARCANJO", label: "SÃO MIGUEL ARCANJO" },
                    { value: "SÃO PAULO", label: "SÃO PAULO" },
                    { value: "SÃO PEDRO", label: "SÃO PEDRO" },
                    { value: "SÃO PEDRO DO TURVO", label: "SÃO PEDRO DO TURVO" },
                    { value: "SÃO ROQUE", label: "SÃO ROQUE" },
                    { value: "SÃO SEBASTIÃO", label: "SÃO SEBASTIÃO" },
                    { value: "SÃO SEBASTIÃO DA GRAMA", label: "SÃO SEBASTIÃO DA GRAMA" },
                    { value: "SÃO SIMÃO", label: "SÃO SIMÃO" },
                    { value: "SÃO VICENTE", label: "SÃO VICENTE" },
                    { value: "SARAPUÍ", label: "SARAPUÍ" },
                    { value: "SARUTAIÁ", label: "SARUTAIÁ" },
                    { value: "SEBASTIANÓPOLIS DO SUL", label: "SEBASTIANÓPOLIS DO SUL" },
                    { value: "SERRA AZUL", label: "SERRA AZUL" },
                    { value: "SERRA NEGRA", label: "SERRA NEGRA" },
                    { value: "SERRANA", label: "SERRANA" },
                    { value: "SERTÃOZINHO", label: "SERTÃOZINHO" },
                    { value: "SETE BARRAS", label: "SETE BARRAS" },
                    { value: "SEVERÍNIA", label: "SEVERÍNIA" },
                    { value: "SILVEIRAS", label: "SILVEIRAS" },
                    { value: "SOCORRO", label: "SOCORRO" },
                    { value: "SOROCABA", label: "SOROCABA" },
                    { value: "SUD MENNUCCI", label: "SUD MENNUCCI" },
                    { value: "SUMARÉ", label: "SUMARÉ" },
                    { value: "SUZANÁPOLIS", label: "SUZANÁPOLIS" },
                    { value: "SUZANO", label: "SUZANO" },
                    { value: "TABAPUÃ", label: "TABAPUÃ" },
                    { value: "TABATINGA", label: "TABATINGA" },
                    { value: "TABOÃO DA SERRA", label: "TABOÃO DA SERRA" },
                    { value: "TACIBA", label: "TACIBA" },
                    { value: "TAGUAÍ", label: "TAGUAÍ" },
                    { value: "TAIAÇU", label: "TAIAÇU" },
                    { value: "TAIÚVA", label: "TAIÚVA" },
                    { value: "TAMBAÚ", label: "TAMBAÚ" },
                    { value: "TANABI", label: "TANABI" },
                    { value: "TAPIRAÍ", label: "TAPIRAÍ" },
                    { value: "TAPIRATIBA", label: "TAPIRATIBA" },
                    { value: "TAQUARAL", label: "TAQUARAL" },
                    { value: "TAQUARITINGA", label: "TAQUARITINGA" },
                    { value: "TAQUARITUBA", label: "TAQUARITUBA" },
                    { value: "TAQUARIVAÍ", label: "TAQUARIVAÍ" },
                    { value: "TARABAI", label: "TARABAI" },
                    { value: "TARUMÃ", label: "TARUMÃ" },
                    { value: "TATUÍ", label: "TATUÍ" },
                    { value: "TAUBATÉ", label: "TAUBATÉ" },
                    { value: "TEJUPÁ", label: "TEJUPÁ" },
                    { value: "TEODORO SAMPAIO", label: "TEODORO SAMPAIO" },
                    { value: "TERRA ROXA", label: "TERRA ROXA" },
                    { value: "TIETÊ", label: "TIETÊ" },
                    { value: "TIMBURI", label: "TIMBURI" },
                    { value: "TORRE DE PEDRA", label: "TORRE DE PEDRA" },
                    { value: "TORRINHA", label: "TORRINHA" },
                    { value: "TRABIJU", label: "TRABIJU" },
                    { value: "TREMEMBÉ", label: "TREMEMBÉ" },
                    { value: "TRÊS FRONTEIRAS", label: "TRÊS FRONTEIRAS" },
                    { value: "TUIUTI", label: "TUIUTI" },
                    { value: "TUPÃ", label: "TUPÃ" },
                    { value: "TUPI PAULISTA", label: "TUPI PAULISTA" },
                    { value: "TURIÚBA", label: "TURIÚBA" },
                    { value: "TURMALINA", label: "TURMALINA" },
                    { value: "UBARANA", label: "UBARANA" },
                    { value: "UBATUBA", label: "UBATUBA" },
                    { value: "UBIRAJARA", label: "UBIRAJARA" },
                    { value: "UCHOA", label: "UCHOA" },
                    { value: "UNIÃO PAULISTA", label: "UNIÃO PAULISTA" },
                    { value: "URÂNIA", label: "URÂNIA" },
                    { value: "URU", label: "URU" },
                    { value: "URUPÊS", label: "URUPÊS" },
                    { value: "VALENTIM GENTIL", label: "VALENTIM GENTIL" },
                    { value: "VALINHOS", label: "VALINHOS" },
                    { value: "VALPARAÍSO", label: "VALPARAÍSO" },
                    { value: "VARGEM", label: "VARGEM" },
                    { value: "VARGEM GRANDE DO SUL", label: "VARGEM GRANDE DO SUL" },
                    { value: "VARGEM GRANDE PAULISTA", label: "VARGEM GRANDE PAULISTA" },
                    { value: "VÁRZEA PAULISTA", label: "VÁRZEA PAULISTA" },
                    { value: "VERA CRUZ", label: "VERA CRUZ" },
                    { value: "VINHEDO", label: "VINHEDO" },
                    { value: "VIRADOURO", label: "VIRADOURO" },
                    { value: "VISTA ALEGRE DO ALTO", label: "VISTA ALEGRE DO ALTO" },
                    { value: "VITÓRIA BRASIL", label: "VITÓRIA BRASIL" },
                    { value: "VOTORANTIM", label: "VOTORANTIM" },
                    { value: "VOTUPORANGA", label: "VOTUPORANGA" },
                    { value: "ZACARIAS", label: "ZACARIAS" },
               ]
               break;

          case "SERGIPE":
               CITIES = [
                    { value: "AMPARO DE SÃO FRANCISCO", label: "AMPARO DE SÃO FRANCISCO" },
                    { value: "AQUIDABÃ", label: "AQUIDABÃ" },
                    { value: "ARACAJU", label: "ARACAJU" },
                    { value: "ARAUÁ", label: "ARAUÁ" },
                    { value: "AREIA BRANCA", label: "AREIA BRANCA" },
                    { value: "BARRA DOS COQUEIROS", label: "BARRA DOS COQUEIROS" },
                    { value: "BOQUIM", label: "BOQUIM" },
                    { value: "BREJO GRANDE", label: "BREJO GRANDE" },
                    { value: "CAMPO DO BRITO", label: "CAMPO DO BRITO" },
                    { value: "CANHOBA", label: "CANHOBA" },
                    { value: "CANINDÉ DE SÃO FRANCISCO", label: "CANINDÉ DE SÃO FRANCISCO" },
                    { value: "CAPELA", label: "CAPELA" },
                    { value: "CARIRA", label: "CARIRA" },
                    { value: "CARMÓPOLIS", label: "CARMÓPOLIS" },
                    { value: "CEDRO DE SÃO JOÃO", label: "CEDRO DE SÃO JOÃO" },
                    { value: "CRISTINÁPOLIS", label: "CRISTINÁPOLIS" },
                    { value: "CUMBE", label: "CUMBE" },
                    { value: "DIVINA PASTORA", label: "DIVINA PASTORA" },
                    { value: "ESTÂNCIA", label: "ESTÂNCIA" },
                    { value: "FEIRA NOVA", label: "FEIRA NOVA" },
                    { value: "FREI PAULO", label: "FREI PAULO" },
                    { value: "GARARU", label: "GARARU" },
                    { value: "GENERAL MAYNARD", label: "GENERAL MAYNARD" },
                    { value: "GRACHO CARDOSO", label: "GRACHO CARDOSO" },
                    { value: "ILHA DAS FLORES", label: "ILHA DAS FLORES" },
                    { value: "INDIAROBA", label: "INDIAROBA" },
                    { value: "ITABAIANA", label: "ITABAIANA" },
                    { value: "ITABAIANINHA", label: "ITABAIANINHA" },
                    { value: "ITABI", label: "ITABI" },
                    { value: "ITAPORANGA D'AJUDA", label: "ITAPORANGA D'AJUDA" },
                    { value: "JAPARATUBA", label: "JAPARATUBA" },
                    { value: "JAPOATÃ", label: "JAPOATÃ" },
                    { value: "LAGARTO", label: "LAGARTO" },
                    { value: "LARANJEIRAS", label: "LARANJEIRAS" },
                    { value: "MACAMBIRA", label: "MACAMBIRA" },
                    { value: "MALHADA DOS BOIS", label: "MALHADA DOS BOIS" },
                    { value: "MALHADOR", label: "MALHADOR" },
                    { value: "MARUIM", label: "MARUIM" },
                    { value: "MOITA BONITA", label: "MOITA BONITA" },
                    { value: "MONTE ALEGRE DE SERGIPE", label: "MONTE ALEGRE DE SERGIPE" },
                    { value: "MURIBECA", label: "MURIBECA" },
                    { value: "NEÓPOLIS", label: "NEÓPOLIS" },
                    { value: "NOSSA SENHORA APARECIDA", label: "NOSSA SENHORA APARECIDA" },
                    { value: "NOSSA SENHORA DA GLÓRIA", label: "NOSSA SENHORA DA GLÓRIA" },
                    { value: "NOSSA SENHORA DAS DORES", label: "NOSSA SENHORA DAS DORES" },
                    { value: "NOSSA SENHORA DE LOURDES", label: "NOSSA SENHORA DE LOURDES" },
                    { value: "NOSSA SENHORA DO SOCORRO", label: "NOSSA SENHORA DO SOCORRO" },
                    { value: "PACATUBA", label: "PACATUBA" },
                    { value: "PEDRA MOLE", label: "PEDRA MOLE" },
                    { value: "PEDRINHAS", label: "PEDRINHAS" },
                    { value: "PINHÃO", label: "PINHÃO" },
                    { value: "PIRAMBU", label: "PIRAMBU" },
                    { value: "POÇO REDONDO", label: "POÇO REDONDO" },
                    { value: "POÇO VERDE", label: "POÇO VERDE" },
                    { value: "PORTO DA FOLHA", label: "PORTO DA FOLHA" },
                    { value: "PROPRIÁ", label: "PROPRIÁ" },
                    { value: "RIACHÃO DO DANTAS", label: "RIACHÃO DO DANTAS" },
                    { value: "RIACHUELO", label: "RIACHUELO" },
                    { value: "RIBEIRÓPOLIS", label: "RIBEIRÓPOLIS" },
                    { value: "ROSÁRIO DO CATETE", label: "ROSÁRIO DO CATETE" },
                    { value: "SALGADO", label: "SALGADO" },
                    { value: "SANTA LUZIA DO ITANHY", label: "SANTA LUZIA DO ITANHY" },
                    { value: "SANTA ROSA DE LIMA", label: "SANTA ROSA DE LIMA" },
                    { value: "SANTANA DO SÃO FRANCISCO", label: "SANTANA DO SÃO FRANCISCO" },
                    { value: "SANTO AMARO DAS BROTAS", label: "SANTO AMARO DAS BROTAS" },
                    { value: "SÃO CRISTÓVÃO", label: "SÃO CRISTÓVÃO" },
                    { value: "SÃO DOMINGOS", label: "SÃO DOMINGOS" },
                    { value: "SÃO FRANCISCO", label: "SÃO FRANCISCO" },
                    { value: "SÃO MIGUEL DO ALEIXO", label: "SÃO MIGUEL DO ALEIXO" },
                    { value: "SIMÃO DIAS", label: "SIMÃO DIAS" },
                    { value: "SIRIRI", label: "SIRIRI" },
                    { value: "TELHA", label: "TELHA" },
                    { value: "TOBIAS BARRETO", label: "TOBIAS BARRETO" },
                    { value: "TOMAR DO GERU", label: "TOMAR DO GERU" },
                    { value: "UMBAÚBA", label: "UMBAÚBA" },
               ]
               break;

          case "TOCANTINS":
               CITIES = [
                    { value: "ABREULÂNDIA", label: "ABREULÂNDIA" },
                    { value: "AGUIARNÓPOLIS", label: "AGUIARNÓPOLIS" },
                    { value: "ALIANÇA DO TOCANTINS", label: "ALIANÇA DO TOCANTINS" },
                    { value: "ALMAS", label: "ALMAS" },
                    { value: "ALVORADA", label: "ALVORADA" },
                    { value: "ANANÁS", label: "ANANÁS" },
                    { value: "ANGICO", label: "ANGICO" },
                    { value: "APARECIDA DO RIO NEGRO", label: "APARECIDA DO RIO NEGRO" },
                    { value: "ARAGOMINAS", label: "ARAGOMINAS" },
                    { value: "ARAGUACEMA", label: "ARAGUACEMA" },
                    { value: "ARAGUAÇU", label: "ARAGUAÇU" },
                    { value: "ARAGUAÍNA", label: "ARAGUAÍNA" },
                    { value: "ARAGUANÃ", label: "ARAGUANÃ" },
                    { value: "ARAGUATINS", label: "ARAGUATINS" },
                    { value: "ARAPOEMA", label: "ARAPOEMA" },
                    { value: "ARRAIAS", label: "ARRAIAS" },
                    { value: "AUGUSTINÓPOLIS", label: "AUGUSTINÓPOLIS" },
                    { value: "AURORA DO TOCANTINS", label: "AURORA DO TOCANTINS" },
                    { value: "AXIXÁ DO TOCANTINS", label: "AXIXÁ DO TOCANTINS" },
                    { value: "BABAÇULÂNDIA", label: "BABAÇULÂNDIA" },
                    { value: "BANDEIRANTES DO TOCANTINS", label: "BANDEIRANTES DO TOCANTINS" },
                    { value: "BARRA DO OURO", label: "BARRA DO OURO" },
                    { value: "BARROLÂNDIA", label: "BARROLÂNDIA" },
                    { value: "BERNARDO SAYÃO", label: "BERNARDO SAYÃO" },
                    { value: "BOM JESUS DO TOCANTINS", label: "BOM JESUS DO TOCANTINS" },
                    { value: "BRASILÂNDIA DO TOCANTINS", label: "BRASILÂNDIA DO TOCANTINS" },
                    { value: "BREJINHO DE NAZARÉ", label: "BREJINHO DE NAZARÉ" },
                    { value: "BURITI DO TOCANTINS", label: "BURITI DO TOCANTINS" },
                    { value: "CACHOEIRINHA", label: "CACHOEIRINHA" },
                    { value: "CAMPOS LINDOS", label: "CAMPOS LINDOS" },
                    { value: "CARIRI DO TOCANTINS", label: "CARIRI DO TOCANTINS" },
                    { value: "CARMOLÂNDIA", label: "CARMOLÂNDIA" },
                    { value: "CARRASCO BONITO", label: "CARRASCO BONITO" },
                    { value: "CASEARA", label: "CASEARA" },
                    { value: "CENTENÁRIO", label: "CENTENÁRIO" },
                    { value: "CHAPADA DA NATIVIDADE", label: "CHAPADA DA NATIVIDADE" },
                    { value: "CHAPADA DE AREIA", label: "CHAPADA DE AREIA" },
                    { value: "COLINAS DO TOCANTINS", label: "COLINAS DO TOCANTINS" },
                    { value: "COLMÉIA", label: "COLMÉIA" },
                    { value: "COMBINADO", label: "COMBINADO" },
                    { value: "CONCEIÇÃO DO TOCANTINS", label: "CONCEIÇÃO DO TOCANTINS" },
                    { value: "COUTO MAGALHÃES", label: "COUTO MAGALHÃES" },
                    { value: "CRISTALÂNDIA", label: "CRISTALÂNDIA" },
                    { value: "CRIXÁS DO TOCANTINS", label: "CRIXÁS DO TOCANTINS" },
                    { value: "DARCINÓPOLIS", label: "DARCINÓPOLIS" },
                    { value: "DIANÓPOLIS", label: "DIANÓPOLIS" },
                    { value: "DIVINÓPOLIS DO TOCANTINS", label: "DIVINÓPOLIS DO TOCANTINS" },
                    { value: "DOIS IRMÃOS DO TOCANTINS", label: "DOIS IRMÃOS DO TOCANTINS" },
                    { value: "DUERÉ", label: "DUERÉ" },
                    { value: "ESPERANTINA", label: "ESPERANTINA" },
                    { value: "FÁTIMA", label: "FÁTIMA" },
                    { value: "FIGUEIRÓPOLIS", label: "FIGUEIRÓPOLIS" },
                    { value: "FILADÉLFIA", label: "FILADÉLFIA" },
                    { value: "FORMOSO DO ARAGUAIA", label: "FORMOSO DO ARAGUAIA" },
                    { value: "FORTALEZA DO TABOCÃO", label: "FORTALEZA DO TABOCÃO" },
                    { value: "GOIANORTE", label: "GOIANORTE" },
                    { value: "GOIATINS", label: "GOIATINS" },
                    { value: "GUARAÍ", label: "GUARAÍ" },
                    { value: "GURUPI", label: "GURUPI" },
                    { value: "IPUEIRAS", label: "IPUEIRAS" },
                    { value: "ITACAJÁ", label: "ITACAJÁ" },
                    { value: "ITAGUATINS", label: "ITAGUATINS" },
                    { value: "ITAPIRATINS", label: "ITAPIRATINS" },
                    { value: "ITAPORÃ DO TOCANTINS", label: "ITAPORÃ DO TOCANTINS" },
                    { value: "JAÚ DO TOCANTINS", label: "JAÚ DO TOCANTINS" },
                    { value: "JUARINA", label: "JUARINA" },
                    { value: "LAGOA DA CONFUSÃO", label: "LAGOA DA CONFUSÃO" },
                    { value: "LAGOA DO TOCANTINS", label: "LAGOA DO TOCANTINS" },
                    { value: "LAJEADO", label: "LAJEADO" },
                    { value: "LAVANDEIRA", label: "LAVANDEIRA" },
                    { value: "LIZARDA", label: "LIZARDA" },
                    { value: "LUZINÓPOLIS", label: "LUZINÓPOLIS" },
                    { value: "MARIANÓPOLIS DO TOCANTINS", label: "MARIANÓPOLIS DO TOCANTINS" },
                    { value: "MATEIROS", label: "MATEIROS" },
                    { value: "MAURILÂNDIA DO TOCANTINS", label: "MAURILÂNDIA DO TOCANTINS" },
                    { value: "MIRACEMA DO TOCANTINS", label: "MIRACEMA DO TOCANTINS" },
                    { value: "MIRANORTE", label: "MIRANORTE" },
                    { value: "MONTE DO CARMO", label: "MONTE DO CARMO" },
                    { value: "MONTE SANTO DO TOCANTINS", label: "MONTE SANTO DO TOCANTINS" },
                    { value: "MURICILÂNDIA", label: "MURICILÂNDIA" },
                    { value: "NATIVIDADE", label: "NATIVIDADE" },
                    { value: "NAZARÉ", label: "NAZARÉ" },
                    { value: "NOVA OLINDA", label: "NOVA OLINDA" },
                    { value: "NOVA ROSALÂNDIA", label: "NOVA ROSALÂNDIA" },
                    { value: "NOVO ACORDO", label: "NOVO ACORDO" },
                    { value: "NOVO ALEGRE", label: "NOVO ALEGRE" },
                    { value: "NOVO JARDIM", label: "NOVO JARDIM" },
                    { value: "OLIVEIRA DE FÁTIMA", label: "OLIVEIRA DE FÁTIMA" },
                    { value: "PALMAS", label: "PALMAS" },
                    { value: "PALMEIRANTE", label: "PALMEIRANTE" },
                    { value: "PALMEIRAS DO TOCANTINS", label: "PALMEIRAS DO TOCANTINS" },
                    { value: "PALMEIRÓPOLIS", label: "PALMEIRÓPOLIS" },
                    { value: "PARAÍSO DO TOCANTINS", label: "PARAÍSO DO TOCANTINS" },
                    { value: "PARANÃ", label: "PARANÃ" },
                    { value: "PAU D'ARCO", label: "PAU D'ARCO" },
                    { value: "PEDRO AFONSO", label: "PEDRO AFONSO" },
                    { value: "PEIXE", label: "PEIXE" },
                    { value: "PEQUIZEIRO", label: "PEQUIZEIRO" },
                    { value: "PINDORAMA DO TOCANTINS", label: "PINDORAMA DO TOCANTINS" },
                    { value: "PIRAQUÊ", label: "PIRAQUÊ" },
                    { value: "PIUM", label: "PIUM" },
                    { value: "PONTE ALTA DO BOM JESUS", label: "PONTE ALTA DO BOM JESUS" },
                    { value: "PONTE ALTA DO TOCANTINS", label: "PONTE ALTA DO TOCANTINS" },
                    { value: "PORTO ALEGRE DO TOCANTINS", label: "PORTO ALEGRE DO TOCANTINS" },
                    { value: "PORTO NACIONAL", label: "PORTO NACIONAL" },
                    { value: "PRAIA NORTE", label: "PRAIA NORTE" },
                    { value: "PRESIDENTE KENNEDY", label: "PRESIDENTE KENNEDY" },
                    { value: "PUGMIL", label: "PUGMIL" },
                    { value: "RECURSOLÂNDIA", label: "RECURSOLÂNDIA" },
                    { value: "RIACHINHO", label: "RIACHINHO" },
                    { value: "RIO DA CONCEIÇÃO", label: "RIO DA CONCEIÇÃO" },
                    { value: "RIO DOS BOIS", label: "RIO DOS BOIS" },
                    { value: "RIO SONO", label: "RIO SONO" },
                    { value: "SAMPAIO", label: "SAMPAIO" },
                    { value: "SANDOLÂNDIA", label: "SANDOLÂNDIA" },
                    { value: "SANTA FÉ DO ARAGUAIA", label: "SANTA FÉ DO ARAGUAIA" },
                    { value: "SANTA MARIA DO TOCANTINS", label: "SANTA MARIA DO TOCANTINS" },
                    { value: "SANTA RITA DO TOCANTINS", label: "SANTA RITA DO TOCANTINS" },
                    { value: "SANTA ROSA DO TOCANTINS", label: "SANTA ROSA DO TOCANTINS" },
                    { value: "SANTA TEREZA DO TOCANTINS", label: "SANTA TEREZA DO TOCANTINS" },
                    { value: "SANTA TEREZINHA DO TOCANTINS", label: "SANTA TEREZINHA DO TOCANTINS" },
                    { value: "SÃO BENTO DO TOCANTINS", label: "SÃO BENTO DO TOCANTINS" },
                    { value: "SÃO FÉLIX DO TOCANTINS", label: "SÃO FÉLIX DO TOCANTINS" },
                    { value: "SÃO MIGUEL DO TOCANTINS", label: "SÃO MIGUEL DO TOCANTINS" },
                    { value: "SÃO SALVADOR DO TOCANTINS", label: "SÃO SALVADOR DO TOCANTINS" },
                    { value: "SÃO SEBASTIÃO DO TOCANTINS", label: "SÃO SEBASTIÃO DO TOCANTINS" },
                    { value: "SÃO VALÉRIO", label: "SÃO VALÉRIO" },
                    { value: "SILVANÓPOLIS", label: "SILVANÓPOLIS" },
                    { value: "SÍTIO NOVO DO TOCANTINS", label: "SÍTIO NOVO DO TOCANTINS" },
                    { value: "SUCUPIRA", label: "SUCUPIRA" },
                    { value: "TAGUATINGA", label: "TAGUATINGA" },
                    { value: "TAIPAS DO TOCANTINS", label: "TAIPAS DO TOCANTINS" },
                    { value: "TALISMÃ", label: "TALISMÃ" },
                    { value: "TOCANTÍNIA", label: "TOCANTÍNIA" },
                    { value: "TOCANTINÓPOLIS", label: "TOCANTINÓPOLIS" },
                    { value: "TUPIRAMA", label: "TUPIRAMA" },
                    { value: "TUPIRATINS", label: "TUPIRATINS" },
                    { value: "WANDERLÂNDIA", label: "WANDERLÂNDIA" },
                    { value: "XAMBIOÁ", label: "XAMBIOÁ" },
               ]
               break;
          default:
               CITIES = [

               ]
               break;
     }
     return CITIES
}
